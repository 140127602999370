import { JiraFilterAri } from '@atlassian/ari/jira/filter';
import type { generalSettingsPageQuery } from '@atlassian/jira-relay/src/__generated__/generalSettingsPageQuery.graphql';
import {
	type SharePermission,
	GROUP_SHARE,
	PROJECT_SHARE,
	PROJECT_UNKNOWN_SHARE,
	OPEN_SHARE,
	PUBLIC_SHARE,
	USER_SHARE,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import {
	ADMINISTER_PROJECT_PERMISSION_KEY,
	EDIT_BOARD_CONFIG,
	SPRINTS_FEATURE_KEY,
	USER_PICKER_PROJECT_PERMISSION_KEY,
	ADMINISTER_PARENT_PROJECT,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import type { BoardAdmins } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/board-admin.tsx';
import type {
	FilterConfig,
	QueryProjects,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/filter.tsx';
import type { GeneralConfigProps } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
import { convertToDefinedArray } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/utils.tsx';

type RelayQueryProjects =
	generalSettingsPageQuery['response']['boardScope']['savedFilterConfig']['queryProjects'];

const convertToQueryProject = (queryProjects: RelayQueryProjects): QueryProjects => {
	const {
		projectsCount,
		isMaxSupportShowingProjectsReached,
		projects,
		isProjectsUnboundedInFilter,
	} = queryProjects;

	if (projects) {
		const convertedProjects = convertToDefinedArray(projects).map((project) => ({
			...project,
			isValidEditProjectConfigAction: project.canEditProject,
		}));
		return { projects: convertedProjects };
	}

	return {
		projects: undefined,
		isProjectsUnboundedInFilter: !!isProjectsUnboundedInFilter,
		isMaxSupportShowingProjectsReached: !!isMaxSupportShowingProjectsReached,
		projectsCount: projectsCount ?? 0,
	};
};

type RelaySharePermissionEntry =
	generalSettingsPageQuery['response']['boardScope']['savedFilterConfig']['sharePermissionEntries'][number];

const convertToSharePermission = (
	permission: RelaySharePermissionEntry | null,
): SharePermission | null => {
	if (!permission) {
		return null;
	}

	const { type, user, role, project, group } = permission;

	if (type === OPEN_SHARE || type === PUBLIC_SHARE || type === PROJECT_UNKNOWN_SHARE) {
		return { type };
	}

	if (type === USER_SHARE && user) {
		return { type, user };
	}

	if (type === PROJECT_SHARE && project) {
		return {
			type,
			project: {
				...project,
				id: project.id.toString(),
			},
			role: role ?? undefined,
		};
	}

	if (type === GROUP_SHARE && group) {
		return {
			type,
			group: { name: group.name ?? undefined, groupId: group.id?.toString() ?? undefined },
		};
	}

	return null;
};

type RelayBoardAdmins = generalSettingsPageQuery['response']['boardScope']['boardAdmins'];

const convertBoardAdmins = (gqlBoardAdmins: RelayBoardAdmins): BoardAdmins => {
	const { userKeys, groupKeys } = gqlBoardAdmins;
	return {
		userKeys: convertToDefinedArray(userKeys),
		groupKeys: convertToDefinedArray(groupKeys),
	};
};

type BoardScope = generalSettingsPageQuery['response']['boardScope'];
type Jira = generalSettingsPageQuery['response']['jira'];

export const generalSettingsTransformer = (
	jira: Jira,
	boardScope: BoardScope,
	boardId: number,
	atlassianAccountId: string,
	isInUserContainer: boolean,
): GeneralConfigProps => {
	const {
		features,
		boardLocationModel,
		currentUser,
		savedFilterConfig,
		oldDoneIssuesCutOffConfig,
		subqueryConfig,
		board,
		boardAdmins,
	} = boardScope;

	const { getGlobalPermissionsAndGrants } = jira;

	const globalPermissionGrants = getGlobalPermissionsAndGrants.globalPermissionGrants;

	const oldDoneIssuesCutoff = oldDoneIssuesCutOffConfig.oldDoneIssuesCutoff;
	const oldDoneIssuesCutoffOptions = convertToDefinedArray(oldDoneIssuesCutOffConfig.options);

	const canEdit = currentUser.permissions.includes(EDIT_BOARD_CONFIG);
	const isProjectAdmin = currentUser.permissions.includes(ADMINISTER_PARENT_PROJECT);
	const canUseBoardAdminsPicker = currentUser.permissions.includes(
		USER_PICKER_PROJECT_PERMISSION_KEY,
	);

	const isLocatedInUserContainer = isInUserContainer;
	const isUserBoardAdmin = canEdit;
	const isJiraAdmin =
		!!globalPermissionGrants &&
		convertToDefinedArray(globalPermissionGrants).some(
			(permission) => permission.permission.key === ADMINISTER_PROJECT_PERMISSION_KEY,
		);
	const isJiraOrLocationAdmin = isProjectAdmin || isLocatedInUserContainer || isJiraAdmin;

	const warnBeforeEditingOwner = !isJiraOrLocationAdmin;
	const warnBeforeEditingLocation = !isUserBoardAdmin && !isJiraAdmin;

	const subqueries = convertToDefinedArray(subqueryConfig.subqueries);

	const name = board.name;

	const isSprintEnabled = features.some(
		(feature) => feature && feature.key === SPRINTS_FEATURE_KEY && feature.status === 'ENABLED',
	);

	const boardAdminsConverted = convertBoardAdmins(boardAdmins);

	const subqueryConfigConverted = {
		subqueries,
	};

	const boardLocationModelConverted = {
		avatarURI: boardLocationModel.avatarURI,
		name: boardLocationModel.name,
		projectId: boardLocationModel.projectId ?? undefined,
		projectTypeKey: boardLocationModel.projectTypeKey ?? undefined,
		userLocationId: boardLocationModel.userLocationId ?? undefined,
	};

	const userConfig = {
		accountId: atlassianAccountId,
		name: atlassianAccountId,
	};

	const filterConfig: FilterConfig = {
		id: Number(JiraFilterAri.parse(savedFilterConfig.id).filterId),
		canEdit: savedFilterConfig.canEdit,
		query: savedFilterConfig.query,
		name: savedFilterConfig.name,
		description: savedFilterConfig.description ?? '',
		editPermissionEntries: convertToDefinedArray(
			savedFilterConfig.editPermissionEntries.map(convertToSharePermission),
		),
		sharePermissionEntries: convertToDefinedArray(
			savedFilterConfig.sharePermissionEntries.map(convertToSharePermission),
		),
		owner: savedFilterConfig.owner
			? { ...savedFilterConfig.owner, avatarUrl: savedFilterConfig.owner.avatarUrl ?? '' }
			: undefined,
		isOrderedByRank: savedFilterConfig.isOrderedByRank,
		queryProjects: convertToQueryProject(savedFilterConfig.queryProjects),
	};

	return {
		canEdit,
		isCMPBoard: true,
		filterConfig,
		rapidViewId: boardId,
		oldDoneIssuesCutoff,
		oldDoneIssuesCutoffOptions,
		boardAdmins: boardAdminsConverted,
		userConfig,
		name,
		subqueryConfig: subqueryConfigConverted,
		isSprintSupportEnabled: isSprintEnabled,
		boardLocationModel: boardLocationModelConverted,
		canUseBoardAdminsPicker,
		warnBeforeEditingOwner,
		warnBeforeEditingLocation,
	};
};
