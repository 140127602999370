export const CardColorStrategy = {
	issueType: 'issuetype',
	assignee: 'assignee',
	priority: 'priority',
	custom: 'custom',
	none: 'none',
	requestType: 'request_type',
} as const;

export type CardColorStrategy = (typeof CardColorStrategy)[keyof typeof CardColorStrategy];

export type CardColor = {
	id: number;
	strategy: CardColorStrategy;
	color: string;
	value: string;
	displayValue: string;
	canEdit: boolean;
	isUsed: boolean;
	isGlobalColor: boolean;
};

export type CardColorsConfig = {
	rapidViewId: number;
	canEdit: boolean;
	cardColorStrategy: CardColorStrategy;
};
