import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createCardColorSuccess: {
		id: 'software-board-settings-card-colors.controllers.handlers.use-add-card-color-handler.create-card-color-success',
		defaultMessage: "You've created a card color",
		description:
			'Title message for the success flag which appears when creating a card color operation is successful',
	},
	validationErrorInstructionForCreatingCardColor: {
		id: 'software-board-settings-card-colors.controllers.handlers.use-add-card-color-handler.validation-error-instruction-for-creating-card-color',
		defaultMessage: 'Try again with a valid query.',
		description:
			'Instruction when failing to create a card color due to an invalid JQL query being specified.',
	},
});
