import type { ExperienceAttributes } from '@atlassian/jira-experience-tracker/src/common/types.tsx';
import { useExperienceTracker } from '@atlassian/jira-software-logging/src/services/experience-tracker/index.tsx';
import {
	APP_IDENTIFIER,
	WORKING_DAYS_ANALYTICS_SOURCE,
	WORKING_DAYS_SLA_EXPERIENCE,
} from '../../common/constants.tsx';

export const useWorkingDaysSettingsExperienceTracker = (attributes: ExperienceAttributes = {}) =>
	useExperienceTracker(
		APP_IDENTIFIER,
		WORKING_DAYS_SLA_EXPERIENCE,
		WORKING_DAYS_ANALYTICS_SOURCE,
		attributes,
	);
