import { initState } from './init/index.tsx';
import {
	addSwimlane,
	updateSwimlane,
	updateDefaultSwimlaneName,
	deleteSwimlane,
	setSwimlaneStrategy,
	beginSwimlaneRankUpdate,
	endSwimlaneRankUpdate,
	revertSwimlaneRankUpdate,
	setItemToEdit,
	setItemToDelete,
} from './swimlanes/index.tsx';
import type { Actions } from './types.tsx';

export const actions: Actions = {
	initState,
	addSwimlane,
	updateSwimlane,
	updateDefaultSwimlaneName,
	deleteSwimlane,
	setSwimlaneStrategy,
	beginSwimlaneRankUpdate,
	endSwimlaneRankUpdate,
	revertSwimlaneRankUpdate,
	setItemToEdit,
	setItemToDelete,
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Actions } from './types';
