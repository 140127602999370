import type { State } from '../types/index.tsx';

export const INITIAL_STATE: State = {
	isInitialised: false,
	rapidViewId: -1,
	canEdit: false,
	isSprintSupportEnabled: false,
	isBoardCrossProject: false,
	isChildIssuePlanningEnabled: false,
	prefersChildIssueDatePlanning: false,
	isRoadmapEnabled: false,
	projectName: '',
	boardQueryFilterId: 0,
	isCMPBoard: true,
};
