import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { emptyResponseCommitToBoolean } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/utils.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	DISABLE_TIMELINE_ACTION_SUBJECT,
	DISABLE_TIMELINE_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { TIMELINE_API_URI } from '../../common/constants/url.tsx';
import messages from './messages.tsx';

export const useDisableTimeline = (rapidViewId: number) => {
	const { formatMessage } = useIntl();

	const enableTimeline = useCallback(
		() => performPutRequest<null | undefined>(`${TIMELINE_API_URI}/${rapidViewId}/disableRoadmap`),
		[rapidViewId],
	);

	const { error, hasError, fetch, ...rest } = useServiceWithAnalytics(
		enableTimeline,
		{},
		{
			actionSubject: DISABLE_TIMELINE_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${DISABLE_TIMELINE_BROWSER_METRICS_KEY}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		error,
		errorTitle: formatMessage(messages.disableTimelineFailureTitle),
	});

	return { commit: emptyResponseCommitToBoolean(fetch), error, hasError, ...rest };
};
