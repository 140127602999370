import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	buttonLabel: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.card-layout-table.add-field.button-label',
		defaultMessage: 'Add field',
		description: 'Add field button text',
	},
	cancel: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.card-layout-table.add-field.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for a cancel button, which cancels the process of adding an item to a list',
	},
	save: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.card-layout-table.add-field.save',
		defaultMessage: 'Save',
		description: 'Label for a save button, which adds an item to a list',
	},
	selectFieldLabel: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.card-layout-table.add-field.select-field-label',
		defaultMessage: 'Field name',
		description:
			'This is the aria label for select field name list when adding a new card layout field.',
	},
	selectFieldPlaceholder: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.card-layout-table.add-field.select-field-placeholder',
		defaultMessage: 'Select field',
		description:
			'This is the placeholder for the select field list when adding a new card layout field.',
	},
});
