import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cancel: {
		id: 'software-board-settings-query-list-config-common.common.modal-form.cancel',
		defaultMessage: 'Cancel',
		description: 'Button text for cancelling the modal for adding a query item',
	},
	closeModal: {
		id: 'software-board-settings-query-list-config-common.common.modal-form.close-modal',
		defaultMessage: 'Close modal',
		description: 'Label text for the cross icon used to close the modal',
	},
});
