import React, { useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { Field } from '@atlaskit/form';
import Select, { type ValueType } from '@atlaskit/select';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { CardColorStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { CARD_COLOR_STRATEGY_FIELD_WIDTH } from '../../../common/constants/card-colors.tsx';
import { dataTestIds } from '../../../common/constants/data-test.tsx';
import messages from './messages.tsx';
import type { CardColorStrategyOption, CardColorStrategyFieldProps } from './types.tsx';

export const CardColorStrategyField = ({
	currentStrategy,
	isUpdatingStrategy,
	isDisabled,
	onChange,
}: CardColorStrategyFieldProps) => {
	const { formatMessage } = useIntl();

	const isJSMBoard = useIsJSMBoard();

	const cardColorStrategyOptions = useMemo(
		() => [
			...(!isJSMBoard
				? [
						{
							label: formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.issueTypesOptionLabelIssueTermRefresh
									: messages.issueTypesOptionLabel,
							),
							value: CardColorStrategy.issueType,
						},
					]
				: []),
			...(isJSMBoard
				? [
						{
							label: formatMessage(messages.requestTypeLabel),
							value: CardColorStrategy.requestType,
						},
					]
				: []),
			{
				label: formatMessage(messages.prioritiesOptionLabel),
				value: CardColorStrategy.priority,
			},
			{
				label: formatMessage(messages.assigneeOptionLabel),
				value: CardColorStrategy.assignee,
			},
			{ label: formatMessage(messages.queriesOptionLabel), value: CardColorStrategy.custom },
			{ label: formatMessage(messages.noneOptionLabel), value: CardColorStrategy.none },
		],
		[formatMessage, isJSMBoard],
	);

	const currentCardColorStrategy = useMemo(
		() => cardColorStrategyOptions.find(({ value }) => value === currentStrategy) ?? null,
		[cardColorStrategyOptions, currentStrategy],
	);

	const onSelect = useCallback(
		async (option: CardColorStrategyOption | null) => {
			option?.value && option.value !== currentStrategy && (await onChange(option.value));
		},
		[currentStrategy, onChange],
	);

	return (
		<Field<ValueType<CardColorStrategyOption>>
			id="cardColorStrategy"
			label={formatMessage(messages.fieldLabel)}
			name="cardColorStrategy"
			isDisabled={isDisabled}
			testId={dataTestIds.strategyFieldSelect}
		>
			{({ fieldProps: { id, ...otherFieldProps } }) => (
				<CardColorStrategyFieldWrapper data-testId={dataTestIds.strategyFieldSelect}>
					<Select
						options={cardColorStrategyOptions}
						inputId={id}
						{...otherFieldProps}
						value={currentCardColorStrategy}
						isLoading={isUpdatingStrategy}
						onChange={onSelect}
						name="cardColorStrategy"
					/>
				</CardColorStrategyFieldWrapper>
			)}
		</Field>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardColorStrategyFieldWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${CARD_COLOR_STRATEGY_FIELD_WIDTH}px`,
});
