import React from 'react';
import { styled } from '@compiled/react';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEdit } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/permissions/index.tsx';
import { InlineTextEditWithBorder } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/inline-edit-with-border/index.tsx';
import messages from './messages.tsx';
import { useBoardName } from './utils.tsx';

export const BoardNameField = () => {
	const { formatMessage } = useIntl();
	const { name, onUpdate } = useBoardName();
	const canEdit = useCanEdit();

	return (
		<Container data-testid="software-board-settings-general-settings.ui.board-name-field.board-name-field">
			<InlineTextEditWithBorder
				editButtonLabel={formatMessage(messages.editButtonAccessibleLabel, { name })}
				defaultValue={name}
				isRequired
				label={formatMessage(messages.label)}
				isDisabled={!canEdit}
				onConfirm={onUpdate}
			/>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'grid',
	gridTemplateColumns: '350px 1fr',
	gridAutoRow: 'auto',
});
