import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.delete-stale-card-color-modal.title',
		defaultMessage: 'Remove {cardColorName} card color',
		description: 'Title of modal for deleting a card color',
	},
	descriptionForAssignee: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.delete-stale-card-color-modal.description-for-assignee',
		defaultMessage:
			"This will permanently remove the card color connected to this assignee. This won't remove any issues from your project.",
		description: 'Description of modal for deleting a card color of an assignee.',
	},
	descriptionForIssueType: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.delete-stale-card-color-modal.description-for-issue-type',
		defaultMessage:
			"This will permanently remove the card color connected to this issue type. This won't remove any issues from your project.",
		description: 'Description of modal for deleting a card color of an issue type.',
	},
	descriptionForRequestType: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.delete-stale-card-color-modal.description-for-request-type',
		defaultMessage:
			"This removes the color from any cards connected to this request type, but won't remove any issues from your project.",
		description: 'Description of modal for deleting a card color of a request type.',
	},
	removeButton: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.delete-stale-card-color-modal.remove-button',
		defaultMessage: 'Remove',
		description: 'Remove button label',
	},
	descriptionForIssueTypeIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.delete-stale-card-color-modal.description-for-issue-type-issue-term-refresh',
		defaultMessage:
			"This will permanently remove the card color connected to this work type. This won't remove any work items from your project.",
		description: 'Description of modal for deleting a card color of an issue type.',
	},
	descriptionForRequestTypeIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.delete-stale-card-color-modal.description-for-request-type-issue-term-refresh',
		defaultMessage:
			"This removes the color from any cards connected to this request type, but won't remove any work items from your project.",
		description: 'Description of modal for deleting a card color of a request type.',
	},
	descriptionForAssigneeIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.delete-stale-card-color-modal.description-for-assignee-issue-term-refresh',
		defaultMessage:
			"This will permanently remove the card color connected to this assignee. This won't remove any work items from your project.",
		description: 'Description of modal for deleting a card color of an assignee.',
	},
});
