import React, { useCallback } from 'react';
import { ErrorMessage, Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../../constants/data-test.tsx';
import messages from './messages.tsx';

const MAX_NAME_LENGTH = 255;

type NameFieldProps = {
	name: string;
	defaultValue?: string;
};

export const NameField = (props: NameFieldProps) => {
	const { formatMessage } = useIntl();
	const validate = useCallback(
		(value: string | undefined) => {
			const santisedValue = (value ?? '').trim();
			if (!santisedValue || santisedValue.length > MAX_NAME_LENGTH) {
				return formatMessage(messages.nameLengthValidation);
			}
			return undefined;
		},
		[formatMessage],
	);

	return (
		<Field
			{...props}
			validate={validate}
			label={formatMessage(messages.name)}
			isRequired
			testId={dataTestIds.modalNameField}
		>
			{({ fieldProps, error, valid }) => (
				<>
					<TextField {...fieldProps} autoComplete="off" />
					{error && !valid && <ErrorMessage>{error}</ErrorMessage>}
				</>
			)}
		</Field>
	);
};
