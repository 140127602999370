import React from 'react';
import { type AutoDismissFlagProps, SuccessAutoDismissFlag } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useRouter } from '@atlassian/react-resource-router';
import messages from './messages.tsx';
import { useTimelineUrl } from './utils.tsx';

type Props = AutoDismissFlagProps & {
	isCMPBoard: boolean;
	onDismissed?: (id: number | string) => void;
};

export const SuccessFlagWithTimelineLink = (props: Props) => {
	const { formatMessage } = useIntl();
	const [, { push }] = useRouter();
	const { id, onDismissed, isCMPBoard } = props;
	const timelineUrl = useTimelineUrl(isCMPBoard);

	const actions =
		timelineUrl !== undefined
			? [
					{
						content: formatMessage(messages.actionTimeline),
						onClick: () => {
							push(timelineUrl);
							if (onDismissed) onDismissed(id);
						},
					},
				]
			: [];

	return <SuccessAutoDismissFlag actions={actions} {...props} />;
};
