import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noAssigneeEmptyStateDescription: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.empty-view.no-assignee-empty-state-description',
		defaultMessage: 'Assignees will appear here when an issue on your board is assigned to them.',
		description: 'Empty state description for assignee',
	},
	noIssueTypesEmptyStateDescription: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.empty-view.no-issue-types-empty-state-description',
		defaultMessage:
			'Issue types will appear here when an issue using the issue type has been added to your board.',
		description: 'Empty state description for issue type',
	},
	noIssueTypesEmptyStateDescriptionIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.empty-view.no-issue-types-empty-state-description-issue-term-refresh',
		defaultMessage:
			'Work types will appear here when a work item using the work type has been added to your board.',
		description: 'Empty state description for issue type',
	},
	noAssigneeEmptyStateDescriptionIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.empty-view.no-assignee-empty-state-description-issue-term-refresh',
		defaultMessage:
			'Assignees will appear here when a work item on your board is assigned to them.',
		description: 'Empty state description for assignee',
	},
});
