import { useRef } from 'react';

const defaultPredicate = () => true;

export const useOnce = (callback: () => void, predicate: () => boolean = defaultPredicate) => {
	const done = useRef(false);

	if (!done.current && predicate()) {
		callback();
		done.current = true;
	}
};
