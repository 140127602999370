import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	error: {
		id: 'role-picker.error',
		defaultMessage: "We couldn't retrieve roles",
		description:
			'Label for the role picker error. It is shown in case of error fetching roles from the server and displayed inside inline dialog which places automatically above/below or next to the picker',
	},
	placeholder: {
		id: 'role-picker.placeholder',
		defaultMessage: 'All roles',
		description:
			"Placeholder for the role picker. Displayed in case it does not have selected value. When the user doesn't select a value, it means all roles",
	},
	labelText: {
		id: 'role-picker.label-text',
		defaultMessage: 'Role',
		description: 'Label name of role field for screen reader users',
	},
});
