import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	enableChildLevelIssuePlanningSuccess: {
		id: 'software-board-settings-timeline.controllers.handlers.update-child-planning-handler.enable-child-level-issue-planning-success',
		defaultMessage: "You've added child-level issues to your timeline",
		description: 'Success message when child level issue planning is enabled',
	},
	disableChildLevelIssuePlanningSuccess: {
		id: 'software-board-settings-timeline.controllers.handlers.update-child-planning-handler.disable-child-level-issue-planning-success',
		defaultMessage: "You've removed child-level issues from your timeline",
		description: 'Success message when child level issue planning is disabled',
	},
	enableChildLevelIssuePlanningSuccessIssueTermRefresh: {
		id: 'software-board-settings-timeline.controllers.handlers.update-child-planning-handler.enable-child-level-issue-planning-success-issue-term-refresh',
		defaultMessage: "You've added child-level work items to your timeline",
		description: 'Success message when child level issue planning is enabled',
	},
	disableChildLevelIssuePlanningSuccessIssueTermRefresh: {
		id: 'software-board-settings-timeline.controllers.handlers.update-child-planning-handler.disable-child-level-issue-planning-success-issue-term-refresh',
		defaultMessage: "You've removed child-level work items from your timeline",
		description: 'Success message when child level issue planning is disabled',
	},
});
