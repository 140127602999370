import React, { useCallback, useState } from 'react';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { ColorCardButton } from './color-card-button/index.tsx';
import { ColorPaletteMenu } from './color-palette-menu/index.tsx';
import { useColorPaletteMenuLabel } from './color-palette-menu/utils.tsx';

export type Props = {
	label: string;
	selectedColor: string;
	isDisabled?: boolean;
	informationMessage?: string;
	onChange: (value: string) => void;
	onResetToGlobal?: () => void;
	isShowingCustomColor?: boolean;
	isResetToGlobalAllowed?: boolean;
	resetButtonTooltipContent?: string;
};

export const InlineColorPicker = ({
	selectedColor,
	label,
	informationMessage,
	isDisabled = false,
	isShowingCustomColor = true,
	isResetToGlobalAllowed = false,
	resetButtonTooltipContent,
	onChange,
	onResetToGlobal,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const fullLabel = useColorPaletteMenuLabel(label, selectedColor);

	const handleClosePopup = useCallback(() => {
		setIsOpen(false);
	}, []);

	return (
		<Popup
			isOpen={isOpen}
			onClose={handleClosePopup}
			placement="bottom-start"
			content={() => (
				<Box xcss={colorCardWrapperButtonStyles}>
					<ColorPaletteMenu
						label={label}
						selectedColor={selectedColor}
						onChange={onChange}
						onResetToGlobal={onResetToGlobal}
						onClose={handleClosePopup}
						cols={10}
						informationMessage={informationMessage}
						isInlineUpdate
						isShowingCustomColor={isShowingCustomColor}
						isResetToGlobalAllowed={isResetToGlobalAllowed}
						resetButtonTooltipContent={resetButtonTooltipContent}
					/>
				</Box>
			)}
			trigger={({ ref, ...triggerProps }) => (
				<div ref={ref}>
					<ColorCardButton
						{...triggerProps}
						value={selectedColor}
						label={fullLabel}
						expanded={isOpen}
						onClick={() => !isDisabled && setIsOpen(!isOpen)}
					/>
				</div>
			)}
		/>
	);
};

const colorCardWrapperButtonStyles = xcss({
	borderRadius: 'border.radius',
	paddingTop: 'space.100',
	paddingLeft: 'space.150',
	paddingBottom: 'space.100',
	paddingRight: 'space.050',
});
