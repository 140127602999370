import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { InlineTextEditWithBorder } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/inline-edit-with-border/index.tsx';
import { dataTestIds } from '../../../../../common/constants/data-test.tsx';
import messages from './messages.tsx';
import { useUpdateDefaultSwimlane } from './utils.tsx';

export const DefaultSwimlaneNameField = ({ canEdit }: { canEdit: boolean }) => {
	const { formatMessage } = useIntl();
	const { name, onUpdate } = useUpdateDefaultSwimlane();

	return (
		<Container data-testid={dataTestIds.defaultSwimlaneNameFieldContainer}>
			<InlineTextEditWithBorder
				editButtonLabel={formatMessage(messages.editButtonLabel, {
					swimlane: name,
				})}
				defaultValue={name}
				isRequired
				isDisabled={!canEdit}
				label={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.labelIssueTermRefresh
						: messages.label,
				)}
				onConfirm={onUpdate}
			/>
			<Description>
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.descriptionIssueTermRefresh
						: messages.description,
				)}
			</Description>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'grid',
	marginTop: token('space.150', '12px'),
	gridTemplateColumns: '230px 1fr',
	gridAutoRow: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.p({
	marginTop: token('space.025', '4px'),
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtlest', '#626F86'),
	gridColumn: '1 / 3',
});
