import { createStateHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const useCanEdit = createStateHook(Store, {
	selector: (state: State): boolean => state.canEdit,
});

export const useCanUseBoardAdminsPicker = createStateHook(Store, {
	selector: (state: State): boolean => state.canUseBoardAdminsPicker,
});
