import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	labelWithSelectedColor: {
		id: 'software-board-settings-color-picker.color-picker.color-palette-menu.label-with-selected-color',
		defaultMessage: '{label}, {selectedColorLabel} selected.',
		description: 'This is the radio group label for selected option.',
	},
	labelWithSelectedCustomColor: {
		id: 'software-board-settings-color-picker.color-picker.color-palette-menu.label-with-selected-custom-color',
		defaultMessage: '{label}, custom color {selectedColorLabel} selected.',
		description: 'This is the radio group label for selected option which is custom color.',
	},
	additionalInstructionsForSelectingColorOptions: {
		id: 'software-board-settings-color-picker.color-picker.color-palette-menu.additional-instructions-for-selecting-color-options',
		defaultMessage: 'Press tab to go to a color option and press space to select it.',
		description: 'This is the radio group label for selected option which is custom color.',
	},
	optionLabelForCustomColor: {
		id: 'software-board-settings-color-picker.color-picker.color-palette-menu.option-label-for-custom-color',
		defaultMessage: 'Custom color {customColor}',
		description: 'This is the radio option label for the selected custom color.',
	},
	warningForCustomColor: {
		id: 'software-board-settings-color-picker.color-picker.color-palette-menu.warning-for-custom-color',
		defaultMessage: 'Custom colors will become unavailable once you assign another color.',
		description: 'This is the warning section message for current selected custom color.',
	},
	customColorLabel: {
		id: 'software-board-settings-color-picker.color-picker.color-palette-menu.custom-color-label',
		defaultMessage: 'Custom color',
		description: 'Label for custom color section',
	},
	updateButtonLabel: {
		id: 'software-board-settings-color-picker.color-picker.color-palette-menu.update-button-label',
		defaultMessage: 'Update',
		description: 'Label for the button to update custom color with recommended color palette',
	},
	cancelButtonLabel: {
		id: 'software-board-settings-color-picker.color-picker.color-palette-menu.cancel-button-label',
		defaultMessage: 'Cancel',
		description: 'Label for the button to cancel updating custom color',
	},
	resetButtonLabel: {
		id: 'software-board-settings-color-picker.color-picker.color-palette-menu.reset-button-label',
		defaultMessage: 'Reset',
		description:
			'Short version of label for the button to reset the current color to global settings color',
	},
	resetToGlobalButtonLabel: {
		id: 'software-board-settings-color-picker.color-picker.color-palette-menu.reset-to-global-button-label',
		defaultMessage: 'Reset to global color',
		description:
			'Full version of label for the button to reset the current color to global settings color',
	},
});
