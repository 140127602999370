import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	ENABLE_TIMELINE_ACTION_SUBJECT,
	ENABLE_TIMELINE_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { TIMELINE_API_URI } from '../../common/constants/url.tsx';
import messages from './messages.tsx';
import type { EnableTimelineValidation } from './types.tsx';

export const useEnableTimeline = (rapidViewId: number) => {
	const { formatMessage } = useIntl();

	const enableTimeline = useCallback(
		(body: EnableTimelineValidation) =>
			performPutRequest<EnableTimelineValidation>(
				`${TIMELINE_API_URI}/${rapidViewId}/enableRoadmapWithValidation`,
				{
					body: JSON.stringify(body),
				},
			),
		[rapidViewId],
	);

	const {
		error,
		hasError,
		fetch: commit,
		...rest
	} = useServiceWithAnalytics(
		enableTimeline,
		{},
		{
			actionSubject: ENABLE_TIMELINE_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${ENABLE_TIMELINE_BROWSER_METRICS_KEY}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		error,
		errorTitle: formatMessage(messages.enableTimelineFailureTitle),
	});

	return { commit, error, hasError, ...rest };
};
