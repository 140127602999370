import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	successFlagTitleForCreatingSwimlane: {
		id: 'software-board-settings-swimlanes.controllers.handlers.use-create-swimlane-handler.success-flag-title-for-creating-swimlane',
		defaultMessage: "You've created a swimlane",
		description: 'Title of success flag when succesfully create a new swimlane',
	},
	failedToCreateErrorTitle: {
		id: 'software-board-settings-swimlanes.controllers.handlers.use-create-swimlane-handler.failed-to-create-error-title',
		defaultMessage: "We couldn't create your swimlane",
		description: 'Title of error flag when failed to create a new swimlane',
	},
	validationErrorInstructionForCreatingSwimlane: {
		id: 'software-board-settings-swimlanes.controllers.handlers.use-create-swimlane-handler.validation-error-instruction-for-creating-swimlane',
		defaultMessage: 'Try again with a valid query.',
		description: 'Instruction when failing to create a query.',
	},
});
