import type { ColorItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import type {
	CardColor,
	CardColorStrategy,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const setCardColors =
	(cardColors: CardColor[]) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({ ...getState(), cardColors });
	};

export const setCardColorStrategy =
	(newStrategy: CardColorStrategy) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({ ...getState(), cardColorStrategy: newStrategy });
	};

export const setNextCardColorStrategy =
	(newStrategy: CardColorStrategy | null) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({ ...getState(), nextCardColorStrategy: newStrategy });
	};

export const addCardColor =
	(cardColor: CardColor) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const currentState = getState();
		const newCardColors = [cardColor, ...currentState.cardColors];

		setState({
			cardColors: newCardColors,
		});
	};

export const beginCardColorRankUpdate =
	(targetCardColorId: string, afterCardColorId: string | null) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const currentState = getState();
		const { cardColors } = currentState;

		const targetCardColor = cardColors.find(({ id }) => id.toString() === targetCardColorId);
		if (!targetCardColor) {
			return;
		}

		const nextCardColors: CardColor[] = afterCardColorId ? [] : [targetCardColor];

		cardColors.forEach((cardColor) => {
			const currentCardColorId = cardColor.id.toString();
			if (currentCardColorId !== targetCardColorId) {
				nextCardColors.push(cardColor);
			}

			if (currentCardColorId === afterCardColorId) {
				nextCardColors.push(targetCardColor);
			}
		});

		setState({
			cardColors: nextCardColors,
			cardColorsBeforeOptimistic: cardColors,
			isOptimisticCardColors: true,
		});
	};

export const endCardColorRankUpdate =
	() =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			isOptimisticCardColors: false,
			cardColorsBeforeOptimistic: null,
		});
	};

export const revertCardColorRankUpdate =
	() =>
	({ setState, getState }: StoreActionApi<State>) => {
		const currentState = getState();
		const nextCardColors = currentState.cardColorsBeforeOptimistic || [];

		setState({
			cardColors: nextCardColors,
			isOptimisticCardColors: false,
			cardColorsBeforeOptimistic: null,
		});
	};

export const deleteCardColor =
	(id: number) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const currentState = getState();
		const newCardColors = currentState.cardColors.filter((c) => c.id !== id);
		setState({
			cardColors: newCardColors,
		});
	};

export const editCardColor =
	(cardColor: CardColor) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const currentState = getState();
		const newCardColors = currentState.cardColors.map((item) =>
			String(item.id) === String(cardColor.id)
				? {
						...cardColor,
						// mutation data cannot calculate isUsed correctly so we need to use initial isUsed fetched data
						isUsed: item.isUsed,
					}
				: item,
		);
		setState({
			cardColors: newCardColors,
		});
	};

export const setItemToEdit =
	(cardColor: ColorItem | null) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({ ...getState(), itemToEdit: cardColor });
	};

export type SetCardColorsAction = typeof setCardColors;
export type SetCardColorStrategyAction = typeof setCardColorStrategy;
export type SetNextCardColorStrategyAction = typeof setNextCardColorStrategy;
export type AddCardColorAction = typeof addCardColor;
export type BeginCardColorRankAction = typeof beginCardColorRankUpdate;
export type DeleteCardColorAction = typeof deleteCardColor;
export type EndCardColorRankAction = typeof endCardColorRankUpdate;
export type RevertCardColorRankAction = typeof revertCardColorRankUpdate;
export type EditCardColorAction = typeof editCardColor;
export type SetItemToEditAction = typeof setItemToEdit;
