import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	confirmationModalTitle: {
		id: 'software-board-settings-general-settings.board-location-field.confirmation-modal-title',
		defaultMessage: 'Change board location',
		description:
			'Title for confirmation dialog for moving the board to a place the user cannot access it',
	},
	confirmationModalDescription: {
		id: 'software-board-settings-general-settings.board-location-field.confirmation-modal-description',
		defaultMessage:
			'You’re not an admin in the location you’ve selected so you won’t be able to edit the settings of this board anymore. ',
		description:
			'Description within confirmation dialog, the warning is here to advise the user that they will be prevented from making changes if they proceed.',
	},
	label: {
		id: 'software-board-settings-general-settings.board-location-field.label',
		defaultMessage: 'Location',
		description: 'Select dropdown group heading for the current user location.',
	},
	locationSelectAriaLabel: {
		id: 'software-board-settings-general-settings.board-location-field.location-select-aria-label',
		defaultMessage: 'Location, {name} selected',
		description:
			'Arial label for board location. {name} will be replace with the selected option name',
	},
	projectsSelectGroup: {
		id: 'software-board-settings-general-settings.board-location-field.projects-select-group',
		defaultMessage: 'PROJECTS',
		description: 'Select dropdown group heading for all project locations.',
	},
	userSelectGroup: {
		id: 'software-board-settings-general-settings.board-location-field.user-select-group',
		defaultMessage: 'PERSONALS',
		description: 'Select dropdown group heading for the current user location.',
	},
	updateBoardLocationSuccessfulTitle: {
		id: 'software-board-settings-general-settings.board-location-field.update-board-location-successful-title',
		defaultMessage: 'The location has been updated',
		description:
			'A confirmation message shown after successfully updating the board location title',
	},
	updateBoardLocationFailureTitle: {
		id: 'software-board-settings-general-settings.board-location-field.update-board-location-failure-title',
		defaultMessage: 'We couldn’t update the location',
		description: 'Title of the error flag when failing to update board location',
	},
	updateBoardLocationFailureDescription: {
		id: 'software-board-settings-general-settings.board-location-field.update-board-location-failure-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description of the error flag when failing to update board location',
	},
});
