import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { APP_METRIC_KEY } from '@atlassian/jira-software-board-settings-general-common/src/common/constants/analytics.tsx';
import { emptyResponseCommitToBoolean } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/utils.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import {
	UPDATE_GENERAL_COMPLETED_ISSUES_ACTION_SUBJECT,
	UPDATE_GENERAL_COMPLETED_ISSUES_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import type { UpdateCompletedIssuesInput } from './types.tsx';

const COMPLETED_ISSUES_API_URI = '/rest/greenhopper/1.0/rapidviewconfig/oldDoneIssuesCutoff';

export const useUpdateCompletedIssues = () => {
	const updateCompletedIssues = useCallback(
		({ rapidViewId, oldDoneIssuesCutoff }: UpdateCompletedIssuesInput) =>
			performPutRequest(`${COMPLETED_ISSUES_API_URI}`, {
				body: JSON.stringify({
					rapidViewId,
					oldDoneIssuesCutoff,
				}),
			}),
		[],
	);

	const { fetch } = useServiceWithAnalytics(
		updateCompletedIssues,
		{},
		{
			actionSubject: UPDATE_GENERAL_COMPLETED_ISSUES_ACTION_SUBJECT,
			packageName: APP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_METRIC_KEY}.${UPDATE_GENERAL_COMPLETED_ISSUES_METRICS_KEY}`,
		},
	);

	return {
		commit: emptyResponseCommitToBoolean(fetch),
	};
};
