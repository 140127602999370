import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	enableTimelineFailureTitle: {
		id: 'software-board-settings-timeline.services.enable-timeline.enable-timeline-failure-title',
		defaultMessage: "We couldn't turn on your timeline",
		description:
			'Title for the error flag which appears when the API call to enable the timeline fails',
	},
});
