import { useCallback, useMemo } from 'react';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldMode } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import { emptyResponseCommitToBoolean } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/utils.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	RANK_CARD_LAYOUT_FIELD_ACTION_SUBJECT,
	RANK_CARD_LAYOUT_FIELD_PLAN_MODE_BROWSER_METRICS_KEY,
	RANK_CARD_LAYOUT_FIELD_WORK_MODE_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { PLAN_MODE } from '../../common/constants/card-layout.tsx';
import { CARD_LAYOUT_API_URI } from '../../common/constants/url.tsx';
import messages from './messages.tsx';
import type { RankCardLayoutFieldInput, RankCardLayoutFieldResponse } from './types.tsx';

export const getFieldUrl = ({
	rapidViewId,
	id,
	mode,
}: {
	rapidViewId: number;
	id: number;
	mode: FieldMode;
}) => `${CARD_LAYOUT_API_URI}/${rapidViewId}/${mode}/field/${id}`;

export const useRankCardLayoutField = (rapidViewId: number, fieldMode: FieldMode) => {
	const { formatMessage } = useIntl();

	const performRequest = useCallback(
		({ id, mode, after }: RankCardLayoutFieldInput) => {
			const moveUrl = `${getFieldUrl({ rapidViewId, id, mode })}/move`;
			const afterUrl = after != null ? getFieldUrl({ rapidViewId, id: after, mode }) : null;
			const body = afterUrl == null ? { position: 'First' } : { after: afterUrl };
			return performPostRequest(moveUrl, { body: JSON.stringify(body) });
		},
		[rapidViewId],
	);

	const browserMetricsKey = useMemo(
		() =>
			fieldMode === PLAN_MODE
				? RANK_CARD_LAYOUT_FIELD_PLAN_MODE_BROWSER_METRICS_KEY
				: RANK_CARD_LAYOUT_FIELD_WORK_MODE_BROWSER_METRICS_KEY,
		[fieldMode],
	);

	const {
		data,
		loading,
		error,
		hasError,
		fetch: commit,
	} = useServiceWithAnalytics<RankCardLayoutFieldResponse['data'], RankCardLayoutFieldInput>(
		performRequest,
		{},
		{
			actionSubject: RANK_CARD_LAYOUT_FIELD_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
			attributes: {
				fieldMode,
			},
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${browserMetricsKey}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		handleValidationError: true,
		error,
		errorTitle: formatMessage(messages.title),
	});

	return { data, loading, commit: emptyResponseCommitToBoolean(commit), error, hasError };
};
