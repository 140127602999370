import React from 'react';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { Redirect, type RouteResourceError } from '@atlassian/react-resource-router';

type Props = {
	error: ((RouteResourceError | null) & RouteResourceError) | null | Error | null;
};

export const InvalidStateHandler = (props: Props) => {
	const { error } = props;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const fetchError = error as FetchError;

	if (fetchError) {
		switch (fetchError.statusCode) {
			case 404:
				// edit model only 404s when user is permissionless
				return <Redirect to="/jira/projects?showFlag=jsw.board-not-found" />;
			default:
				throw error;
		}
	}

	// should never get to this case
	throw new Error();
};
