import React from 'react';
import { Stack, xcss } from '@atlaskit/primitives';
import { useIsScrumBoard } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { BoardSubFilterQuery } from './board-sub-filter-query/index.tsx';
import { BoardSubFilterSection } from './board-sub-filter-section/index.tsx';

export const BoardSubFilterSettingsFields = () => {
	const isScrumBoard = useIsScrumBoard();
	const isJSMBoard = useIsJSMBoard();
	if (!isJSMBoard && isScrumBoard) {
		return null;
	}

	return (
		<Stack xcss={containerStyles}>
			<BoardSubFilterSection />
			<BoardSubFilterQuery />
		</Stack>
	);
};

const containerStyles = xcss({
	width: '680px',
	display: 'flex',
	flexDirection: 'column',
	marginTop: 'space.500',
	rowGap: 'space.200',
});
