export const VIEW_CARD_COLOR_SETTINGS_EXPERIENCE_NAME = 'viewCardColorSettings';

export const FETCH_CARD_COLOR_STRATEGY_ACTION_SUBJECT = 'fetchCardColorStrategy';
export const FETCH_CARD_COLOR_STRATEGY_BROWSER_METRICS_KEY = 'fetch-card-color-strategy';

export const UPDATE_CARD_COLOR_STRATEGY_ACTION_SUBJECT = 'updateCardColorStrategy';
export const UPDATE_CARD_COLOR_STRATEGY_BROWSER_METRICS_KEY = 'update-card-color-strategy';

export const ADD_CARD_COLOR_ACTION_SUBJECT = 'addCardColor';
export const ADD_CARD_COLOR_BROWSER_METRICS_KEY = 'add-card-color';

export const MODAL_ANALYTICS_SOURCE = 'card-colors-board-settings-page';

export const RANK_CARD_COLOR_ACTION_SUBJECT = 'rankCardColor';
export const RANK_CARD_COLOR_BROWSER_METRICS_KEY = 'rank-card-color';

export const UPDATE_CARD_COLOR_ACTION_SUBJECT = 'updateCardColor';
export const UPDATE_CARD_COLOR_BROWSER_METRICS_KEY = 'update-card-color';

export const DELETE_CARD_COLOR_ACTION_SUBJECT = 'deleteCardColor';
export const DELETE_CARD_COLOR_BROWSER_METRICS_KEY = 'delete-card-color';
