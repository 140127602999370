import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const issueRankingConfirmationEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-issue-ranking-confirmation-modal" */ './index').then(
			(module) => module.IssueRankingConfirmationModal,
		),
	),
	getPreloadProps: () => ({}),
});
