import type {
	CurrentEstimationStatistic,
	CurrentTrackingStatistic,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/estimation.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const setCurrentEstimationStatistic =
	(currentEstimationStatistic: CurrentEstimationStatistic) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({ ...getState(), currentEstimationStatistic });
	};

export const setCurrentTrackingStatistic =
	(currentTrackingStatistic: CurrentTrackingStatistic) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({ ...getState(), currentTrackingStatistic });
	};

export type SetCurrentEstimationStatistic = typeof setCurrentEstimationStatistic;
export type SetCurrentTrackingStatistic = typeof setCurrentTrackingStatistic;
