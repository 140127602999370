import { createHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const getRapidViewId = (state: State): number => state.rapidViewId;

export const useRapidViewId = createHook(Store, {
	selector: getRapidViewId,
});

export const getIsInitialised = (state: State): boolean => state.isInitialised;

export const useIsInitialised = createHook(Store, {
	selector: getIsInitialised,
});

export const getIsCMPBoard = (state: State) => state.isCMPBoard;

export const useIsCMPBoard = createHook(Store, {
	selector: getIsCMPBoard,
});

export const getIsScrumBoard = (state: State): boolean => state.isSprintSupportEnabled;

export const useIsScrumBoard = createHook(Store, {
	selector: getIsScrumBoard,
});

export const getIsKanPlanEnabled = (state: State): boolean => state.isKanPlanEnabled;

export const useIsKanPlanEnabled = createHook(Store, {
	selector: getIsKanPlanEnabled,
});
