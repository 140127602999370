import React from 'react';
import { Text } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import {
	useProjectName,
	useBoardQueryFilterUrl,
} from '../../../controllers/selectors/board/index.tsx';
import messages from './messages.tsx';

export const BoardCrossProjectMessage = () => {
	const { formatMessage } = useIntl();
	const [projectName] = useProjectName();
	const [projectQueryFilterUrl] = useBoardQueryFilterUrl();

	return (
		<SectionMessage
			title={formatMessage(messages.title)}
			actions={[
				<SectionMessageAction key="1" href={projectQueryFilterUrl}>
					{formatMessage(messages.callToAction)}
				</SectionMessageAction>,
			]}
		>
			<Text as="p">
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.descriptionIssueTermRefresh
						: messages.description,
					{ projectName },
				)}
			</Text>
		</SectionMessage>
	);
};
