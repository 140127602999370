import { useMemo } from 'react';
import { VALIDATION_ERROR_NAME } from '@atlassian/jira-fetch/src/utils/errors.tsx';

type ErrorCause = {
	error?: string;
	field?: string;
};

const QUERY_FIELD_VALUES = ['query', 'value', 'jql'];

export const getQueryValidationErrorMessage = (error?: Error) => {
	if (error && error?.name === VALIDATION_ERROR_NAME && typeof error?.cause === 'object') {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const cause = error?.cause as ErrorCause;
		if (QUERY_FIELD_VALUES.includes(cause.field ?? '') && cause.error) {
			return cause.error;
		}
	}
};

export const useQueryValidationErrorMessage = (error?: Error) =>
	useMemo(() => getQueryValidationErrorMessage(error), [error]);
