import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueRankingHeader: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.issue-ranking.issue-ranking-header',
		defaultMessage: 'Issue rank on board',
		description: 'Header of board setting issue ranking system section.',
	},
	issueRankingDescription: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.issue-ranking.issue-ranking-description',
		defaultMessage:
			'Drag and drop issues to rank them by importance or urgency. “Order by rank” is applied to the board filter query, and is removed by editing the filter directly. <button>Read more about issue ranking</button>.',
		description: 'Description of board setting issue ranking system section',
	},
	enableIssueRankingButtonText: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.issue-ranking.enable-issue-ranking-button-text',
		defaultMessage: 'Allow issue ranking',
		description: 'Button text of allow issue ranking',
	},
	rankingOnButtonText: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.issue-ranking.ranking-on-button-text',
		defaultMessage: 'On',
		description: 'Button text of indicate users that ranking is on for the board',
	},
	editRankingUsingFilter: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.issue-ranking.edit-ranking-using-filter',
		defaultMessage: 'Edit filter',
		description: 'Link text of redirect users to edit filter page.',
	},
	enableIssueRankingConfirmationModalMessage: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.issue-ranking.enable-issue-ranking-confirmation-modal-message',
		defaultMessage:
			'This will add “Order by Rank” to your filter and remove any other “Order By” sorting. It will affect all boards using this filter.',
		description: 'Confirmation modal message when user clicks allow issue ranking button',
	},
	updateBoardIssueRankSuccessfulTitle: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.issue-ranking.update-board-issue-rank-successful-title',
		defaultMessage: 'Your board filter has been updated',
		description: 'Success flag title when board rank is succesfully updated.',
	},
	updateBoardIssueRankFailureTitle: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.issue-ranking.update-board-issue-rank-failure-title',
		defaultMessage: "We couldn't update your board filter",
		description: 'Error flag title when board rank update fails.',
	},
	updateBoardIssueRankFailureDescription: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.issue-ranking.update-board-issue-rank-failure-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Error flag description when board rank update fails.',
	},
});
