import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	toggleOnShowDaysInColumnFailureTitle: {
		id: 'software-board-settings-card-layout.services.update-show-days-in-column.toggle-on-show-days-in-column-failure-title',
		defaultMessage: "We couldn't turn on days in column",
		description: 'Title for flag shown when the show days in column toggled cannot be toggled on',
	},
	toggleOffShowDaysInColumnFailureTitle: {
		id: 'software-board-settings-card-layout.services.update-show-days-in-column.toggle-off-show-days-in-column-failure-title',
		defaultMessage: "We couldn't turn off days in column",
		description: 'Title for flag shown when the show days in column toggled cannot be toggled off',
	},
	toggleShowDaysInColumnFailureDescription: {
		id: 'software-board-settings-card-layout.services.update-show-days-in-column.toggle-show-days-in-column-failure-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description for flag shown when a field cannot be deleted from card layout',
	},
});
