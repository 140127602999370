import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	failedToCreateErrorTitle: {
		id: 'software-board-settings-card-colors.services.add-card-color.failed-to-create-error-title',
		defaultMessage: "We couldn't create your card color",
		description: 'Title of error flag when failed to create a new card color.',
	},
	failedToCreateErrorDescription: {
		id: 'software-board-settings-card-colors.services.add-card-color.failed-to-create-error-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description of error flag when failed to create a new card color.',
	},
});
