import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cancelButton: {
		id: 'software-board-settings-query-list-config-common.delete-query-confirmation-modal.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Cancel button label',
	},
	deleteButton: {
		id: 'software-board-settings-query-list-config-common.delete-query-confirmation-modal.delete-button',
		defaultMessage: 'Delete',
		description: 'Delete button label',
	},
});
