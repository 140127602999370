import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { Roles, Role } from './types.tsx';

export const getRoleEndpointUrl = (baseUrl: string, projectId: string): string =>
	`${baseUrl}/rest/api/2/project/${encodeURIComponent(projectId)}`;

export const getRolesForProjectIdEndpointUrl = (
	baseUrl: string,
	projectId: string,
	isCurrentMember: boolean,
): string =>
	`${getRoleEndpointUrl(baseUrl, projectId)}/roledetails${
		isCurrentMember ? '?currentMember=true' : ''
	}`;

export const getRoleForProjectIdAndRoleIdEndpointUrl = (
	baseUrl: string,
	projectId: string,
	roleId: string,
): string => `${getRoleEndpointUrl(baseUrl, projectId)}/role/${encodeURIComponent(roleId)}`;

export const getRoles = (
	baseUrl: string,
	projectId: string,
	isCurrentMember: boolean,
): Promise<Roles> =>
	fetchJson(getRolesForProjectIdEndpointUrl(baseUrl, projectId, isCurrentMember));

export const getRole = (baseUrl: string, projectId: string, roleId: string): Promise<Role> =>
	fetchJson(getRoleForProjectIdAndRoleIdEndpointUrl(baseUrl, projectId, roleId));
