import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.title',
		defaultMessage: 'Card layout',
		description: 'Title for the card layout settings page',
	},
	description: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.description',
		defaultMessage:
			'Add more fields to cards on your board or backlog. <button>Read more about customizing cards</button>.',
		description: 'Description for the card layout settings page',
	},
	backlog: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.backlog',
		defaultMessage: 'Backlog',
		description: 'Title for the card layout table matching the backlog',
	},
	activeSprints: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.active-sprints',
		defaultMessage: 'Active sprints',
		description: 'Title for the card layout table matching the active sprints',
	},
	kanbanBoard: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.kanban-board',
		defaultMessage: 'Kanban board',
		description: 'Title for the card layout table matching the kanban board',
	},
	jsmBoard: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.jsm-board',
		defaultMessage: 'Extra card fields',
		description: 'Title for the card layout table matching the JSM board',
	},
});
