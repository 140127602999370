import React from 'react';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import type { GroupShare } from '@atlassian/jira-shared-types/src/share-permission.tsx';
import { NameWrapper, IconWrapper } from '../common/styled.tsx';

type Props = {
	sharePermission: GroupShare;
};

export default function GroupName({ sharePermission: { group } }: Props) {
	return (
		<>
			<IconWrapper>
				{}
				{/* @ts-expect-error - TS2769 - No overload matches this call. */}
				<PeopleGroupIcon />
			</IconWrapper>
			<NameWrapper>{group.name}</NameWrapper>
		</>
	);
}
