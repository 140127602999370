import React, { Component } from 'react';
import uuid from 'uuid';
import { PickerWithAvatar } from '@atlassian/jira-common-components-picker/src/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { User, Props, State } from '../model/index.tsx';
import { requestUser, requestUsers } from '../services/index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class UserPicker extends Component<Props, State> {
	static defaultProps = {
		isRequired: false,
		isDisabled: false,
		isClearable: true,
		isCompact: false,
		shouldFitContainer: false,
		width: '200px',
	};

	constructor(props: Props) {
		super(props);
		this.userPickerId = `group-picker.label.id-${uuid()}`;
	}

	static getDerivedStateFromProps(props: Props, state: State) {
		const currentAccountId = props.selectedUser && props.selectedUser.accountId;
		const prevAccountId = state.prevSelectedUser && state.prevSelectedUser.accountId;

		if (currentAccountId !== prevAccountId) {
			return {
				...state,
				selectedUser: props.selectedUser,
				prevSelectedUser: props.selectedUser,
			};
		}

		return null;
	}

	state = {
		selectedUser: this.props.selectedUser,
		prevSelectedUser: this.props.selectedUser,
	};

	componentDidMount() {
		this.ensureSelectedUserInfoPresent();
	}

	componentDidUpdate(prevProps: Props) {
		this.ensureSelectedUserInfoPresent();

		if (prevProps.suggestedAccountId !== this.props.suggestedAccountId) {
			// Good to know:
			// react-select caches resolved Promise value for an empty filter so
			// there is no way to change the suggestion other then replacing the component with a new one
			log.safeErrorWithoutCustomerData(
				'user-picker.prop-update',
				"suggestedAccountId prop change won't be applied, you have to replace the component with a new one",
			);
		}
	}

	userPickerId;

	onSelected = (selectedUser?: User) => {
		this.setState({ selectedUser });
		this.props.onChange(selectedUser);
	};

	/* eslint-disable jira/react-arrow-function-property-naming */
	formatOptionDataTransformer = (user: User) => ({
		avatar: user.avatarUrl,
		label: user.displayName,
	});

	fetchOptions = (query: string): Promise<User[]> => {
		const { baseUrl, suggestedAccountId } = this.props;

		if (query) {
			return requestUsers(baseUrl, query);
		}

		if (suggestedAccountId) {
			return new Promise((resolve) => {
				requestUser(baseUrl, suggestedAccountId)
					.then((user) => resolve([user]))
					.catch(() => resolve([]));
			});
		}

		return Promise.resolve([]);
	};

	ensureSelectedUserInfoPresent() {
		const { selectedUser } = this.state;
		if (!selectedUser || selectedUser.displayName) {
			return;
		}

		const { accountId } = selectedUser;
		// @ts-expect-error - TS7006 - Parameter 'newUser' implicitly has an 'any' type.
		const updateSelectedUser = (newUser) => {
			const currentUser = this.state.selectedUser;
			const currentAccountId = currentUser && currentUser.accountId;

			// Update the state only in case the user wasn't changed
			if (currentAccountId === accountId) {
				this.setState({ selectedUser: newUser });
			}
		};

		requestUser(this.props.baseUrl, accountId)
			.then((user) => updateSelectedUser(user))
			.catch(() => updateSelectedUser(undefined));
	}

	render() {
		const {
			intl: { formatMessage },
			baseUrl,
			suggestedAccountId,
			selectedUser,
			placeholder = this.props.placeholder || formatMessage(messages.placeholder),
			onChange,
			shouldFitContainer,
			width,
			...props
		} = this.props;

		const { selectedUser: user } = this.state;

		return (
			<PickerWithAvatar
				aria-label={!this.props.label ? formatMessage(messages.labelText) : undefined}
				inputId={this.userPickerId}
				{...props}
				errorMessage={formatMessage(messages.error)}
				placeholder={placeholder}
				onSelected={this.onSelected}
				options={this.fetchOptions}
				formatOptionDataTransformer={this.formatOptionDataTransformer}
				value={user && user.displayName ? user : null}
				width={shouldFitContainer ? undefined : width}
			/>
		);
	}
}

export default injectIntl(UserPicker);
