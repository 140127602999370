import { useCallback } from 'react';
import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { emptyResponseCommitToBoolean } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/utils.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	DELETE_CARD_COLOR_ACTION_SUBJECT,
	DELETE_CARD_COLOR_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { CARD_COLOR_API_URI } from '../../common/constants/url.tsx';
import messages from './messages.tsx';
import type { DeleteCardColorResponse, DeleteCardColorInput } from './types.tsx';

// only exported for test purposes
export const getDeleteUrl = (rapidViewId: number, id: number) =>
	`${CARD_COLOR_API_URI}/${rapidViewId}/color/${id}`;

export const useDeleteCardColor = (
	rapidViewId: number,
	errorTitle?: string,
): DeleteCardColorResponse => {
	const { formatMessage } = useIntl();

	const deleteCardColor = useCallback(
		({ id }: DeleteCardColorInput) => performDeleteRequest(getDeleteUrl(rapidViewId, id)),
		[rapidViewId],
	);

	const { data, loading, error, hasError, fetch } = useServiceWithAnalytics<
		DeleteCardColorResponse['data'],
		DeleteCardColorInput
	>(
		deleteCardColor,
		{},
		{
			actionSubject: DELETE_CARD_COLOR_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{ browserMetricsKey: `${APP_CMP_METRIC_KEY}.${DELETE_CARD_COLOR_BROWSER_METRICS_KEY}` },
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		handleValidationError: true,
		errorTitle: errorTitle ?? formatMessage(messages.failedToDeleteErrorTitle),
		errorMessage: formatMessage(messages.failedToDeleteErrorDescription),
		error,
	});

	return { data, loading, commit: emptyResponseCommitToBoolean(fetch), error, hasError };
};
