import { createHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const getRapidViewId = (state: State): number => state.rapidViewId;

export const useRapidViewId = createHook(Store, {
	selector: getRapidViewId,
});

export const getIsInitialised = (state: State): boolean => state.isInitialised;

export const useIsInitialised = createHook(Store, {
	selector: getIsInitialised,
});
