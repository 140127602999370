/**
 * @generated SignedSource<<805d153f812ce535aec22842874a72b5>>
 * @relayHash 21e7cc18134b2ea082e0d4054b8f4adf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ead03414f4dfad56635e9d6f9d0bc45713fa41cffa7c52f2ab56e8c98a02b3c2

import type { ConcreteRequest, Query } from 'relay-runtime';
export type estimationSettingsPageQuery$variables = {
  boardId: string;
  cloudId: string;
};
export type estimationSettingsPageQuery$data = {
  readonly boardScope: {
    readonly currentUser: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
    readonly estimation: {
      readonly available: ReadonlyArray<{
        readonly name: string;
        readonly statisticFieldId: string;
      }>;
      readonly current: {
        readonly statisticFieldId: string;
      };
    };
    readonly trackingStatistic: {
      readonly isEnabled: boolean;
      readonly statisticFieldId: string;
    };
  };
  readonly jira: {
    readonly globalTimeTrackingSettings: {
      readonly isJiraConfiguredTimeTrackingEnabled: boolean;
    };
  };
};
export type estimationSettingsPageQuery = {
  response: estimationSettingsPageQuery$data;
  variables: estimationSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "isJiraConfiguredTimeTrackingEnabled"
},
v3 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  {
    "kind": "Literal",
    "name": "isCMP",
    "value": true
  }
],
v4 = {
  "concreteType": "CurrentUser",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "permissions"
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "name": "statisticFieldId"
},
v6 = {
  "concreteType": "AvailableEstimations",
  "kind": "LinkedField",
  "name": "available",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "name": "name"
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "name": "isEnabled"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "estimationSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "concreteType": "JiraTimeTrackingSettings",
                "kind": "LinkedField",
                "name": "globalTimeTrackingSettings",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v2/*: any*/),
                    "action": "THROW",
                    "path": "jira.globalTimeTrackingSettings.isJiraConfiguredTimeTrackingEnabled"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.globalTimeTrackingSettings"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      },
      {
        "kind": "RequiredField",
        "field": {
          "args": (v3/*: any*/),
          "concreteType": "BoardScope",
          "kind": "LinkedField",
          "name": "boardScope",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "EstimationConfig",
                "kind": "LinkedField",
                "name": "estimation",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "CurrentEstimation",
                      "kind": "LinkedField",
                      "name": "current",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v5/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.estimation.current.statisticFieldId"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.estimation.current"
                  },
                  (v6/*: any*/)
                ]
              },
              "action": "THROW",
              "path": "boardScope.estimation"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JswTrackingStatistic",
                "kind": "LinkedField",
                "name": "trackingStatistic",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v5/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.trackingStatistic.statisticFieldId"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v7/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.trackingStatistic.isEnabled"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.trackingStatistic"
            }
          ]
        },
        "action": "THROW",
        "path": "boardScope"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "estimationSettingsPageQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraTimeTrackingSettings",
            "kind": "LinkedField",
            "name": "globalTimeTrackingSettings",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      },
      {
        "args": (v3/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "concreteType": "EstimationConfig",
            "kind": "LinkedField",
            "name": "estimation",
            "plural": false,
            "selections": [
              {
                "concreteType": "CurrentEstimation",
                "kind": "LinkedField",
                "name": "current",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              },
              (v6/*: any*/)
            ]
          },
          {
            "concreteType": "JswTrackingStatistic",
            "kind": "LinkedField",
            "name": "trackingStatistic",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ead03414f4dfad56635e9d6f9d0bc45713fa41cffa7c52f2ab56e8c98a02b3c2",
    "metadata": {},
    "name": "estimationSettingsPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5e75122ed346f8792c17c8a728c8da36";

export default node;
