import React, { Component } from 'react';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Field } from '@atlaskit/form';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { fireUiAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-ui-event.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	PUBLIC_SHARE,
	PROJECT_SHARE,
	PROJECT_UNKNOWN_SHARE,
	OPEN_SHARE,
	type SharePermission,
	GROUP_SHARE,
	USER_SHARE,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import { PRIVATE_SHARE } from '../../model/constants.tsx';
import {
	isPublicOrOpenOrPrivate,
	areSharePermissionsEqual,
	mapAccessTypeFromPermissions,
	allShareTypesProperties,
} from '../../model/index.tsx';
import type {
	Option,
	SharePermissionOrPrivate,
	ShareType,
	ServerValidationError,
} from '../../model/types.tsx';
import messages from '../messages.tsx';
import Access from './view.tsx';

type Props = {
	readonly isShareScopeLoading: boolean;
	readonly isEditorListShown: boolean;
} & Intl & {
		readonly sharePermissionsServerValidationError: ServerValidationError;
		readonly editPermissionsServerValidationError: ServerValidationError;
		readonly shareeOptions: Option[];
		readonly editOptions: Option[];
		readonly sharePermissions: SharePermission[];
		readonly viewersCustomMessage: string | undefined;
		readonly editPermissions: SharePermission[];
		readonly onSharePermissionChange: (arg1: SharePermission[]) => void;
		readonly onEditPermissionChange: (arg1: SharePermission[]) => void;
	};

type State = {
	duplicatedIndex: number | undefined;
	duplicatedEditIndex: number | undefined;
	revokePermissionMessage?: string;
};

type AccessTypeAndSharePermissionRows = {
	accessType: ShareType | undefined;
	sharePermissionRows: SharePermissionOrPrivate[];
};

type AccessTypeAndEditPermissionRows = {
	editAccessType: ShareType | undefined;
	editPermissionRows: SharePermissionOrPrivate[];
};

export const mapShareTypeFromPermissions = memoizeOne(
	(sharePermissions: SharePermission[]): AccessTypeAndSharePermissionRows => {
		const accessType = mapAccessTypeFromPermissions(sharePermissions);
		const sharePermissionRows =
			accessType === PRIVATE_SHARE ? [{ type: PRIVATE_SHARE }] : [...sharePermissions];

		return {
			accessType,
			sharePermissionRows,
		};
	},
);

export const mapEditTypeFromPermissions = memoizeOne(
	(sharePermissions: SharePermission[]): AccessTypeAndEditPermissionRows => {
		const editAccessType = mapAccessTypeFromPermissions(sharePermissions);
		const editPermissionRows =
			editAccessType === PRIVATE_SHARE ? [{ type: PRIVATE_SHARE }] : [...sharePermissions];

		return {
			editAccessType,
			editPermissionRows,
		};
	},
);

// eslint-disable-next-line jira/react/no-class-components
class AccessWrapper extends Component<Props, State> {
	state = {
		duplicatedIndex: undefined,
		duplicatedEditIndex: undefined,
		revokePermissionMessage: undefined,
	};

	onRemove = (index: number, analyticsEvent: UIAnalyticsEvent) => {
		const { sharePermissions, onSharePermissionChange } = this.props;
		const newPermissions = sharePermissions.filter((_, i) => i !== index);

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const permission = sharePermissions[index] as SharePermissionOrPrivate;
		const { type } = permission;

		fireUiAnalytics(analyticsEvent, {
			name: 'shareeRowRemoveButton',
			attributes: {
				shareeType: type,
				shareeIndex: index,
				shareesRemaining: newPermissions.length,
			},
		});

		this.setState({
			duplicatedIndex: undefined,
			duplicatedEditIndex: undefined,
		});

		const { formatMessage } = this.props.intl;

		let title: string | undefined;
		switch (type) {
			case GROUP_SHARE:
				title = permission.group.name;
				break;
			case PROJECT_SHARE:
				title = permission.project.name;
				break;
			case PROJECT_UNKNOWN_SHARE:
				title = formatMessage(allShareTypesProperties[type].messageDescriptor);
				break;
			case USER_SHARE:
				title = permission.user.displayName;
				break;
			case OPEN_SHARE:
			case PUBLIC_SHARE:
			case PRIVATE_SHARE:
				title = formatMessage(allShareTypesProperties[type].messageDescriptor);
				break;
			default:
				title = 'none';
		}

		this.setState({
			revokePermissionMessage: formatMessage(messages.permissionsRevoke, {
				title,
			}),
		});

		onSharePermissionChange(newPermissions);
	};

	onEditRemove = (index: number, analyticsEvent: UIAnalyticsEvent) => {
		const { editPermissions, onEditPermissionChange } = this.props;
		const newPermissions = editPermissions.filter((_, i) => i !== index);

		fireUiAnalytics(analyticsEvent, {
			name: 'editorRowRemoveButton',
			attributes: {
				shareeType: editPermissions[index].type,
				shareeIndex: index,
				shareesRemaining: newPermissions.length,
			},
		});

		this.setState({
			duplicatedIndex: undefined,
			duplicatedEditIndex: undefined,
		});

		onEditPermissionChange(newPermissions);
	};

	onAddSharePermission = (
		sharePermission: SharePermissionOrPrivate,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const { sharePermissions, editPermissions, isEditorListShown } = this.props;
		const { type } = sharePermission;

		this.setState({
			duplicatedIndex: undefined,
		});

		if (isPublicOrOpenOrPrivate(type)) {
			this.setSharePermission(sharePermission, analyticsEvent);
		} else {
			// If the sharePermission already exists in sharePermissions then it should not be added.
			const duplicatedIndex = sharePermissions.findIndex((element) =>
				areSharePermissionsEqual(sharePermission, element),
			);

			if (duplicatedIndex >= 0) {
				this.setState({
					duplicatedIndex,
				});
			} else if (isEditorListShown) {
				// If the sharePermission already exists in editPermissions then it should not be added.
				const duplicatedEditIndex = editPermissions.findIndex((element) =>
					areSharePermissionsEqual(sharePermission, element),
				);

				if (duplicatedEditIndex >= 0) {
					this.setState({
						duplicatedEditIndex,
					});
				} else {
					this.setSharePermission(sharePermission, analyticsEvent);
				}
			} else {
				this.setSharePermission(sharePermission, analyticsEvent);
			}
		}
	};

	onAddEditPermission = (
		sharePermission: SharePermissionOrPrivate,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const { sharePermissions, editPermissions } = this.props;
		const { type } = sharePermission;

		this.setState({
			duplicatedIndex: undefined,
		});

		if (isPublicOrOpenOrPrivate(type)) {
			this.setEditPermission(sharePermission, analyticsEvent);
		} else {
			// If the editPermissions take preference over editPermissions.
			const duplicatedIndex = sharePermissions.findIndex((element) =>
				areSharePermissionsEqual(sharePermission, element),
			);
			if (duplicatedIndex >= 0) {
				this.onRemove(duplicatedIndex, analyticsEvent);
			}

			// If the sharePermission already exists in editPermissions, then it should not be added.
			const duplicatedEditIndex = editPermissions.findIndex((element) =>
				areSharePermissionsEqual(sharePermission, element),
			);
			if (duplicatedEditIndex >= 0) {
				this.setState({
					duplicatedEditIndex,
				});
			} else {
				this.setEditPermission(sharePermission, analyticsEvent);
			}
		}
	};

	setSharePermission(sharePermission: SharePermissionOrPrivate, analyticsEvent: UIAnalyticsEvent) {
		const { onSharePermissionChange } = this.props;
		const { type } = sharePermission;

		const attributes = {
			shareeType: type,
			shareesCount: undefined,
			allRoles: undefined,
		};

		switch (sharePermission.type) {
			case PUBLIC_SHARE:
			case OPEN_SHARE:
				onSharePermissionChange([sharePermission]);
				break;
			case PRIVATE_SHARE:
				onSharePermissionChange([]);
				break;
			default:
				{
					const newPermissions = this.props.sharePermissions
						.filter((element) => !isPublicOrOpenOrPrivate(element.type)) // remove possible PUBLIC_SHARE or OPEN_SHARE permissions
						.concat(sharePermission);

					// @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'undefined'.
					attributes.shareesCount = newPermissions.length;
					// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'undefined'.
					attributes.allRoles =
						sharePermission.type === PROJECT_SHARE ? !sharePermission.role : undefined; // If it is a project, we may want to know if all roles where given access

					onSharePermissionChange(newPermissions);
				}
				break;
		}

		fireUiAnalytics(analyticsEvent, {
			name: 'shareeRowAddButton',
			attributes,
		});
	}

	setEditPermission(sharePermission: SharePermissionOrPrivate, analyticsEvent: UIAnalyticsEvent) {
		const { onEditPermissionChange } = this.props;
		const { type } = sharePermission;

		const attributes = {
			shareeType: type,
			shareesCount: undefined,
			allRoles: undefined,
		};

		switch (sharePermission.type) {
			case PUBLIC_SHARE:
			case OPEN_SHARE:
				onEditPermissionChange([sharePermission]);
				break;
			case PRIVATE_SHARE:
				onEditPermissionChange([]);
				break;
			default:
				{
					const newPermissions = this.props.editPermissions
						.filter((element) => !isPublicOrOpenOrPrivate(element.type)) // remove possible PUBLIC_SHARE or OPEN_SHARE permissions
						.concat(sharePermission);

					// @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'undefined'.
					attributes.shareesCount = newPermissions.length;
					// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'undefined'.
					attributes.allRoles =
						sharePermission.type === PROJECT_SHARE ? !sharePermission.role : undefined; // If it is a project, we may want to know if all roles where given access

					onEditPermissionChange(newPermissions);
				}
				break;
		}

		fireUiAnalytics(analyticsEvent, {
			name: 'editorRowAddButton',
			attributes,
		});
	}

	render() {
		const {
			intl: { formatMessage },
			shareeOptions,
			editOptions,
			editPermissions,
			sharePermissions,
			viewersCustomMessage,
			isShareScopeLoading,
			isEditorListShown,
			sharePermissionsServerValidationError,
			editPermissionsServerValidationError,
		} = this.props;
		const { accessType, sharePermissionRows } = mapShareTypeFromPermissions(sharePermissions);
		const { editAccessType, editPermissionRows } = mapEditTypeFromPermissions(editPermissions);
		const { duplicatedIndex, duplicatedEditIndex, revokePermissionMessage } = this.state;

		return (
			<>
				<ScreenReaderText aria-live="polite" data-testid="shareable-entity-dialog.access.screen">
					{revokePermissionMessage}
				</ScreenReaderText>
				<Field
					label={formatMessage(messages.access)}
					// @ts-expect-error - TS2322 - Type '{ children: () => Element; label: string; isInvalid: boolean; invalidMessage: string; name: string; }' is not assignable to type 'IntrinsicAttributes & FieldComponentProps<string, HTMLInputElement>'.
					isInvalid={!!sharePermissionsServerValidationError}
					invalidMessage={sharePermissionsServerValidationError}
					name="access"
				>
					{({
						fieldProps: {
							//  @ts-expect-error - TS7053: Element implicitly has an 'any' type because expression of type '"aria-label"' can't be used to index type 'FieldProps<string, HTMLInputElement>'.
							'aria-label': ariaLabel,
							'aria-labelledby': ariaLabelledby,
						},
					}) => (
						<Access
							ariaLabel={ariaLabel}
							ariaLabelledby={ariaLabelledby}
							type={accessType}
							duplicatedIndex={duplicatedIndex}
							isShareScopeLoading={isShareScopeLoading}
							shareeOptions={shareeOptions}
							customMessage={viewersCustomMessage}
							sharePermissionRows={sharePermissionRows}
							onAddSharePermission={this.onAddSharePermission}
							onRemove={this.onRemove}
							errorMessage={sharePermissionsServerValidationError}
						/>
					)}
				</Field>{' '}
				{isEditorListShown && (
					<Field
						label={formatMessage(messages.editor)}
						// @ts-expect-error - TS2322 - Type '{ children: () => Element; label: string; isInvalid: boolean; invalidMessage: string; name: string; }' is not assignable to type 'IntrinsicAttributes & FieldComponentProps<string, HTMLInputElement>'.
						isInvalid={!!editPermissionsServerValidationError}
						invalidMessage={editPermissionsServerValidationError}
						name="editor"
					>
						{({
							fieldProps: {
								//  @ts-expect-error - TS7053: Element implicitly has an 'any' type because expression of type '"aria-label"' can't be used to index type 'FieldProps<string, HTMLInputElement>'.
								'aria-label': ariaLabel,
								'aria-labelledby': ariaLabelledby,
							},
						}) => (
							<Access
								ariaLabel={ariaLabel}
								ariaLabelledby={ariaLabelledby}
								isEditorList
								type={editAccessType}
								duplicatedIndex={duplicatedEditIndex}
								isShareScopeLoading={isShareScopeLoading}
								shareeOptions={editOptions}
								sharePermissionRows={editPermissionRows}
								onAddSharePermission={this.onAddEditPermission}
								onRemove={this.onEditRemove}
								errorMessage={editPermissionsServerValidationError}
							/>
						)}
					</Field>
				)}
			</>
		);
	}
}

export default injectIntl(AccessWrapper);
