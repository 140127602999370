import type { CardColorsConfig } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import { createContainer, createStore } from '@atlassian/react-sweet-state';
import { INITIAL_STATE } from '../common/constants/state.tsx';
import type { CardColorsConfigWithCardColors, State } from '../common/types/index.tsx';
import { actions } from './actions/index.tsx';
import type { Actions } from './actions/types.tsx';

export const Store = createStore<State, Actions>({
	name: 'CardColorsRemoteState',
	initialState: INITIAL_STATE,
	actions,
});

export const CardColorsConfigDataContainer = createContainer<
	State,
	Actions,
	CardColorsConfig | CardColorsConfigWithCardColors
>(Store, {
	onInit: actions.initState,
});
