import React, { useMemo, type PropsWithChildren } from 'react';
import { graphql, usePreloadedQuery, type EntryPointProps } from 'react-relay';
import { useBoardId } from '@atlassian/jira-board-settings-common/src/common/utils/use-board-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import type { cardColorsSettingsPageQuery } from '@atlassian/jira-relay/src/__generated__/cardColorsSettingsPageQuery.graphql';
import type { RapidBoardConfig } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/types/index.tsx';
import { EDIT_BOARD_CONFIG } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import type { CardColorsConfig } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import type { CardColorsConfigProps } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { CardColorsConfigDataContainer } from '../../controllers/main.tsx';
import { CardColorSettingsContainer } from './main.tsx';
import { isCardColorStrategy, transformToCardColors } from './utils.tsx';

// Deprecated with simplified-boards-milestone-2_07hbk
const CardColorsSettingsPage = ({ cardColorsConfig }: CardColorsConfigProps) => (
	<JiraRelayEnvironmentProvider>
		<CardColorsConfigDataContainer {...cardColorsConfig}>
			<CardColorSettingsContainer />
		</CardColorsConfigDataContainer>
	</JiraRelayEnvironmentProvider>
);

type Query = { queryReference: cardColorsSettingsPageQuery };

export const CardColorsSettingPageContent = (
	entryPointProps: EntryPointProps<Query, {}, {}, {}>,
) => {
	if (fg('board-settings-graphql-refactor_cyvz9')) {
		return <GraphQlPageContent {...entryPointProps} />;
	}

	return <EditModelPageContent />;
};

const GraphQlPageContent = ({
	queries: { queryReference },
}: EntryPointProps<Query, {}, {}, {}>) => {
	/* eslint-disable @atlassian/relay/unused-fields */
	const { boardScope } = usePreloadedQuery(
		graphql`
			query cardColorsSettingsPageQuery($boardId: ID!) {
				boardScope(boardId: $boardId, isCMP: true) @required(action: THROW) {
					currentUser {
						permissions
					}
					cardColorConfig @optIn(to: "modularBoardSettings") @required(action: THROW) {
						current @required(action: THROW) {
							id @required(action: THROW)
							cardColors @required(action: THROW) {
								edges @required(action: THROW) {
									node @required(action: THROW) {
										id @required(action: THROW)
										strategy @required(action: THROW)
										value @required(action: THROW)
										displayValue @required(action: THROW)
										color @required(action: THROW)
										isUsed @required(action: THROW)
										isGlobalColor ### optional, only present for priority method
									}
								}
							}
						}
					}
				}
			}
		`,
		queryReference,
	);

	const rapidViewId = useBoardId();

	if (!rapidViewId) {
		throw new Error('Card color page failed to load');
	}

	const cardColorProps: CardColorsConfig = useMemo(() => {
		const {
			cardColorConfig: {
				current: { id: cardColorStrategy, cardColors: aggCardColors },
			},
			currentUser: { permissions },
		} = boardScope;
		const canEdit = permissions.includes(EDIT_BOARD_CONFIG);

		if (!isCardColorStrategy(cardColorStrategy)) {
			throw new Error('Invalid card color strategy');
		}

		const cardColors = transformToCardColors(aggCardColors, canEdit);

		return {
			canEdit,
			rapidViewId,
			cardColorStrategy,
			cardColors,
		};
	}, [boardScope, rapidViewId]);

	return (
		<CardColorsConfigDataContainer {...cardColorProps}>
			<CardColorSettingsContainer />
			<SpaStatePageReady />
		</CardColorsConfigDataContainer>
	);
};

const EditModelPageContent = () => (
	<>
		<CardColorSettingsContainer />
		<SpaStatePageReady />
	</>
);

export const CardColorSettingsEditModelProvider = ({
	data,
	children,
}: PropsWithChildren<{
	data: RapidBoardConfig;
}>) => {
	const { cardColorConfig } = data;
	return (
		<CardColorsConfigDataContainer {...cardColorConfig} isGlobal>
			{children}
		</CardColorsConfigDataContainer>
	);
};

export default CardColorsSettingsPage;
