import { useCallback } from 'react';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	FETCH_CARD_COLOR_STRATEGY_ACTION_SUBJECT,
	FETCH_CARD_COLOR_STRATEGY_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import type { FetchStrategyConfigInput, FetchStrategyConfigResponse } from './types.tsx';
import { getFetchStrategyConfigUrl } from './utils.tsx';

export const useFetchStrategyConfig = (rapidViewId: number): FetchStrategyConfigResponse => {
	const fetchStrategyConfig = useCallback(
		({ cardColorStrategy }: FetchStrategyConfigInput) =>
			performGetRequest(getFetchStrategyConfigUrl({ rapidViewId, cardColorStrategy })),
		[rapidViewId],
	);

	const {
		data,
		loading,
		error,
		hasError,
		fetch: commit,
		resetError,
	} = useServiceWithAnalytics<FetchStrategyConfigResponse['data'], FetchStrategyConfigInput>(
		fetchStrategyConfig,
		{},
		{
			actionSubject: FETCH_CARD_COLOR_STRATEGY_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${FETCH_CARD_COLOR_STRATEGY_BROWSER_METRICS_KEY}`,
		},
	);

	useErrorFlagOnUpdateFailure({ hasFailure: hasError });

	return { data, loading, error, hasError, commit, resetError };
};
