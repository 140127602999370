import React from 'react';
import { styled } from '@compiled/react';
import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEdit } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/permissions/index.tsx';
import { dataTestIds } from '../../../common/constants/data-test.tsx';
import messages from './messages.tsx';
import { useCompletedIssues } from './utils.tsx';

export const CompletedIssuesField = () => {
	const { formatMessage } = useIntl();
	const canEdit = useCanEdit();
	const { oldDoneIssuesCutoffOptions, currentOldDoneIssuesCutoff, onChange } = useCompletedIssues();

	return (
		<Container data-testid={dataTestIds.completedIssuesField}>
			<Field name="completed-issues" label={formatMessage(messages.label)}>
				{({ fieldProps: { id, ...props } }) => (
					<Select
						{...props}
						inputId={id}
						value={currentOldDoneIssuesCutoff}
						options={oldDoneIssuesCutoffOptions}
						onChange={onChange}
						isDisabled={!canEdit}
					/>
				)}
			</Field>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'grid',
	gridTemplateColumns: '350px 1fr',
});
