import { useCallback } from 'react';
import {
	useAnalyticsEvents,
	fireTrackAnalytics,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useServiceDeskAnalyticsContext } from '@atlassian/jira-router-resources-service-desk-analytics-context/src/services/index.tsx';

export const useFireAnalyticsTrackEvent = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data: analyticsContextAttributes } = useServiceDeskAnalyticsContext();
	const { data: project } = useProjectContext();

	const fireTrackEvent = useCallback(
		(actionName: string, extraAttributes: Attributes = {}) => {
			const event = createAnalyticsEvent({});
			fireTrackAnalytics(event, actionName, {
				...analyticsContextAttributes,
				projectId: project?.projectId,
				...extraAttributes,
			});
		},
		[analyticsContextAttributes, createAnalyticsEvent, project?.projectId],
	);

	return { fireTrackEvent };
};
