import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import { Inline, Box, xcss, Flex } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { StaleColorRemovalButtonProps } from '../../../common/types/index.tsx';
import messages from './messages.tsx';

export const StaleColorRemovalButton = ({
	onDelete,
	item,
	staleColorWarningMessage,
}: StaleColorRemovalButtonProps) => {
	const { formatMessage } = useIntl();
	const handleClick = useCallback(() => {
		onDelete(item);
	}, [onDelete, item]);

	return (
		<Inline>
			<Box xcss={staleColorWarningMessageStyles}>{staleColorWarningMessage}</Box>

			<Flex xcss={staleColorWarningButtonStyles}>
				<Button appearance="link" spacing="compact" onClick={handleClick}>
					{formatMessage(messages.removeButtonLabel)}
				</Button>
			</Flex>
		</Inline>
	);
};

const staleColorWarningMessageStyles = xcss({
	color: 'color.text.subtlest',
	alignSelf: 'center',
	paddingInlineStart: 'space.200',
});

const staleColorWarningButtonStyles = xcss({
	alignSelf: 'center',
});
