import { useCallback } from 'react';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { APP_METRIC_KEY } from '@atlassian/jira-software-board-settings-general-common/src/common/constants/analytics.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnFetchFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	FETCH_MY_PERMISSIONS_ACTION_SUBJECT,
	FETCH_MY_PERMISSIONS_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { FETCH_MY_PERIMISSIONS_API_URI } from '../../common/constants/url.tsx';
import type {
	FetchUserProjectPermissionsRequest,
	FetchUserProjectPermissionsResponse,
} from './types.tsx';

export const useFetchMyPermissions = () => {
	const boardAdmins = useCallback(
		({ projectId }: FetchUserProjectPermissionsRequest) =>
			performGetRequest<FetchUserProjectPermissionsResponse>(
				`${FETCH_MY_PERIMISSIONS_API_URI}?projectId=${projectId}&permissions=ADMINISTER_PROJECTS`,
			),
		[],
	);

	const {
		data,
		loading,
		error,
		hasError,
		fetch: commit,
		resetError,
	} = useServiceWithAnalytics(
		boardAdmins,
		{},
		{
			actionSubject: FETCH_MY_PERMISSIONS_ACTION_SUBJECT,
			packageName: APP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_METRIC_KEY}.${FETCH_MY_PERMISSIONS_METRICS_KEY}`,
		},
	);

	useErrorFlagOnFetchFailure({ hasFailure: hasError });

	return {
		loading,
		error,
		data,
		commit,
		resetError,
		hasError,
	};
};
