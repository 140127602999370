import { useJiraUserBoardNavigationResource } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/jsw-user-board-navigation-resource/index.tsx';
import { useJiraSoftwareProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { useIsUserLocatedBoard } from '../use-is-user-located-board/index.tsx';

export const useIsKanbanBoard = (): boolean => {
	const isUserLocated = useIsUserLocatedBoard();
	// This hook returns project-located board data
	const { data: projectData } = useJiraSoftwareProjectNavigation();
	// This hook returns user-located board data
	const { data: userData } = useJiraUserBoardNavigationResource();

	return isUserLocated
		? userData?.boardInfo?.type === 'kanban'
		: projectData?.boardInfo?.type === 'kanban';
};
