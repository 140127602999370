import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-general-completed-issues-settings.completed-issues-section.title',
		defaultMessage: 'Completed issues',
		description: 'Title of the completed issues section of the page.',
	},
	about: {
		id: 'software-board-settings-general-completed-issues-settings.completed-issues-section.about',
		defaultMessage: 'Choose how long completed issues stay on your board.',
		description: 'Describes the purpose of the completed issues setting.',
	},
});
