import { useCallback } from 'react';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { APP_METRIC_KEY } from '@atlassian/jira-software-board-settings-general-common/src/common/constants/analytics.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnFetchFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	FETCH_GENERAL_SAVED_FILTERS_ACTION_SUBJECT,
	FETCH_GENERAL_SAVED_FILTERS_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { SAVED_FILTER_API_PATH } from '../../common/constants/urls.tsx';
import type { FetchSavedFiltersQuery, FetchSavedFiltersResponse } from './types.tsx';

export const useFetchRemoteSavedFilters = () => {
	const fetchSavedFilters = useCallback(
		({ query }: FetchSavedFiltersQuery) =>
			performGetRequest<FetchSavedFiltersResponse>(`${SAVED_FILTER_API_PATH}?query=${query}`),
		[],
	);

	const { hasError, loading, fetch } = useServiceWithAnalytics(
		fetchSavedFilters,
		{},
		{
			actionSubject: FETCH_GENERAL_SAVED_FILTERS_ACTION_SUBJECT,
			packageName: APP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_METRIC_KEY}.${FETCH_GENERAL_SAVED_FILTERS_METRICS_KEY}`,
		},
	);

	useErrorFlagOnFetchFailure({ hasFailure: hasError });

	return { commit: fetch, loading };
};
