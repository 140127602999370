import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-estimation.estimation-settings-page.estimation-method.title',
		defaultMessage: 'Estimation method',
		description: 'Title for the estimation method seciton of the page',
	},
	description: {
		id: 'software-board-settings-estimation.estimation-settings-page.estimation-method.description',
		defaultMessage:
			'Select how you’ll measure effort. Reports and insights also use this method to help you analyze output.',
		description:
			'Description on what estimation methods are and how it is used to measure velocity',
	},
	fieldLabel: {
		id: 'software-board-settings-estimation.estimation-settings-page.estimation-method.field-label',
		defaultMessage: 'Estimation method',
		description: 'Field label for the select input',
	},
	successFlagTitle: {
		id: 'software-board-settings-estimation.estimation-settings-page.estimation-method.success-flag-title',
		defaultMessage: 'Your estimation method was changed to {estimationMethod}',
		description: 'Success flag title after succesfully changing the estimation method',
	},
});
