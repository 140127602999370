import React, { type UIEvent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { type Appearance } from '@atlaskit/button';
import ButtonGroup from '@atlaskit/button/button-group';
import { ModalFooter } from '@atlaskit/modal-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

type Props = {
	isActionEnabled: boolean;
	isActionInProgress: boolean;
	actionButtonCaption: string;
	actionButtonAppearance: Appearance;
	onAction: (arg1: UIEvent, arg2: UIAnalyticsEvent) => void;
	onCancel: () => void;
	intl: IntlShape;
};

export function ActionModalFooter(props: Props) {
	const {
		isActionEnabled,
		isActionInProgress,
		actionButtonCaption,
		actionButtonAppearance,
		onCancel,
		onAction,
		intl: { formatMessage },
	} = props;

	const SaveBtn = (
		<Button
			appearance={actionButtonAppearance}
			type="submit"
			isDisabled={!isActionEnabled}
			onClick={onAction}
		>
			{actionButtonCaption}
		</Button>
	);

	const CancelBtn = (
		<Button appearance="subtle-link" isDisabled={isActionInProgress} onClick={onCancel}>
			{formatMessage(messages.cancelButtonCaption)}
		</Button>
	);

	const footerGroup =
		isVisualRefreshEnabled() && fg('visual-refresh_drop_2') ? (
			<>
				{CancelBtn}
				{SaveBtn}
			</>
		) : (
			<>
				{SaveBtn}
				{CancelBtn}
			</>
		);

	return (
		<ModalFooter>
			<Container>
				{isActionInProgress && (
					<Box xcss={spinnerContainerStyles}>
						<Spinner size="small" />
					</Box>
				)}
				<ButtonGroup>{footerGroup}</ButtonGroup>
			</Container>
		</ModalFooter>
	);
}

ActionModalFooter.defaultProps = {
	isActionEnabled: true,
	isActionInProgress: false,
	actionButtonAppearance: 'primary' as const,
	onAction: noop,
	onCancel: noop,
};

export default injectIntl(ActionModalFooter);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'center',
	flexGrow: 1,
});

const spinnerContainerStyles = xcss({
	marginRight: 'space.200',
});
