import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import DynamicTableStateless from '@atlaskit/dynamic-table';

type PropsOf<T> = T extends ComponentType<infer U> ? U : never;

type Width = 'fill' | 'initial';

type Props = PropsOf<typeof DynamicTableStateless> & {
	width: Width;
};

export const DynamicTable = ({ width, ...props }: Props) => (
	<Wrapper width={width}>
		<DynamicTableStateless {...props} />
	</Wrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ width: Width }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (p) => (p.width === 'fill' ? '100%' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		marginBottom: '0',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > table': {
		borderBlockEnd: '0',
		// necessary to deal with legacy global styles
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		width: (p) => (p.width === 'fill' ? '100%' : undefined),
	},
});
