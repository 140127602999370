import React, { useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { Field, HelperMessage } from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import Select, { type InputActionMeta, type ValueType } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import {
	useCanEdit,
	useCanUseBoardAdminsPicker,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/permissions/index.tsx';
import { ADMINISTRATOR_FIELD_WIDTH } from '../../common/constants/index.tsx';
import { ConfirmationModal } from '../../common/ui/confirmation-modal/index.tsx';
import messages from './messages.tsx';
import type { AdministratorFieldOption } from './types.tsx';
import {
	useAvailableBoardAdmins,
	useUpdateBoardAdminsUtils,
	mapToSelectOptions,
} from './utils.tsx';

const BoardAdministratorField = () => {
	const { formatMessage } = useIntl();
	const canEdit = useCanEdit();
	const canUseBoardAdminsPicker = useCanUseBoardAdminsPicker();
	const {
		loading: isOptionsLoading,
		availableBoardAdmins,
		fetchAvailableBoardAdmins,
	} = useAvailableBoardAdmins();

	const { selectedOptions, showModal, onModalSubmit, onModalDismiss, onSelectSubmit, onChange } =
		useUpdateBoardAdminsUtils();

	const adminOptions = useMemo(() => {
		if (availableBoardAdmins) {
			const options = [
				{
					label: formatMessage(messages.selectGroupLabelUsers),
					options: mapToSelectOptions(availableBoardAdmins.userKeys, 'userKeys'),
				},
				{
					label: formatMessage(messages.selectGroupLabelGroups),
					options: mapToSelectOptions(availableBoardAdmins.groupKeys, 'groupKeys'),
				},
			];

			return options;
		}
	}, [availableBoardAdmins, formatMessage]);

	const handleSearch = useCallback(
		(textInput: string, actionMeta: InputActionMeta) => {
			if (actionMeta.action === 'input-change') {
				fetchAvailableBoardAdmins({ query: textInput });
			}
		},
		[fetchAvailableBoardAdmins],
	);

	return (
		<Field<ValueType<AdministratorFieldOption>>
			id="boardAdmins"
			label={formatMessage(messages.fieldLabel)}
			name="boardAdmins"
			isRequired
		>
			{({ fieldProps: { id, ...otherFieldProps } }) => (
				<>
					<Box
						testId="software-board-settings-general-settings.ui.board-administrator-field.select"
						xcss={fieldWrapper}
					>
						<Select
							{...otherFieldProps}
							options={adminOptions}
							value={selectedOptions}
							onMenuOpen={() => fetchAvailableBoardAdmins({ query: '' })}
							inputId={id}
							isMulti
							required
							closeMenuOnSelect={false}
							onChange={onChange}
							onBlur={onSelectSubmit}
							name="boardAdminSelect"
							isDisabled={!canEdit || !canUseBoardAdminsPicker}
							instanceId="board-admins"
							isLoading={isOptionsLoading}
							onInputChange={debounce(handleSearch, 200)}
							placeholder={formatMessage(messages.selectFieldPlaceholderText)}
						/>
					</Box>
					{!canUseBoardAdminsPicker && (
						<HelperMessage>{formatMessage(messages.cannotEdit)}</HelperMessage>
					)}
					{showModal && (
						<ConfirmationModal
							title={formatMessage(messages.confirmationModalTitle)}
							description={formatMessage(messages.confirmationModalDescription)}
							onCancel={onModalDismiss}
							onSubmit={onModalSubmit}
						/>
					)}
				</>
			)}
		</Field>
	);
};

const fieldWrapper = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${ADMINISTRATOR_FIELD_WIDTH}px`,
});

export { BoardAdministratorField };
