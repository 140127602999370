import { useCallback, useMemo } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	ESTIMATION_ACTION_SOURCE,
	type TIME_TRACKING_ACTION_SOURCE,
	UPDATE_ESTIMATION_BROWSER_METRICS_KEY,
	UPDATE_TIME_TRACKING_BROWSER_METRICS_KEY,
	UPDATE_ESTIMATION_AND_TRACKING_ACTION_SUBJECT,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { ESTIMATION_API_URI } from '../../common/constants/url.tsx';
import type {
	UpdateEstimationAndTrackingInput,
	UpdateEstimationAndTrackingResponse,
} from './types.tsx';

export const useUpdateEstimationAndTracking = (
	actionSource: typeof ESTIMATION_ACTION_SOURCE | typeof TIME_TRACKING_ACTION_SOURCE,
) => {
	const updateEstimationAndTracking = useCallback(
		(payload: UpdateEstimationAndTrackingInput) =>
			performPutRequest(ESTIMATION_API_URI, {
				body: JSON.stringify(payload),
			}),
		[],
	);

	const browserMetricsKey = useMemo(
		() =>
			actionSource === ESTIMATION_ACTION_SOURCE
				? UPDATE_ESTIMATION_BROWSER_METRICS_KEY
				: UPDATE_TIME_TRACKING_BROWSER_METRICS_KEY,
		[actionSource],
	);

	const {
		data,
		loading,
		error,
		hasError,
		fetch: commit,
	} = useServiceWithAnalytics<
		UpdateEstimationAndTrackingResponse['data'],
		UpdateEstimationAndTrackingInput
	>(
		updateEstimationAndTracking,
		{},
		{
			actionSubject: UPDATE_ESTIMATION_AND_TRACKING_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
			attributes: {
				actionSource,
			},
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${browserMetricsKey}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		error,
	});

	return { data, loading, commit, error, hasError };
};
