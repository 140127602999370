import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { APP_METRIC_KEY } from '@atlassian/jira-software-board-settings-general-common/src/common/constants/analytics.tsx';
import { useQueryValidationErrorMessage } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-query-validation-error-message/index.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	UPDATE_GENERAL_BOARD_SUB_FILTER_ACTION_SUBJECT,
	UPDATE_GENERAL_BOARD_SUB_FILTER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { BOARD_SUB_FILTER_API_PATH } from '../../common/constants/urls.tsx';
import messages from './messages.tsx';
import type { UpdateBoardArguments } from './types.tsx';

export const useUpdateRemoteBoardSubFilter = (rapidBoardViewId: number) => {
	const { formatMessage } = useIntl();

	const updateRemoteBoardSubFilter = useCallback(
		({ query }: UpdateBoardArguments) =>
			performPutRequest<{ query: string } | undefined>(
				[BOARD_SUB_FILTER_API_PATH, rapidBoardViewId].join('/'),
				{
					body: JSON.stringify({
						query,
					}),
				},
			),
		[rapidBoardViewId],
	);

	const { error, hasError, fetch } = useServiceWithAnalytics(
		updateRemoteBoardSubFilter,
		{},
		{
			actionSubject: UPDATE_GENERAL_BOARD_SUB_FILTER_ACTION_SUBJECT,
			packageName: APP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_METRIC_KEY}.${UPDATE_GENERAL_BOARD_SUB_FILTER_METRICS_KEY}`,
		},
	);

	const queryValidationErrorMessage = useQueryValidationErrorMessage(error);
	useErrorFlagOnUpdateFailure({
		// Donot show error flag when we have validation error
		hasFailure: hasError && !queryValidationErrorMessage,
		error,
		// TODO discussion in https://atlassian.slack.com/archives/C054HN56ZLP/p1710900308815249
		errorTitle: formatMessage(messages.errorFlag),
	});

	return {
		queryValidationErrorMessage,
		commit: fetch,
	};
};
