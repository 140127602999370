import { useCallback, useState, useMemo } from 'react';
import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldMode } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import { emptyResponseCommitToBoolean } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/utils.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	DELETE_CARD_LAYOUT_FIELD_ACTION_SUBJECT,
	DELETE_CARD_LAYOUT_FIELD_PLAN_MODE_BROWSER_METRICS_KEY,
	DELETE_CARD_LAYOUT_FIELD_WORK_MODE_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { PLAN_MODE } from '../../common/constants/card-layout.tsx';
import { CARD_LAYOUT_API_URI } from '../../common/constants/url.tsx';
import messages from './messages.tsx';
import type { DeleteCardLayoutFieldInput, DeleteCardLayoutFieldResponse } from './types.tsx';

export const getDeleteUrl = ({
	rapidViewId,
	id,
	mode,
}: Omit<DeleteCardLayoutFieldInput, 'name'> & { rapidViewId: number }) =>
	`${CARD_LAYOUT_API_URI}/${rapidViewId}/${mode}/field/${id}`;

export const useDeleteCardLayoutField = (
	rapidViewId: number,
	fieldMode: FieldMode,
): DeleteCardLayoutFieldResponse => {
	const { formatMessage } = useIntl();

	const [fieldToDeleteName, setFieldToDeleteName] = useState<string | null>(null);

	const deleteCardLayoutField = useCallback(
		({ id, mode, name }: DeleteCardLayoutFieldInput) => {
			setFieldToDeleteName(name);
			return performDeleteRequest(getDeleteUrl({ rapidViewId, id, mode }));
		},
		[rapidViewId],
	);

	const browserMetricsKey = useMemo(
		() =>
			fieldMode === PLAN_MODE
				? DELETE_CARD_LAYOUT_FIELD_PLAN_MODE_BROWSER_METRICS_KEY
				: DELETE_CARD_LAYOUT_FIELD_WORK_MODE_BROWSER_METRICS_KEY,
		[fieldMode],
	);

	const { error, hasError, fetch, ...rest } = useServiceWithAnalytics<
		DeleteCardLayoutFieldResponse['data'],
		DeleteCardLayoutFieldInput
	>(
		deleteCardLayoutField,
		{},
		{
			actionSubject: DELETE_CARD_LAYOUT_FIELD_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
			attributes: {
				fieldMode,
			},
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${browserMetricsKey}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		error,
		errorTitle: formatMessage(messages.deleteCardLayoutFieldFailureTitle, {
			fieldName: fieldToDeleteName,
		}),
		errorMessage: formatMessage(messages.deleteCardLayoutFieldFailureDescription),
	});

	return { commit: emptyResponseCommitToBoolean(fetch), error, hasError, ...rest };
};
