import { useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import {
	BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES,
	type ConfigParamKey,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';

export const useConfigQuery = (): [ConfigParamKey | null, (param: string) => void] => {
	const [configQuery, setConfigParam] = useQueryParam('config');

	if (!configQuery) {
		throw new Error('No config param found');
	}

	const result = isConfigQuery(configQuery) ? configQuery : null;

	return [result, setConfigParam];
};

const isConfigQuery = (configQuery: string): configQuery is ConfigParamKey =>
	BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES.has(configQuery);

const needsEditModelSet: Set<ConfigParamKey> = new Set([
	'cardColors',
	'cardLayout',
	'estimation',
	'filter',
	'quickFilters',
	'swimlanes',
	'timelineConfig',
]);

export const useIsNeedsEditModel = (config: ConfigParamKey | null) =>
	useMemo(() => {
		if (!config) {
			return false;
		}

		return fg('board-settings-graphql-refactor_cyvz9') ? false : needsEditModelSet.has(config);
	}, [config]);
