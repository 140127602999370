import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moveUp: {
		id: 'software-board-settings-sortable-table.sortable-table.drag-handle.move-up',
		defaultMessage: 'Move up',
		description:
			'This is the label of a button in a flyout menu for moving row in a table up a row',
	},
	moveDown: {
		id: 'software-board-settings-sortable-table.sortable-table.drag-handle.move-down',
		defaultMessage: 'Move down',
		description:
			'This is the label of a button in a flyout menu for moving row in a table down a row',
	},
});
