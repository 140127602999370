import React, { useCallback, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { type Appearance, type Spacing } from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button/src/index.tsx';
import { fireUIAnalytics, type Attributes } from '@atlassian/jira-product-analytics-bridge';

export const useInProductHelp = ({
	articleId,
	articleAnalyticsId,
	analyticsAttributes = {},
}: {
	articleId: string;
	articleAnalyticsId: string;
	analyticsAttributes?: Attributes;
}) => {
	const onOpenInProductHelpArticle = useHelpPanelLink();

	const onClick = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (!articleId || !articleAnalyticsId) return;

			fireUIAnalytics(analyticsEvent, articleAnalyticsId, analyticsAttributes);
			onOpenInProductHelpArticle(articleId);
		},
		[onOpenInProductHelpArticle, articleAnalyticsId, articleId, analyticsAttributes],
	);

	return { onClick };
};

type Props = {
	children: ReactNode;
	articleId: string;
	articleAnalyticsId: string;
	tooltip?: ReactNode;
	appearance?: Appearance;
	analyticsAttributes?: Attributes;
	spacing?: Spacing;
	fontWeight?: number;
};

export const InProductHelpButton = ({
	children,
	articleId,
	articleAnalyticsId,
	tooltip,
	appearance,
	analyticsAttributes = {},
	spacing = 'none',
	fontWeight,
}: Props) => {
	const onOpenInProductHelpArticle = useHelpPanelLink();
	const onClickHandler = useCallback(
		(_: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, articleAnalyticsId, analyticsAttributes);
			onOpenInProductHelpArticle(articleId);
		},
		[onOpenInProductHelpArticle, articleAnalyticsId, articleId, analyticsAttributes],
	);

	return (
		<ButtonWrapper>
			<Tooltip content={tooltip != null ? tooltip : children}>
				<StyledButton
					onClick={onClickHandler}
					appearance={appearance != null ? appearance : 'subtle'}
					spacing={spacing}
					fontWeight={fontWeight}
				>
					{children}
				</StyledButton>
			</Tooltip>
		</ButtonWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const StyledButton = styled(Button)<{ fontWeight?: number }>(({ fontWeight }) =>
	fontWeight !== undefined ? { fontWeight } : {},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	display: 'inline-block',
});
