import { createStateHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const useRapidViewId = createStateHook(Store, {
	selector: (state: State): number => state.rapidViewId,
});

export const useIsInitialised = createStateHook(Store, {
	selector: (state: State): boolean => state.isInitialised,
});

export const useIsScrumBoard = createStateHook(Store, {
	selector: ({ isSprintSupportEnabled }) => isSprintSupportEnabled,
});

export const useIsCMPBoard = createStateHook(Store, {
	selector: ({ isCMPBoard }) => isCMPBoard,
});

export const useWarnBeforeEditingOwner = createStateHook(Store, {
	selector: ({ warnBeforeEditingOwner }) => warnBeforeEditingOwner,
});

export const useWarnBeforeEditingLocation = createStateHook(Store, {
	selector: ({ warnBeforeEditingLocation }) => warnBeforeEditingLocation,
});
