import React from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { fg } from '@atlassian/jira-feature-gating';
import AddOnImageOld from '@atlassian/jira-illustrations/src/assets/add-on-2.svg';
import AddOnImage, {
	viewBox,
} from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/add-on-v2/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	onAdd: () => void;
};

const MAX_IMAGE_HEIGHT = 160;

export const EmptyView = ({ onAdd }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<EmptyState
			header={formatMessage(messages.header)}
			description={formatMessage(messages.description)}
			primaryAction={
				<Button appearance="link" onClick={onAdd}>
					{formatMessage(messages.buttonText)}
				</Button>
			}
			{...(fg('jsw-board-settings-swimlane-asset-consolidation')
				? {
						renderImage: getAkEmptyStateRenderImageFn(AddOnImage),
						maxImageHeight: MAX_IMAGE_HEIGHT,
						// Max width is calcualted based on max height to maintain the original image's aspect ratio
						maxImageWidth: MAX_IMAGE_HEIGHT * (viewBox[2] / viewBox[3]),
					}
				: { imageUrl: AddOnImageOld })}
		/>
	);
};
