import { CardColorStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import type { State } from '../types/index.tsx';

export const INITIAL_STATE: State = {
	rapidViewId: -1,
	canEdit: false,
	cardColorStrategy: CardColorStrategy.none,
	cardColors: [],
	isOptimisticCardColors: false,
	cardColorsBeforeOptimistic: null,
	isInitialised: false,
	isCMPBoard: true,
	itemToDelete: null,
	itemToEdit: null,
	nextCardColorStrategy: null,
};
