import parameters from '@atlassian/jira-relay/src/__generated__/estimationSettingsPageQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { boardId: string; cloudId: string };
export const estimationPageEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jsw-board-settings-estimation" */ './ui/estimation-settings-page/index.tsx'
		).then((module) => module.EstimationSettingPageContent),
	),
	getPreloadProps: ({ boardId, cloudId }: EntryPointParams) => ({
		queries: {
			queryReference: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters,
				variables: {
					boardId,
					cloudId,
				},
			},
		},
	}),
});
