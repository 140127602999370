export const VIEW_GENERAL_SETTINGS_EXPERIENCE_NAME = 'viewTimelineSettings';

export const ENABLE_TIMELINE_ACTION_SUBJECT = 'enableTimeline';
export const ENABLE_TIMELINE_BROWSER_METRICS_KEY = 'enable-timeline';

export const DISABLE_TIMELINE_ACTION_SUBJECT = 'disableTimeline';
export const DISABLE_TIMELINE_BROWSER_METRICS_KEY = 'disable-timeline';

export const ENABLE_INCLUDE_CHILD_LEVEL_ISSUES_ACTION_SUBJECT = 'enableIncludeChildLevelIssues';
export const ENABLE_INCLUDE_CHILD_LEVEL_ISSUES_BROWSER_METRICS_KEY =
	'enable-include-child-level-issues';

export const DISABLE_INCLUDE_CHILD_LEVEL_ISSUES_ACTION_SUBJECT = 'disableIncludeChildLevelIssues';
export const DISABLE_INCLUDE_CHILD_LEVEL_ISSUES_BROWSER_METRICS_KEY =
	'disable-include-child-level-issues';

export const ENABLE_CHILD_LEVEL_ISSUES_SCHEDULING_ACTION_SUBJECT =
	'enableChildLevelIssuesScheduling';
export const ENABLE_CHILD_LEVEL_ISSUES_SCHEDULING_BROWSER_METRICS_KEY =
	'enable-child-level-issues-scheduling';

export const DISABLE_CHILD_LEVEL_ISSUES_SCHEDULING_ACTION_SUBJECT =
	'disableChildLevelIssuesScheduling';
export const DISABLE_CHILD_LEVEL_ISSUES_SCHEDULING_BROWSER_METRICS_KEY =
	'disable-child-level-issues-scheduling';
