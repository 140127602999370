import { useRef, useMemo, useEffect } from 'react';
import { determineFocusFromDiff, type FocusDirective } from '../../utils/focus-handler/index.tsx';

export const useFocusDirective = function <T extends { id: unknown }>(current: T[]) {
	const previousRef = useRef<T[]>();

	return useMemo<FocusDirective | undefined>(() => {
		const { current: previous } = previousRef;

		if (previous == null) {
			previousRef.current = current;
			return;
		}

		previousRef.current = current;

		return determineFocusFromDiff(previous, current);
	}, [current]);
};

export const useInternalRowFocusRecovery = function <T extends { id: unknown }>(current: T[]) {
	const directive = useFocusDirective(current);

	const tableRef = useRef<HTMLDivElement & HTMLTableElement>(null);

	useEffect(() => {
		if (directive == null) return;

		// Focus does not always work without the setTimeout
		// this issue can be replicated in the integration
		// tests when commenting this out.
		const timeout = setTimeout(() => {
			if (directive.location === 'row') {
				const rows = tableRef.current?.querySelectorAll<HTMLTableRowElement>('tbody > tr');
				rows && Array.from(rows)[directive.index]?.focus();
			}
		}, 0);

		return () => clearTimeout(timeout);
	}, [directive]);

	return tableRef;
};

export const useExternalRowFocusRecovery = function <T extends { id: unknown }>(current: T[]) {
	const directive = useFocusDirective(current);
	const externalRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (directive == null) return;

		// Focus does not always work without the setTimeout
		// this issue can be replicated in the integration
		// tests when commenting this out.
		const timeout = setTimeout(() => {
			if (directive.location === 'external') {
				externalRef.current?.focus();
			}
		}, 0);

		return () => clearTimeout(timeout);
	}, [directive]);

	return externalRef;
};
