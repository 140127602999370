import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fieldLabel: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.field-label',
		defaultMessage: 'Select method',
		description: 'Label text for the Swimlane Strategy select field',
	},
	queriesOptionLabel: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.queries-option-label',
		defaultMessage: 'Queries',
		description: 'Label text for Queries option for the Swimlane Strategy select field',
	},
	storiesOptionLabel: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.stories-option-label',
		defaultMessage: 'Stories',
		description: 'Label text for Stories option for the Swimlane Strategy select field',
	},
	assigneeOptionLabel: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.assignee-option-label',
		defaultMessage: 'Assignee',
		description: 'Label text for Assignee option for the Swimlane Strategy select field',
	},
	epicsOptionLabel: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.epics-option-label',
		defaultMessage: 'Epics',
		description: 'Label text for Epics option for the Swimlane Strategy select field',
	},
	projectsOptionLabel: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.projects-option-label',
		defaultMessage: 'Projects',
		description: 'Label text for Projects option for the Swimlane Strategy select field',
	},
	noneOptionLabel: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.none-option-label',
		defaultMessage: 'None',
		description: 'Label text for None option for the Swimlane Strategy select field',
	},
	storiesOptionHelperMessage: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.stories-option-helper-message',
		defaultMessage:
			'Group sub-tasks under their parent issue. Issues without sub-tasks will be grouped in their own swimlane.',
		description: 'Helper Message text for Stories option for the Swimlane Strategy select field',
	},
	epicsOptionHelperMessage: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.epics-option-helper-message',
		defaultMessage:
			"Issues without an epic will be grouped in their own swimlane. Epics with no issues on the board won't display at all.",
		description: 'Helper Message text for Epics option for the Swimlane Strategy select field',
	},
	epicsOptionCustomNameHelperMessage: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.epics-option-custom-name-helper-message',
		defaultMessage:
			"Issues without {levelOneName} will be grouped in their own swimlane. {levelOneName} with no issues on the board won't display at all.",
		description:
			'Helper Message text for Epics option for the Swimlane Strategy select field when custom name is configured',
	},
	noneOptionHelperMessage: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.none-option-helper-message',
		defaultMessage: 'No swimlanes will be displayed.',
		description: 'Helper Message text for None option for the Swimlane Strategy select field',
	},
	requestTypeOptionLabel: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.request-type-option-label',
		defaultMessage: 'Request types',
		description: 'Label text for Request Types option for the Swimlane Strategy select field',
	},
	storiesOptionHelperMessageIssueTermRefresh: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.stories-option-helper-message-issue-term-refresh',
		defaultMessage:
			'Group subtasks under their parent work item. Work items without subtasks will be grouped in their own swimlane.',
		description: 'Helper Message text for Stories option for the Swimlane Strategy select field',
	},
	epicsOptionCustomNameHelperMessageIssueTermRefresh: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.epics-option-custom-name-helper-message-issue-term-refresh',
		defaultMessage:
			"Work items without {levelOneName} will be grouped in their own swimlane. {levelOneName} with no work items on the board won't display at all.",
		description:
			'Helper Message text for Epics option for the Swimlane Strategy select field when custom name is configured',
	},
	epicsOptionHelperMessageIssueTermRefresh: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.swimlane-strategy-field.epics-option-helper-message-issue-term-refresh',
		defaultMessage:
			"Work items without an epic will be grouped in their own swimlane. Epics with no work items on the board won't display at all.",
		description: 'Helper Message text for Epics option for the Swimlane Strategy select field',
	},
});
