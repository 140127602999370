import { useCallback } from 'react';
import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	DELETE_SWIMLANE_ACTION_SUBJECT,
	DELETE_SWIMLANE_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { SWIMLANE_API_URI } from '../../common/constants/url.tsx';
import type { DeleteSwimlaneResponse } from './types.tsx';

export const useDeleteSwimlane = (
	rapidViewId: number,
	errorTitle: string,
): DeleteSwimlaneResponse => {
	const deleteSwimlane = useCallback(
		(swimlaneId: number) =>
			performDeleteRequest(`${SWIMLANE_API_URI}/${rapidViewId}/${swimlaneId}`),
		[rapidViewId],
	);
	const {
		data,
		loading,
		error,
		hasError,
		fetch: commit,
		resetError,
	} = useServiceWithAnalytics<DeleteSwimlaneResponse['data'], number>(
		deleteSwimlane,
		{},
		{
			actionSubject: DELETE_SWIMLANE_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${DELETE_SWIMLANE_BROWSER_METRICS_KEY}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		handleValidationError: true,
		error,
		errorTitle,
	});

	return { loading, error, data, commit, resetError, hasError };
};
