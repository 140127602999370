import { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { defaultColorPalette } from '../../common/constants/index.tsx';
import type { PaletteColor } from '../../common/types.tsx';
import messages from './messages.tsx';

type MessagesKey = keyof typeof messages;

export const useDefaultI18nColorPalette = (): PaletteColor[] => {
	const { formatMessage } = useIntl();

	return useMemo(
		() =>
			defaultColorPalette.map(
				({ label, value }) => ({
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					label: formatMessage(messages[label as MessagesKey]),
					value,
				}),
				[],
			),
		[formatMessage],
	);
};
