import { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { cardLayoutSettingsPageQuery$data } from '@atlassian/jira-relay/src/__generated__/cardLayoutSettingsPageQuery.graphql';
import {
	BACKLOG_FEATURE_KEY,
	SPRINTS_FEATURE_KEY,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import type {
	CurrentField,
	FieldMode,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import type { CardLayoutConfigProps } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { PLAN_MODE, WORK_MODE } from '../../common/constants/card-layout.tsx';
import { dataTestIds } from '../../common/constants/data-test.tsx';
import { useIsKanPlanEnabled, useIsScrumBoard } from '../../controllers/selectors/board/index.tsx';
import { useCurrentFields } from '../../controllers/selectors/card-layout/index.tsx';
import messages from './messages.tsx';

type TableConfig = {
	title: string;
	fields: CurrentField[];
	fieldMode: FieldMode;
	testId: string;
};

export const useCardLayoutTableConfig = () => {
	const { formatMessage } = useIntl();

	const isJSMBoard = useIsJSMBoard();

	const [currentFields] = useCurrentFields();
	const [isScrumBoard] = useIsScrumBoard();
	const [isKanPlanEnabled] = useIsKanPlanEnabled();

	const [planModeFields, workModeFields] = useMemo(
		() => [
			currentFields.filter((field) => field.mode === PLAN_MODE),
			currentFields.filter((field) => field.mode === WORK_MODE),
		],
		[currentFields],
	);

	return useMemo(() => {
		const tableConfig: Array<TableConfig> = [];

		if (isJSMBoard) {
			tableConfig.push({
				title: formatMessage(messages.jsmBoard),
				fields: workModeFields,
				fieldMode: WORK_MODE,
				testId: dataTestIds.workModeTable,
			});
			return tableConfig;
		}

		if (isScrumBoard || isKanPlanEnabled) {
			tableConfig.push({
				title: formatMessage(messages.backlog),
				fields: planModeFields,
				fieldMode: PLAN_MODE,
				testId: dataTestIds.planModeTable,
			});
		}

		tableConfig.push({
			title: formatMessage(isScrumBoard ? messages.activeSprints : messages.kanbanBoard),
			fields: workModeFields,
			fieldMode: WORK_MODE,
			testId: dataTestIds.workModeTable,
		});

		return tableConfig;
	}, [formatMessage, isKanPlanEnabled, isScrumBoard, planModeFields, workModeFields, isJSMBoard]);
};

// TODO: remove all this transform logic once card layout page is refactored to adopt relay fully
export const transformCardLayoutData = (
	boardScope: cardLayoutSettingsPageQuery$data['boardScope'],
): Omit<CardLayoutConfigProps, 'rapidViewId' | 'canEdit'> => {
	const {
		features,
		board: { showDaysInColumn },
		cardLayoutConfig: { backlog, board },
	} = boardScope;

	const isBacklogEnabled = features.some(
		(feature) => feature && feature.key === BACKLOG_FEATURE_KEY && feature.status === 'ENABLED',
	);
	const isSprintEnabled = features.some(
		(feature) => feature && feature.key === SPRINTS_FEATURE_KEY && feature.status === 'ENABLED',
	);

	const currentFields = [
		...backlog.fields.flatMap((field) => {
			if (!field) {
				return [];
			}
			const { cardLayoutId, ...rest } = field;
			return [{ ...rest, id: Number(cardLayoutId), mode: PLAN_MODE }];
		}),
		...board.fields.flatMap((field) => {
			if (!field) {
				return [];
			}
			const { cardLayoutId, ...rest } = field;
			return [{ ...rest, id: Number(cardLayoutId), mode: WORK_MODE }];
		}),
	];
	const availableFields = [
		...backlog.availableFields.edges.flatMap((edge) =>
			edge ? [{ ...edge.node, mode: PLAN_MODE }] : [],
		),
		...board.availableFields.edges.flatMap((edge) =>
			edge ? [{ ...edge.node, mode: WORK_MODE }] : [],
		),
	];

	return {
		showDaysInColumn,
		isSprintSupportEnabled: isSprintEnabled,
		isKanPlanEnabled: isBacklogEnabled && !isSprintEnabled,
		currentFields,
		availableFields,
	};
};
