import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { APP_METRIC_KEY } from '@atlassian/jira-software-board-settings-general-common/src/common/constants/analytics.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import {
	UPDATE_ISSUE_RANK_ACTION_SUBJECT,
	UPDATE_ISSUE_RANK_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { UPDATE_ISSUE_RANK_API_PATH } from '../../common/constants/urls.tsx';
import type { UpdateBoardIssueRankInput, UpdateBoardIssueRankResponse } from './types.tsx';

export const useUpdateRemoteIssueRank = (): UpdateBoardIssueRankResponse => {
	const updateIssueRank = useCallback(
		({ id }: UpdateBoardIssueRankInput) =>
			performPutRequest(`${UPDATE_ISSUE_RANK_API_PATH}`, {
				body: JSON.stringify({
					id,
				}),
			}),
		[],
	);

	const {
		data,
		loading,
		error,
		hasError,
		fetch: commit,
		resetError,
	} = useServiceWithAnalytics<UpdateBoardIssueRankResponse['data'], UpdateBoardIssueRankInput>(
		updateIssueRank,
		{},
		{
			actionSubject: UPDATE_ISSUE_RANK_ACTION_SUBJECT,
			packageName: APP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_METRIC_KEY}.${UPDATE_ISSUE_RANK_METRICS_KEY}`,
		},
	);

	return { data, loading, error, hasError, commit, resetError };
};
