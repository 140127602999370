import React, { memo, useCallback, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../common/constants/data-test.tsx';
import type { QueryItem } from '../../common/types/index.tsx';
import { DescriptionField } from '../../common/ui/modal-form/description-field/index.tsx';
import { ModalForm } from '../../common/ui/modal-form/index.tsx';
import { NameField } from '../../common/ui/modal-form/name-field/index.tsx';
import { QueryField, type QueryFieldValue } from '../../common/ui/modal-form/query-field/index.tsx';
import messages from './messages.tsx';

export interface EditQueryModalProps {
	queryItem: QueryItem;
	title: string;
	description: React.ReactNode;
	onSubmit: (newQueryItem: QueryItem) => Promise<void>;
	onCancel: () => void;
	isDisabled?: boolean;
	analyticsSource: string;
	queryValidationErrorMessage?: string;
}

type ModalFormValue = {
	name: string;
	query: QueryFieldValue;
	description: string;
};

export const EditQueryModal = memo(
	({
		onSubmit,
		queryItem,
		analyticsSource,
		queryValidationErrorMessage,
		...props
	}: EditQueryModalProps) => {
		const { formatMessage } = useIntl();
		const [isSubmitting, setIsSubmitting] = useState(false);
		const { id } = queryItem;

		const onSubmitImpl = useCallback(
			async ({ query: { jql }, ...value }: ModalFormValue) => {
				setIsSubmitting(true);
				await onSubmit({ ...value, id, query: jql });
				setIsSubmitting(false);
			},
			[onSubmit, id],
		);

		const { name, description, query } = queryItem;

		return (
			<ModalForm<ModalFormValue>
				onSubmit={onSubmitImpl}
				submitButtonLabel={formatMessage(messages.submit)}
				isSubmitting={isSubmitting}
				testId={dataTestIds.editModal}
				{...props}
			>
				<NameField name="name" defaultValue={name} />
				<QueryField
					name="query"
					defaultValue={query}
					analyticsSource={analyticsSource}
					queryValidationErrorMessage={queryValidationErrorMessage}
				/>
				<DescriptionField name="description" defaultValue={description} />
			</ModalForm>
		);
	},
);
