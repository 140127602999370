import React, { useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { InProductHelpButton } from '@atlassian/jira-insights-common/src/common/ui/in-product-help-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { PageHeader } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/page-header/index.tsx';
import {
	BOARD_SETTINGS_ARTICLE_ID_ESTIMATION,
	BOARD_SETTINGS_ARTICLE_ID_ESTIMATION_ANALYTICS_ID,
} from '../../../common/constants/estimation.tsx';
import messages from './messages.tsx';

export const Header = () => {
	const { formatMessage } = useIntl();

	const onHelpClick = useCallback(
		(_e: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) =>
			fireUIAnalytics(analyticsEvent, BOARD_SETTINGS_ARTICLE_ID_ESTIMATION_ANALYTICS_ID),
		[],
	);

	return (
		<PageHeader
			title={formatMessage(messages.title)}
			description={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.descriptionIssueTermRefresh
					: messages.description,
				{
					button: (chunks: React.ReactNode[]) => {
						return fg('jsw-board-settings-general-page-a11y-fixes') ? (
							<HelpPanelLink
								articleId={BOARD_SETTINGS_ARTICLE_ID_ESTIMATION}
								appearance="link"
								spacing="none"
								onClick={onHelpClick}
								fallbackComponent={null}
							>
								{chunks}
							</HelpPanelLink>
						) : (
							<InProductHelpButton
								articleId={BOARD_SETTINGS_ARTICLE_ID_ESTIMATION}
								articleAnalyticsId={BOARD_SETTINGS_ARTICLE_ID_ESTIMATION_ANALYTICS_ID}
								appearance="link"
							>
								{chunks}
							</InProductHelpButton>
						);
					},
				},
			)}
		/>
	);
};
