import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addCardColorDescription: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.query-strategy.add-card-color-description',
		defaultMessage:
			'Assign colors to cards using Jira Query Language (JQL). If a card matches more than one query, it will be assigned the color of the first query it matches in your list.',
		description: 'Card color table description',
	},
	addButtonLabel: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.query-strategy.add-button-label',
		defaultMessage: 'Add color',
		description: 'Button label for adding a new card color',
	},
});
