import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	confirm: {
		id: 'software-board-settings-general-settings.common.confirmation-modal.confirm',
		defaultMessage: 'Confirm',
		description:
			'Label for confirmation button in dialog. Said dialogs purpose is to get additional user confirmation before they perform an action they cannot undo themselves.',
	},
	cancel: {
		id: 'software-board-settings-general-settings.common.confirmation-modal.cancel',
		defaultMessage: 'Cancel',
		description:
			'Label for cancel button in dialog. Said dialogs purpose is to get additional user confirmation before they perform an action they cannot undo themselves.',
	},
});
