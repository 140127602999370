import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	successFlagTitleForUpdatingSwimlane: {
		id: 'software-board-settings-swimlanes.controllers.handlers.use-edit-swimlane-handler.success-flag-title-for-updating-swimlane',
		defaultMessage: "You've updated a swimlane",
		description: 'Title of success flag when succesfully update a swimlane',
	},
	failedToUpdateErrorTitle: {
		id: 'software-board-settings-swimlanes.controllers.handlers.use-edit-swimlane-handler.failed-to-update-error-title',
		defaultMessage: "We couldn't update your swimlane",
		description: 'Title of error flag when failed to update a new swimlane',
	},
	validationErrorInstructionForUpdatingSwimlane: {
		id: 'software-board-settings-swimlanes.controllers.handlers.use-edit-swimlane-handler.validation-error-instruction-for-updating-swimlane',
		defaultMessage: 'Update the query and save it again.',
		description: 'Instruction when failing to update a query.',
	},
});
