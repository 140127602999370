import React, { useCallback, useEffect, useMemo } from 'react';
import traceUFOTransition from '@atlaskit/react-ufo/trace-transition';
import { JiraSoftwareBoardAri } from '@atlassian/ari/jira-software/board';
import { useBoardId } from '@atlassian/jira-board-settings-common/src/common/utils/use-board-id/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JSErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	APP_IDENTIFIER,
	APP_TEAM_NAME,
	BOARD_SETTINGS_WORKING_DAYS_UFO_NAME,
	COMPONENT_ID,
} from './common/constants.tsx';
import { workingDaysPageEntryPoint } from './entrypoint.tsx';
import { useWorkingDaysSettingsExperienceTracker } from './services/experience-tracker/index.tsx';

export const WorkingDaysSettingsPage = () => {
	const boardId = useBoardId();
	const cloudId = useCloudId();

	const boardAri = JiraSoftwareBoardAri.create({
		siteId: cloudId,
		boardId: String(boardId),
	}).toString();

	const entrypointParams = useMemo(() => ({ boardId: boardAri }), [boardAri]);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		workingDaysPageEntryPoint,
		entrypointParams,
	);

	const { fireExperienceError } = useWorkingDaysSettingsExperienceTracker({
		isClassic: true,
	});

	const errorFallback = useCallback(
		({ error }: { error: Error }) => {
			fireExperienceError(error);

			return <JSErrorPageAsync error={error} isFullPage={false} />;
		},
		[fireExperienceError],
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
		return () => {
			entryPointActions.unload();
		};
	}, [entryPointActions]);

	useEffect(() => {
		if (fg('onboard_board_settings_to_vc90')) {
			traceUFOTransition(BOARD_SETTINGS_WORKING_DAYS_UFO_NAME);
		}
	}, []);

	return (
		<JiraEntryPointContainer
			id={COMPONENT_ID}
			packageName={APP_IDENTIFIER}
			teamName={APP_TEAM_NAME}
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={{}}
			fallback={null}
			errorFallback={errorFallback}
		/>
	);
};
