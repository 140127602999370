export const dataTestIds = {
	createModal: 'software-board-settings-query-list-config-common.ui.create-query-modal.modal',
	deleteModal: 'software-board-settings-query-list-config-common.ui.delete-query-modal.modal',
	editModal: 'software-board-settings-query-list-config-common.ui.edit-query-modal.modal',
	modalColorField:
		'software-board-settings-query-list-config-common.common.ui.modal-form.color-field.field',
	modalDescriptionField:
		'software-board-settings-query-list-config-common.common.ui.modal-form.description-field.field',
	modalNameField:
		'software-board-settings-query-list-config-common.common.ui.modal-form.name-field.field',
	modalQueryField:
		'software-board-settings-query-list-config-common.common.ui.modal-form.query-field.field',
	queryList: 'software-board-settings-query-list-config-common.ui.query-list.list',
	queryListActionMenuButton:
		'software-board-settings-query-list-config-common.ui.query-list.actions-menu.actions',
	queryListActionMenu:
		'software-board-settings-query-list-config-common.ui.query-list.actions-menu.menu',
} as const;
