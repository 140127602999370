import React, { useMemo, useState, useRef, useEffect } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import InlineEdit from '@atlaskit/inline-edit';
import type { ExternalError } from '@atlaskit/jql-editor';
import TextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import JQLBuilderAdvanced from '@atlassian/jira-jql-builder-advanced/src/async.tsx';
import { PAGE_ANALYTICS_SOURCE } from '@atlassian/jira-software-board-settings-general-common/src/common/constants/analytics.tsx';
import { useCanEdit } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/permissions/index.tsx';
import messages from './messages.tsx';
import { useCurrentBoardSubFilter } from './utils.tsx';

export interface QueryFieldValue {
	builderErrors?: { message: string }[];
	jql: string;
}

const ErrorKind = {
	InvalidJQL: 'INVALID_JQL',
};

const createError = (message: string) => ({
	type: 'error' as const,
	message,
});

const validate = (state: QueryFieldValue | undefined): string | undefined => {
	if (!state) {
		return undefined;
	}

	const sanitisedJql = (state.jql ?? '').trim();
	if (sanitisedJql === '') {
		return undefined;
	}

	if ((state.builderErrors?.length ?? 0) > 0) {
		return ErrorKind.InvalidJQL;
	}

	return undefined;
};

const getJqlErrors = (
	{ builderErrors }: QueryFieldValue,
	error: string | undefined,
): ExternalError[] => {
	if (!error) {
		return [];
	}

	if (error === ErrorKind.InvalidJQL) {
		return builderErrors?.map((e) => createError(e.message)) ?? [];
	}

	if (Array.isArray(error) && error.length > 0) {
		return error.map(createError);
	}

	// Server validation errors
	if (error) {
		return [createError(error)];
	}

	return [];
};

// replace with react 18 useId once stable
const useId = () => useMemo(() => `uif-inline-edit-${Math.random()}-${Math.random()}`, []);

export const BoardSubFilterQuery = () => {
	const { formatMessage } = useIntl();
	const canEdit = useCanEdit();
	const [isEditing, setIsEditing] = useState(false);
	const { value, pendingValue, validationError, requestUpdate, resetFilter } =
		useCurrentBoardSubFilter();

	const ref = useRef<HTMLDivElement>(null);
	const labelId = useId();
	const inputId = useId();

	useEffect(() => {
		const labelEl = ref.current?.querySelector('label');
		if (labelEl) {
			labelEl.htmlFor = inputId;
			labelEl.id = labelId;
		}
	}, [isEditing, inputId, labelId]);

	useEffect(() => {
		if (validationError) {
			setIsEditing(true);
		}
	}, [validationError]);

	const currentValue = useMemo<QueryFieldValue>(
		() => ({ jql: pendingValue ?? value ?? '', buildErrors: [] }),
		[pendingValue, value],
	);
	const [updatedCurrentValue, setUpdatedCurrentValue] = useState<QueryFieldValue>(currentValue);

	const onEditRequested = () => {
		if (canEdit) {
			setUpdatedCurrentValue(currentValue);
			setIsEditing(true);
		}
	};

	const onConfirm = (confirmValue: QueryFieldValue | undefined) => {
		if ((confirmValue?.builderErrors?.length ?? 0) > 0) {
			return;
		}

		updatedCurrentValue.jql !== value && requestUpdate(updatedCurrentValue.jql);
		setIsEditing(false);
	};

	const onCancel = () => {
		resetFilter();
		setIsEditing(false);
	};

	const accessbilityLabel = currentValue.jql
		? formatMessage(messages.subFilterAriaLabel, { query: currentValue.jql })
		: formatMessage(messages.subFilterAriaLabelForEmptyQuery);

	return (
		<FieldWrapper ref={ref}>
			<InlineEdit
				readViewFitContainerWidth
				onConfirm={onConfirm}
				onEdit={onEditRequested}
				editButtonLabel={accessbilityLabel}
				onCancel={onCancel}
				defaultValue={currentValue}
				isEditing={isEditing}
				label={formatMessage(messages.label)}
				readView={() => (
					/**
					 * Should use "value" to render updated JQL if different is returned in update response,
					 * eg. "null" will be replaced with "EMPTY" on the backend and change will not be detected
					 * by component if defaultValue prop is used.
					 * onChange callback is set to avoid JS error to use defaultValue instead of value prop.
					 */
					<TextField
						id={inputId}
						aria-labelledby={labelId}
						value={currentValue.jql}
						onChange={noop}
						isDisabled={!canEdit}
						onFocus={onEditRequested}
					/>
				)}
				editView={({ errorMessage: error, ...fieldProps }) => {
					const jqlErrors = getJqlErrors(updatedCurrentValue, error || validationError);

					return (
						<JQLBuilderWrapper>
							<JQLBuilderAdvanced
								analyticsSource={PAGE_ANALYTICS_SOURCE}
								query={updatedCurrentValue.jql}
								jqlMessages={jqlErrors}
								autoFocus
								onUpdate={(jql, { errors }) => {
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									fieldProps.onChange({ jql, builderErrors: errors } as never);
									setUpdatedCurrentValue({ jql, builderErrors: errors });
								}}
							/>
						</JQLBuilderWrapper>
					);
				}}
				validate={validate}
			/>
		</FieldWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldWrapper = styled.div({
	// field margin should be 0
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > form > div': {
		margin: 0,
		// editor confirm / cancel buttons should be just below jql input
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'& > div > div:nth-of-type(2)': {
			marginTop: token('space.negative.150', '-12px'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const JQLBuilderWrapper = styled.div({
	// error / info text below input should not overflow with confirm / cancel buttons
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > div > div:nth-of-type(2)': {
		width: 'calc(100% - 80px)',
		// error / info text below input should be aligned left
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > div': {
			marginLeft: 0,
			marginRight: 'auto',
		},
	},
});
