import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	globalDisableReason: {
		id: 'shareable-entity-dialog.access.sharee-picker.sharee-type-picker.global-disable-reason',
		defaultMessage: 'This option has been disabled by your Jira administrator',
		description:
			'Tooltip to be displayed while hovering a disabled "Public" option in the Access picker',
	},
});
