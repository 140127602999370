/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../common/ui/AppSkeletonImage.tsx';
import cardColorsImgSVG from './assets/card-colors-app-skeleton.tpl.svg';
import cardLayoutImgSVG from './assets/card-layout-app-skeleton.tpl.svg';
import estimatesImgSVG from './assets/estimates-app-skeleton.tpl.svg';
import generalSettingImgSVG from './assets/general-settings-app-skeleton.tpl.svg';
import itemListImgSVG from './assets/item-list-app-skeleton.tpl.svg';
import quickFiltersImgSVG from './assets/quick-filters-app-skeleton.tpl.svg';
import swimlanesImgSVG from './assets/swimlanes-app-skeleton.tpl.svg';
import timelineImgSVG from './assets/timeline-app-skeleton.tpl.svg';

const customCssStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		border: 'none',
	},
});

export const BoardSettingsQuickFiltersSkeleton = () => (
	<AppSkeletonImage src={quickFiltersImgSVG} />
);

export const BoardSettingsEstimatesSkeleton = () => <AppSkeletonImage src={estimatesImgSVG} />;

export const BoardSettingsSwimlanesSkeleton = () => <AppSkeletonImage src={swimlanesImgSVG} />;

export const BoardSettingsTimelineSkeleton = () => <AppSkeletonImage src={timelineImgSVG} />;

export const BoardSettingsCardColorsSkeleton = () => <AppSkeletonImage src={cardColorsImgSVG} />;

export const BoardSettingsCardLayoutSkeleton = () => <AppSkeletonImage src={cardLayoutImgSVG} />;

export const BoardSettingsGeneralSettingsSkeleton = () => (
	<AppSkeletonImage src={generalSettingImgSVG} />
);

export const BoardSettingsItemListSkeleton = () => (
	<AppSkeletonImage
		src={itemListImgSVG}
		css={customCssStyles}
		containerStyles={{ height: '240px' }}
	/>
);
