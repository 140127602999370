import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	name: {
		id: 'shareable-entity-dialog.name',
		defaultMessage: 'Name',
		description: 'Label for the field name, name of the entity',
	},
	description: {
		id: 'shareable-entity-dialog.description',
		defaultMessage: 'Description',
		description: 'Label for the field description, description of the entity',
	},
	access: {
		id: 'shareable-entity-dialog.access',
		defaultMessage: 'Viewers',
		description:
			'Label for the field access, in this field is listed the project, roles or groups that can see this entity',
	},
	editor: {
		id: 'shareable-entity-dialog.editor',
		defaultMessage: 'Editors',
		description:
			'Label for the field editor, in this field is listed the project, roles or groups that can edit this entity',
	},
	owner: {
		id: 'shareable-entity-dialog.owner',
		defaultMessage: 'Owner',
		description:
			'Label for the field owner, owner is the user that owns this entity and who can edit or delete the entity',
	},
	nameIsRequired: {
		id: 'shareable-entity-dialog.name-is-required',
		defaultMessage: 'Name field is required',
		description:
			'Message to display to the user when the user tries to perform the modal action with name field empty',
	},
	permissionsRevoke: {
		id: 'shareable-entity-dialog.permissions-revoke',
		defaultMessage: 'Permission to share revoked for {title}',
		description: 'Message on permissions revoke',
	},
	requiredFieldsSummary: {
		id: 'shareable-entity-dialog.required-fields-summary',
		defaultMessage: 'Required fields are marked with an asterisk',
		description: 'Summary info of required fields asterisk.',
	},
});
