import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	warning: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.card-layout-table.field-limit.warning',
		defaultMessage: 'You can add up to three fields. Delete a field to replace it with another.',
		description:
			"A message that warns the user they've added the maximum number of allowed fields to the card layout and they need to delete existings before adding new ones.",
	},
});
