import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	enableTimelineSuccess: {
		id: 'software-board-settings-timeline.controllers.handlers.update-timeline-handler.enable-timeline-success',
		defaultMessage: "You've enabled your timeline",
		description: 'Success message when timeline is enabled',
	},
	disableTimelineSuccess: {
		id: 'software-board-settings-timeline.controllers.handlers.update-timeline-handler.disable-timeline-success',
		defaultMessage: "You've turned off your timeline",
		description: 'Success message when timeline is disabled',
	},
});
