import { useEffect, useRef } from 'react';
import type { FieldMode } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import { useFocusDirective } from '../../selectors/focus/index.tsx';

export const useAddFieldFocusHandler = (mode: FieldMode) => {
	const [focus, { resetFocus }] = useFocusDirective();
	const ref = useRef<HTMLElement>(null);

	useEffect(() => {
		if (focus != null && ref.current != null && mode === focus.mode && focus.dest === 'add-field') {
			ref.current.focus();
			resetFocus();
		}
	}, [mode, focus, resetFocus]);

	return { addFieldRef: ref };
};

export const useLastRowFocusHandler = ({
	mode,
	rowSelector,
}: {
	mode: FieldMode;
	rowSelector: string;
}) => {
	const [focus, { resetFocus }] = useFocusDirective();
	const ref = useRef<HTMLElement>(null);

	useEffect(() => {
		if (focus != null && ref.current != null && mode === focus.mode && focus.dest === 'focus-row') {
			const items = ref.current.querySelectorAll<HTMLElement>(rowSelector);
			Array.from(items).at(focus.index)?.focus();
			resetFocus();
		}
	}, [mode, focus, resetFocus, rowSelector]);

	return { tableContainerRef: ref };
};
