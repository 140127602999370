import { useRouter } from '@atlassian/react-resource-router';

export const useTimelineUrl = (isCMPBoard: boolean) => {
	const [{ match }] = useRouter();

	const projectKey = match.params.projectKey ?? '';
	const boardId = match.params.boardId ?? '';

	if (!projectKey || !boardId) {
		return undefined;
	}

	return `/jira/software${isCMPBoard ? '/c' : ''}/projects/${projectKey}/boards/${boardId}/timeline`;
};
