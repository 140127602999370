import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.title',
		defaultMessage: 'Card colors',
		description: 'Page title',
	},
	description: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.description',
		defaultMessage: 'Assign colors to cards to help your team identify issues easily.',
		description: 'Page description',
	},
	inProductHelp: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.in-product-help',
		defaultMessage: 'Read more about card colors',
		description: 'Text for In Product Help link',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.description-issue-term-refresh',
		defaultMessage: 'Assign colors to cards to help your team identify work items easily.',
		description: 'Page description',
	},
});
