import parameters from '@atlassian/jira-relay/src/__generated__/cardLayoutSettingsPageQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { boardId: string };

export const cardLayoutSettingPageEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jsw-board-settings-card-layout" */ './ui/card-layout-settings-page/index.tsx'
		).then((module) => module.CardLayoutSettingPageContent),
	),
	getPreloadProps: ({ boardId }: EntryPointParams) => ({
		queries: {
			queryReference: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters,
				variables: {
					boardId,
				},
			},
		},
	}),
});
