import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-general-sub-filter-settings.board-sub-filter-section.title',
		defaultMessage: 'Board sub-filter',
		description: 'Title of the board sub-filter section of the page.',
	},
	aboutJsw: {
		id: 'software-board-settings-general-sub-filter-settings.board-sub-filter-section.about-jsw',
		defaultMessage:
			'Apply another optional filter to further refine your issues. This won’t change your board filter query. <button>Read more about sub-filters</button>.',
		description: 'Describes the purpose of the board sub-filter in JSW product.',
	},
	aboutJsm: {
		id: 'software-board-settings-general-sub-filter-settings.board-sub-filter-section.about-jsm',
		defaultMessage:
			'Apply another optional filter to further refine your issues. This won’t change your board filter query.',
		description: 'Describes the purpose of the board sub-filter in JSM product.',
	},
});
