export const VIEW_CARD_LAYOUT_SETTINGS_EXPERIENCE_NAME = 'viewCardLayoutSettings';

export const FETCH_CARD_LAYOUT_CONFIG_ACTION_SUBJECT = 'fetchCardLayoutConfig';
export const FETCH_CARD_LAYOUT_CONFIG_BROWSER_METRICS_KEY = 'fetch-card-layout-config';

export const ADD_CARD_LAYOUT_FIELD_ACTION_SUBJECT = 'addCardLayoutField';
export const ADD_CARD_LAYOUT_FIELD_PLAN_MODE_BROWSER_METRICS_KEY =
	'add-card-layout-field-plan-mode';
export const ADD_CARD_LAYOUT_FIELD_WORK_MODE_BROWSER_METRICS_KEY =
	'add-card-layout-field-work-mode';

export const DELETE_CARD_LAYOUT_FIELD_ACTION_SUBJECT = 'deleteCardLayoutField';
export const DELETE_CARD_LAYOUT_FIELD_PLAN_MODE_BROWSER_METRICS_KEY =
	'delete-card-layout-field-plan-mode';
export const DELETE_CARD_LAYOUT_FIELD_WORK_MODE_BROWSER_METRICS_KEY =
	'delete-card-layout-field-work-mode';

export const UPDATE_SHOW_DAYS_IN_COLUMN_ACTION_SUBJECT = 'updateShowDaysInColumn';
export const UPDATE_SHOW_DAYS_IN_COLUMN_BROWSER_METRICS_KEY = 'update-show-days-in-column';

export const RANK_CARD_LAYOUT_FIELD_ACTION_SUBJECT = 'rankCardLayoutField';
export const RANK_CARD_LAYOUT_FIELD_PLAN_MODE_BROWSER_METRICS_KEY =
	'rank-card-layout-field-plan-mode';
export const RANK_CARD_LAYOUT_FIELD_WORK_MODE_BROWSER_METRICS_KEY =
	'rank-card-layout-field-work-mode';
