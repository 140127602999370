import { expVal } from '@atlassian/jira-feature-experiments';
import { SwimlaneStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';
import messages from './messages.tsx';

export const getStrategyHelperMessage = (
	strategy: SwimlaneStrategy,
	isDefaultLevelOneName = true,
) => {
	switch (strategy) {
		case SwimlaneStrategy.epic: {
			if (isDefaultLevelOneName) {
				return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.epicsOptionHelperMessageIssueTermRefresh
					: messages.epicsOptionHelperMessage;
			}
			return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.epicsOptionCustomNameHelperMessageIssueTermRefresh
				: messages.epicsOptionCustomNameHelperMessage;
		}
		case SwimlaneStrategy.parentChild:
			return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.storiesOptionHelperMessageIssueTermRefresh
				: messages.storiesOptionHelperMessage;
		case SwimlaneStrategy.none:
			return messages.noneOptionHelperMessage;
		case SwimlaneStrategy.custom:
		case SwimlaneStrategy.assigneeUnassignedLast:
		default:
			return null;
	}
};
