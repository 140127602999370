import React, {
	type RefObject,
	Component,
	type ComponentType,
	type ChangeEvent,
	type SyntheticEvent,
} from 'react';
import { styled } from '@compiled/react';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { type FieldProps, Field, ErrorMessage, RequiredAsterisk } from '@atlaskit/form';
import {
	ModalBody as AkModalBody,
	ModalHeader as AkModalHeader,
	ModalTitle as AkModalTitle,
} from '@atlaskit/modal-dialog';
import TextArea from '@atlaskit/textarea';
import TextField from '@atlaskit/textfield';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import ViewTracker from '@atlassian/jira-analytics-web-react/src/components/view-tracker.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { SharePermission } from '@atlassian/jira-shared-types/src/share-permission.tsx';
import type { User as PickerUser } from '@atlassian/jira-user-picker/src/model/index.tsx';
import {
	EDIT_PERMISSIONS_FIELD_NAME,
	ENTITY_DESCRIPTION_FIELD_NAME,
	ENTITY_NAME_FIELD_NAME,
	SHARE_PERMISSIONS_FIELD_NAME,
} from '../model/constants.tsx';
import type {
	User,
	ServerValidationErrors,
	Option,
	ServerValidationError,
} from '../model/types.tsx';
import ActionModalCustomHeader from './action-modal-custom-header/index.tsx';
import ActionModalFooter from './action-modal-footer/index.tsx';
import messages from './messages.tsx';

// exported for testing
export const NAME_FIELD_ID = 'shareable-entity-dialog.input-name';
export const NAME_FIELD_TEST_ID = 'shareable-entity-dialog.text-field';

export type Props = {
	readonly isPending: boolean;
	readonly isNameRequiredError: boolean;
	readonly isShareScopeLoading: boolean;
	readonly isEditorListShown: boolean;
	readonly isNameReadOnly: boolean;
} & Intl & {
		readonly title?: string;
		readonly headingTitleLevel?: 1 | 2 | 3 | 4 | 5 | 6;
		readonly shareeOptions: Option[];
		readonly editOptions: Option[];
		readonly name: string;
		readonly nameInputRef: RefObject<HTMLInputElement>;
		readonly description: string;
		readonly owner: User | undefined;
		readonly actionButtonCaption: string;
		readonly sharePermissions: SharePermission[];
		readonly viewersCustomMessage: string | undefined;
		readonly nameTooltip: string | undefined;
		readonly editPermissions: SharePermission[];
		readonly serverValidationErrors: ServerValidationErrors;

		readonly UserPicker: ComponentType<{
			selectedUser: PickerUser;
			ariaLabel?: string;
			ariaLabelledby?: string;
			onChange: (arg1: PickerUser) => void;
		}>;
		readonly Access: ComponentType<{
			isShareScopeLoading: boolean;
			isEditorListShown: boolean;
			sharePermissionsServerValidationError: ServerValidationError;
			editPermissionsServerValidationError: ServerValidationError;
			shareeOptions: Option[];
			editOptions: Option[];
			sharePermissions: SharePermission[];
			viewersCustomMessage: string | undefined;
			editPermissions: SharePermission[];
			onSharePermissionChange: (arg1: SharePermission[]) => void;
			onEditPermissionChange: (arg1: SharePermission[]) => void;
		}>;
		readonly onSharePermissionChange: (arg1: SharePermission[]) => void;
		readonly onEditPermissionChange: (arg1: SharePermission[]) => void;
		readonly onNameChange: (arg1: ChangeEvent<HTMLInputElement>) => void;
		readonly onDescriptionChange: (arg1: ChangeEvent<HTMLTextAreaElement>) => void;
		readonly onOwnerChange: (arg1: PickerUser) => void;
		readonly onAction: (arg1: SyntheticEvent, arg2: UIAnalyticsEvent) => void;
		readonly onCancel: () => void;
	};

const toPickerUser = memoizeOne(
	({ accountId, displayName, avatarUrl }: User): PickerUser => ({
		accountId,
		value: accountId,
		displayName,
		label: displayName,
		avatarUrl,
	}),
);

// eslint-disable-next-line jira/react/no-class-components
export class ShareableEntityDialog extends Component<Props> {
	static defaultProps = {
		isPending: false,
		isEditorListShown: false,
		isNameReadOnly: false,
		owner: undefined,
	};

	renderFooter() {
		const {
			onCancel,
			actionButtonCaption,
			onAction,
			isPending,
			isNameRequiredError,
			isShareScopeLoading = false,
		} = this.props;

		return (
			<ActionModalFooter
				isActionEnabled={!isPending && !isNameRequiredError && !isShareScopeLoading}
				isActionInProgress={isPending}
				actionButtonCaption={actionButtonCaption}
				onAction={onAction}
				onCancel={onCancel}
			/>
		);
	}

	renderModalBody() {
		const {
			isNameRequiredError,
			isShareScopeLoading = false,
			intl: { formatMessage },
			isEditorListShown,
			isNameReadOnly,
			editOptions,
			editPermissions,
			name,
			nameInputRef,
			nameTooltip,
			description,
			owner,
			sharePermissions,
			viewersCustomMessage,
			UserPicker,
			Access,
			onDescriptionChange,
			onEditPermissionChange,
			onNameChange,
			onOwnerChange,
			onSharePermissionChange,
			shareeOptions,
			serverValidationErrors,
		} = this.props;

		const nameServerValidationError = serverValidationErrors[ENTITY_NAME_FIELD_NAME];
		const descriptionServerValidationError = serverValidationErrors[ENTITY_DESCRIPTION_FIELD_NAME];
		const sharePermissionsServerValidationError =
			serverValidationErrors[SHARE_PERMISSIONS_FIELD_NAME];
		const editPermissionsServerValidationError =
			serverValidationErrors[EDIT_PERMISSIONS_FIELD_NAME];

		const nameValidationErrorMessage = isNameRequiredError
			? formatMessage(messages.nameIsRequired)
			: nameServerValidationError;

		const renderNameField = (fieldProps: FieldProps<string, HTMLInputElement>) => (
			<TextField
				{...fieldProps}
				testId={NAME_FIELD_TEST_ID}
				value={name}
				isDisabled={isNameReadOnly}
				onChange={onNameChange}
				isInvalid={!!nameValidationErrorMessage}
				ref={nameInputRef}
				aria-describedby={`${NAME_FIELD_ID}-error`}
			/>
		);

		return (
			<>
				<Field label={formatMessage(messages.name)} isRequired name="name" id={NAME_FIELD_ID}>
					{({ fieldProps }) => (
						<>
							{nameTooltip ? (
								<Tooltip content={nameTooltip}>{renderNameField(fieldProps)}</Tooltip>
							) : (
								renderNameField(fieldProps)
							)}
							{nameValidationErrorMessage && (
								<ErrorMessage>{nameValidationErrorMessage}</ErrorMessage>
							)}
						</>
					)}
				</Field>
				<Field label={formatMessage(messages.description)} name="description">
					{({ fieldProps }) => (
						<>
							<TextArea
								{...fieldProps}
								value={description}
								onChange={onDescriptionChange}
								isInvalid={!!descriptionServerValidationError}
							/>
							{descriptionServerValidationError && (
								<ErrorMessage>{descriptionServerValidationError}</ErrorMessage>
							)}
						</>
					)}
				</Field>
				{owner && (
					<Field label={formatMessage(messages.owner)} name="owner">
						{({
							fieldProps: {
								//  @ts-expect-error - TS7053: Element implicitly has an 'any' type because expression of type '"aria-label"' can't be used to index type 'FieldProps<string, HTMLInputElement>'.
								'aria-label': ariaLabel,
								'aria-labelledby': ariaLabelledby,
							},
						}) => (
							<>
								<UserPicker
									aria-label={ariaLabel}
									aria-labelledby={ariaLabelledby}
									selectedUser={toPickerUser(owner)}
									onChange={onOwnerChange}
								/>
							</>
						)}
					</Field>
				)}
				<Access
					isShareScopeLoading={isShareScopeLoading}
					shareeOptions={shareeOptions}
					editOptions={editOptions}
					sharePermissions={sharePermissions}
					viewersCustomMessage={viewersCustomMessage}
					editPermissions={editPermissions}
					onSharePermissionChange={onSharePermissionChange}
					onEditPermissionChange={onEditPermissionChange}
					isEditorListShown={isEditorListShown}
					sharePermissionsServerValidationError={sharePermissionsServerValidationError}
					editPermissionsServerValidationError={editPermissionsServerValidationError}
				/>
				<ViewTracker />
			</>
		);
	}

	render() {
		const { title, headingTitleLevel, intl } = this.props;

		return (
			<>
				{title &&
					(headingTitleLevel ? (
						<ActionModalCustomHeader headingLevel={headingTitleLevel} title={title} />
					) : (
						<AkModalHeader>
							<AkModalTitle>{title}</AkModalTitle>
						</AkModalHeader>
					))}
				<AkModalBody>
					<RequiredMessage aria-hidden="true">
						{intl.formatMessage(messages.requiredFieldsSummary)} <RequiredAsterisk />
					</RequiredMessage>
					{this.renderModalBody()}
				</AkModalBody>
				{this.renderFooter()}
			</>
		);
	}
}

export default injectIntl(ShareableEntityDialog);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RequiredMessage = styled.p({
	color: token('color.text.subtle', N300),
});
