import get from 'lodash/get';
import type { ExperienceAttributes } from '@atlassian/jira-experience-tracker/src/common/types.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { isSynthetic } from '@atlassian/jira-is-synthetic/src/index.tsx';
import {
	NOT_PRESENT,
	STATUS_CODE_1XX,
	STATUS_CODE_3XX,
	STATUS_CODE_4XX,
	STATUS_CODE_5XX,
	UNKNOWN,
} from './constants.tsx';
import type { Attributes } from './types.tsx';

type ExtractAttributeValue = string | number | boolean | typeof NOT_PRESENT;

const extractGraphQLExtensionErrorName = (error: Error): string | undefined => {
	const graphqlErrorExtensions = get(error, ['extensions'], null);

	const errorType = get(graphqlErrorExtensions, ['errorType']);
	if (errorType) {
		return errorType;
	}

	return get(graphqlErrorExtensions, ['operationName']);
};

const extractErrorName = (error: Error): ExtractAttributeValue => {
	const networkError = get(error, ['networkError']);
	if (networkError) {
		return get(networkError, ['name'], get(error, ['name'], NOT_PRESENT));
	}

	const graphQLErrors = get(error, ['graphQLErrors'], []);
	if (graphQLErrors && graphQLErrors.length > 0) {
		const graphqlErrorExtensionsName = extractGraphQLExtensionErrorName(get(graphQLErrors, [0]));
		if (graphqlErrorExtensionsName) {
			return graphqlErrorExtensionsName;
		}
	}

	const graphqlErrorExtensionsName = extractGraphQLExtensionErrorName(error);
	if (graphqlErrorExtensionsName) {
		return graphqlErrorExtensionsName;
	}

	return get(error, ['name'], NOT_PRESENT);
};

export const extractStatusCode = (error: Error): ExtractAttributeValue =>
	get(error, ['networkError', 'statusCode']) ||
	get(error, ['graphQLErrors', 0, 'extensions', 'statusCode']) ||
	get(error, ['extensions', 'statusCode']) ||
	get(error, ['extensions', 'status']) ||
	get(error, ['statusCode']) ||
	get(error, ['status']) ||
	NOT_PRESENT;

export const extractStatusCodeGroup = (error: Error) => {
	const statusCode = Number(extractStatusCode(error));
	if (!statusCode) {
		return NOT_PRESENT;
	}

	if (statusCode >= 100 && statusCode < 200) {
		return STATUS_CODE_1XX;
	}
	if (statusCode >= 300 && statusCode < 400) {
		return STATUS_CODE_3XX;
	}
	if (statusCode >= 400 && statusCode < 500) {
		return STATUS_CODE_4XX;
	}
	if (statusCode >= 500 && statusCode < 600) {
		return STATUS_CODE_5XX;
	}

	return UNKNOWN;
};

const extractTraceId = (error: Error): ExtractAttributeValue =>
	get(error, ['traceId']) ||
	get(error, ['extensions', 'traceId']) ||
	get(error, ['extensions', 'request_id'], NOT_PRESENT) ||
	NOT_PRESENT;

const extractErrorMessage = (error: Error): string =>
	get(error, ['message']) ||
	get(error, ['extensions', 'message']) ||
	get(error, ['extensions', 'userMessage'], NOT_PRESENT);

const extractErrorStack = (error: Error): string => get(error, ['stack'], NOT_PRESENT);

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getBuildKey = () => window.BUILD_KEY || NOT_PRESENT;

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getSsrBuildKey = () => window.SSR_BUILD_KEY || NOT_PRESENT;

export const getErrorAttributes = (error: Error): Attributes => ({
	errorName: extractErrorName(error),
	errorMessage: extractErrorMessage(error),
	errorStack: extractErrorStack(error),
	statusCode: extractStatusCode(error),
	statusCodeGroup: extractStatusCodeGroup(error),
	traceId: extractTraceId(error),
	buildKey: getBuildKey(),
	ssrBuildKey: getSsrBuildKey(),
});

export const isHttpClientErrorResponse = (error: Error): boolean => {
	const statusCode = Number(extractStatusCode(error));
	return Number.isNaN(statusCode) ? false : statusCode >= 400 && statusCode < 500;
};

export const isClientNetworkError = (error: Error): boolean => isClientFetchError(error);

export const isSloFailure = (error: Error): boolean =>
	!isClientNetworkError(error) && !isHttpClientErrorResponse(error);

export const isHttpTooManyRequestsResponse = (error: Error): boolean => {
	const statusCode = Number(extractStatusCode(error));
	return Number.isNaN(statusCode) ? false : statusCode === 429;
};

export const isHttpConflictRequestResponse = (error: Error): boolean => {
	const statusCode = Number(extractStatusCode(error));
	return Number.isNaN(statusCode) ? false : statusCode === 409;
};

export const createCommonAttributes = (
	attributes: ExperienceAttributes = {},
): ExperienceAttributes => ({
	synthetic: isSynthetic(),
	isUIF: true,
	isClassic: false,
	...attributes,
});
