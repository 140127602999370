import type { State } from '../types/index.tsx';

export const INITIAL_STATE: State = {
	isInitialised: false,
	isCMPBoard: true,
	rapidViewId: -1,
	canEdit: false,
	showDaysInColumn: false,
	currentFields: [],
	availableFields: [],
	isKanPlanEnabled: false,
	isSprintSupportEnabled: false,
};
