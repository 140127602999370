import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import { useGetI18nStringForColor } from '@atlassian/jira-software-board-settings-color-picker/src/controllers/use-get-i18n-string-for-color/index.tsx';
import { InlineColorPicker } from '@atlassian/jira-software-board-settings-color-picker/src/ui/color-picker/index.tsx';
import type { QueryItem, ColorItem, ColorListProps } from '../../common/types/index.tsx';
import { ActionsMenu, type ActionsMenuProps } from './actions-menu/index.tsx';
import messages from './messages.tsx';
import { StaleColorRemovalButton } from './stale-color-removal-button/index.tsx';

const TableColumns = {
	Handler: 'handler',
	Name: 'name',
	Color: 'color',
	Query: 'query',
	Description: 'description',
	Actions: 'actions',
} as const;

export const useTableHeadAndRows = ({
	items,
	isReadOnly,
	onEdit,
	onDelete,
}: {
	items: QueryItem[];
	isReadOnly?: boolean;
	onEdit: ActionsMenuProps['onEdit'];
	onDelete: ActionsMenuProps['onDelete'];
}) => {
	const { formatMessage } = useIntl();

	const head = useMemo(
		() => getTableHead({ isReadOnly, formatMessage }),
		[isReadOnly, formatMessage],
	);

	const rows = useMemo(
		() => getTableRows(items, { isReadOnly, onEdit, onDelete }),
		[items, isReadOnly, onDelete, onEdit],
	);

	return { head, rows };
};

export const useTableHeadAndRowsForColorItems = ({
	items,
	isReadOnly,
	isNonQueryColor = false,
	headerName,
	staleColorWarningMessage,
	onEdit,
	onDelete,
	onColorChange,
	onResetToGlobal,
}: {
	items: ColorItem[];
	isReadOnly?: boolean;
	isNonQueryColor?: boolean;
	headerName?: string;
	staleColorWarningMessage?: string;
	onEdit: ActionsMenuProps<ColorItem>['onEdit'];
	onDelete: ActionsMenuProps<ColorItem>['onDelete'];
	onColorChange: ColorListProps['onColorChange'];
	onResetToGlobal?: ColorListProps['onResetToGlobal'];
}) => {
	const { formatMessage } = useIntl();

	const head = useMemo(
		() =>
			getTableHeadForColorItems({
				isReadOnly,
				isNonQueryColor,
				headerName,
				formatMessage,
			}),
		[isReadOnly, isNonQueryColor, headerName, formatMessage],
	);

	const getI18nStringForColor = useGetI18nStringForColor();

	const rows = useMemo(
		() =>
			getTableRowsForColorItems(items, {
				isReadOnly,
				isNonQueryColor,
				staleColorWarningMessage,
				onEdit,
				onDelete,
				onColorChange,
				onResetToGlobal,
				getI18nStringForColor,
				formatMessage,
			}),
		[
			items,
			isReadOnly,
			isNonQueryColor,
			staleColorWarningMessage,
			onEdit,
			onDelete,
			onColorChange,
			onResetToGlobal,
			getI18nStringForColor,
			formatMessage,
		],
	);

	return { head, rows };
};

export const getTableHead = ({
	isReadOnly,
	formatMessage,
}: {
	isReadOnly?: boolean;
	formatMessage: FormatMessage;
}) => ({
	cells: [
		{
			key: TableColumns.Name,
			content: formatMessage(messages.tableHeadNameLabel),
		},
		{
			key: TableColumns.Query,
			content: formatMessage(messages.tableHeadJqlQueryLabel),
		},
		{
			key: TableColumns.Description,
			content: formatMessage(messages.tableHeadDescriptionLabel),
		},
		{
			key: TableColumns.Actions,
			width: 1,
			disabled: isReadOnly,
			content: <ActionHeader />,
		},
	].filter((cell) => !cell.disabled),
});

export const getTableHeadForColorItems = ({
	isReadOnly,
	isNonQueryColor,
	headerName = '',
	formatMessage,
}: {
	isReadOnly?: boolean;
	isNonQueryColor?: boolean;
	headerName?: string;
	formatMessage: FormatMessage;
}) => {
	const isRankDisabled = isReadOnly || isNonQueryColor;

	return {
		cells: [
			{
				width: 1,
				key: TableColumns.Color,
				content: (
					<ColorHeadWrapper isRankDisabled={isRankDisabled}>
						{formatMessage(messages.tableHeadColorLabel)}
					</ColorHeadWrapper>
				),
			},
			{
				key: isNonQueryColor ? TableColumns.Name : TableColumns.Query,
				content: isNonQueryColor ? headerName : formatMessage(messages.tableHeadJqlQueryLabel),
			},
			{
				key: TableColumns.Actions,
				width: 1,
				disabled: isRankDisabled,
				content: <ActionHeader />,
			},
		].filter((cell) => !cell.disabled),
	};
};

export const getTableRows = (
	items: QueryItem[],
	{
		isReadOnly,
		onEdit,
		onDelete,
	}: {
		isReadOnly?: boolean;
		onEdit: ActionsMenuProps['onEdit'];
		onDelete: ActionsMenuProps['onDelete'];
	},
) =>
	items.map((item) => ({
		key: item.id,
		label: item.name,
		cells: [
			{
				key: TableColumns.Name,
				content: <CellWrapper>{item.name}</CellWrapper>,
			},
			{
				key: TableColumns.Query,
				content: <CellWrapper>{item.query}</CellWrapper>,
			},
			{
				key: TableColumns.Description,
				content: <CellWrapper>{item.description}</CellWrapper>,
			},
			{
				key: TableColumns.Actions,
				content: (
					<ActionsMenu labelName={item.name} item={item} onEdit={onEdit} onDelete={onDelete} />
				),
				disabled: isReadOnly,
			},
		].filter((cell) => !cell.disabled),
	}));

export const getTableRowsForColorItems = (
	items: ColorItem[],
	{
		isReadOnly,
		isNonQueryColor,
		staleColorWarningMessage,
		onEdit,
		onDelete,
		onColorChange,
		onResetToGlobal,
		getI18nStringForColor,
		formatMessage,
	}: {
		isReadOnly?: boolean;
		isNonQueryColor?: boolean;
		staleColorWarningMessage?: string;
		onEdit: ActionsMenuProps<ColorItem>['onEdit'];
		onDelete: ActionsMenuProps<ColorItem>['onDelete'];
		onColorChange: ColorListProps['onColorChange'];
		onResetToGlobal?: ColorListProps['onResetToGlobal'];
		getI18nStringForColor: (color: string) => string;
		formatMessage: FormatMessage;
	},
) => {
	const handleColorChange = (itemId: string) => (nextColor: string) => {
		onColorChange(itemId, nextColor);
	};

	const handleResetToGlobal = (itemId: string) => () => {
		onResetToGlobal && onResetToGlobal(itemId);
	};

	const isRankDisabled = isReadOnly || isNonQueryColor;

	return items.map((item) => {
		const staleColorRemovalContent =
			!isEmpty(staleColorWarningMessage) && !item.isUsed ? (
				<StaleColorRemovalButton
					staleColorWarningMessage={staleColorWarningMessage}
					item={item}
					onDelete={onDelete}
				/>
			) : null;

		return {
			key: item.id,
			label: getI18nStringForColor(item.color),
			cells: [
				{
					key: TableColumns.Color,
					content: (
						<ColorCellWrapper isRankDisabled={isRankDisabled}>
							<InlineColorPicker
								label={
									isNonQueryColor
										? formatMessage(messages.changeColorForTypeLabel, {
												type: item.name,
											})
										: formatMessage(messages.changeColorLabel)
								}
								onChange={handleColorChange(item.id)}
								onResetToGlobal={handleResetToGlobal(item.id)}
								isDisabled={isReadOnly}
								selectedColor={item.color}
								isResetToGlobalAllowed={!!onResetToGlobal && !item.isGlobalColor}
								isShowingCustomColor={!item.isGlobalColor}
								informationMessage={
									item.isGlobalColor
										? formatMessage(messages.priorityGlobalColorInformationMessage)
										: undefined
								}
								resetButtonTooltipContent={formatMessage(messages.resetButtonTooltipContent)}
							/>
						</ColorCellWrapper>
					),
				},
				{
					key: isNonQueryColor ? TableColumns.Name : TableColumns.Query,
					content: (
						<CellWrapper>
							{isNonQueryColor && !staleColorRemovalContent ? item.name : item.query}
							{isNonQueryColor && staleColorRemovalContent && (
								<Flex direction="row" justifyContent="space-between">
									<Inline xcss={colorNameStyles}>{item.name}</Inline>
									{staleColorRemovalContent}
								</Flex>
							)}
						</CellWrapper>
					),
				},
				{
					key: TableColumns.Actions,
					content: (
						<ActionsMenu
							labelName={getI18nStringForColor(item.color)}
							item={item}
							onEdit={onEdit}
							onDelete={onDelete}
						/>
					),
					disabled: isRankDisabled,
				},
			].filter((cell) => !cell.disabled),
		};
	});
};

const ActionHeader = () => {
	const { formatMessage } = useIntl();

	return <Box paddingInlineEnd="space.150">{formatMessage(messages.tableHeadActionsLabel)}</Box>;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CellWrapper = styled.div({
	padding: `${token('space.100', '8px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'td:has(&)': {
		borderBottom: `${token('color.border', '#091E4224')} 1px solid`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorCellWrapper = styled.div<{ isRankDisabled?: boolean }>({
	padding: `${token('space.100', '8px')} ${token('space.500', '40px')} ${token(
		'space.100',
		'8px',
	)} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ isRankDisabled }) =>
		isRankDisabled ? 'none' : `translateX(${token('space.negative.100', '-8px')})`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'td:has(&)': {
		borderBottom: `${token('color.border', '#091E4224')} 1px solid`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorHeadWrapper = styled.div<{ isRankDisabled?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ isRankDisabled }) =>
		isRankDisabled ? 'none' : `translateX(${token('space.negative.075', '-6px')})`,
});

const colorNameStyles = xcss({
	alignSelf: 'center',
});
