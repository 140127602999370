import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	confirmationModalTitle: {
		id: 'software-board-settings-general-settings.board-administrator-field.confirmation-modal-title',
		defaultMessage: 'Remove yourself as a board administrator',
		description: 'Title for confirmation dialog.',
	},
	confirmationModalDescription: {
		id: 'software-board-settings-general-settings.board-administrator-field.confirmation-modal-description',
		defaultMessage:
			'By removing yourself as a board administrator, you won’t be able to change any board settings.',
		description:
			'Description within confirmation dialog, the warning is here to advise the user that they will be prevented from making changes if they proceed.',
	},
	fieldLabel: {
		id: 'software-board-settings-general-settings.board-administrator-field.field-label',
		defaultMessage: 'Administrators',
		description: 'Label text for the administrators select field',
	},
	selectGroupLabelUsers: {
		id: 'software-board-settings-general-settings.board-administrator-field.select-group-label-users',
		defaultMessage: 'Users',
		description: 'description for error flag when board admins cannot be removed.',
	},
	selectFieldPlaceholderText: {
		id: 'software-board-settings-general-settings.board-administrator-field.select-field-placeholder-text',
		defaultMessage: 'Select administrators',
		description: 'Placeholder text for administrators select.',
	},
	selectGroupLabelGroups: {
		id: 'software-board-settings-general-settings.board-administrator-field.select-group-label-groups',
		defaultMessage: 'Groups',
		description: 'description for error flag when board admins cannot be removed.',
	},
	boardAdminsUpdatedSuccessfully: {
		id: 'software-board-settings-general-settings.board-administrator-field.board-admins-updated-successfully',
		defaultMessage: 'Your board administrators have been updated',
		description: 'Title for success flag when board admins are updated.',
	},
	unableToRemoveAdmin: {
		id: 'software-board-settings-general-settings.board-administrator-field.unable-to-remove-admin',
		defaultMessage: "We couldn't remove the administrator",
		description: 'Title for error flag when board admins cannot be removed.',
	},
	boardNeedsAtLeastOneAdmin: {
		id: 'software-board-settings-general-settings.board-administrator-field.board-needs-at-least-one-admin',
		defaultMessage: 'You must have at least one administrator for this board.',
		description: 'Description for error flag when board admins cannot be removed.',
	},
	unableToUpdate: {
		id: 'software-board-settings-general-settings.board-administrator-field.unable-to-update',
		defaultMessage: "We couldn't update your board administrators",
		description: 'An error message shown when failing to update the board admins',
	},
	updateBoardAdminsFailureDescription: {
		id: 'software-board-settings-general-settings.board-administrator-field.update-board-admins-failure-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description of the error flag when failing to update board admins',
	},
	cannotEdit: {
		id: 'software-board-settings-general-settings.board-administrator-field.cannot-edit',
		defaultMessage: "You don't have permission to edit administrators.",
		description:
			'Message shown to the user in a tooltip when they lack permissions to edit the the admin',
	},
});
