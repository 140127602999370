import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { DeleteQueryConfirmationModal } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/delete-query-confirmation-modal/index.tsx';
import messages from './messages.tsx';

export const DeleteCardColorModal = ({
	onSubmit,
	onCancel,
	isSubmitting,
}: {
	onSubmit: () => void;
	onCancel: () => void;
	isSubmitting: boolean;
}) => {
	const { formatMessage } = useIntl();

	return (
		<DeleteQueryConfirmationModal
			title={formatMessage(messages.title)}
			description={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.descriptionIssueTermRefresh
					: messages.description,
			)}
			onSubmit={onSubmit}
			onCancel={onCancel}
			isSubmitting={isSubmitting}
		/>
	);
};
