import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.card-layout-table.delete-field-button.delete',
		defaultMessage: 'Delete',
		description: 'Delete button label',
	},
	deleteButtonAriaLabel: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.card-layout-table.delete-field-button.delete-button-aria-label',
		defaultMessage: 'Delete {fieldName}',
		description: 'Aria label for button to delete a card layout field',
	},
});
