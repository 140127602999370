import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fieldLabel: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.card-color-strategy-field.field-label',
		defaultMessage: 'Select method',
		description: 'Label text for the Card Color Strategy select field',
	},
	issueTypesOptionLabel: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.card-color-strategy-field.issue-types-option-label',
		defaultMessage: 'Issue types',
		description: 'Label text for Issue type option for the Card Color Strategy select field',
	},
	prioritiesOptionLabel: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.card-color-strategy-field.priorities-option-label',
		defaultMessage: 'Priorities',
		description: 'Label text for Priorities option for the Card Color Strategy select field',
	},
	assigneeOptionLabel: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.card-color-strategy-field.assignee-option-label',
		defaultMessage: 'Assignee',
		description: 'Label text for Assignee option for the Card Color Strategy select field',
	},
	queriesOptionLabel: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.card-color-strategy-field.queries-option-label',
		defaultMessage: 'Queries',
		description: 'Label text for Queries option for the Card Color Strategy select field',
	},
	noneOptionLabel: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.card-color-strategy-field.none-option-label',
		defaultMessage: 'None',
		description: 'Label text for None option for the Card Color Strategy select field',
	},
	requestTypeLabel: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.card-color-strategy-field.request-type-label',
		defaultMessage: 'Request type',
		description: 'Label text for Request type option for the Card Color Strategy select field',
	},
	issueTypesOptionLabelIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.card-color-strategy-field.issue-types-option-label-issue-term-refresh',
		defaultMessage: 'Work types',
		description: 'Label text for Issue type option for the Card Color Strategy select field',
	},
});
