import { useCallback, useMemo } from 'react';
import {
	ESTIMATION_ACTION_SOURCE,
	TIME_TRACKING_ACTION_SOURCE,
} from '../../../common/constants/analytics.tsx';
import { useUpdateEstimationAndTracking } from '../../../services/update-estimation-and-tracking/index.tsx';
import { useRapidViewId } from '../../selectors/board/index.tsx';
import {
	useCurrentEstimationStatistic,
	useCurrentTrackingStatistic,
} from '../../selectors/estimation/index.tsx';

export type EstimateStatisticIdInput = { estimateStatisticId: string };
export type TrackingStatisticIdInput = { trackingStatisticId: string };

export const useUpdateStatisticsHandler = () => {
	const [rapidViewId] = useRapidViewId();
	const [currentEstimationStatistic, { setCurrentEstimationStatistic }] =
		useCurrentEstimationStatistic();
	const [currentTrackingStatistic, { setCurrentTrackingStatistic }] = useCurrentTrackingStatistic();

	const { loading: isEstimationUpdating, commit: commitEstimation } =
		useUpdateEstimationAndTracking(ESTIMATION_ACTION_SOURCE);
	const { loading: isTimeTrackingUpdating, commit: commitTimeTracking } =
		useUpdateEstimationAndTracking(TIME_TRACKING_ACTION_SOURCE);

	const payload = useMemo(
		() => ({
			estimateStatisticId: currentEstimationStatistic.id,
			trackingStatisticId: currentTrackingStatistic.id,
			rapidViewId,
		}),
		[currentEstimationStatistic.id, currentTrackingStatistic.id, rapidViewId],
	);

	const handleEstimationMethodUpdate = useCallback(
		async (newEstimateStatisticId: EstimateStatisticIdInput) => {
			const result = await commitEstimation({
				...payload,
				...newEstimateStatisticId,
			});
			if (result) {
				setCurrentEstimationStatistic(result.currentEstimationStatistic);
			}

			return result;
		},
		[commitEstimation, payload, setCurrentEstimationStatistic],
	);

	const handleTimeTrackingUpdate = useCallback(
		async (newTrackingStatisticId: TrackingStatisticIdInput) => {
			const result = await commitTimeTracking({
				...payload,
				...newTrackingStatisticId,
			});
			if (result) {
				setCurrentTrackingStatistic(result.currentTrackingStatistic);
			}

			return result;
		},
		[commitTimeTracking, payload, setCurrentTrackingStatistic],
	);

	return {
		isEstimationUpdating,
		isTimeTrackingUpdating,
		handleEstimationMethodUpdate,
		handleTimeTrackingUpdate,
	};
};
