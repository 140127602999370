import React, { type PropsWithChildren, type ReactNode } from 'react';
import { Inline, Box, xcss } from '@atlaskit/primitives';
import { AsyncLocationlessDialog } from '@atlassian/jira-board-location-dialogs-locationless/src/async.tsx';
import { useBoardId } from '@atlassian/jira-board-settings-common/src/common/utils/use-board-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { editModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import type { RapidBoardConfig } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/types/index.tsx';
import {
	BoardSettingsCardColorsSkeleton,
	BoardSettingsCardLayoutSkeleton,
	BoardSettingsEstimatesSkeleton,
	BoardSettingsGeneralSettingsSkeleton,
	BoardSettingsQuickFiltersSkeleton,
	BoardSettingsSwimlanesSkeleton,
	BoardSettingsTimelineSkeleton,
} from '@atlassian/jira-skeletons/src/ui/board-settings/BoardSettingsCardLayoutSkeleton.tsx';
import { CardColorSettingsEditModelProvider } from '@atlassian/jira-software-board-settings-card-colors/src/ui/card-colors-settings-page/index.tsx';
import { CardLayoutSettingsEditModelProvider } from '@atlassian/jira-software-board-settings-card-layout/src/ui/card-layout-settings-page/index.tsx';
import { EstimationSettingsEditModelProvider } from '@atlassian/jira-software-board-settings-estimation/src/ui/estimation-settings-page/index.tsx';
import { GeneralSettingsEditModelProvider } from '@atlassian/jira-software-board-settings-general/src/ui/general-settings-page/index.tsx';
import { useIsKanbanBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-is-kanban-board/index.tsx';
import { useIsUserLocatedBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-is-user-located-board/index.tsx';
import { useBoardDetailsData } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-board-details-data/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { InvalidStateHandler } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/invalid-state-handler/index.tsx';
import { SwimlanesSettingsEditModelProvider } from '@atlassian/jira-software-board-settings-swimlanes/src/ui/swimlanes-settings-page/index.tsx';
import { TimelineSettingsEditModelProvider } from '@atlassian/jira-software-board-settings-timeline/src/ui/timeline-settings-page/index.tsx';
import { AsyncNoSoftwareLicense } from '@atlassian/jira-spa-apps-common/src/error-screens/no-software-license/async.tsx';
import { checkLicense } from '@atlassian/jira-spa-apps-common/src/interceptors/license/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { Redirect, useResource } from '@atlassian/react-resource-router';
import { Header } from './header/index.tsx';
import Menu from './menu/index.tsx';
import { useConfigQuery, useIsNeedsEditModel } from './utils.tsx';

const BoardNotFoundRedirect = () => <Redirect to="/jira/projects?showFlag=jsw.board-not-found" />;

const SettingsContainerContent = ({ children }: PropsWithChildren) => {
	const willShowNav4 = getWillShowNav4();

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isJsm = willShowNav4 ? false : useIsJSMBoard();
	const isUserLocatedBoard = willShowNav4
		? false
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsUserLocatedBoard();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isKanban = willShowNav4 ? false : useIsKanbanBoard();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [configQuery] = willShowNav4 ? [''] : useConfigQuery();
	const { data, error } = useBoardDetailsData();
	const tenantContext = useTenantContext();
	const hasSoftwareLicense = checkLicense(tenantContext, 'software');
	const boardId = useBoardId();

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const fetchError = error as FetchError;

	if (!hasSoftwareLicense) {
		return <AsyncNoSoftwareLicense />;
	}

	if (!boardId) {
		return <BoardNotFoundRedirect />;
	}

	if (data && !data.location) {
		// board is locationless
		return <AsyncLocationlessDialog rapidViewId={data.id} />;
	}

	if (fetchError?.statusCode === 404) {
		return <BoardNotFoundRedirect />;
	}

	return (
		<Box paddingInline="space.500">
			<Header />
			{willShowNav4 ? (
				<Box paddingBlockStart="space.300" paddingBlockEnd="space.250">
					<SettingsStateProvider>{children}</SettingsStateProvider>
				</Box>
			) : (
				<Inline>
					<Menu
						isJsm={isJsm}
						isUserLocatedBoard={isUserLocatedBoard}
						isKanban={isKanban}
						currentConfigQuery={configQuery ?? ''}
					/>
					<Box paddingBlockStart="space.300" paddingBlockEnd="space.250" xcss={pageStyles}>
						<SettingsStateProvider>{children}</SettingsStateProvider>
					</Box>
				</Inline>
			)}
		</Box>
	);
};

// Extracted out since we need to have each fg check in a separate statement
const ProviderRenderer = ({ data }: { data: RapidBoardConfig | null }) => {
	if (data && fg('simplified-boards-milestone-2_07hbk')) {
		if (!fg('board-settings-graphql-refactor_cyvz9')) {
			return (
				<>
					<CardColorSettingsEditModelProvider data={data} />
					<CardLayoutSettingsEditModelProvider data={data} />
					<EstimationSettingsEditModelProvider data={data} />
					<GeneralSettingsEditModelProvider data={data} />
					<SwimlanesSettingsEditModelProvider data={data} />
					<TimelineSettingsEditModelProvider data={data} />
				</>
			);
		}
	}

	return null;
};

const SettingsStateProvider = ({ children }: { children: ReactNode }) => {
	const [configQuery] = useConfigQuery();
	const { data, loading, error } = useResource(editModelResource);

	// need to update FF in useIsNeedsEditModel when rolling
	// out graphQL on a per page basis
	const needsEditModel = useIsNeedsEditModel(configQuery);

	// CSM and Working Days on GQL completely
	if (loading) {
		switch (configQuery) {
			case 'cardColors':
				return <BoardSettingsCardColorsSkeleton />;
			case 'cardLayout':
				return <BoardSettingsCardLayoutSkeleton />;
			case 'estimation':
				return <BoardSettingsEstimatesSkeleton />;
			case 'filter':
				return <BoardSettingsGeneralSettingsSkeleton />;
			case 'quickFilters':
				return <BoardSettingsQuickFiltersSkeleton />;
			case 'swimlanes':
				return <BoardSettingsSwimlanesSkeleton />;
			case 'timelineConfig':
				return <BoardSettingsTimelineSkeleton />;
			case 'columns':
			case 'dateFields':
			case 'time':
				// pages do not require edit model data
				break;
			default:
				throw new Error('invalid config query');
		}
	}

	if (error || (needsEditModel && !loading && !data)) {
		return <InvalidStateHandler error={error} />;
	}

	return (
		<>
			<ProviderRenderer data={data} />
			{children}
		</>
	);
};

const pageStyles = xcss({
	flexGrow: 1,
	minWidth: '0',
});

export default SettingsContainerContent;
