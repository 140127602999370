import React, { Component, type KeyboardEvent } from 'react';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { PickerWithAvatarAndAnalytics } from '@atlassian/jira-common-components-picker/src/index.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { PUBLIC_SHARE } from '@atlassian/jira-shared-types/src/share-permission.tsx';
import type { ShareType, Option } from '../../../../model/types.tsx';
import messages from './messages.tsx';

export type Props = {
	isShareScopeLoading: boolean;
	isEditorList: boolean;
	ariaLabel?: string;
	ariaLabelledby?: string;
} & Intl & {
		type: ShareType;
		options: Option[];
		onChange: (arg1: ShareType, arg2: UIAnalyticsEvent) => void;
	};

type State = {
	selectedOption: Option;
};

const getOption = (options: Option[], type: ShareType) =>
	options.find((option) => option.value === type) || options[0];

const getNormalizedOptions = memoizeOne((options: Option[], formatMessage) =>
	options.map((option) => {
		const returnOption = { ...option, label: formatMessage(option.messageDescriptor) };

		if (!returnOption.isDisabled) {
			return returnOption;
		}

		if (returnOption.value === PUBLIC_SHARE) {
			return {
				...returnOption,
				disableReason: formatMessage(messages.globalDisableReason),
			};
		}
		return returnOption;
	}),
);

// eslint-disable-next-line jira/react/no-class-components
export class ShareeTypePicker extends Component<Props, State> {
	static getDerivedStateFromProps(props: Props, state: State) {
		const { options, type } = props;

		if (state.selectedOption && state.selectedOption.value === type) {
			return null;
		}

		return {
			selectedOption: getOption(options, type),
		};
	}

	state = {
		selectedOption: getOption(this.props.options, this.props.type),
	};

	onChange = (option: Option, analyticsEvent: UIAnalyticsEvent) => {
		const { onChange } = this.props;
		onChange(option.value, analyticsEvent);
	};

	onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Escape') {
			event.stopPropagation();
		}
	};

	// eslint-disable-next-line jira/react-arrow-function-property-naming
	screenReaderLabelForOption = (option: Option) => {
		const { options } = this.props;

		const currentOption =
			options?.length && options.find((optionFromList) => optionFromList.value === option.value);

		if (currentOption && currentOption.label) {
			return `${option.label}, ${currentOption.label}`;
		}
		return option.label;
	};

	/* eslint-disable jira/react-arrow-function-property-naming */
	formatOptionDataTransformer = (option: Option) => {
		const {
			intl: { formatMessage },
		} = this.props;

		return {
			avatar: option.icon,
			label: formatMessage(option.messageDescriptor),
		};
	};

	render() {
		const {
			isShareScopeLoading,
			options,
			intl: { formatMessage },
			ariaLabel,
			ariaLabelledby,
		} = this.props;
		const normalizedOptions = getNormalizedOptions(options, formatMessage);
		const normalizedSelectOption = getOption(normalizedOptions, this.props.type);

		return (
			<PickerWithAvatarAndAnalytics
				// @ts-expect-error - Type '(option: Option, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type '(arg1: unknown, arg2: UIAnalyticsEvent | undefined, args_2: UIAnalyticsEvent) => any'.
				onSelected={this.onChange}
				options={normalizedOptions}
				// @ts-expect-error - Type '(option: Option) => { avatar: React.ReactNode; label: string; }' is not assignable to type 'FormatOptionDataTransformer<unknown>'.
				formatOptionDataTransformer={this.formatOptionDataTransformer}
				// @ts-expect-error - Type 'number' is not assignable to type 'string'.
				width={gridSize * 20}
				listItemCustomStyles={{
					// Custom internal styling API for PickerWithAvatarAndAnalytics

					marginBottom: 2,
				}}
				isRequired={false}
				isDisabled={isShareScopeLoading}
				isClearable={false}
				isCompact
				{...(isShareScopeLoading
					? { placeholder: '' }
					: {
							value: normalizedSelectOption,
						})}
				aria-label={ariaLabel}
				aria-labelledby={ariaLabelledby}
				getOptionLabel={this.screenReaderLabelForOption}
				// @ts-expect-error - Type '(event: KeyboardEvent<HTMLDivElement>) => void' is not assignable to type '(arg1: KeyboardEvent<HTMLElement>) => void'.
				onKeyDown={this.onKeyDown}
			/>
		);
	}
}

export default injectIntl(ShareeTypePicker);
