import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import type { SwimlaneStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import type { SwimlaneStrategyTransition } from '../../../common/types/index.tsx';
import { useUpdateSwimlaneStrategy } from '../../../services/update-swimlane-strategy/index.tsx';
import type { UpdateSwimlaneStrategyResponse } from '../../../services/update-swimlane-strategy/types.tsx';
import { useIsCMPBoard } from '../../selectors/board/index.tsx';
import { useSwimlaneStrategy } from '../../selectors/swimlanes/index.tsx';
import messages from './messages.tsx';

type Response = Omit<UpdateSwimlaneStrategyResponse, 'commit'> & {
	requestSwimlaneStrategyUpdate(strategy: SwimlaneStrategy): void;
	transition: SwimlaneStrategyTransition;
};

export const useSwimlaneStrategyTransition = (rapidViewId: number): Response => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const [isCMPBoard] = useIsCMPBoard();
	const [current, { setSwimlaneStrategy }] = useSwimlaneStrategy();
	const [next, setNext] = useState<SwimlaneStrategy | null>(null);

	const transition = useMemo<SwimlaneStrategyTransition>(() => {
		if (current === null) {
			return { kind: 'initial', current: null, next: null };
		}
		if (next !== null) {
			return { kind: 'in-progress', current, next };
		}
		return { kind: 'settled', current, next: null };
	}, [next, current]);

	const { commit, hasError, loading, ...other } = useUpdateSwimlaneStrategy(
		rapidViewId,
		formatMessage(messages.failedToUpdateSwimlaneStrategy),
	);

	useEffect(() => {
		if (next == null || loading) return;

		if (!hasError) {
			setSwimlaneStrategy(next);
			showFlag((props) => (
				<SuccessFlagWithBoardLink
					isCMPBoard={isCMPBoard}
					title={formatMessage(messages.successFlagForUpdatingStrategy)}
					{...props}
				/>
			));
		}

		setNext(null);
	}, [hasError, next, loading, setSwimlaneStrategy, isCMPBoard, showFlag, formatMessage]);

	const requestSwimlaneStrategyUpdate = useCallback(
		(swimlaneStrategy: SwimlaneStrategy) => {
			commit({ swimlaneStrategy });
			setNext(swimlaneStrategy);
		},
		[commit],
	);

	return {
		...other,
		loading,
		hasError,
		transition,
		requestSwimlaneStrategyUpdate,
	};
};
