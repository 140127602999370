/**
 * Helper function to return a boolean output from operations which don't return a body
 * in order to differentiate between success and failure. The response signature for these cases is
 * success = null, failure = undefined which is quite confusing.
 *
 * This is useful for callbacks hitting endpoints with a 204 status code response
 *
 * @param commit callback function which calls an API operation wtih a 204 success response
 * @returns boolean output of whether DELETE was successful or not
 */
export const emptyResponseCommitToBoolean =
	<Input = void, Response = void>(commit: (arg: Input) => Promise<Response>) =>
	(arg: Input): Promise<{ ok: boolean }> =>
		commit(arg)
			.then((output) => ({ ok: output === null }))
			.catch(() => ({ ok: false }));

export const convertToDefinedArray = <T,>(arr: readonly (T | null | undefined)[]): T[] =>
	arr.flatMap((el) => (el !== undefined && el !== null ? [el] : []));
