import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	userColumn: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.user-column',
		defaultMessage: 'Users',
		description: 'Table column heading for a column containing users.',
	},
	permissionColumn: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.permission-column',
		defaultMessage: 'Permission',
		description: 'Table column heading for a column containing permissions assigned to users.',
	},
	permissionEditor: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.permission-editor',
		defaultMessage: 'Editor',
		description: 'Text for labelling the edit items within the table.',
	},
	permissionViewer: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.permission-viewer',
		defaultMessage: 'Viewer',
		description: 'Text for labelling the viewer items within the table.',
	},
});
