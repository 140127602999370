import type { PaletteColor } from '../types.tsx';

export const COLOR_CARD_SIZE = 30;

export const defaultColorPalette: PaletteColor[] = [
	{
		label: 'purple',
		value: '#8F7EE7',
	},
	{
		label: 'magenta',
		value: '#DA62AC',
	},
	{
		label: 'red',
		value: '#F15B50',
	},
	{
		label: 'orange',
		value: '#E56910',
	},
	{
		label: 'yellow',
		value: '#B38600',
	},
	{
		label: 'lime',
		value: '#6A9A23',
	},
	{
		label: 'green',
		value: '#22A06B',
	},
	{
		label: 'teal',
		value: '#2898BD',
	},
	{
		label: 'blue',
		value: '#388BFF',
	},
	{
		label: 'grey',
		value: '#8590A2',
	},
	{
		label: 'darkPurple',
		value: '#5E4DB2',
	},
	{
		label: 'darkMagenta',
		value: '#943D73',
	},
	{
		label: 'darkRed',
		value: '#AE2E24',
	},
	{
		label: 'darkOrange',
		value: '#A54800',
	},
	{
		label: 'darkYellow',
		value: '#7F5F01',
	},
	{
		label: 'darkLime',
		value: '#4C6B1F',
	},
	{
		label: 'darkGreen',
		value: '#216E4E',
	},
	{
		label: 'darkTeal',
		value: '#206A83',
	},
	{
		label: 'darkBlue',
		value: '#0055CC',
	},
	{
		label: 'darkGrey',
		value: '#44546F',
	},
];
