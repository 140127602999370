import React, { useState, useCallback, useEffect } from 'react';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useBoardSubFilterConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import {
	useIsCMPBoard,
	useRapidViewId,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board/index.tsx';
import type { SubQueryConfig } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/sub-query.tsx';
import { useFireAnalyticsTrackEvent } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-fire-analytics-event/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useUpdateRemoteBoardSubFilter } from '../../services/update-remote-board-sub-filter/index.tsx';
import messages from './messages.tsx';

const getValue = (config: SubQueryConfig): string | undefined => config.subqueries[0]?.query;

export const useCurrentBoardSubFilter = (): {
	value: string | undefined;
	pendingValue: string | undefined;
	validationError: string | undefined;
	requestUpdate(value: string | undefined): void;
	resetFilter(): void;
} => {
	const [pendingValue, setPendingValue] = useState<string | undefined>();
	const rapidViewId = useRapidViewId();
	const isCMPBoard = useIsCMPBoard();
	const [boardSubFilter, { updateBoardSubFilter }] = useBoardSubFilterConfig();

	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const { fireTrackEvent } = useFireAnalyticsTrackEvent();
	const { queryValidationErrorMessage, commit: requestCommit } =
		useUpdateRemoteBoardSubFilter(rapidViewId);
	const [validationError, setValidationError] = useState<string | undefined>(
		queryValidationErrorMessage,
	);

	const resetFilter = () => {
		setValidationError(undefined);
		setPendingValue(undefined);
	};

	useEffect(() => {
		if (queryValidationErrorMessage) {
			setValidationError(queryValidationErrorMessage);
		}
	}, [queryValidationErrorMessage]);

	const requestUpdate = useCallback(
		async (update: string | undefined) => {
			if (update === undefined) {
				return;
			}

			setPendingValue(update);
			setValidationError(undefined);

			const response = await requestCommit({ query: update });

			if (response?.query != null) {
				updateBoardSubFilter({
					subqueries: [
						{
							id: rapidViewId,
							query: response.query,
						},
					],
				});

				fireTrackEvent('updateBoardSubfilter succeeded');
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						isCMPBoard={isCMPBoard}
						// TODO discussion in https://atlassian.slack.com/archives/C054HN56ZLP/p1710900308815249
						title={formatMessage(messages.successFlag)}
						{...props}
					/>
				));

				setPendingValue(undefined);
			}
		},
		[
			requestCommit,
			updateBoardSubFilter,
			rapidViewId,
			showFlag,
			isCMPBoard,
			formatMessage,
			fireTrackEvent,
		],
	);

	return {
		value: getValue(boardSubFilter),
		pendingValue,
		validationError,
		requestUpdate,
		resetFilter,
	};
};
