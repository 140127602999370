import React from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import RainbowImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/rainbow-paint/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { BOARD_SETTINGS_CREATE_CARD_COLOR_MODAL_ID } from '../../../../../common/constants/modals.tsx';
import { useCanEdit } from '../../../../../controllers/selectors/permissions/index.tsx';
import messages from './messages.tsx';

export const EmptyView = () => {
	const { formatMessage } = useIntl();

	const [, { on: openCreateModal }] = useSwitchModals(BOARD_SETTINGS_CREATE_CARD_COLOR_MODAL_ID);
	const [canEdit] = useCanEdit();

	return (
		<EmptyState
			header={formatMessage(messages.header)}
			description={formatMessage(messages.description)}
			primaryAction={
				canEdit && (
					<Button
						appearance="link"
						onClick={openCreateModal}
						testId="software-board-settings-card-colors.ui.card-colors-settings-page.strategy-content.query-strategy.empty-view.button"
					>
						{formatMessage(messages.buttonText)}
					</Button>
				)
			}
			renderImage={getAkEmptyStateRenderImageFn(RainbowImage)}
		/>
	);
};
