import React, { memo, useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../common/constants/data-test.tsx';
import type { ColorItem } from '../../common/types/index.tsx';
import { ColorField } from '../../common/ui/modal-form/color-field/index.tsx';
import { ModalForm } from '../../common/ui/modal-form/index.tsx';
import { QueryField, type QueryFieldValue } from '../../common/ui/modal-form/query-field/index.tsx';
import messages from './messages.tsx';

export interface CreateColorQueryModalProps {
	title: string;
	description: React.ReactNode;
	onSubmit: (colorQueryItem: Omit<ColorItem, 'id'>) => void;
	onCancel: () => void;
	isSubmitting: boolean;
	analyticsSource: string;
	queryValidationErrorMessage?: string;
}

type ModalFormValue = {
	color: string;
	name?: string;
	query: QueryFieldValue;
	isUsed: boolean;
	isGlobalColor: boolean;
};

export const CreateColorQueryModal = memo(
	({
		analyticsSource,
		onSubmit,
		queryValidationErrorMessage,
		...props
	}: CreateColorQueryModalProps) => {
		const { formatMessage } = useIntl();

		const onSubmitCallback = useCallback(
			({ query: { jql }, ...value }: ModalFormValue) => {
				onSubmit({ ...value, query: jql });
			},
			[onSubmit],
		);

		return (
			<ModalForm<ModalFormValue>
				{...props}
				submitButtonLabel={formatMessage(messages.submit)}
				onSubmit={onSubmitCallback}
				testId={dataTestIds.createModal}
			>
				<ColorField name="color" />
				<QueryField
					name="query"
					analyticsSource={analyticsSource}
					queryValidationErrorMessage={queryValidationErrorMessage}
				/>
			</ModalForm>
		);
	},
);
