import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Link from '@atlaskit/link';
import { Box } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { fg } from '@atlassian/jira-feature-gating';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { InProductHelpButton } from '@atlassian/jira-insights-common/src/common/ui/in-product-help-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import {
	BOARD_SETTINGS_ARTICLE_ID_CONFIG_DISABLE_SECTION,
	BOARD_SETTINGS_ARTICLE_ID_CONFIG_DISABLE_SECTION_ANALYTICS_ID,
	BOARD_SETTINGS_ARTICLE_SAC_URL_CONFIG_DISABLE_SECTION,
} from '../../common/constants.tsx';
import { messages } from './messages.tsx';

export const ConfigDisabledSection = () => {
	const { formatMessage } = useIntl();

	const onHelpClick = useCallback(
		(_e: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) =>
			fireUIAnalytics(
				analyticsEvent,
				BOARD_SETTINGS_ARTICLE_ID_CONFIG_DISABLE_SECTION_ANALYTICS_ID,
			),
		[],
	);

	const helpLink = fg('jsw-board-settings-general-page-a11y-fixes') ? (
		<Box>
			<HelpPanelLink
				articleId={BOARD_SETTINGS_ARTICLE_ID_CONFIG_DISABLE_SECTION}
				appearance="link"
				spacing="none"
				onClick={onHelpClick}
				fallbackComponent={
					<Link
						href={BOARD_SETTINGS_ARTICLE_SAC_URL_CONFIG_DISABLE_SECTION}
						target="_blank"
						onClick={onHelpClick}
					>
						{formatMessage(messages.disabledSectionLearnMore)}
					</Link>
				}
			>
				{formatMessage(messages.disabledSectionLearnMore)}
			</HelpPanelLink>
		</Box>
	) : (
		<InProductHelpButton
			articleId={BOARD_SETTINGS_ARTICLE_ID_CONFIG_DISABLE_SECTION}
			articleAnalyticsId={BOARD_SETTINGS_ARTICLE_ID_CONFIG_DISABLE_SECTION_ANALYTICS_ID}
			appearance="link"
		>
			{formatMessage(messages.disabledSectionLearnMore)}
		</InProductHelpButton>
	);

	if (!fg('onboard_board_settings_to_vc90')) {
		return (
			<DisabledBannerWrapper>
				<SectionMessage>
					{formatMessage(messages.disabledSectionDescription)} {helpLink}
				</SectionMessage>
			</DisabledBannerWrapper>
		);
	}

	return (
		<UFOSegment name="config-disabled-section">
			<DisabledBannerWrapper>
				<SectionMessage>
					{formatMessage(messages.disabledSectionDescription)} {helpLink}
				</SectionMessage>
			</DisabledBannerWrapper>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledBannerWrapper = styled.div({
	maxWidth: '680px',
});
