import {
	OPEN_SHARE,
	PUBLIC_SHARE,
	type SharePermission,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import { PRIVATE_SHARE } from '../../model/constants.tsx';
import {
	shareeOptions,
	mapAccessTypeFromPermissions,
	shareeOptionsWithoutPublicAccess,
	shareeOptionsWithPublicAccessDisabled,
	sharingTypeToPermissionsMap,
} from '../../model/index.tsx';
import type { SharingPermissionKey } from '../../model/types.tsx';

type SharedObjectsPermission =
	| {
			permissions?: {
				CREATE_SHARED_OBJECTS?: {
					havePermission?: boolean;
				};
			};
	  }
	| undefined;

type DataProviderProps = {
	data?: Record<SharingPermissionKey, boolean> | undefined;
	loading: boolean;
	sharedObjectsPermission?: SharedObjectsPermission;
};

const getShareeOptionsIfPublicSharingDisabled = (isEntitySharePermissionSetAsPublic: boolean) =>
	isEntitySharePermissionSetAsPublic
		? shareeOptionsWithPublicAccessDisabled
		: shareeOptionsWithoutPublicAccess;

const getShareOptionsPublicOrganizationOnly = (
	isPublicSharingEnabled: boolean | undefined,
	isEntitySharePermissionSetAsPublic: boolean,
) => {
	const publicOrganizationOnlyShareOptions = shareeOptions.filter(
		(option) => option.value === PUBLIC_SHARE || option.value === OPEN_SHARE,
	);
	if (isPublicSharingEnabled === true) {
		return publicOrganizationOnlyShareOptions;
	}
	return isEntitySharePermissionSetAsPublic
		? publicOrganizationOnlyShareOptions.map((option) => ({
				...option,
				isDisabled: option.value === PUBLIC_SHARE,
			}))
		: publicOrganizationOnlyShareOptions.filter((option) => option.value !== PUBLIC_SHARE);
};

export const isEntitySharePermissionPublic = (sharePermissions: SharePermission[]) =>
	Boolean(mapAccessTypeFromPermissions(sharePermissions) === PUBLIC_SHARE);

export const isCreateSharedObjectsPermission = (sharedObjectsPermission: SharedObjectsPermission) =>
	sharedObjectsPermission?.permissions?.CREATE_SHARED_OBJECTS?.havePermission;

export const getSharePermissions = (
	{ sharedObjectsPermission, loading }: DataProviderProps,
	sharePermissions: SharePermission[],
	avoidInitialPublicSharePermission = false,
) => {
	if (isCreateSharedObjectsPermission(sharedObjectsPermission) === false || loading) {
		return [];
	}
	const isEntitySharePermissionSetAsPublic =
		mapAccessTypeFromPermissions(sharePermissions) === PUBLIC_SHARE;

	return isEntitySharePermissionSetAsPublic && avoidInitialPublicSharePermission
		? []
		: sharePermissions;
};

export const getShareeOptions = (
	{ data, sharedObjectsPermission, loading }: DataProviderProps,
	sharePermissions: SharePermission[],
	sharingPermissionType: 'dashboard' | 'filter',
	allowSharePublicOrganizationOnly = false,
) => {
	const isPublicSharingEnabled = data && data[sharingTypeToPermissionsMap[sharingPermissionType]];
	const isEntitySharePermissionSetAsPublic = isEntitySharePermissionPublic(sharePermissions);

	let dialogShareeOptions;

	if (allowSharePublicOrganizationOnly) {
		dialogShareeOptions = getShareOptionsPublicOrganizationOnly(
			isPublicSharingEnabled,
			isEntitySharePermissionSetAsPublic,
		);
	} else {
		dialogShareeOptions =
			isPublicSharingEnabled === true
				? shareeOptions
				: getShareeOptionsIfPublicSharingDisabled(isEntitySharePermissionSetAsPublic);
	}

	if (isCreateSharedObjectsPermission(sharedObjectsPermission) === false || loading) {
		return dialogShareeOptions.filter(
			(option) => option.value === (allowSharePublicOrganizationOnly ? OPEN_SHARE : PRIVATE_SHARE),
		);
	}
	return dialogShareeOptions;
};
