import React, { useMemo, type PropsWithChildren } from 'react';
import { graphql, usePreloadedQuery, type EntryPointProps } from 'react-relay';
import { useBoardId } from '@atlassian/jira-board-settings-common/src/common/utils/use-board-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import type { generalSettingsPageQuery } from '@atlassian/jira-relay/src/__generated__/generalSettingsPageQuery.graphql';
import { GeneralSettingsDataContainer } from '@atlassian/jira-software-board-settings-general-common/src/controllers/main.tsx';
import type {
	RapidBoardConfig,
	GeneralConfigProps,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
import { useIsLocatedInUserContainer } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-is-located-in-user-container/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { GeneralSettings } from './main.tsx';
import { generalSettingsTransformer } from './transformer.tsx';

// Deprecated with simplified-boards-milestone-2_07hbk
const GeneralSettingsPage = ({ props }: EntryPointProps<{}, {}, GeneralConfigProps, {}>) => (
	<JiraRelayEnvironmentProvider>
		<GeneralSettingsDataContainer {...props}>
			<GeneralSettings />
		</GeneralSettingsDataContainer>
	</JiraRelayEnvironmentProvider>
);

type Query = { queryReference: generalSettingsPageQuery };

export const GeneralSettingPageContent = (entryPointProps: EntryPointProps<Query, {}, {}, {}>) => {
	if (fg('board-settings-graphql-refactor_cyvz9')) {
		return <GraphQlPageContent {...entryPointProps} />;
	}

	return <EditModelPageContent />;
};

const GraphQlPageContent = ({
	queries: { queryReference },
}: EntryPointProps<Query, {}, {}, {}>) => {
	const boardId = useBoardId();
	const { atlassianAccountId } = useTenantContext();
	const isLocatedInUserContainer = useIsLocatedInUserContainer();

	/* eslint-disable @atlassian/relay/unused-fields */
	const { boardScope, jira } = usePreloadedQuery(
		graphql`
			query generalSettingsPageQuery($boardId: ID!, $cloudId: ID!) {
				jira @required(action: THROW) {
					getGlobalPermissionsAndGrants(cloudId: $cloudId)
						@optIn(to: "JiraGlobalPermissions")
						@required(action: THROW) {
						... on JiraGlobalPermissionGrantsList {
							globalPermissionGrants @required(action: THROW) {
								permission @required(action: THROW) {
									key @required(action: THROW)
								}
							}
						}
					}
				}
				boardScope(boardId: $boardId, isCMP: true) @required(action: THROW) {
					board @required(action: THROW) {
						name @required(action: THROW)
					}
					currentUser @required(action: THROW) {
						permissions @required(action: THROW)
					}
					savedFilterConfig @optIn(to: "modularBoardSettings") @required(action: THROW) {
						id @required(action: THROW)
						canEdit @required(action: THROW)
						query @required(action: THROW)
						name @required(action: THROW)
						# description can be null for user located
						description
						owner {
							displayName @required(action: THROW)
							avatarUrl
							accountId @required(action: THROW)
						}
						editPermissionEntries @required(action: THROW) {
							type @required(action: THROW)
							group {
								name
								id
							}
							project {
								id @required(action: THROW)
								name @required(action: THROW)
								avatarUrl @required(action: THROW)
							}
							role {
								name @required(action: THROW)
								id @required(action: THROW)
							}
							user {
								accountId @required(action: THROW)
								displayName @required(action: THROW)
								avatarUrl @required(action: THROW)
							}
						}
						sharePermissionEntries @required(action: THROW) {
							type @required(action: THROW)
							group {
								name
								id
							}
							project {
								id @required(action: THROW)
								name @required(action: THROW)
								avatarUrl @required(action: THROW)
							}
							role {
								name @required(action: THROW)
								id @required(action: THROW)
							}
							user {
								accountId @required(action: THROW)
								displayName @required(action: THROW)
								avatarUrl @required(action: THROW)
							}
						}
						isOrderedByRank @required(action: THROW)
						queryProjects @required(action: THROW) {
							projects {
								id @required(action: THROW)
								name @required(action: THROW)
								key @required(action: THROW)
								canEditProject @required(action: THROW)
							}
							isProjectsUnboundedInFilter
							isMaxSupportShowingProjectsReached
							projectsCount
						}
					}
					oldDoneIssuesCutOffConfig @optIn(to: "modularBoardSettings") @required(action: THROW) {
						oldDoneIssuesCutoff @required(action: THROW)
						options @required(action: THROW) {
							label @required(action: THROW)
							value @required(action: THROW)
						}
					}
					boardAdmins @optIn(to: "modularBoardSettings") @required(action: THROW) {
						userKeys @required(action: THROW) {
							key @required(action: THROW)
							displayName @required(action: THROW)
						}
						groupKeys @required(action: THROW) {
							key @required(action: THROW)
							displayName @required(action: THROW)
						}
					}
					features @required(action: THROW) {
						key @required(action: THROW)
						status @required(action: THROW)
					}
					subqueryConfig @optIn(to: "modularBoardSettings") @required(action: THROW) {
						subqueries @required(action: THROW) {
							id @required(action: THROW)
							query @required(action: THROW)
						}
					}
					boardLocationModel @optIn(to: "modularBoardSettings") @required(action: THROW) {
						avatarURI @required(action: THROW)
						name @required(action: THROW)
						projectId
						projectTypeKey
						userLocationId
					}
				}
			}
		`,
		queryReference,
	);

	if (!boardId) {
		throw new Error('boardId not found');
	}

	if (!atlassianAccountId) {
		throw new Error('accountId not found');
	}

	const mergedProps: GeneralConfigProps = useMemo(
		() =>
			generalSettingsTransformer(
				jira,
				boardScope,
				boardId,
				atlassianAccountId,
				isLocatedInUserContainer,
			),
		[jira, boardScope, isLocatedInUserContainer, atlassianAccountId, boardId],
	);

	return (
		<GeneralSettingsDataContainer {...mergedProps}>
			<GeneralSettings />
			<SpaStatePageReady />
		</GeneralSettingsDataContainer>
	);
};

const EditModelPageContent = () => (
	<>
		<GeneralSettings />
		<SpaStatePageReady />
	</>
);

export const GeneralSettingsEditModelProvider = ({
	data,
	children,
}: PropsWithChildren<{
	data: RapidBoardConfig;
}>) => {
	const props = {
		rapidViewId: data.id,
		warnBeforeEditingOwner: data.warnBeforeEditingOwner,
		warnBeforeEditingLocation: data.warnBeforeEditingLocation,
		name: data.name,
		canEdit: data.canEdit,
		boardLocationModel: data.boardLocationModel,
		filterConfig: data.filterConfig,
		oldDoneIssuesCutoff: data.oldDoneIssuesCutoff,
		oldDoneIssuesCutoffOptions: data.oldDoneIssuesCutoffOptions,
		boardAdmins: data.boardAdmins,
		canUseBoardAdminsPicker: data.canUseBoardAdminsPicker,
		subqueryConfig: data.subqueryConfig,
		isSprintSupportEnabled: data.isSprintSupportEnabled,
		isCMPBoard: true,
		userConfig: data.globalConfig.userConfig,
	};
	return (
		<GeneralSettingsDataContainer {...props} isGlobal>
			{children}
		</GeneralSettingsDataContainer>
	);
};

export default GeneralSettingsPage;
