import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	query: {
		id: 'software-board-settings-query-list-config-common.common.modal-form.query-field.query',
		defaultMessage: 'JQL query',
		description: 'Label for JQL text field',
	},
	requiredError: {
		id: 'software-board-settings-query-list-config-common.common.modal-form.query-field.required-error',
		defaultMessage: 'Enter a valid JQL query.',
		description: 'Error message to show when the jql field is empty',
	},
});
