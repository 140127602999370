import type { RoleOption } from '../model/index.tsx';
import type { Roles as RolesResponse, Role as RoleResponse } from '../rest/types.tsx';

export const transformRole = (role: RoleResponse): RoleOption => ({
	value: role.id,
	label: role.name,
	description: role.description,
	url: role.self,
});

export const transformRoles = (roles: RolesResponse): RoleOption[] =>
	(roles && roles.map(transformRole)) || [];
