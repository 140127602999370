import { useCallback } from 'react';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { APP_METRIC_KEY } from '@atlassian/jira-software-board-settings-general-common/src/common/constants/analytics.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import {
	FETCH_GENERAL_BOARD_LOCATION_ACTION_SUBJECT,
	FETCH_GENERAL_BOARD_LOCATION_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import type { BoardLocations, Location } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { BoardLocations, Location };

const getEndPointUrl = (query: string) => {
	const queryParam = query ? `?query=${encodeURIComponent(query)}` : '';
	return `/rest/greenhopper/1.0/boardLocation.json${queryParam}`;
};

export const useFetchBoardLocations = () => {
	const boardLocations = useCallback(
		(query: string) => fetchJson<BoardLocations>(getEndPointUrl(query)),
		[],
	);

	const { fetch } = useServiceWithAnalytics(
		boardLocations,
		{},
		{
			actionSubject: FETCH_GENERAL_BOARD_LOCATION_ACTION_SUBJECT,
			packageName: APP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_METRIC_KEY}.${FETCH_GENERAL_BOARD_LOCATION_METRICS_KEY}`,
		},
	);

	return { commit: fetch };
};
