import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Flex, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useExternalRowFocusRecovery } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/ui/focus-handler/index.tsx';
import { ColorList } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/query-list/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { dataTestIds } from '../../../../common/constants/data-test.tsx';
import { BOARD_SETTINGS_CREATE_CARD_COLOR_MODAL_ID } from '../../../../common/constants/modals.tsx';
import { useDeleteCardColorHandler } from '../../../../controllers/handlers/use-delete-card-color-handler/index.tsx';
import { useEditCardColorHandler } from '../../../../controllers/handlers/use-edit-card-color-handler/index.tsx';
import { useRankCardColorHandler } from '../../../../controllers/handlers/use-rank-card-color-handler/index.tsx';
import { useCardColors } from '../../../../controllers/selectors/colors/index.tsx';
import { useCanEdit } from '../../../../controllers/selectors/permissions/index.tsx';
import { AddCardColorModal } from './add-card-color-modal/index.tsx';
import { DeleteCardColorModal } from './delete-card-color-modal/index.tsx';
import { EditCardColorModal } from './edit-card-color-modal/index.tsx';
import { EmptyView } from './empty-view/index.tsx';
import messages from './messages.tsx';

export const QueryStrategy = () => {
	const { formatMessage } = useIntl();

	const [cardColors] = useCardColors();
	const [canEdit] = useCanEdit();

	const [, { on: openCreateModal }] = useSwitchModals(BOARD_SETTINGS_CREATE_CARD_COLOR_MODAL_ID);

	const { openEditModal, onInlineEditSubmit } = useEditCardColorHandler();
	const onRank = useRankCardColorHandler();
	const {
		beginItemDelete,
		abortItemDelete,
		commitItemDelete,
		isDeleteSubmitting,
		isDeleteModalOpen,
	} = useDeleteCardColorHandler();
	const addColorRef = useExternalRowFocusRecovery(cardColors);

	const colorItems = useMemo(
		() =>
			cardColors.map((cardColor) => ({
				id: String(cardColor.id),
				color: cardColor.color,
				query: cardColor.displayValue,
				isUsed: cardColor.isUsed,
				isGlobalColor: cardColor.isGlobalColor,
			})),
		[cardColors],
	);

	return (
		<CardColorQueryListContainer>
			<Flex justifyContent="space-between" alignItems="center">
				<Inline xcss={descriptionStyles}>{formatMessage(messages.addCardColorDescription)}</Inline>
				{canEdit && (
					<Button
						ref={addColorRef}
						testId={dataTestIds.queryStrategyAddButton}
						appearance="primary"
						onClick={openCreateModal}
					>
						{formatMessage(messages.addButtonLabel)}
					</Button>
				)}
			</Flex>
			<ColorList
				colorItems={colorItems}
				isReadOnly={!canEdit}
				onDelete={beginItemDelete}
				onEdit={openEditModal}
				onRank={onRank}
				onColorChange={onInlineEditSubmit}
				emptyView={<EmptyView />}
			/>
			<AddCardColorModal />
			<EditCardColorModal />
			{isDeleteModalOpen && (
				<DeleteCardColorModal
					onCancel={abortItemDelete}
					onSubmit={commitItemDelete}
					isSubmitting={isDeleteSubmitting}
				/>
			)}
		</CardColorQueryListContainer>
	);
};

const descriptionStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 85}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardColorQueryListContainer = styled.div({
	display: 'grid',
	gridAutoFlow: 'row',
	gridAutoRow: 'auto',
	gridTemplateColumns: '1fr',
	gap: token('space.250', '20px'),
});
