import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { CreateColorQueryModal } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/create-color-query-modal/index.tsx';
import { QueryModalDescription } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/query-modal-description/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { MODAL_ANALYTICS_SOURCE } from '../../../../../common/constants/analytics.tsx';
import { BOARD_SETTINGS_CREATE_CARD_COLOR_MODAL_ID } from '../../../../../common/constants/modals.tsx';
import { useAddCardColorHandler } from '../../../../../controllers/handlers/use-add-card-color-handler/index.tsx';
import messages from './messages.tsx';

export const AddCardColorModal = () => {
	const { formatMessage } = useIntl();

	const [isCreateOpen, { off: closeCreateModal }] = useSwitchModals(
		BOARD_SETTINGS_CREATE_CARD_COLOR_MODAL_ID,
	);
	const { isSubmitting, onAddSubmit, createQueryValidationErrorMessage } = useAddCardColorHandler();

	return (
		<>
			{isCreateOpen && (
				<CreateColorQueryModal
					title={formatMessage(messages.addCardColorModalTitle)}
					description={
						<QueryModalDescription
							description={formatMessage(messages.addCardColorModalGuidelines)}
						/>
					}
					onSubmit={onAddSubmit}
					isSubmitting={isSubmitting}
					onCancel={closeCreateModal}
					analyticsSource={MODAL_ANALYTICS_SOURCE}
					queryValidationErrorMessage={createQueryValidationErrorMessage}
				/>
			)}
		</>
	);
};
