import { CARD_COLOR_API_URI } from '../../common/constants/url.tsx';
import type { FetchStrategyConfigInput } from './types.tsx';

export const getFetchStrategyConfigUrl = ({
	rapidViewId,
	cardColorStrategy,
}: FetchStrategyConfigInput & { rapidViewId: number }) =>
	// ?preloadValues=true parameter is used to generate missing card colors
	`${CARD_COLOR_API_URI}/${rapidViewId}/strategy/${
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		cardColorStrategy as string
	}?preloadValues=true`;
