import { updateBoardAdmins } from './board-admins/index.tsx';
import { updateBoardFilter, updateSelectedBoardFilter } from './board-filter/index.tsx';
import { updateBoardLocation } from './board-location/index.tsx';
import { updateBoardName } from './board-name/index.tsx';
import { updateBoardSubFilter } from './board-sub-filter/index.tsx';
import { updateOldDoneIssueCutoff } from './completed-issues/index.tsx';
import { removeEditAccess } from './permissions/index.tsx';
import { updateSavedFilters } from './saved-filters/index.tsx';

export const actions = {
	updateBoardFilter,
	updateSelectedBoardFilter,
	updateBoardName,
	updateBoardAdmins,
	updateSavedFilters,
	updateBoardSubFilter,
	updateBoardLocation,
	removeEditAccess,
	updateOldDoneIssueCutoff,
} as const;

export type Actions = typeof actions;
