import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import FolderIcon from '@atlaskit/icon/glyph/folder';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import WorldIcon from '@atlaskit/icon/glyph/world';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { SharePermission } from '@atlassian/jira-shared-types/src/share-permission.tsx';
import { useBoardFilterConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import { unreachable } from '../../../../../common/types.tsx';
import messages from './messages.tsx';
import {
	AVATAR_GROUP,
	AVATAR_GLOBAL,
	AVATAR_LOGGEDIN,
	AVATAR_UNKNOWN,
	AVATAR_URL,
} from './types.tsx';
import { useLabelAndAvatar } from './utils.tsx';

export const ShareIdentityColumn = ({ permission }: { permission: SharePermission }) => {
	const variant = useLabelAndAvatar(permission);

	const [{ owner }] = useBoardFilterConfig();
	const { formatMessage } = useIntl();

	const avatar = useMemo(() => {
		if (variant == null) return undefined;

		const { avatar: avatarVariant, label } = variant;

		switch (avatarVariant.type) {
			case AVATAR_URL: {
				const appearance = avatarVariant.circle ? 'circle' : 'square';

				return <Avatar size="small" src={avatarVariant.url} appearance={appearance} name={label} />;
			}

			case AVATAR_GROUP:
				return <PeopleGroupIcon label={label} />;

			case AVATAR_UNKNOWN:
				return <FolderIcon label={label} />;

			case AVATAR_GLOBAL:
				return <WorldIcon label={label} />;

			case AVATAR_LOGGEDIN:
				return <OfficeBuildingIcon label={label} />;

			default:
				return unreachable(avatarVariant, undefined);
		}
	}, [variant]);

	// This won't happen.
	if (variant == null) return null;

	return (
		<Container>
			{avatar}
			<span>
				{variant.label}
				{variant.isOwner && (
					<>
						{' '}
						{owner?.accountId
							? formatMessage(messages.ownerAndProfileText, {
									button: (chunks: React.ReactNode[]) => (
										<Button
											spacing="none"
											appearance="link"
											aria-label={formatMessage(messages.viewProfileLinkAriaLabel)}
											href={`/jira/people/${owner.accountId}`}
											target="_blank"
										>
											{chunks}
										</Button>
									),
								})
							: formatMessage(messages.ownerOnlyText)}
					</>
				)}
			</span>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'grid',
	paddingLeft: token('space.100', '8px'),
	paddingTop: token('space.025', '2px'),
	paddingBottom: token('space.025', '2px'),
	gap: token('space.100', '8px'),
	alignItems: 'center',
	gridTemplateColumns: `${token('space.300', '24px')} 1fr`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'td:has(> &)': {
		width: '400px',
		overflow: 'hidden',
	},
});
