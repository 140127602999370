import React, { type ReactNode, useEffect } from 'react';
import { Stack, xcss } from '@atlaskit/primitives';
import { InProductHelpButton } from '@atlassian/jira-insights-common/src/common/ui/in-product-help-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { boardSettingsQueryConfigParamValues } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { useIsBoardSettingsTabOpen } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-board-settings-tab-open/index.tsx';
import { ConfigDisabledSection } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/config-disabled-section/index.tsx';
import { PageHeader } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/page-header/index.tsx';
import {
	BOARD_SETTINGS_ARTICLE_ID_TIMELINE,
	BOARD_SETTINGS_ARTICLE_ID_TIMELINE_ANALYTICS_ID,
} from '../../common/constants/timeline.tsx';
import { useIsInitialised } from '../../controllers/selectors/board/index.tsx';
import { useCanEdit } from '../../controllers/selectors/permissions/index.tsx';
import { useIsBoardCrossProject } from '../../controllers/selectors/timeline/index.tsx';
import { useViewTimelineSettingsExperienceTracker } from '../../services/experience-tracker/index.tsx';
import { BoardCrossProjectMessage } from './board-cross-project-message/index.tsx';
import { EnableTimeline } from './enable-timeline/index.tsx';
import messages from './messages.tsx';

export const TimelineSettingsContainer = () => {
	const { formatMessage } = useIntl();

	const [canEdit] = useCanEdit();
	const [isInitialised] = useIsInitialised();
	const [isBoardCrossProject] = useIsBoardCrossProject();

	const { fireExperienceSuccess } = useViewTimelineSettingsExperienceTracker({
		isClassic: true,
	});

	const isTimelineTabOpen = useIsBoardSettingsTabOpen(
		boardSettingsQueryConfigParamValues.timelineConfig,
	);

	useEffect(() => {
		if (isInitialised) {
			// State initialisation is now finished so we mark view experience as success
			fireExperienceSuccess({ isClassic: true });
		}
	}, [isInitialised, fireExperienceSuccess]);

	if (!isTimelineTabOpen) {
		return null;
	}

	return (
		<Stack space="space.500" xcss={timelineSettingsContainerStyles}>
			<PageHeader
				title={formatMessage(messages.title)}
				description={formatMessage(messages.description, {
					button: (chunks: ReactNode[]) => (
						<InProductHelpButton
							articleId={BOARD_SETTINGS_ARTICLE_ID_TIMELINE}
							articleAnalyticsId={BOARD_SETTINGS_ARTICLE_ID_TIMELINE_ANALYTICS_ID}
							appearance="link"
						>
							{chunks}
						</InProductHelpButton>
					),
				})}
			/>
			{!canEdit && <ConfigDisabledSection />}
			{canEdit && isBoardCrossProject && <BoardCrossProjectMessage />}
			<EnableTimeline />
		</Stack>
	);
};

const timelineSettingsContainerStyles = xcss({
	maxWidth: '680px',
});
