import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	enableTimeline: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.enable-timeline',
		defaultMessage: 'Enable timeline',
		description: 'Title for the enable timeline toggle on the board settings timeline page',
	},
	enableTimelineToggleLabel: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.enable-timeline-toggle-label',
		defaultMessage: 'Enable timeline',
		description: 'Label for the enable timeline toggle on the board settings timeline page',
	},
});
