import type { SubQueryConfig } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/sub-query.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const updateBoardSubFilter =
	(subFilterConfig: SubQueryConfig) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const currentState = getState();
		setState({
			...currentState,
			subqueryConfig: subFilterConfig,
		});
	};

export type UpdateBoardSubFilter = typeof updateBoardSubFilter;
