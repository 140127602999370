import { useCallback } from 'react';
import { useDefaultI18nColorPalette } from '../use-default-color-palette/index.tsx';

export const useGetI18nStringForColor = () => {
	const palette = useDefaultI18nColorPalette();

	return useCallback(
		(color: string) => {
			const colorInPalette = palette.find(
				({ value }) => value.toLowerCase() === color.toLowerCase(),
			);

			if (colorInPalette) {
				return colorInPalette.label;
			}

			return color;
		},
		[palette],
	);
};
