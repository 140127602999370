import React, { Component, type ChangeEvent, type SyntheticEvent, createRef } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	PROJECT_UNKNOWN_SHARE,
	type SharePermission,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import UserPicker from '@atlassian/jira-user-picker/src/index.tsx';
import type { User as PickerUser } from '@atlassian/jira-user-picker/src/model/index.tsx';
import {
	ENTITY_DESCRIPTION_FIELD_NAME,
	ENTITY_NAME_FIELD_NAME,
	SHARE_PERMISSIONS_FIELD_NAME,
	EDIT_PERMISSIONS_FIELD_NAME,
} from '../model/constants.tsx';
import type { InternalProps, ServerValidationErrors, User } from '../model/types.tsx';
import Access from './access/index.tsx';
import ShareableEntityDialog from './view.tsx';

type State = {
	name: string;
	description: string;
	owner?: User;
	isPending: boolean;
	isNameRequiredError: boolean;
	serverValidationErrors: ServerValidationErrors;
	sharePermissions: SharePermission[];
	viewersCustomMessage: string;
	nameTooltip: string;
	editPermissions: SharePermission[];
};

const UserPickerWithAnalytics = withFireUiAnalytics({ onChange: 'owner' })(UserPicker);

const isNameEmpty = (name: string) => !name.trim();

const transformToUser = ({ accountId, displayName, avatarUrl, label }: PickerUser): User => ({
	accountId,
	displayName,
	avatarUrl,
	label,
});

// eslint-disable-next-line jira/react/no-class-components
export default class ShareableEntityDialogWrapper<OR> extends Component<InternalProps<OR>, State> {
	constructor(props: InternalProps<OR>) {
		super(props);

		const {
			initialName,
			initialDescription,
			initialOwner,
			sharePermissions,
			editPermissions,
			viewersCustomMessage,
			nameTooltip,
		} = props;

		this.state = {
			name: initialName || '',
			description: initialDescription || '',
			owner: initialOwner,
			isPending: false,
			isNameRequiredError: false,
			// @ts-expect-error(PARTIAL_RECORD) TS2739 - Type '{}' is missing the following properties from type 'ServerValidationErrors': description, name, sharePermissions, editPermissions
			serverValidationErrors: {},
			sharePermissions: sharePermissions || [],
			viewersCustomMessage: viewersCustomMessage || '',
			nameTooltip: nameTooltip || '',
			editPermissions: editPermissions || [],
		};
	}

	componentDidMount() {
		this.isComponentMounted = true;
	}

	componentDidUpdate(prevProps: InternalProps<OR>) {
		if (prevProps.isShareScopeLoading && !this.props.isShareScopeLoading) {
			this.setState({
				// @ts-expect-error - TS2322 - Type 'SharePermission[] | undefined' is not assignable to type 'SharePermission[]'.
				sharePermissions: this.props.sharePermissions,
				// @ts-expect-error - TS2322 - Type 'SharePermission[] | undefined' is not assignable to type 'SharePermission[]'.
				editPermissions: this.props.editPermissions,
			});
		}
	}

	componentWillUnmount() {
		this.isComponentMounted = false;
	}

	nameInputRef = createRef<HTMLInputElement>();

	onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.target.value;

		this.setState({
			name,
			isNameRequiredError: isNameEmpty(name),
			serverValidationErrors: {
				...this.state.serverValidationErrors,
				// @ts-expect-error(PARTIAL_RECORD) TS2418 - Type of computed property's value is 'undefined', which is not assignable to type 'string'.
				[ENTITY_NAME_FIELD_NAME]: undefined,
			},
		});
	};

	onDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({
			description: event.target.value,
			serverValidationErrors: {
				...this.state.serverValidationErrors,
				// @ts-expect-error(PARTIAL_RECORD) TS2418 - Type of computed property's value is 'undefined', which is not assignable to type 'string'.
				[ENTITY_DESCRIPTION_FIELD_NAME]: undefined,
			},
		});
	};

	onOwnerChange = (selectedUser?: PickerUser) => {
		const { initialOwner } = this.props;

		const owner = selectedUser ? transformToUser(selectedUser) : initialOwner;

		this.setState({
			owner,
		});
	};

	onAction = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		const { operation, onOperationSucceed, onOperationFailed, getServerSideValidationErrors } =
			this.props;

		const { name, description, owner, sharePermissions, editPermissions } = this.state;

		this.setState({ isPending: true });

		// Filter out project unknown shares as they cannot be saved. Filters backend ignores them,
		// while dashboards backend throws a 400 error.
		const filteredSharePermissions = sharePermissions.filter(
			(share) => share.type !== PROJECT_UNKNOWN_SHARE,
		);
		const filteredEditPermissions = editPermissions.filter(
			(share) => share.type !== PROJECT_UNKNOWN_SHARE,
		);

		const operationData = {
			name,
			description,
			owner,
			sharePermissions: filteredSharePermissions,
			editPermissions: filteredEditPermissions,
		};

		operation(operationData).then(
			(operationResult) => {
				this.setStateIfMounted({
					isPending: false,
					// @ts-expect-error(PARTIAL_RECORD) TS2739 - Type '{}' is missing the following properties from type 'ServerValidationErrors': description, name, sharePermissions, editPermissions
					serverValidationErrors: {},
				});

				onOperationSucceed(operationData, operationResult, analyticsEvent);
			},
			(error) => {
				this.setStateIfMounted({ isPending: false });

				if (error instanceof ValidationError) {
					const newServerValidationErrors = getServerSideValidationErrors(error);

					this.setStateIfMounted({
						serverValidationErrors: newServerValidationErrors, // set or reset
					});
					// setting the focus to name field on error (implemented as a part of JCA11Y-1635)
					if (newServerValidationErrors.name) {
						requestAnimationFrame(() => this.nameInputRef.current?.focus());
					}
				}

				onOperationFailed(error, analyticsEvent);
			},
		);
	};

	onSharePermissionChange = (sharePermissions: SharePermission[]) => {
		this.setState({
			sharePermissions,
			serverValidationErrors: {
				...this.state.serverValidationErrors,
				// @ts-expect-error(PARTIAL_RECORD) TS2418 - Type of computed property's value is 'undefined', which is not assignable to type 'string'.
				[SHARE_PERMISSIONS_FIELD_NAME]: undefined,
			},
		});
	};

	onEditPermissionChange = (editPermissions: SharePermission[]) => {
		this.setState({
			editPermissions,
			serverValidationErrors: {
				...this.state.serverValidationErrors,
				// @ts-expect-error(PARTIAL_RECORD) TS2418 - Type of computed property's value is 'undefined', which is not assignable to type 'string'.
				[EDIT_PERMISSIONS_FIELD_NAME]: undefined,
			},
		});
	};

	setStateIfMounted(stateUpdates: Partial<State>) {
		if (this.isComponentMounted) {
			// @ts-expect-error - TS2345 - Argument of type 'Partial<State>' is not assignable to parameter of type 'State | ((prevState: Readonly<State>, props: Readonly<AppProps<OR>>) => State | Pick<State, keyof State> | null) | Pick<...> | null'.
			this.setState(stateUpdates);
		}
	}

	/*
        By the time the operation promise resolves, this component could be already unmounted,
        We need to check this before calling setState to prevent "Can't call setState (or forceUpdate) on an unmounted component." error.
    */
	// @ts-expect-error - TS2564 - Property 'isComponentMounted' has no initializer and is not definitely assigned in the constructor.
	isComponentMounted: boolean;

	render() {
		const {
			isShareScopeLoading,
			onCancel,
			title,
			headingTitleLevel,
			shareeOptions,
			editOptions,
			actionButtonCaption,
			isEditorListShown,
			isNameReadOnly,
		} = this.props;

		const {
			name,
			description,
			owner,
			isPending,
			isNameRequiredError,
			sharePermissions,
			viewersCustomMessage,
			nameTooltip,
			editPermissions,
			serverValidationErrors,
		} = this.state;

		return (
			<ShareableEntityDialog
				isPending={isPending}
				isNameRequiredError={isNameRequiredError}
				isShareScopeLoading={isShareScopeLoading}
				title={title}
				headingTitleLevel={headingTitleLevel}
				shareeOptions={shareeOptions}
				editOptions={editOptions}
				name={name}
				nameTooltip={nameTooltip}
				description={description}
				owner={owner}
				serverValidationErrors={serverValidationErrors}
				actionButtonCaption={actionButtonCaption}
				// @ts-expect-error - Type 'WithUIAnalyticsEvent<Omit<{ baseUrl: string; label: string | undefined; placeholder: string | undefined; suggestedAccountId: string | undefined; selectedUser: User | undefined; onChange: (arg1: User | undefined, args_1: UIAnalyticsEvent) => void; ... 6 more ...; forwardedRef?: Ref<...> | undefined; }, keyof WithAnal...' is not assignable to type 'ComponentType<{ selectedUser: User; ariaLabel?: string | undefined; ariaLabelledby?: string | undefined; onChange: (arg1: User) => void; }>'.
				UserPicker={UserPickerWithAnalytics}
				Access={Access}
				isEditorListShown={isEditorListShown}
				isNameReadOnly={isNameReadOnly}
				onNameChange={this.onNameChange}
				onDescriptionChange={this.onDescriptionChange}
				onOwnerChange={this.onOwnerChange}
				onAction={this.onAction}
				onCancel={onCancel}
				sharePermissions={sharePermissions}
				viewersCustomMessage={viewersCustomMessage}
				editPermissions={editPermissions}
				onSharePermissionChange={this.onSharePermissionChange}
				onEditPermissionChange={this.onEditPermissionChange}
				nameInputRef={this.nameInputRef}
			/>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledHeaderContainer = styled.div({
	marginTop: token('space.negative.025', '-2px'),
	marginLeft: token('space.negative.300', '-24px'),
	paddingBottom: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledFooterContainer = styled.div({
	marginBottom: token('space.negative.025', '-2px'),
	marginRight: token('space.negative.300', '-24px'),
	paddingTop: token('space.025', '2px'),
});
