import React, { useCallback } from 'react';
import { transformSharePermissionsToServer } from '@atlassian/jira-common-directory-v2/src/services/transformers.tsx';
import type { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	toServerValidationError,
	type ServerValidationErrors,
	type OperationData,
	type OperationResult,
} from '@atlassian/jira-shareable-entity-dialog/src/model/types.tsx';
import { useBoardFilterConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import { useIsCMPBoard } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board/index.tsx';
import { useFireAnalyticsTrackEvent } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-fire-analytics-event/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import {
	useUpdateRemoteBoardFilter,
	type UpdateBoardFilterResponseBody,
} from '../../../../services/update-remote-board-filter/index.tsx';
import messages from './messages.tsx';

export const getServerSideValidationErrors = (
	validationError: ValidationError,
): ServerValidationErrors => {
	const validationErrors: Record<string, unknown> = {};
	if (validationError.errors) {
		validationError.errors.forEach((error) => {
			Object.assign(validationErrors, {
				[error.field]: toServerValidationError(error.error),
			});
		});
	}

	// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type 'Record<string, any>' is not assignable to type 'ServerValidationErrors'.
	return validationErrors;
};

export const useModalOperation = ({ onDismiss }: { onDismiss: () => void }) => {
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();
	const isCMPBoard = useIsCMPBoard();
	const [filterConfig, { updateBoardFilter }] = useBoardFilterConfig();
	const { commit: requestFilterUpdate } = useUpdateRemoteBoardFilter();
	const { fireTrackEvent } = useFireAnalyticsTrackEvent();

	const requestPermissionUpdate = useCallback(
		({ name, description, editPermissions, sharePermissions }: OperationData) =>
			requestFilterUpdate({
				filterId: filterConfig.id,
				body: {
					name,
					description,
					editPermissions: transformSharePermissionsToServer(editPermissions, true),
					sharePermissions: transformSharePermissionsToServer(sharePermissions, true),
				},
			}),
		[filterConfig, requestFilterUpdate],
	);

	const onUpdateCompleted = useCallback(
		(update: OperationData, result: OperationResult<UpdateBoardFilterResponseBody | undefined>) => {
			if (result) {
				updateBoardFilter(filterConfig, update);
				fireTrackEvent('upBoardFilterPermission succeeded');
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						isCMPBoard={isCMPBoard}
						title={formatMessage(messages.successFlag)}
						{...props}
					/>
				));
			}
			onDismiss();
		},
		[
			filterConfig,
			formatMessage,
			isCMPBoard,
			onDismiss,
			showFlag,
			updateBoardFilter,
			fireTrackEvent,
		],
	);

	return {
		requestPermissionUpdate,
		onUpdateCompleted,
	};
};
