export const dataTestIds = {
	app: 'software-board-settings-swimlanes.ui.swimlanes-settings-page.swimlanes-settings-page-container',
	appHeader: 'software-board-settings-swimlanes.ui.swimlanes-settings-page.header',
	swimlanesStrategyField:
		'software-board-settings-swimlanes.ui.swimlanes-settings-page.swimlane-strategy-field.select',
	unassignedIssuesRadioGroup:
		'software-board-settings-swimlanes.ui.swimlanes-settings-page.strategy-content.assignee-strategy.radio-group',
	queryList:
		'software-board-settings-swimlanes.ui.swimlanes-settings-page.strategy-content.query-strategy.query-list',
	createSwimlaneQueryButton:
		'software-board-settings-swimlanes.ui.swimlanes-settings-page.strategy-content.query-strategy.create-swimlane-button',
	defaultSwimlaneNameFieldContainer:
		'software-board-settings-swimlanes.ui.swimlanes-settings-page.strategy-content.query-strategy.default-swimlane-name-field.container',
};
