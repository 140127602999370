import type {
	AvailableField,
	CurrentField,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, FocusDirective } from '../../../common/types/index.tsx';
import { reorderField } from '../../../common/utils/index.tsx';

export const setCardLayoutFields =
	(currentFields: CurrentField[], availableFields: AvailableField[]) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({
			...getState(),
			currentFields,
			availableFields,
		});
	};

export const rankCardLayoutField =
	(src: CurrentField, dst?: CurrentField) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const { currentFields: cf } = getState();
		setState({ ...getState(), currentFields: reorderField(cf, src, dst) });
	};

export const addCurrentField =
	(fieldToAdd: CurrentField) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const { availableFields, currentFields } = getState();

		const { mode } = fieldToAdd;
		const relatedFields = currentFields.filter((f) => f.mode === fieldToAdd.mode);

		setState({
			...getState(),
			currentFields: [...currentFields, fieldToAdd],
			availableFields: availableFields.filter(
				(field) => field.mode !== fieldToAdd.mode || field.fieldId !== fieldToAdd.fieldId,
			),
			focusDirective:
				relatedFields.length === 2
					? { mode, dest: 'focus-row', index: 2 }
					: { mode, dest: 'add-field' },
		});
	};

export const deleteCurrentField =
	(fieldToDelete: CurrentField) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const { availableFields, currentFields } = getState();
		const { id: _, ...availableField } = fieldToDelete;

		const newAvailableFields = [...availableFields, availableField].sort((a, b) =>
			a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
		);

		const { mode, id } = fieldToDelete;
		const modeRows = currentFields.filter((f) => f.mode === mode);
		const tableIndex = modeRows.findIndex((f) => f.id === id);

		let focusDirective: FocusDirective;
		if (modeRows.length === 1) {
			focusDirective = { mode, dest: 'add-field' };
		} else if (tableIndex > 0) {
			focusDirective = { mode, dest: 'focus-row', index: tableIndex - 1 };
		} else if (tableIndex === 0 && currentFields.length > 1) {
			focusDirective = { mode, dest: 'focus-row', index: 0 };
		} else {
			focusDirective = { mode, dest: 'add-field' };
		}

		setState({
			...getState(),
			currentFields: currentFields.filter(
				(field) => field.mode !== fieldToDelete.mode || field.id !== fieldToDelete.id,
			),
			availableFields: newAvailableFields,
			focusDirective,
		});
	};

export const setShowDaysInColumn =
	(showDaysInColumn: boolean) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({
			...getState(),
			showDaysInColumn,
		});
	};

export type SetCardLayoutFields = typeof setCardLayoutFields;
export type AddCurrentField = typeof addCurrentField;
export type DeleteCurrentField = typeof deleteCurrentField;
export type SetShowDaysInColumn = typeof setShowDaysInColumn;
export type RankCardLayoutField = typeof rankCardLayoutField;
