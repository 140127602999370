export const UPDATE_GENERAL_BOARD_NAME_ACTION_SUBJECT = 'updateBoardName';
export const UPDATE_GENERAL_BOARD_NAME_METRICS_KEY = 'update-board-name';

export const UPDATE_GENERAL_BOARD_LOCATION_ACTION_SUBJECT = 'updateBoardLocation';
export const UPDATE_GENERAL_BOARD_LOCATION_METRICS_KEY = 'update-board-location';

export const FETCH_USER_WITH_GROUPS_ACTION_SUBJECT = 'fetchUserWithGroups';
export const FETCH_USER_WITH_GROUPS_METRICS_KEY = 'fetch-user-with-groups';

export const FETCH_MY_PERMISSIONS_ACTION_SUBJECT = 'fetchMyPermissions';
export const FETCH_MY_PERMISSIONS_METRICS_KEY = 'fetch-my-permissions';

export const FETCH_GENERAL_BOARD_LOCATION_ACTION_SUBJECT = 'fetchBoardLocations';
export const FETCH_GENERAL_BOARD_LOCATION_METRICS_KEY = 'fetch-board-locations';

export const FETCH_AVAILABLE_BOARD_ADMINS_ACTION_SUBJECT = 'fetchAvailableBoardAdmins';
export const FETCH_AVAILABLE_BOARD_ADMINS_BROWSER_METRICS_KEY = 'fetch-available-board-admins';

export const UPDATE_BOARD_ADMINS_ACTION_SUBJECT = 'updateBoardAdmins';
export const UPDATE_BOARD_ADMINS_BROWSER_METRICS_KEY = 'update-board-admins';
