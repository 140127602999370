import type { QueryItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import type {
	Swimlane,
	SwimlaneStrategy,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const addSwimlane =
	(newSwimlane: Swimlane) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const currentState = getState();

		setState({
			swimlanes: [newSwimlane, ...currentState.swimlanes],
		});
	};

export const deleteSwimlane =
	(deletedSwimlaneId: number | string) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const currentState = getState();

		setState({
			swimlanes: currentState.swimlanes.filter((swimlane) => swimlane.id !== deletedSwimlaneId),
		});
	};

export const updateSwimlane =
	(swimlane: Swimlane) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		const currentState = getState();

		setState({
			// poor man replace without mutation
			swimlanes: currentState.swimlanes.map((item) =>
				String(item.id) === String(swimlane.id) ? swimlane : item,
			),
		});
	};

export const updateDefaultSwimlaneName =
	(name: string) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		const { defaultSwimlane } = getState();
		if (!defaultSwimlane) return;

		setState({
			defaultSwimlane: { ...defaultSwimlane, name },
		});
	};

export const setSwimlaneStrategy =
	(newStartegy: SwimlaneStrategy) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({ ...getState(), swimlaneStrategy: newStartegy });
	};

export const beginSwimlaneRankUpdate =
	(targetSwimlaneId: string, afterSwimlaneId: string | null) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const currentState = getState();
		const { swimlanes } = currentState;

		const targetSwimlane = swimlanes.find(({ id }) => id.toString() === targetSwimlaneId);
		if (!targetSwimlane) {
			return;
		}

		const nextSwimlanes: Swimlane[] = afterSwimlaneId ? [] : [targetSwimlane];

		swimlanes.forEach((swimlane) => {
			const currentSwimlaneId = swimlane.id.toString();

			if (currentSwimlaneId !== targetSwimlaneId) {
				nextSwimlanes.push(swimlane);
			}

			if (currentSwimlaneId === afterSwimlaneId) {
				nextSwimlanes.push(targetSwimlane);
			}
		});

		setState({
			swimlanes: nextSwimlanes,
			swimlanesBeforeOptimistic: swimlanes,
			isOptimisticSwimlanes: true,
		});
	};

export const endSwimlaneRankUpdate =
	() =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			swimlanesBeforeOptimistic: null,
			isOptimisticSwimlanes: false,
		});
	};

export const revertSwimlaneRankUpdate =
	() =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({
			swimlanesBeforeOptimistic: null,
			isOptimisticSwimlanes: false,
			swimlanes: getState().swimlanesBeforeOptimistic || [],
		});
	};

export const setItemToEdit =
	(itemToEdit: QueryItem | null) =>
	({ setState }: StoreActionApi<State>) => {
		setState({ itemToEdit });
	};

export const setItemToDelete =
	(itemToDelete: QueryItem | null) =>
	({ setState }: StoreActionApi<State>) => {
		setState({ itemToDelete });
	};

export type AddSwimlaneAction = typeof addSwimlane;
export type UpdateSwimlaneAction = typeof updateSwimlane;
export type UpdateDefaultSwimlaneNameAction = typeof updateDefaultSwimlaneName;
export type DeleteSwimlaneAction = typeof deleteSwimlane;
export type SetSwimlaneStrategyAction = typeof setSwimlaneStrategy;
export type BeginSwimlaneRankUpdateAction = typeof beginSwimlaneRankUpdate;
export type EndSwimlaneRankUpdateAction = typeof endSwimlaneRankUpdate;
export type RevertSwimlaneRankUpdateAction = typeof revertSwimlaneRankUpdate;
export type SetItemToEdit = typeof setItemToEdit;
export type SetItemToDelete = typeof setItemToDelete;
