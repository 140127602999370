import React, { useMemo, type PropsWithChildren } from 'react';
import { graphql, usePreloadedQuery, type EntryPointProps } from 'react-relay';
import { useBoardId } from '@atlassian/jira-board-settings-common/src/common/utils/use-board-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import type { estimationSettingsPageQuery } from '@atlassian/jira-relay/src/__generated__/estimationSettingsPageQuery.graphql';
import type { RapidBoardConfig } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/types/index.tsx';
import { EDIT_BOARD_CONFIG } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import type { EstimationStatisticConfig } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/estimation.tsx';
import type { EstimationConfigProps } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { EstimationConfigDataContainer } from '../../controllers/main.tsx';
import { EstimationSettingsContainer } from './main.tsx';

// Deprecated with simplified-boards-milestone-2_07hbk
const EstimationSettingsPage = ({ estimationStatisticConfig }: EstimationConfigProps) => (
	<JiraRelayEnvironmentProvider>
		<EstimationConfigDataContainer {...estimationStatisticConfig}>
			<EstimationSettingsContainer />
		</EstimationConfigDataContainer>
	</JiraRelayEnvironmentProvider>
);

type Query = { queryReference: estimationSettingsPageQuery };

export const EstimationSettingPageContent = (
	entryPointProps: EntryPointProps<Query, {}, {}, {}>,
) => {
	if (fg('board-settings-graphql-refactor_cyvz9')) {
		return <GraphQlPageContent {...entryPointProps} />;
	}

	return <EditModelPageContent />;
};

const GraphQlPageContent = ({
	queries: { queryReference },
}: EntryPointProps<Query, {}, {}, {}>) => {
	const { jira, boardScope } = usePreloadedQuery(
		graphql`
			query estimationSettingsPageQuery($boardId: ID!, $cloudId: ID!) {
				jira @required(action: THROW) {
					globalTimeTrackingSettings(cloudId: $cloudId) @required(action: THROW) {
						isJiraConfiguredTimeTrackingEnabled @required(action: THROW)
					}
				}
				boardScope(boardId: $boardId, isCMP: true) @required(action: THROW) {
					currentUser {
						permissions
					}
					estimation @required(action: THROW) {
						current @required(action: THROW) {
							statisticFieldId @required(action: THROW)
						}
						available {
							statisticFieldId
							name
						}
					}
					trackingStatistic @optIn(to: "modularBoardSettings") @required(action: THROW) {
						statisticFieldId @required(action: THROW)
						isEnabled @required(action: THROW)
					}
				}
			}
		`,
		queryReference,
	);

	const rapidViewId = useBoardId();

	if (!rapidViewId) {
		throw new Error('Estimation settings page failed to load');
	}

	const estimationStatisticConfig: EstimationStatisticConfig = useMemo(() => {
		const canEdit = boardScope.currentUser.permissions.includes(EDIT_BOARD_CONFIG);

		return {
			rapidViewId,
			canEdit,
			currentEstimationStatistic: {
				id: boardScope.estimation.current.statisticFieldId,
			},
			currentTrackingStatistic: {
				id: boardScope.trackingStatistic.statisticFieldId,
				isEnabled: boardScope.trackingStatistic.isEnabled,
			},
			availableEstimationStatistics: boardScope.estimation.available.map(
				({ name, statisticFieldId }) => ({
					id: statisticFieldId,
					name,
				}),
			),
			isTimeTrackingEnabledInJira:
				jira.globalTimeTrackingSettings.isJiraConfiguredTimeTrackingEnabled,
		};
	}, [
		boardScope.currentUser.permissions,
		boardScope.estimation,
		boardScope.trackingStatistic.isEnabled,
		boardScope.trackingStatistic.statisticFieldId,
		jira.globalTimeTrackingSettings.isJiraConfiguredTimeTrackingEnabled,
		rapidViewId,
	]);

	return (
		<EstimationConfigDataContainer {...estimationStatisticConfig}>
			<EstimationSettingsContainer />
			<SpaStatePageReady />
		</EstimationConfigDataContainer>
	);
};

const EditModelPageContent = () => (
	<>
		<EstimationSettingsContainer />
		<SpaStatePageReady />
	</>
);

export const EstimationSettingsEditModelProvider = ({
	data,
	children,
}: PropsWithChildren<{
	data: RapidBoardConfig;
}>) => {
	const { estimationStatisticConfig } = data;

	return (
		<EstimationConfigDataContainer {...estimationStatisticConfig} isGlobal>
			{children}
		</EstimationConfigDataContainer>
	);
};

export default EstimationSettingsPage;
