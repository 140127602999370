import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useQueryValidationErrorMessage } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-query-validation-error-message/index.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	UPDATE_SWIMLANE_ACTION_SUBJECT,
	UPDATE_SWIMLANE_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { SWIMLANE_API_URI } from '../../common/constants/url.tsx';
import type { UpdateSwimlaneInput, UpdateSwimlaneResponse } from './types.tsx';

export const useUpdateSwimlane = (
	rapidViewId: number,
	errorTitle: string,
): UpdateSwimlaneResponse => {
	const updateSwimlane = useCallback(
		({ id, name, description, query }: UpdateSwimlaneInput) =>
			performPutRequest(`${SWIMLANE_API_URI}/${rapidViewId}/${id}`, {
				body: JSON.stringify({
					id,
					name,
					description,
					query,
				}),
			}),
		[rapidViewId],
	);
	const {
		data,
		loading,
		error,
		hasError,
		resetError,
		fetch: commit,
	} = useServiceWithAnalytics<UpdateSwimlaneResponse['data'], UpdateSwimlaneInput>(
		updateSwimlane,
		{},
		{
			actionSubject: UPDATE_SWIMLANE_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${UPDATE_SWIMLANE_BROWSER_METRICS_KEY}`,
		},
	);

	const queryValidationErrorMessage = useQueryValidationErrorMessage(error);

	useErrorFlagOnUpdateFailure({
		// We want to show validation query error inline with ErrorMessage
		hasFailure: hasError && !queryValidationErrorMessage,
		error,
		errorTitle,
	});

	return { loading, error, data, commit, resetError, hasError, queryValidationErrorMessage };
};
