import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	failedToDeleteErrorTitle: {
		id: 'software-board-settings-card-colors.services.delete-card-color.failed-to-delete-error-title',
		defaultMessage: "We couldn't delete your card color",
		description: 'Title of error flag when failed to delete a card color.',
	},
	failedToDeleteErrorDescription: {
		id: 'software-board-settings-card-colors.services.delete-card-color.failed-to-delete-error-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description of error flag when failed to delete a card color.',
	},
});
