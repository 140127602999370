import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.heading',
		defaultMessage: 'Filter Permissions',
		description: 'Header above a table containing permissions filters.',
	},
	about: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.about',
		defaultMessage: 'Give others access to view or edit this filter.',
		description: 'Description above a table containing permissions filters, outlining its purpose.',
	},
	editPermissions: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.edit-permissions',
		defaultMessage: 'Edit permissions',
		description: 'Label for a button that edits permissions.',
	},
	editPermissionButtonArialLabel: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.edit-permission-button-arial-label',
		defaultMessage: 'Edit filter permissions',
		description: 'Label for a button that edits permissions.',
	},
	userColumn: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.user-column',
		defaultMessage: 'Users',
		description: 'Table column heading for a column containing users.',
	},
	permissionColumn: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-column',
		defaultMessage: 'Permission',
		description: 'Table column heading for a column containing permissions assigned to users.',
	},
});
