import React from 'react';
import Heading from '@atlaskit/heading';
import { Stack, Inline } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../../common/constants/data-test.tsx';
import { useUpdateTimelineHandler } from '../../../controllers/handlers/update-timeline-handler/index.tsx';
import { useCanEdit } from '../../../controllers/selectors/permissions/index.tsx';
import { useIsBoardCrossProject } from '../../../controllers/selectors/timeline/index.tsx';
import { IncludeChildLevelIssues } from './include-child-level-issues/index.tsx';
import messages from './messages.tsx';

export const EnableTimeline = () => {
	const { formatMessage } = useIntl();

	const [canEdit] = useCanEdit();
	const [isBoardCrossProject] = useIsBoardCrossProject();

	const { updateTimeline, isEnabled } = useUpdateTimelineHandler();

	return (
		<Stack space="space.250">
			<Inline space="space.200" alignBlock="center" alignInline="start">
				<Heading as="h3" size="small">
					{formatMessage(messages.enableTimeline)}
				</Heading>
				<Toggle
					testId={dataTestIds.enableTimelineToggle}
					label={formatMessage(messages.enableTimelineToggleLabel)}
					isChecked={isEnabled}
					isDisabled={!canEdit || (!isEnabled && isBoardCrossProject)}
					onChange={updateTimeline}
				/>
			</Inline>
			{isEnabled && !isBoardCrossProject && <IncludeChildLevelIssues />}
		</Stack>
	);
};
