import { APP_METRIC_KEY } from '@atlassian/jira-software-board-settings-general-common/src/common/constants/analytics.tsx';

export const LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SERVICEDESK = '4ph0DVeRFxNImDDZqlgtTe';
export const LEARN_MORE_ABOUT_ISSUE_RANKING_SAC_ARTICLE_JIRA_SERVICEDESK =
	'https://support.atlassian.com/jira-service-management-cloud/docs/rank-issues-on-your-board-manually/';
export const LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SERVICEDESK_ANALYTICS_ID = `${APP_METRIC_KEY}.jsm.issue-ranking.article.analytics`;

export const LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SOFTWARE = '2TZMyMQ1ZslDAsPrknlfZ2';
export const LEARN_MORE_ABOUT_ISSUE_RANKING_SAC_ARTICLE_JIRA_SOFTWARE =
	'https://support.atlassian.com/jira-software-cloud/docs/rank-an-issue/';
export const LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SOFTWARE_ANALYTICS_ID = `${APP_METRIC_KEY}.jsw.issue-ranking.article.analytics`;

export const LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SERVICEDESK =
	'1vOCc4VLQhCWihetLhNolo';
export const LEARN_MORE_ABOUT_FILTER_ARTICLE_SAC_ARTICLE_JIRA_SERVICEDESK =
	'https://support.atlassian.com/jira-service-management-cloud/docs/save-your-search-as-a-custom-filter/';
export const LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SERVICEDESK_ANALYTICS_ID = `${APP_METRIC_KEY}.jsm.filter.article.analytics`;

export const LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SOFTWARE = '4v1uISrS2gTig6nGPKga3M';
export const LEARN_MORE_ABOUT_FILTER_ARTICLE_SAC_ARTICLE_JIRA_SOFTWARE =
	'https://support.atlassian.com/jira-software-cloud/docs/what-is-a-saved-search/';
export const LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SOFTWARE_ANALYTICS_ID = `${APP_METRIC_KEY}.jsw.filter.article.analytics`;

export const LEARN_MORE_ABOUT_JQL_FOR_BOARD_FILTER_IPH_ARTICLE = '35tNWWNQ3MfWaWAFUANLvM';
export const LEARN_MORE_ABOUT_JQL_FOR_BOARD_FILTER_IPH_ARTICLE_ANALYTICS_ID = `${APP_METRIC_KEY}.jql-for-board-filter.article.analytics`;

export const LEARN_MORE_ABOUT_CALENDAR_IPH_ARTICLE = '64Rt47cO6Aw6VnWaqUkriU';
export const LEARN_MORE_ABOUT_CALENDAR_SAC_ARTICLE =
	'https://support.atlassian.com/jira-service-management-cloud/docs/use-the-calendar-to-view-your-issues/';
export const LEARN_MORE_ABOUT_CALENDAR_IPH_ARTICLE_ANALYTICS_ID = `${APP_METRIC_KEY}.learn.more.about.calendar.article.analytics`;
