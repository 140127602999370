import { useCallback } from 'react';
import type { RankEnd } from '@atlaskit/dynamic-table/types';
import type { RankEffect } from './drag-handle/index.tsx';

export const useHandleRank = (
	onRank: (itemIndex: number, afterItemIndex: number | null) => void,
) => {
	const onDragHandleRank = useCallback(
		(index: number, effect: RankEffect) => {
			const diff = effect === 'MoveUp' ? -2 : 1;
			const afterIndex = index + diff;
			onRank(index, afterIndex >= 0 ? afterIndex : null);
		},
		[onRank],
	);

	const onRankEnd = useCallback(
		({ sourceIndex, destination }: RankEnd) => {
			// Ignore the drag if the item stays in the same index
			if (destination && destination.index !== sourceIndex) {
				const { index: destinationIndex } = destination;
				onRank(
					sourceIndex,
					// if we move item up it's destination's gonna be an item before
					sourceIndex > destinationIndex && destinationIndex > 0
						? destinationIndex - 1
						: destinationIndex || null,
				);
			}
		},
		[onRank],
	);

	return {
		onDragHandleRank,
		onRankEnd,
	};
};
