import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	purple: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.purple',
		defaultMessage: 'Purple',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkPurple: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.dark-purple',
		defaultMessage: 'Dark Purple',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	magenta: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.magenta',
		defaultMessage: 'Magenta',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkMagenta: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.dark-magenta',
		defaultMessage: 'Dark Magenta',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	red: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.red',
		defaultMessage: 'Red',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkRed: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.dark-red',
		defaultMessage: 'Dark Red',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	orange: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.orange',
		defaultMessage: 'Orange',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkOrange: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.dark-orange',
		defaultMessage: 'Dark Orange',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	yellow: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.yellow',
		defaultMessage: 'Yellow',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkYellow: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.dark-yellow',
		defaultMessage: 'Dark Yellow',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	lime: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.lime',
		defaultMessage: 'Lime',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkLime: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.dark-lime',
		defaultMessage: 'Dark Lime',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	green: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.green',
		defaultMessage: 'Green',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkGreen: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.dark-green',
		defaultMessage: 'Dark Green',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	teal: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.teal',
		defaultMessage: 'Teal',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkTeal: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.dark-teal',
		defaultMessage: 'Dark Teal',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	blue: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.blue',
		defaultMessage: 'Blue',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkBlue: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.dark-blue',
		defaultMessage: 'Dark Blue',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	grey: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.grey',
		defaultMessage: 'Grey',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkGrey: {
		id: 'software-board-settings-color-picker.controllers.use-default-color-palette.dark-grey',
		defaultMessage: 'Dark Grey',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
});
