import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { EditColorQueryModal } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/edit-color-query-modal/index.tsx';
import { QueryModalDescription } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/query-modal-description/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { MODAL_ANALYTICS_SOURCE } from '../../../../../common/constants/analytics.tsx';
import { BOARD_SETTINGS_EDIT_CARD_COLOR_MODAL_ID } from '../../../../../common/constants/modals.tsx';
import { useEditCardColorHandler } from '../../../../../controllers/handlers/use-edit-card-color-handler/index.tsx';
import messages from './messages.tsx';

export const EditCardColorModal = () => {
	const { formatMessage } = useIntl();

	const [isEditModalOpen] = useSwitchModals(BOARD_SETTINGS_EDIT_CARD_COLOR_MODAL_ID);

	const {
		itemToEdit,
		closeEditModal,
		onEditSubmit,
		isSubmitting,
		editQueryValidationErrorMessage,
	} = useEditCardColorHandler();

	return (
		<>
			{isEditModalOpen && itemToEdit && (
				<EditColorQueryModal
					title={formatMessage(messages.editCardColorModalTitle)}
					description={
						<QueryModalDescription
							description={formatMessage(messages.editCardColorModalGuidelines)}
						/>
					}
					onSubmit={onEditSubmit}
					isSubmitting={isSubmitting}
					onCancel={closeEditModal}
					analyticsSource={MODAL_ANALYTICS_SOURCE}
					colorItem={itemToEdit}
					queryValidationErrorMessage={editQueryValidationErrorMessage}
				/>
			)}
		</>
	);
};
