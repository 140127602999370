import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	updateFailureTitle: {
		id: 'software-board-settings-rapidboard-config-common.flags.use-error-flag-on-request-failure.update-failure-title',
		defaultMessage: 'We couldn’t apply your changes',
		description: 'Title of the error flag when failing to update board setttings',
	},
	fetchFailureTitle: {
		id: 'software-board-settings-rapidboard-config-common.flags.use-error-flag-on-request-failure.fetch-failure-title',
		defaultMessage: 'We couldn’t load your data',
		description: 'Title of the error flag when failing to fetch some data for board setttings',
	},
	requestFailureMessage: {
		id: 'software-board-settings-rapidboard-config-common.flags.use-error-flag-on-request-failure.request-failure-message',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description of the error flag when some requests failed on board settings',
	},
});
