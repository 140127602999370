import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-estimation.estimation-settings-page.header.title',
		defaultMessage: 'Estimation',
		description: 'Page title',
	},
	description: {
		id: 'software-board-settings-estimation.estimation-settings-page.header.description',
		defaultMessage:
			'Add expected effort to an issue so you can plan with more accuracy. <button>Read about estimation methods</button>.',
		description:
			'Page description, along with text for an in help product link (the text within the button tag)',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-settings-estimation.estimation-settings-page.header.description-issue-term-refresh',
		defaultMessage:
			'Add expected effort to a work item so you can plan with more accuracy. <button>Read about estimation methods</button>.',
		description:
			'Page description, along with text for an in help product link (the text within the button tag)',
	},
});
