import React, { useEffect } from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { InProductHelpButton } from '@atlassian/jira-insights-common/src/common/ui/in-product-help-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { boardSettingsQueryConfigParamValues } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { useIsBoardSettingsTabOpen } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-board-settings-tab-open/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { useOnce } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-once/index.tsx';
import { ConfigDisabledSection } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/config-disabled-section/index.tsx';
import { PageHeader } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/page-header/index.tsx';
import {
	BOARD_SETTINGS_ARTICLE_ID_CARD_COLORS_JSW,
	BOARD_SETTINGS_ARTICLE_ID_CARD_COLORS_JSM,
	BOARD_SETTINGS_ARTICLE_ID_CARD_COLORS_JSW_ANALYTICS_ID,
} from '../../common/constants/card-colors.tsx';
import { useFetchStrategyConfigHandler } from '../../controllers/handlers/use-fetch-strategy-handler/index.tsx';
import { useUpdateCardColorStrategyHandler } from '../../controllers/handlers/use-update-card-color-strategy-handler/index.tsx';
import { useIsInitialised } from '../../controllers/selectors/board/index.tsx';
import { useCardColorStrategy } from '../../controllers/selectors/colors/index.tsx';
import { useCanEdit } from '../../controllers/selectors/permissions/index.tsx';
import { useViewCardColorSettingsExperienceTracker } from '../../services/experience-tracker/index.tsx';
import { CardColorStrategyField } from './card-color-strategy-field/index.tsx';
import messages from './messages.tsx';
import { CardColorStrategyContent } from './strategy-content/index.tsx';

export const CardColorSettingsContainer = () => {
	const { formatMessage } = useIntl();

	const [canEdit] = useCanEdit();
	const isJSMBoard = useIsJSMBoard();
	const [isInitialised] = useIsInitialised();
	const [cardColorStrategy] = useCardColorStrategy();

	const { fireExperienceSuccess } = useViewCardColorSettingsExperienceTracker({
		isClassic: true,
	});

	useEffect(() => {
		if (isInitialised) {
			// State initialisation is now finished so we mark view experience as success
			fireExperienceSuccess({ isClassic: true });
		}
	}, [isInitialised, fireExperienceSuccess]);

	const { loading: isUpdatingStrategyWithContent, handleStrategyUpdate } =
		useUpdateCardColorStrategyHandler();
	const { loading: isFetchingInitialCardColors, handleFetchStrategyConfig } =
		useFetchStrategyConfigHandler();

	useOnce(
		() => {
			handleFetchStrategyConfig(cardColorStrategy);
		},
		() =>
			isJSMBoard ||
			!fg('simplified-boards-milestone-2_07hbk') ||
			!fg('board-settings-graphql-refactor_cyvz9'),
	);

	const isCardColorsTabOpen = useIsBoardSettingsTabOpen(
		boardSettingsQueryConfigParamValues.cardColors,
	);

	const articleId = isJSMBoard
		? BOARD_SETTINGS_ARTICLE_ID_CARD_COLORS_JSM
		: BOARD_SETTINGS_ARTICLE_ID_CARD_COLORS_JSW;

	return isCardColorsTabOpen ? (
		<>
			<Flex direction="column" alignItems="start" gap="space.300" xcss={headerStyle}>
				<PageHeader
					title={formatMessage(messages.title)}
					description={
						<>
							{formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.descriptionIssueTermRefresh
									: messages.description,
							)}{' '}
							<InProductHelpButton
								articleId={articleId}
								articleAnalyticsId={BOARD_SETTINGS_ARTICLE_ID_CARD_COLORS_JSW_ANALYTICS_ID}
								appearance="link"
							>
								{formatMessage(messages.inProductHelp)}
							</InProductHelpButton>
							.
						</>
					}
				/>
				{!canEdit && <ConfigDisabledSection />}
				<CardColorStrategyField
					currentStrategy={cardColorStrategy}
					isUpdatingStrategy={isUpdatingStrategyWithContent}
					isDisabled={!canEdit}
					onChange={handleStrategyUpdate}
				/>
			</Flex>
			<CardColorStrategyContent
				isLoading={isUpdatingStrategyWithContent || isFetchingInitialCardColors}
			/>
		</>
	) : null;
};

const headerStyle = xcss({
	paddingBottom: 'space.400',
});
