import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorFlag: {
		id: 'software-board-settings-general-sub-filter-settings.services.update-remote-board-sub-filter.error-flag',
		defaultMessage: "We couldn't save your filter",
		description:
			'The error message shown the user when there is an issue with updating their board sub-filter',
	},
});
