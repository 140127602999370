import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.show-days-in-column.title',
		defaultMessage: 'Days in column',
		description: 'Title for the days in column toggle',
	},
	fieldLabel: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.show-days-in-column.field-label',
		defaultMessage: 'Days in column',
		description: 'Label for the days in column toggle',
	},
	description: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.show-days-in-column.description',
		defaultMessage:
			'Track how long a card is in a column so you can visually identify slow moving issues.',
		description: 'Description for the days in column toggle',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.show-days-in-column.description-issue-term-refresh',
		defaultMessage:
			'Track how long a card is in a column so you can visually identify slow moving work items.',
		description: 'Description for the days in column toggle',
	},
});
