export const FETCH_GENERAL_SAVED_FILTERS_ACTION_SUBJECT = 'fetchSavedFilters';
export const FETCH_GENERAL_SAVED_FILTERS_METRICS_KEY = 'fetch-saved-filters';

export const UPDATE_GENERAL_SELECTED_BOARD_FILTER_ACTION_SUBJECT = 'updateSelectedBoardFilter';
export const UPDATE_GENERAL_SELECTED_BOARD_FILTER_METRICS_KEY = 'update-selected-board-filter';

export const UPDATE_GENERAL_BOARD_FILTER_ACTION_SUBJECT = 'updateBoardFilter';
export const UPDATE_GENERAL_BOARD_FILTER_METRICS_KEY = 'update-board-filter';

export const UPDATE_ISSUE_RANK_ACTION_SUBJECT = 'updateBoardIssueRank';
export const UPDATE_ISSUE_RANK_METRICS_KEY = 'update-board-issue-rank';
