import parameters from '@atlassian/jira-relay/src/__generated__/cardColorsSettingsPageQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { boardId: string };

export const cardColorsSettingPageEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jsw-board-settings-card-colors" */ './ui/card-colors-settings-page/index.tsx'
		).then((module) => module.CardColorsSettingPageContent),
	),
	getPreloadProps: ({ boardId }: EntryPointParams) => ({
		queries: {
			queryReference: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters,
				variables: {
					boardId,
				},
			},
		},
	}),
});
