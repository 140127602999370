import { useRouter } from '@atlassian/react-resource-router';
import { useIsJSMBoard } from '../../services/use-is-jsm-board/index.tsx';

export const getBoardUrl = (projectKey: string, boardId: string, isCMPBoard: boolean) =>
	`/jira/software${isCMPBoard ? '/c' : ''}/projects/${projectKey}/boards/${boardId}`;

export const getJSMBoardUrl = (projectKey: string, boardId: string) =>
	`/jira/servicedesk/projects/${projectKey}/boards/${boardId}`;

export const useBoardUrl = (isCMPBoard = false) => {
	const [{ match }] = useRouter();

	const isJSMBoard = useIsJSMBoard();
	const projectKey = match.params.projectKey ?? '';
	const boardId = match.params.boardId ?? '';

	if (!projectKey || !boardId) {
		return undefined;
	}

	if (isJSMBoard) {
		return getJSMBoardUrl(projectKey, boardId);
	}

	return getBoardUrl(projectKey, boardId, isCMPBoard);
};
