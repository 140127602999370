import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	failedToDeleteErrorTitle: {
		id: 'software-board-settings-swimlanes.controllers.handlers.use-delete-swimlane-handler.failed-to-delete-error-title',
		defaultMessage: "We couldn't delete your swimlane",
		description: 'Title of error flag when failed to delete a swimlane',
	},
	successFlagForDeletingSwimlane: {
		id: 'software-board-settings-swimlanes.controllers.handlers.use-delete-swimlane-handler.success-flag-for-deleting-swimlane',
		defaultMessage: 'Your swimlane has been deleted',
		description: 'Title of success flag when succesfully delete a swimlane',
	},
});
