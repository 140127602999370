import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	generalMenuItemLabel: {
		id: 'software-board-settings-rapidboard-config-common.settings-container.menu.general-menu-item-label',
		defaultMessage: 'General',
		description: 'Menu item label for general settings',
	},
	columnsMenuItemLabel: {
		id: 'software-board-settings-rapidboard-config-common.settings-container.menu.columns-menu-item-label',
		defaultMessage: 'Columns',
		description: 'Menu item label for columns settings',
	},
	swimlanesMenuItemLabel: {
		id: 'software-board-settings-rapidboard-config-common.settings-container.menu.swimlanes-menu-item-label',
		defaultMessage: 'Swimlanes',
		description: 'Menu item label for swimlanes settings',
	},
	quickFiltersMenuItemLabel: {
		id: 'software-board-settings-rapidboard-config-common.settings-container.menu.quick-filters-menu-item-label',
		defaultMessage: 'Quick filters',
		description: 'Menu item label for quick filters settings',
	},
	cardColoursMenuItemLabel: {
		id: 'software-board-settings-rapidboard-config-common.settings-container.menu.card-colours-menu-item-label',
		defaultMessage: 'Card colors',
		description: 'Menu item label for card colours settings',
	},
	cardLayoutMenuItemLabel: {
		id: 'software-board-settings-rapidboard-config-common.settings-container.menu.card-layout-menu-item-label',
		defaultMessage: 'Card layout',
		description: 'Menu item label for card layout settings',
	},
	estimationMenuItemLabel: {
		id: 'software-board-settings-rapidboard-config-common.settings-container.menu.estimation-menu-item-label',
		defaultMessage: 'Estimation',
		description: 'Menu item label for estimation settings',
	},
	workingDaysMenuItemLabel: {
		id: 'software-board-settings-rapidboard-config-common.settings-container.menu.working-days-menu-item-label',
		defaultMessage: 'Working Days',
		description: 'Menu item label for working days settings',
	},
	timelineMenuItemLabel: {
		id: 'software-board-settings-rapidboard-config-common.settings-container.menu.timeline-menu-item-label',
		defaultMessage: 'Timeline',
		description: 'Menu item label for timeline setting',
	},
});
