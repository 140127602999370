import { SwimlaneStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';
import type { SwimlaneStrategyTransition } from '../../../common/types/index.tsx';

export const unreachable = (_item: never) => null;

export const showSkeleton = (transition: SwimlaneStrategyTransition) =>
	transition.kind === 'in-progress' && getLayout(transition.current) !== getLayout(transition.next);

const StrategyContentLayoutKind = {
	queryTable: 'queryTable',
	assigneeRadio: 'assigneeRadio',
	none: 'none',
} as const;

type StrategyContentLayoutKind =
	(typeof StrategyContentLayoutKind)[keyof typeof StrategyContentLayoutKind];

const getLayout = (strategy: SwimlaneStrategy) => {
	switch (strategy) {
		case SwimlaneStrategy.custom:
			return StrategyContentLayoutKind.queryTable;

		case SwimlaneStrategy.assigneeUnassignedLast:
		case SwimlaneStrategy.assigneeUnassignedFirst:
			return StrategyContentLayoutKind.assigneeRadio;

		case SwimlaneStrategy.none:
		case SwimlaneStrategy.epic:
		case SwimlaneStrategy.issueChildren:
		case SwimlaneStrategy.issueParent:
		case SwimlaneStrategy.parentChild:
		case SwimlaneStrategy.project:
		case SwimlaneStrategy.requestType:
			return StrategyContentLayoutKind.none;

		default:
			return unreachable(strategy);
	}
};
