import { useCallback } from 'react';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useQueryValidationErrorMessage } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-query-validation-error-message/index.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	ADD_SWIMLANE_ACTION_SUBJECT,
	ADD_SWIMLANE_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { SWIMLANE_API_URI } from '../../common/constants/url.tsx';
import type { AddSwimlaneResponse, AddSwimlaneInput } from './types.tsx';

export const useAddSwimlane = (rapidViewId: number, errorTitle: string): AddSwimlaneResponse => {
	const addSwimlane = useCallback(
		(addSwimlaneInput: AddSwimlaneInput) =>
			performPostRequest(`${SWIMLANE_API_URI}/${rapidViewId}`, {
				body: JSON.stringify(addSwimlaneInput),
			}),
		[rapidViewId],
	);
	const {
		data,
		loading,
		error,
		hasError,
		fetch: commit,
		resetError,
	} = useServiceWithAnalytics<AddSwimlaneResponse['data'], AddSwimlaneInput>(
		addSwimlane,
		{},
		{
			actionSubject: ADD_SWIMLANE_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${ADD_SWIMLANE_BROWSER_METRICS_KEY}`,
		},
	);

	const queryValidationErrorMessage = useQueryValidationErrorMessage(error);

	useErrorFlagOnUpdateFailure({
		// We want to show validation query error inline with ErrorMessage
		hasFailure: hasError && !queryValidationErrorMessage,
		error,
		errorTitle,
	});

	return {
		loading,
		error,
		data,
		commit,
		resetError,
		hasError,
		queryValidationErrorMessage,
	};
};
