import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AllGroupsResponse, UserGroupsResponse } from './types.tsx';

// Get all groups (including ones user is not a member of)

const MAX_GROUPS = 5;

export const getAllGroupsPath = (baseUrl: string): string => `${baseUrl}/rest/api/2/groups/picker`;

const getAllGroupsUrl = (baseUrl: string, query: string, maxResults: number = MAX_GROUPS): string =>
	`${getAllGroupsPath(baseUrl)}?maxResults=${maxResults}&query=${encodeURIComponent(query)}`;

export const getAllGroups = (
	baseUrl: string,
	query = '',
	maxResults: number = MAX_GROUPS,
): Promise<AllGroupsResponse> => fetchJson(getAllGroupsUrl(baseUrl, query, maxResults));

// Get only groups user is a member of
export const getUserGroupsPath = (baseUrl: string): string => `${baseUrl}/rest/api/3/user/groups`;

const getUserGroupsUrl = (baseUrl: string, accountId: AccountId): string =>
	`${getUserGroupsPath(baseUrl)}?accountId=${accountId}`;

export const getUserGroups = (baseUrl: string, accountId: AccountId): Promise<UserGroupsResponse> =>
	fetchJson(getUserGroupsUrl(baseUrl, accountId));
