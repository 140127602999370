import { JiraSoftwareCardColorAri } from '@atlassian/ari/jira-software/card-color';
import {
	CardColorStrategy,
	type CardColor,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import { convertToDefinedArray } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/utils.tsx';
import type { AggCardColors } from '../../common/types/index.tsx';

export const isCardColorStrategy = (strategy: string): strategy is CardColorStrategy =>
	Object.values<string>(CardColorStrategy).includes(strategy);

export const transformToCardColors = (
	aggCardColors: AggCardColors,
	canEdit: boolean,
): CardColor[] =>
	convertToDefinedArray(aggCardColors.edges).flatMap((edge) => {
		const { id: ari, isGlobalColor, strategy, ...rest } = edge.node;
		const { cardColorId } = JiraSoftwareCardColorAri.parse(ari);

		if (!isCardColorStrategy(strategy)) {
			throw new Error('Invalid card color strategy');
		}

		return [
			{
				...rest,
				strategy,
				isGlobalColor: !!isGlobalColor,
				id: Number(cardColorId),
				canEdit,
			},
		];
	});
