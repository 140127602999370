import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cancelButtonCaption: {
		id: 'shareable-entity-dialog.action-modal-footer.cancel-button-caption',
		defaultMessage: 'Cancel',
		description:
			'Button that cancels all forms of action modals, e.g. to create, edit, or delete an entity',
	},
});
