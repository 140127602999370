export const dataTestIds = {
	planModeTable: 'software-board-settings-card-layout.ui.card-layout-settings-page.table-planMode',
	workModeTable: 'software-board-settings-card-layout.ui.card-layout-settings-page.table-workMode',
	addFieldButton:
		'software-board-settings-card-layout.ui.card-layout-settings-page.card-layout-table.add-field.button',
	addFieldSelectContainer:
		'software-board-settings-card-layout.ui.card-layout-settings-page.card-layout-table.add-field.select-container',
	addFieldSubmit:
		'software-board-settings-card-layout.ui.card-layout-settings-page.card-layout-table.add-field.submit',
	fieldLimitWarning:
		'software-board-settings-card-layout.ui.card-layout-settings-page.card-layout-table.field-limit.warning',
	tableRowDelete:
		'software-board-settings-card-layout.ui.card-layout-settings-page.card-layout-table.delete-field-button.button',
	showDaysInColumnToggle:
		'software-board-settings-card-layout.ui.card-layout-settings-page.show-days-in-column.toggle',
} as const;
