import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	toggleOnShowDaysInColumn: {
		id: 'software-board-settings-card-layout.controllers.handlers.update-show-days-in-column-handler.toggle-on-show-days-in-column',
		defaultMessage: "You've turned on days in column",
		description:
			'Title message for the success flag which appears when toggling on show days in column',
	},
	toggleOffShowDaysInColumn: {
		id: 'software-board-settings-card-layout.controllers.handlers.update-show-days-in-column-handler.toggle-off-show-days-in-column',
		defaultMessage: "You've turned off days in column",
		description:
			'Title message for the success flag which appears when toggling off show days in column',
	},
});
