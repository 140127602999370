import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const resetFocus =
	() =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({
			...getState(),
			focusDirective: undefined,
		});
	};

export type ResetFocus = typeof resetFocus;
