import { useCallback } from 'react';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	RANK_CARD_COLOR_ACTION_SUBJECT,
	RANK_CARD_COLOR_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { CARD_COLOR_API_URI } from '../../common/constants/url.tsx';
import type { RankCardColorPayload, RankCardColorInput, RankCardColorResponse } from './types.tsx';

export const useRankCardColor = (
	rapidViewId: number,
	errorTitle: string,
): RankCardColorResponse => {
	const rankCardColor = useCallback(
		({ cardColorId, afterCardColorId }: RankCardColorInput) => {
			const moveApiUrl = `${CARD_COLOR_API_URI}/${rapidViewId}/color/${cardColorId}/move`;
			const payload: RankCardColorPayload = afterCardColorId
				? {
						after: `${CARD_COLOR_API_URI}/${rapidViewId}/color/${afterCardColorId}`,
					}
				: {
						position: 'First',
					};
			return performPostRequest(moveApiUrl, {
				body: JSON.stringify(payload),
			});
		},
		[rapidViewId],
	);

	const { data, loading, error, hasError, fetch, resetError } = useServiceWithAnalytics<
		RankCardColorResponse['data'],
		RankCardColorInput
	>(
		rankCardColor,
		{},
		{
			actionSubject: RANK_CARD_COLOR_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${RANK_CARD_COLOR_BROWSER_METRICS_KEY}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		handleValidationError: true,
		error,
		errorTitle,
	});

	return { loading, error, data, commit: fetch, hasError, resetError };
};
