import React from 'react';
import { BoardSettingsItemListSkeleton } from '@atlassian/jira-skeletons/src/ui/board-settings/BoardSettingsCardLayoutSkeleton.tsx';
import type { QueryItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import { SwimlaneStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';
import type { SwimlaneStrategyTransition } from '../../../common/types/index.tsx';
import { AssigneeStrategy } from './assignee-strategy/index.tsx';
import { QueryStrategy } from './query-strategy/index.tsx';
import { showSkeleton, unreachable } from './utils.tsx';

export const SwimlaneStrategyContent = ({
	transition,
	onChange,
	handleEdit,
	handleDelete,
	handleRank,
	openCreateModal,
}: {
	transition: SwimlaneStrategyTransition;
	onChange: (strategy: SwimlaneStrategy) => void;
	handleEdit: (queryItem: QueryItem) => void;
	handleDelete: (queryItem: QueryItem) => void;
	handleRank: (queryItem: QueryItem, afterQueryItemId: QueryItem['id'] | null) => void;
	openCreateModal: () => void;
}) => {
	if (transition.kind === 'initial') {
		return <BoardSettingsItemListSkeleton />;
	}

	if (showSkeleton(transition)) {
		switch (transition.next) {
			case SwimlaneStrategy.assigneeUnassignedLast:
			case SwimlaneStrategy.assigneeUnassignedFirst:
			case SwimlaneStrategy.custom:
				return <BoardSettingsItemListSkeleton />;

			default:
				return null;
		}
	}

	switch (transition.current) {
		case SwimlaneStrategy.custom:
			return (
				<QueryStrategy
					handleEdit={handleEdit}
					handleDelete={handleDelete}
					handleRank={handleRank}
					openCreateModal={openCreateModal}
				/>
			);

		case SwimlaneStrategy.assigneeUnassignedLast:
		case SwimlaneStrategy.assigneeUnassignedFirst:
			return <AssigneeStrategy onChange={onChange} transition={transition} />;

		case SwimlaneStrategy.none:
		case SwimlaneStrategy.epic:
		case SwimlaneStrategy.issueChildren:
		case SwimlaneStrategy.issueParent:
		case SwimlaneStrategy.parentChild:
		case SwimlaneStrategy.requestType:
		case SwimlaneStrategy.project:
			return null;

		default:
			return unreachable(transition);
	}
};
