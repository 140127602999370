/**
 * @generated SignedSource<<746e668827cb8cc1af0d80bfd1d819fc>>
 * @relayHash 330887d1a91a28f008f08fb8707c78e9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e959cd2c577ba3e1b2956c5256ac6adf3a7b437a31b330460ad5280ebb92fa0c

import type { ConcreteRequest, Query } from 'relay-runtime';
export type BoardFeatureStatus = "COMING_SOON" | "DISABLED" | "ENABLED" | "%future added value";
export type generalSettingsPageQuery$variables = {
  boardId: string;
  cloudId: string;
};
export type generalSettingsPageQuery$data = {
  readonly boardScope: {
    readonly board: {
      readonly name: string;
    };
    readonly boardAdmins: {
      readonly groupKeys: ReadonlyArray<{
        readonly displayName: string;
        readonly key: string;
      } | null | undefined>;
      readonly userKeys: ReadonlyArray<{
        readonly displayName: string;
        readonly key: string;
      } | null | undefined>;
    };
    readonly boardLocationModel: {
      readonly avatarURI: string;
      readonly name: string;
      readonly projectId: string | null | undefined;
      readonly projectTypeKey: string | null | undefined;
      readonly userLocationId: string | null | undefined;
    };
    readonly currentUser: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
    readonly features: ReadonlyArray<{
      readonly key: AGG$SoftwareBoardFeatureKey;
      readonly status: BoardFeatureStatus;
    } | null | undefined>;
    readonly oldDoneIssuesCutOffConfig: {
      readonly oldDoneIssuesCutoff: string;
      readonly options: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      } | null | undefined>;
    };
    readonly savedFilterConfig: {
      readonly canEdit: boolean;
      readonly description: string | null | undefined;
      readonly editPermissionEntries: ReadonlyArray<{
        readonly group: {
          readonly id: AGG$Long | null | undefined;
          readonly name: string | null | undefined;
        } | null | undefined;
        readonly project: {
          readonly avatarUrl: string;
          readonly id: AGG$Long;
          readonly name: string;
        } | null | undefined;
        readonly role: {
          readonly id: AGG$Long;
          readonly name: string;
        } | null | undefined;
        readonly type: string;
        readonly user: {
          readonly accountId: string;
          readonly avatarUrl: string;
          readonly displayName: string;
        } | null | undefined;
      } | null | undefined>;
      readonly id: string;
      readonly isOrderedByRank: boolean;
      readonly name: string;
      readonly owner: {
        readonly accountId: string;
        readonly avatarUrl: string | null | undefined;
        readonly displayName: string;
      } | null | undefined;
      readonly query: string;
      readonly queryProjects: {
        readonly isMaxSupportShowingProjectsReached: boolean | null | undefined;
        readonly isProjectsUnboundedInFilter: boolean | null | undefined;
        readonly projects: ReadonlyArray<{
          readonly canEditProject: boolean;
          readonly id: AGG$Long;
          readonly key: string;
          readonly name: string;
        } | null | undefined> | null | undefined;
        readonly projectsCount: number | null | undefined;
      };
      readonly sharePermissionEntries: ReadonlyArray<{
        readonly group: {
          readonly id: AGG$Long | null | undefined;
          readonly name: string | null | undefined;
        } | null | undefined;
        readonly project: {
          readonly avatarUrl: string;
          readonly id: AGG$Long;
          readonly name: string;
        } | null | undefined;
        readonly role: {
          readonly id: AGG$Long;
          readonly name: string;
        } | null | undefined;
        readonly type: string;
        readonly user: {
          readonly accountId: string;
          readonly avatarUrl: string;
          readonly displayName: string;
        } | null | undefined;
      } | null | undefined>;
    };
    readonly subqueryConfig: {
      readonly subqueries: ReadonlyArray<{
        readonly id: AGG$Long;
        readonly query: string;
      } | null | undefined>;
    };
  };
  readonly jira: {
    readonly getGlobalPermissionsAndGrants: {
      readonly globalPermissionGrants?: ReadonlyArray<{
        readonly permission: {
          readonly key: string;
        };
      } | null | undefined>;
    };
  };
};
export type generalSettingsPageQuery = {
  response: generalSettingsPageQuery$data;
  variables: generalSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "key"
},
v3 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  {
    "kind": "Literal",
    "name": "isCMP",
    "value": true
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "name"
},
v5 = {
  "kind": "ScalarField",
  "name": "permissions"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "kind": "ScalarField",
  "name": "canEdit"
},
v8 = {
  "kind": "ScalarField",
  "name": "query"
},
v9 = {
  "kind": "ScalarField",
  "name": "description"
},
v10 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v11 = {
  "kind": "ScalarField",
  "name": "avatarUrl"
},
v12 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v13 = {
  "kind": "ScalarField",
  "name": "type"
},
v14 = [
  (v4/*: any*/),
  (v6/*: any*/)
],
v15 = {
  "concreteType": "JswSavedFilterSharePermissionValue",
  "kind": "LinkedField",
  "name": "group",
  "plural": false,
  "selections": (v14/*: any*/)
},
v16 = {
  "kind": "ScalarField",
  "name": "isOrderedByRank"
},
v17 = {
  "kind": "ScalarField",
  "name": "canEditProject"
},
v18 = {
  "kind": "ScalarField",
  "name": "isProjectsUnboundedInFilter"
},
v19 = {
  "kind": "ScalarField",
  "name": "isMaxSupportShowingProjectsReached"
},
v20 = {
  "kind": "ScalarField",
  "name": "projectsCount"
},
v21 = {
  "kind": "ScalarField",
  "name": "oldDoneIssuesCutoff"
},
v22 = {
  "kind": "ScalarField",
  "name": "label"
},
v23 = {
  "kind": "ScalarField",
  "name": "value"
},
v24 = {
  "kind": "ScalarField",
  "name": "status"
},
v25 = {
  "kind": "ScalarField",
  "name": "avatarURI"
},
v26 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v27 = {
  "kind": "ScalarField",
  "name": "projectTypeKey"
},
v28 = {
  "kind": "ScalarField",
  "name": "userLocationId"
},
v29 = [
  (v13/*: any*/),
  (v15/*: any*/),
  {
    "concreteType": "JswSavedFilterSharePermissionProjectValue",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      (v4/*: any*/),
      (v11/*: any*/)
    ]
  },
  {
    "concreteType": "JswSavedFilterSharePermissionValue",
    "kind": "LinkedField",
    "name": "role",
    "plural": false,
    "selections": (v14/*: any*/)
  },
  {
    "concreteType": "JswSavedFilterSharePermissionUserValue",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v12/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/)
    ]
  }
],
v30 = [
  (v2/*: any*/),
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "generalSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "kind": "LinkedField",
                "name": "getGlobalPermissionsAndGrants",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "concreteType": "JiraGlobalPermissionGrants",
                          "kind": "LinkedField",
                          "name": "globalPermissionGrants",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraGlobalPermission",
                                "kind": "LinkedField",
                                "name": "permission",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v2/*: any*/),
                                    "action": "THROW",
                                    "path": "jira.getGlobalPermissionsAndGrants.globalPermissionGrants.permission.key"
                                  }
                                ]
                              },
                              "action": "THROW",
                              "path": "jira.getGlobalPermissionsAndGrants.globalPermissionGrants.permission"
                            }
                          ]
                        },
                        "action": "THROW",
                        "path": "jira.getGlobalPermissionsAndGrants.globalPermissionGrants"
                      }
                    ],
                    "type": "JiraGlobalPermissionGrantsList"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.getGlobalPermissionsAndGrants"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      },
      {
        "kind": "RequiredField",
        "field": {
          "args": (v3/*: any*/),
          "concreteType": "BoardScope",
          "kind": "LinkedField",
          "name": "boardScope",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "SoftwareBoard",
                "kind": "LinkedField",
                "name": "board",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v4/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.board.name"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.board"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v5/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.currentUser.permissions"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.currentUser"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JswSavedFilterConfig",
                "kind": "LinkedField",
                "name": "savedFilterConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v6/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.savedFilterConfig.id"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v7/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.savedFilterConfig.canEdit"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v8/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.savedFilterConfig.query"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v4/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.savedFilterConfig.name"
                  },
                  (v9/*: any*/),
                  {
                    "concreteType": "JswSavedFilterOwner",
                    "kind": "LinkedField",
                    "name": "owner",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v10/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.savedFilterConfig.owner.displayName"
                      },
                      (v11/*: any*/),
                      {
                        "kind": "RequiredField",
                        "field": (v12/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.savedFilterConfig.owner.accountId"
                      }
                    ]
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JswSavedFilterSharePermissionEntry",
                      "kind": "LinkedField",
                      "name": "editPermissionEntries",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v13/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.savedFilterConfig.editPermissionEntries.type"
                        },
                        (v15/*: any*/),
                        {
                          "concreteType": "JswSavedFilterSharePermissionProjectValue",
                          "kind": "LinkedField",
                          "name": "project",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v6/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.editPermissionEntries.project.id"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v4/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.editPermissionEntries.project.name"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v11/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.editPermissionEntries.project.avatarUrl"
                            }
                          ]
                        },
                        {
                          "concreteType": "JswSavedFilterSharePermissionValue",
                          "kind": "LinkedField",
                          "name": "role",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v4/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.editPermissionEntries.role.name"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v6/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.editPermissionEntries.role.id"
                            }
                          ]
                        },
                        {
                          "concreteType": "JswSavedFilterSharePermissionUserValue",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v12/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.editPermissionEntries.user.accountId"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v10/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.editPermissionEntries.user.displayName"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v11/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.editPermissionEntries.user.avatarUrl"
                            }
                          ]
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.savedFilterConfig.editPermissionEntries"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JswSavedFilterSharePermissionEntry",
                      "kind": "LinkedField",
                      "name": "sharePermissionEntries",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v13/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.savedFilterConfig.sharePermissionEntries.type"
                        },
                        (v15/*: any*/),
                        {
                          "concreteType": "JswSavedFilterSharePermissionProjectValue",
                          "kind": "LinkedField",
                          "name": "project",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v6/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.sharePermissionEntries.project.id"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v4/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.sharePermissionEntries.project.name"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v11/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.sharePermissionEntries.project.avatarUrl"
                            }
                          ]
                        },
                        {
                          "concreteType": "JswSavedFilterSharePermissionValue",
                          "kind": "LinkedField",
                          "name": "role",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v4/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.sharePermissionEntries.role.name"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v6/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.sharePermissionEntries.role.id"
                            }
                          ]
                        },
                        {
                          "concreteType": "JswSavedFilterSharePermissionUserValue",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v12/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.sharePermissionEntries.user.accountId"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v10/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.sharePermissionEntries.user.displayName"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v11/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.sharePermissionEntries.user.avatarUrl"
                            }
                          ]
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.savedFilterConfig.sharePermissionEntries"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v16/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.savedFilterConfig.isOrderedByRank"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JswSavedFilterQueryProjects",
                      "kind": "LinkedField",
                      "name": "queryProjects",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JswSavedFilterQueryProjectEntry",
                          "kind": "LinkedField",
                          "name": "projects",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v6/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.queryProjects.projects.id"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v4/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.queryProjects.projects.name"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v2/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.queryProjects.projects.key"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v17/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.savedFilterConfig.queryProjects.projects.canEditProject"
                            }
                          ]
                        },
                        (v18/*: any*/),
                        (v19/*: any*/),
                        (v20/*: any*/)
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.savedFilterConfig.queryProjects"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.savedFilterConfig"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JswOldDoneIssuesCutOffConfig",
                "kind": "LinkedField",
                "name": "oldDoneIssuesCutOffConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v21/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.oldDoneIssuesCutOffConfig.oldDoneIssuesCutoff"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JswOldDoneIssuesCutoffOption",
                      "kind": "LinkedField",
                      "name": "options",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v22/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.oldDoneIssuesCutOffConfig.options.label"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v23/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.oldDoneIssuesCutOffConfig.options.value"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.oldDoneIssuesCutOffConfig.options"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.oldDoneIssuesCutOffConfig"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JswBoardAdmins",
                "kind": "LinkedField",
                "name": "boardAdmins",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JswBoardAdmin",
                      "kind": "LinkedField",
                      "name": "userKeys",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v2/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.boardAdmins.userKeys.key"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v10/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.boardAdmins.userKeys.displayName"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.boardAdmins.userKeys"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JswBoardAdmin",
                      "kind": "LinkedField",
                      "name": "groupKeys",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v2/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.boardAdmins.groupKeys.key"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v10/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.boardAdmins.groupKeys.displayName"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.boardAdmins.groupKeys"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.boardAdmins"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "BoardFeature",
                "kind": "LinkedField",
                "name": "features",
                "plural": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v2/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.key"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v24/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.status"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.features"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JswSubqueryConfig",
                "kind": "LinkedField",
                "name": "subqueryConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JswSubqueryEntry",
                      "kind": "LinkedField",
                      "name": "subqueries",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v6/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.subqueryConfig.subqueries.id"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v8/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.subqueryConfig.subqueries.query"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.subqueryConfig.subqueries"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.subqueryConfig"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JswBoardLocationModel",
                "kind": "LinkedField",
                "name": "boardLocationModel",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v25/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.boardLocationModel.avatarURI"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v4/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.boardLocationModel.name"
                  },
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/)
                ]
              },
              "action": "THROW",
              "path": "boardScope.boardLocationModel"
            }
          ]
        },
        "action": "THROW",
        "path": "boardScope"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "generalSettingsPageQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "LinkedField",
            "name": "getGlobalPermissionsAndGrants",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__typename"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "concreteType": "JiraGlobalPermissionGrants",
                    "kind": "LinkedField",
                    "name": "globalPermissionGrants",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraGlobalPermission",
                        "kind": "LinkedField",
                        "name": "permission",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "type": "JiraGlobalPermissionGrantsList"
              }
            ]
          }
        ]
      },
      {
        "args": (v3/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          {
            "concreteType": "SoftwareBoard",
            "kind": "LinkedField",
            "name": "board",
            "plural": false,
            "selections": (v14/*: any*/)
          },
          {
            "concreteType": "CurrentUser",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ]
          },
          {
            "concreteType": "JswSavedFilterConfig",
            "kind": "LinkedField",
            "name": "savedFilterConfig",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v4/*: any*/),
              (v9/*: any*/),
              {
                "concreteType": "JswSavedFilterOwner",
                "kind": "LinkedField",
                "name": "owner",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ]
              },
              {
                "concreteType": "JswSavedFilterSharePermissionEntry",
                "kind": "LinkedField",
                "name": "editPermissionEntries",
                "plural": true,
                "selections": (v29/*: any*/)
              },
              {
                "concreteType": "JswSavedFilterSharePermissionEntry",
                "kind": "LinkedField",
                "name": "sharePermissionEntries",
                "plural": true,
                "selections": (v29/*: any*/)
              },
              (v16/*: any*/),
              {
                "concreteType": "JswSavedFilterQueryProjects",
                "kind": "LinkedField",
                "name": "queryProjects",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JswSavedFilterQueryProjectEntry",
                    "kind": "LinkedField",
                    "name": "projects",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v4/*: any*/),
                      (v2/*: any*/),
                      (v17/*: any*/)
                    ]
                  },
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/)
                ]
              }
            ]
          },
          {
            "concreteType": "JswOldDoneIssuesCutOffConfig",
            "kind": "LinkedField",
            "name": "oldDoneIssuesCutOffConfig",
            "plural": false,
            "selections": [
              (v21/*: any*/),
              {
                "concreteType": "JswOldDoneIssuesCutoffOption",
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": [
                  (v22/*: any*/),
                  (v23/*: any*/)
                ]
              }
            ]
          },
          {
            "concreteType": "JswBoardAdmins",
            "kind": "LinkedField",
            "name": "boardAdmins",
            "plural": false,
            "selections": [
              {
                "concreteType": "JswBoardAdmin",
                "kind": "LinkedField",
                "name": "userKeys",
                "plural": true,
                "selections": (v30/*: any*/)
              },
              {
                "concreteType": "JswBoardAdmin",
                "kind": "LinkedField",
                "name": "groupKeys",
                "plural": true,
                "selections": (v30/*: any*/)
              }
            ]
          },
          {
            "concreteType": "BoardFeature",
            "kind": "LinkedField",
            "name": "features",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v24/*: any*/)
            ]
          },
          {
            "concreteType": "JswSubqueryConfig",
            "kind": "LinkedField",
            "name": "subqueryConfig",
            "plural": false,
            "selections": [
              {
                "concreteType": "JswSubqueryEntry",
                "kind": "LinkedField",
                "name": "subqueries",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/)
                ]
              }
            ]
          },
          {
            "concreteType": "JswBoardLocationModel",
            "kind": "LinkedField",
            "name": "boardLocationModel",
            "plural": false,
            "selections": [
              (v25/*: any*/),
              (v4/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "e959cd2c577ba3e1b2956c5256ac6adf3a7b437a31b330460ad5280ebb92fa0c",
    "metadata": {},
    "name": "generalSettingsPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ff5b989765c72ded76cdce38415273ba";

export default node;
