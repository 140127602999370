import React, { useCallback, useMemo, useEffect } from 'react';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import type { QueryItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { BOARD_SETTINGS_CREATE_SWIMLANE_MODAL_ID } from '../../../common/constants/modals.tsx';
import { useAddSwimlane } from '../../../services/add-swimlane/index.tsx';
import { useRapidViewId, useIsCMPBoard } from '../../selectors/board/index.tsx';
import { useSwimlanesWithoutDefault } from '../../selectors/swimlanes/index.tsx';
import messages from './messages.tsx';

export const useCreateSwimlaneHandler = () => {
	const [rapidViewId] = useRapidViewId();
	const [isCMPBoard] = useIsCMPBoard();
	const [, { addSwimlane }] = useSwimlanesWithoutDefault();
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const [isCreateModalOpen, { off: closeCreateModal }] = useSwitchModals(
		BOARD_SETTINGS_CREATE_SWIMLANE_MODAL_ID,
	);

	const {
		commit: commitAddSwimlane,
		queryValidationErrorMessage,
		error,
		resetError,
	} = useAddSwimlane(rapidViewId, formatMessage(messages.failedToCreateErrorTitle));

	const handleItemCreateSubmit = useCallback(
		async (newSwimlane: Omit<QueryItem, 'id'>) => {
			const result = await commitAddSwimlane(newSwimlane);

			if (result) {
				addSwimlane(result);
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						isCMPBoard={isCMPBoard}
						title={formatMessage(messages.successFlagTitleForCreatingSwimlane)}
						{...props}
					/>
				));
				closeCreateModal();
			}
		},
		[addSwimlane, closeCreateModal, commitAddSwimlane, showFlag, formatMessage, isCMPBoard],
	);

	useEffect(() => {
		// Close modal if there is error but error is not validation query error
		if (error && !queryValidationErrorMessage) {
			closeCreateModal();
		}
	}, [error, queryValidationErrorMessage, closeCreateModal]);

	const createQueryValidationErrorMessage = useMemo(() => {
		if (queryValidationErrorMessage) {
			return `${queryValidationErrorMessage} ${formatMessage(
				messages.validationErrorInstructionForCreatingSwimlane,
			)}`;
		}
	}, [formatMessage, queryValidationErrorMessage]);

	// reset request error on modal open so it won't show error from the last modal request
	useEffect(() => {
		if (isCreateModalOpen) {
			resetError();
		}
	}, [resetError, isCreateModalOpen]);

	return {
		handleItemCreateSubmit,
		createQueryValidationErrorMessage,
	};
};
