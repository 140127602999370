import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const updateIsRoadmapEnabled =
	(isRoadmapEnabled: boolean) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({
			...getState(),
			isRoadmapEnabled,
		});
	};

export const updateIsChildIssuePlanningEnabled =
	(isChildIssuePlanningEnabled: boolean) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({
			...getState(),
			isChildIssuePlanningEnabled,
		});
	};

export const updatePrefersChildIssueDatePlanning =
	(prefersChildIssueDatePlanning: boolean) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({
			...getState(),
			prefersChildIssueDatePlanning,
		});
	};

export type UpdateIsRoadmapEnabled = typeof updateIsRoadmapEnabled;
export type UpdateIsChildIssuePlanningEnabled = typeof updateIsChildIssuePlanningEnabled;
export type UpdatePrefersChildIssueDatePlanning = typeof updatePrefersChildIssueDatePlanning;
