import { useCallback, useMemo } from 'react';
import type { ExperienceAttributes } from '@atlassian/jira-experience-tracker/src/common/types.tsx';
import { useExperienceAbort } from '@atlassian/jira-experience-tracker/src/ui/experience-abort/index.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { EXPERIENCE_ABORT_REASON } from '../../common/constants.tsx';
import { createCommonAttributes, getErrorAttributes, isSloFailure } from '../../common/utils.tsx';
import type {
	ErrorBoundaryExperienceCallback,
	StartExperienceCallback,
	ErrorExperienceCallback,
	SuccessExperienceCallback,
	AbortExperienceCallback,
	FailExperienceCallback,
	ExperienceTrackerActions,
} from './types.tsx';

export const useExperienceTracker = (
	experienceId: string,
	experience: string,
	analyticsSource: string,
	attributes: ExperienceAttributes = {},
): ExperienceTrackerActions => {
	const { appEditions } = useTenantContext();

	const fireExperienceStart: StartExperienceCallback = useExperienceStart({
		experienceId,
		experience,
		analyticsSource,
		edition: appEditions.software,
		application: JIRA_SOFTWARE,
	});

	const commonAttributes = useMemo(() => createCommonAttributes(attributes), [attributes]);

	const fireExperienceSuccess: SuccessExperienceCallback = useExperienceSuccess({
		experience,
		attributes: commonAttributes,
	});

	const fireExperienceFail: FailExperienceCallback = useExperienceFail({
		experience,
		attributes: commonAttributes,
	});

	const fireExperienceAbort: AbortExperienceCallback = useExperienceAbort({
		experience,
		attributes: commonAttributes,
	});

	const fireExperienceError: ErrorExperienceCallback = useCallback(
		(error: Error, errorAttributes: ExperienceAttributes = {}) => {
			if (isSloFailure(error)) {
				fireExperienceFail(experienceId, error, {
					...getErrorAttributes(error),
					...errorAttributes,
				});
			} else {
				fireExperienceAbort(EXPERIENCE_ABORT_REASON, {
					...getErrorAttributes(error),
					...errorAttributes,
				});
			}
		},
		[experienceId, fireExperienceAbort, fireExperienceFail],
	);

	const fireErrorBoundaryExperienceFail: ErrorBoundaryExperienceCallback = useCallback(
		(location: string, error: Error, errorAttributes: ExperienceAttributes = {}) => {
			fireExperienceFail(location || experienceId, error, {
				...getErrorAttributes(error),
				...errorAttributes,
			});
		},
		[experienceId, fireExperienceFail],
	);

	return {
		fireExperienceStart,
		fireExperienceSuccess,
		fireExperienceError,
		fireExperienceFail,
		fireExperienceAbort,
		fireErrorBoundaryExperienceFail,
	};
};
