import React from 'react';
import { LinkButton } from '@atlaskit/button/new';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import { Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import BreadcrumbRenderer from '@atlassian/jira-breadcrumbs/src/breadcrumb-renderer/index.tsx';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useBoardAndLocationContext } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-board-and-location-context/index.tsx';
import { useSettingsContainerContext } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-settings-container-context/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { SettingsContainerBreadcrumbs } from './breadcrumbs/index.tsx';
import messages from './messages.tsx';

export const Header = () => {
	const { formatMessage } = useIntl();
	const [{ location }] = useRouter();
	const { board } = useBoardAndLocationContext();
	const { boardName } = useSettingsContainerContext();

	const breadcrumbs = (
		<>
			<BreadcrumbRenderer />
			<SettingsContainerBreadcrumbs />
		</>
	);

	return (
		<PageHeader
			breadcrumbs={breadcrumbs}
			actions={
				<LinkButton href={location.pathname} appearance="subtle">
					<Inline xcss={buttonTextStyles} alignBlock="center" as="span">
						<ArrowLeftIcon size="small" label="" />
						{formatMessage(messages.backToBoard)}
					</Inline>
				</LinkButton>
			}
		>
			{formatMessage(messages.boardSettingsTitle, { boardName: boardName ?? board?.name ?? '' })}
		</PageHeader>
	);
};

const buttonTextStyles = xcss({
	color: 'color.text.subtle',
	fontWeight: token('font.weight.regular'),
});
