import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const updateBoardName =
	(name: string) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const currentState = getState();
		setState({
			...currentState,
			name,
		});
	};

export type UpdateBoardNameAction = typeof updateBoardName;
