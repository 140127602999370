import React, { useCallback, useState } from 'react';
import Button, { LoadingButton } from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { FormattedMessage } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const ConfirmationModal = ({
	title,
	description,
	onSubmit,
	onCancel,
}: {
	title: React.ReactNode;
	description: React.ReactNode;
	onSubmit: () => Promise<void>;
	onCancel: () => void;
}) => {
	const [submitting, setSubmitting] = useState(false);
	const onConfirm = useCallback(async () => {
		setSubmitting(true);
		await onSubmit();
		setSubmitting(false);
	}, [onSubmit]);

	return (
		<ModalTransition>
			<ModalDialog onClose={onCancel} shouldReturnFocus>
				<ModalHeader>
					<ModalTitle appearance="warning">{title}</ModalTitle>
				</ModalHeader>
				<ModalBody>{description}</ModalBody>
				<ModalFooter>
					<Button isDisabled={submitting} appearance="subtle" onClick={onCancel}>
						<FormattedMessage {...messages.cancel} />
					</Button>
					<LoadingButton
						isLoading={submitting}
						appearance="primary"
						onClick={onConfirm}
						type="submit"
					>
						<FormattedMessage {...messages.confirm} />
					</LoadingButton>
				</ModalFooter>
			</ModalDialog>
		</ModalTransition>
	);
};
