import type { CurrentField } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';

type BasicField = Pick<CurrentField, 'mode' | 'fieldId'>;

export const createFieldFinder = (target: BasicField) => (f: BasicField) =>
	f.fieldId === target.fieldId && f.mode === target.mode;

export function reorderField<T extends BasicField>(fields: readonly T[], src: T, after?: T) {
	const srcIndex = fields.findIndex(createFieldFinder(src));
	const afterIndex = after ? fields.findIndex(createFieldFinder(after)) : null;
	const reorderedFields = afterIndex === null ? [src] : [];

	fields.forEach((currentField, currentIndex) => {
		if (currentIndex !== srcIndex) {
			reorderedFields.push(currentField);
		}

		if (currentIndex === afterIndex) {
			reorderedFields.push(src);
		}
	});

	return reorderedFields;
}

export const tick = () => new Promise<void>((r) => setTimeout(() => r(), 0));
