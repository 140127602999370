import React, { useCallback, useState } from 'react';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useDeleteCardColor } from '../../../services/delete-card-color/index.tsx';
import { useRapidViewId, useIsCMPBoard } from '../../selectors/board/index.tsx';
import { useCardColors } from '../../selectors/colors/index.tsx';
import messages from './messages.tsx';

export const useDeleteCardColorHandler = () => {
	const [toDeleteItemId, setToDeleteItemId] = useState<string | null>(null);
	const [rapidViewId] = useRapidViewId();
	const [isCMPBoard] = useIsCMPBoard();
	const [, { deleteCardColor }] = useCardColors();
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const { commit: commitDeleteCardColor, loading: isDeleteSubmitting } =
		useDeleteCardColor(rapidViewId);

	const beginItemDelete = useCallback((item: { id: string }) => setToDeleteItemId(item.id), []);

	const abortItemDelete = useCallback(() => setToDeleteItemId(null), []);

	const commitItemDelete = useCallback(async () => {
		if (toDeleteItemId == null) return;
		const id = Number(toDeleteItemId);
		const result = await commitDeleteCardColor({ id });

		if (result.ok) {
			deleteCardColor(id);
			showFlag((props) => (
				<SuccessFlagWithBoardLink
					isCMPBoard={isCMPBoard}
					title={formatMessage(messages.successFlagForDeletingCardColor)}
					{...props}
				/>
			));
		}

		setToDeleteItemId(null);
	}, [toDeleteItemId, commitDeleteCardColor, deleteCardColor, formatMessage, isCMPBoard, showFlag]);

	return {
		beginItemDelete,
		abortItemDelete,
		commitItemDelete,
		isDeleteSubmitting,
		isDeleteModalOpen: toDeleteItemId !== null,
		toDeleteItemId,
	};
};
