import React, { useCallback } from 'react';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useUpdateShowDaysInColumn } from '../../../services/update-show-days-in-column/index.tsx';
import { useIsCMPBoard, useRapidViewId } from '../../selectors/board/index.tsx';
import { useShowDaysInColumn } from '../../selectors/card-layout/index.tsx';
import messages from './messages.tsx';

export const useUpdateShowDaysInColumnHandler = () => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const [isCMPBoard] = useIsCMPBoard();
	const [rapidViewId] = useRapidViewId();
	const [showDaysInColumn, { setShowDaysInColumn }] = useShowDaysInColumn();

	const {
		commit: commitShowDaysInColumn,
		hasError,
		...other
	} = useUpdateShowDaysInColumn(rapidViewId);

	const handleShowDaysInColumnUpdate = useCallback(async () => {
		const next = !showDaysInColumn;

		const result = await commitShowDaysInColumn({
			showDaysInColumn: next,
		});

		if (result.ok) {
			showFlag((props) => (
				<SuccessFlagWithBoardLink
					{...props}
					isCMPBoard={isCMPBoard}
					title={formatMessage(
						next ? messages.toggleOnShowDaysInColumn : messages.toggleOffShowDaysInColumn,
					)}
					{...props}
				/>
			));

			setShowDaysInColumn(next);
		}
	}, [
		commitShowDaysInColumn,
		formatMessage,
		isCMPBoard,
		setShowDaysInColumn,
		showDaysInColumn,
		showFlag,
	]);

	return {
		...other,
		hasError,
		handleShowDaysInColumnUpdate,
	};
};
