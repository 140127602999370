import React, { type PropsWithChildren } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

export const AvatarIllustrationContainer = ({ children }: PropsWithChildren<{}>) => (
	<Box xcss={containerStyles}>{children}</Box>
);

const containerStyles = xcss({
	borderRadius: 'border.radius.050',
	display: 'inline-block',
	height: '24px',
	margin: 'space.025',
	overflow: 'hidden',
	width: '24px',
});
