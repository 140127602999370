import { useRouter } from '@atlassian/react-resource-router';

export const useBoardId = (): number | null => {
	const [
		{
			match: { params },
		},
	] = useRouter();

	return Number(params.boardId) || null;
};
