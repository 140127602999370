import React, { useState, type KeyboardEvent } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { FilterPermissions } from './filter-permissions/index.tsx';
import { IssueRanking } from './issue-ranking/index.tsx';
import messages from './messages.tsx';
import { ProjectsIncluded } from './projects-included/index.tsx';

export const BoardFilterDetails = () => {
	const [collapsed, setCollapsed] = useState<boolean>(true);

	const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.code.toLowerCase() === 'enter' || event.code.toLowerCase() === 'space') {
			event.preventDefault();
			setCollapsed(!collapsed);
		}
	};

	const headerContent = (
		<>
			{!collapsed ? (
				<ChevronDownIcon label="" color="currentColor" />
			) : (
				<ChevronRightIcon label="" color="currentColor" />
			)}
			<Heading as="h4" size="xsmall" id="board-filter-details-heading">
				<FormattedMessage {...messages.header} />
			</Heading>
		</>
	);

	return (
		<Box
			as="div"
			xcss={wrapperStyles}
			testId="software-board-settings-general-filter-settings.ui.board-filter-details.container"
		>
			{isVisualRefreshEnabled() ? (
				<Box
					role="button"
					tabIndex={0}
					aria-expanded={!collapsed}
					onClick={() => setCollapsed(!collapsed)}
					onKeyDown={onKeyDown}
					testId="software-board-settings-general-filter-settings.ui.board-filter-details.accordion-header"
					aria-labelledby="board-filter-details-heading"
					xcss={headerContainerStyles}
				>
					{headerContent}
				</Box>
			) : (
				<HeaderContainer
					role="button"
					tabIndex={0}
					aria-expanded={!collapsed}
					onClick={() => setCollapsed(!collapsed)}
					onKeyDown={onKeyDown}
					data-testid="software-board-settings-general-filter-settings.ui.board-filter-details.accordion-header"
					aria-labelledby="board-filter-details-heading"
				>
					{headerContent}
				</HeaderContainer>
			)}
			{!collapsed && <BoardFilterDetailsContent />}
		</Box>
	);
};

export const BoardFilterDetailsContent = () => (
	<Stack space="space.400" xcss={contentStyles}>
		<ProjectsIncluded />
		<IssueRanking />
		<FilterPermissions />
	</Stack>
);

const wrapperStyles = xcss({
	display: 'flex',
	alignItems: 'flex-start',
	flexDirection: 'column',
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled.div({
	display: 'flex',
	backgroundColor: token('elevation.surface', 'white'),
	alignItems: 'center',
	cursor: 'pointer',
});

const headerContainerStyles = xcss({
	display: 'flex',
	background: 'elevation.surface',
	alignItems: 'center',
	cursor: 'pointer',
	columnGap: 'space.050',
});

const contentStyles = xcss({
	marginTop: 'space.100',
	background: 'elevation.surface',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.300',
	rowGap: 'space.200',
});
