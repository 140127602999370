import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	add: {
		id: 'shareable-entity-dialog.access.sharee-picker.add',
		defaultMessage: 'Add',
		description:
			'Label for the add button, this button adds a new row (permission to access) into the list below',
	},
	update: {
		id: 'shareable-entity-dialog.access.sharee-picker.update',
		defaultMessage: 'Update',
		description:
			'Label for the update button, this button updates the only row (permission to access) in the list below',
	},
	openShareHelperMessage: {
		id: 'shareable-entity-dialog.access.sharee-picker.open-share-helper-message',
		defaultMessage: 'Any logged in user on {website}',
		description:
			'Helper text. Explains what open share permission for an item (e.g. filter or dashboard) means. "website" is a website host name, e.g. "http://hello.atlassian.net"',
	},
	safePublicShareHelperMessage: {
		id: 'shareable-entity-dialog.access.sharee-picker.safe-public-share-helper-message',
		defaultMessage: 'Anyone on the internet. No sign-in required',
		description:
			'Helper text. Explains what public share permission for an item (e.g. filter or dashboard) means.',
	},
	privateShareHelperMessage: {
		id: 'shareable-entity-dialog.access.sharee-picker.private-share-helper-message',
		defaultMessage: 'Only you',
		description:
			'Helper text. Explains what private share permission for an item (e.g. filter or dashboard) means.',
	},
	shareScopeLoadingHelperMessage: {
		id: 'shareable-entity-dialog.access.sharee-picker.share-scope-loading-helper-message',
		defaultMessage: 'Checking for the default user preference',
		description: 'Text to let user know that default user preference is loading',
	},
	addViewers: {
		id: 'shareable-entity-dialog.access.sharee-picker.add-viewers',
		defaultMessage: 'Add viewers',
		description: 'Accessibility label for add viewers button',
	},
	addEditors: {
		id: 'shareable-entity-dialog.access.sharee-picker.add-editors',
		defaultMessage: 'Add editors',
		description: 'Accessibility label for add editors button',
	},
	projectPlaceholderText: {
		id: 'shareable-entity-dialog.access.sharee-picker.project-placeholder-text',
		defaultMessage: 'Select project',
		description:
			'Placeholder for the project picker. Displayed in case it does not have a selected value',
	},
	userPlaceholderText: {
		id: 'shareable-entity-dialog.access.sharee-picker.user-placeholder-text',
		defaultMessage: 'Select user',
		description:
			'Placeholder for the user picker. Displayed in case it does not have a selected value',
	},
	groupPlaceholderText: {
		id: 'shareable-entity-dialog.access.sharee-picker.group-placeholder-text',
		defaultMessage: 'Select group',
		description:
			'Placeholder for the group picker. Displayed in case it does not have a selected value',
	},
	rolePlaceholderText: {
		id: 'shareable-entity-dialog.access.sharee-picker.role-placeholder-text',
		defaultMessage: 'Select role',
		description:
			'Placeholder for the role picker. Displayed in case it does not have a selected value',
	},
});
