import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-board-settings-general-filter-settings.board-filter-section.board-filter-field.label',
		defaultMessage: 'Select filter',
		description: 'An input field for a select input that picks a filter for the board.',
	},
	successFlag: {
		id: 'software-board-settings-general-filter-settings.board-filter-section.board-filter-field.success-flag',
		defaultMessage: 'Your board filter has been updated',
		description: 'The message shown the user when successfully updating their board filter',
	},
});
