import React, { useCallback } from 'react';
import { Text } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import type { QueryItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import { CreateQueryModal } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/create-query-modal/index.tsx';
import { DeleteQueryConfirmationModal } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/delete-query-confirmation-modal/index.tsx';
import { EditQueryModal } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/edit-query-modal/index.tsx';
import { Asterisk } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/query-modal-description/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import {
	BOARD_SETTINGS_CREATE_SWIMLANE_MODAL_ID,
	BOARD_SETTINGS_DELETE_SWIMLANE_MODAL_ID,
	BOARD_SETTINGS_EDIT_SWIMLANE_MODAL_ID,
} from '../../../common/constants/modals.tsx';
import messages from './messages.tsx';

export type Props = {
	canEdit: boolean;
	onCreateSubmit: (newQueryItem: Omit<QueryItem, 'id'>) => Promise<void>;
	createQueryValidationErrorMessage?: string;
	itemToEdit: QueryItem | null;
	onEditSubmit: (queryItem: QueryItem) => Promise<void>;
	editQueryValidationErrorMessage?: string;
	itemToDelete: QueryItem | null;
	onDeleteSubmit: (queryItem: QueryItem) => void;
	isDeleteSubmitting: boolean;
};

const CreateOrEditQueryModalDescription = ({ description }: { description: string }) => (
	<Text as="p">
		{description} <Asterisk />
	</Text>
);

export const SwimlaneModals = ({
	canEdit,
	onCreateSubmit,
	createQueryValidationErrorMessage,
	itemToEdit,
	editQueryValidationErrorMessage,
	onEditSubmit,
	itemToDelete,
	onDeleteSubmit,
	isDeleteSubmitting,
}: Props) => {
	const { formatMessage } = useIntl();

	const [isCreateSwimlaneModalOpen, { off: closeCreateModal }] = useSwitchModals(
		BOARD_SETTINGS_CREATE_SWIMLANE_MODAL_ID,
	);

	const [isEditSwimlaneModalOpen, { off: closeEditModal }] = useSwitchModals(
		BOARD_SETTINGS_EDIT_SWIMLANE_MODAL_ID,
	);

	const [isDeleteSwimlaneModalOpen, { off: closeDeleteModal }] = useSwitchModals(
		BOARD_SETTINGS_DELETE_SWIMLANE_MODAL_ID,
	);

	const handleDeleteSubmit = useCallback(
		() => itemToDelete && onDeleteSubmit(itemToDelete),
		[itemToDelete, onDeleteSubmit],
	);

	return (
		<>
			{isCreateSwimlaneModalOpen && (
				<CreateQueryModal
					analyticsSource="swimlanes-boards-settings-page"
					isDisabled={!canEdit}
					queryValidationErrorMessage={createQueryValidationErrorMessage}
					onCancel={closeCreateModal}
					onSubmit={onCreateSubmit}
					title={formatMessage(messages.createSwimlaneModalTitle)}
					description={
						<CreateOrEditQueryModalDescription
							description={formatMessage(messages.createOrEditSwimlaneModalGuidelines)}
						/>
					}
				/>
			)}
			{isEditSwimlaneModalOpen && itemToEdit && (
				<EditQueryModal
					analyticsSource="swimlanes-boards-settings-page"
					isDisabled={!canEdit}
					queryItem={itemToEdit}
					queryValidationErrorMessage={editQueryValidationErrorMessage}
					title={formatMessage(messages.editModalTitle)}
					description={
						<CreateOrEditQueryModalDescription
							description={formatMessage(messages.createOrEditSwimlaneModalGuidelines)}
						/>
					}
					onCancel={closeEditModal}
					onSubmit={onEditSubmit}
				/>
			)}
			{isDeleteSwimlaneModalOpen && itemToDelete && (
				<DeleteQueryConfirmationModal
					title={formatMessage(messages.deleteSwimlaneModalTitle, {
						swimlaneName: itemToDelete.name,
					})}
					description={formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.deleteSwimlaneModalDescriptionIssueTermRefresh
							: messages.deleteSwimlaneModalDescription,
					)}
					onCancel={closeDeleteModal}
					onSubmit={handleDeleteSubmit}
					isSubmitting={isDeleteSubmitting}
				/>
			)}
		</>
	);
};
