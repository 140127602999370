import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.title',
		defaultMessage: 'Swimlanes',
		description: 'Page title',
	},
	description: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.description',
		defaultMessage: 'Add swimlanes to your board to group issues together.',
		description: 'Page description',
	},
	inProductHelp: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.in-product-help',
		defaultMessage: 'Read more about swimlanes',
		description: 'Text for In Product Help link',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.description-issue-term-refresh',
		defaultMessage: 'Add swimlanes to your board to group work items together.',
		description: 'Page description',
	},
});
