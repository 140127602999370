import React, { useMemo, type PropsWithChildren } from 'react';
import { graphql, usePreloadedQuery, type EntryPointProps } from 'react-relay';
import { useBoardId } from '@atlassian/jira-board-settings-common/src/common/utils/use-board-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ISSUE_HIERARCHY_LEVEL_EPIC } from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import type { swimlanesSettingsPageQuery } from '@atlassian/jira-relay/src/__generated__/swimlanesSettingsPageQuery.graphql';
import type { RapidBoardConfig } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/types/index.tsx';
import {
	DEFAULT_LEVEL_ONE_NAME,
	EDIT_BOARD_CONFIG,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import type { FlexibleNomenclatureData } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/global-config.tsx';
import type { SwimlanesConfigProps } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
import type { SwimlanesConfig } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { SwimlanesConfigDataContainer } from '../../controllers/main.tsx';
import { SwimlanesSettingsContainer } from './main.tsx';
import { transformSwimlaneStrategy } from './utils.tsx';

export type Props = SwimlanesConfigProps & FlexibleNomenclatureData;

// Deprecated with simplified-boards-milestone-2_07hbk
const SwimlanesSettingsPage = ({
	swimlanesConfig,
	levelOneName = DEFAULT_LEVEL_ONE_NAME,
}: Props) => (
	<JiraRelayEnvironmentProvider>
		<SwimlanesConfigDataContainer {...swimlanesConfig} levelOneName={levelOneName}>
			<SwimlanesSettingsContainer />
		</SwimlanesConfigDataContainer>
	</JiraRelayEnvironmentProvider>
);

type Query = { queryReference: swimlanesSettingsPageQuery };

export const SwimlanesSettingPageContent = (
	entryPointProps: EntryPointProps<Query, {}, {}, {}>,
) => {
	if (fg('board-settings-graphql-refactor_cyvz9')) {
		return <GraphQlPageContent {...entryPointProps} />;
	}

	return <EditModelPageContent />;
};

const GraphQlPageContent = ({
	queries: { queryReference },
}: EntryPointProps<Query, {}, {}, {}>) => {
	/* eslint-disable @atlassian/relay/unused-fields */
	const { jira, boardScope } = usePreloadedQuery(
		graphql`
			query swimlanesSettingsPageQuery($boardId: ID!, $cloudId: ID!) {
				jira @required(action: THROW) {
					issueHierarchyConfig(cloudId: $cloudId) @required(action: THROW) {
						data @required(action: THROW) {
							hierarchyLevel @required(action: THROW) {
								level @required(action: THROW)
								name @required(action: THROW)
							}
						}
					}
				}
				boardScope(boardId: $boardId, isCMP: true) @required(action: THROW) {
					currentUser {
						permissions
					}
					board @required(action: THROW) {
						boardSwimlaneStrategy @optIn(to: "modularBoardSettings") @required(action: THROW)
						customSwimlaneConfig @optIn(to: "modularBoardSettings") @required(action: THROW) {
							edges @required(action: THROW) {
								node @required(action: THROW) {
									id @required(action: THROW)
									name @required(action: THROW)
									description @required(action: THROW)
									isDefault @required(action: THROW)
									query @required(action: THROW)
								}
							}
						}
					}
				}
			}
		`,
		queryReference,
	);

	const rapidViewId = useBoardId();

	if (!rapidViewId) {
		throw new Error('Swimlanes settings page failed to load');
	}

	const swimlanesConfig: SwimlanesConfig & FlexibleNomenclatureData = useMemo(() => {
		const canEdit = boardScope.currentUser.permissions.includes(EDIT_BOARD_CONFIG);

		const levelOneIssueHierarchy = jira.issueHierarchyConfig.data.find(
			({ hierarchyLevel: { level } }) => level === ISSUE_HIERARCHY_LEVEL_EPIC,
		);

		return {
			rapidViewId,
			canEdit,
			swimlaneStrategy: transformSwimlaneStrategy(boardScope.board.boardSwimlaneStrategy),
			swimlanes: boardScope.board.customSwimlaneConfig.edges.flatMap((edge) =>
				edge ? [edge.node] : [],
			),
			levelOneName: levelOneIssueHierarchy?.hierarchyLevel?.name ?? DEFAULT_LEVEL_ONE_NAME,
		};
	}, [
		boardScope.board,
		boardScope.currentUser.permissions,
		jira.issueHierarchyConfig.data,
		rapidViewId,
	]);

	return (
		<SwimlanesConfigDataContainer {...swimlanesConfig}>
			<SwimlanesSettingsContainer />
			<SpaStatePageReady />
		</SwimlanesConfigDataContainer>
	);
};

const EditModelPageContent = () => (
	<>
		<SwimlanesSettingsContainer />
		<SpaStatePageReady />
	</>
);

export const SwimlanesSettingsEditModelProvider = ({
	data,
	children,
}: PropsWithChildren<{
	data: RapidBoardConfig;
}>) => {
	const swimlanesConfig = data.swimlanesConfig;
	const levelOneName =
		data.globalConfig?.flexibleNomenclatureData?.levelOneName ?? DEFAULT_LEVEL_ONE_NAME;

	const props = {
		...swimlanesConfig,
		levelOneName,
	};

	return (
		<SwimlanesConfigDataContainer {...props} isGlobal>
			{children}
		</SwimlanesConfigDataContainer>
	);
};

export default SwimlanesSettingsPage;
