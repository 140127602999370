import React, { useMemo, type PropsWithChildren } from 'react';
import { graphql, usePreloadedQuery, type EntryPointProps } from 'react-relay';
import { JiraFilterAri } from '@atlassian/ari/jira/filter';
import { useBoardId } from '@atlassian/jira-board-settings-common/src/common/utils/use-board-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import type { timelineSettingsPageQuery } from '@atlassian/jira-relay/src/__generated__/timelineSettingsPageQuery.graphql';
import type { RapidBoardConfig } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/types/index.tsx';
import {
	EDIT_BOARD_CONFIG,
	SPRINTS_FEATURE_KEY,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import type { TimelineConfigProps } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
import { useBoardDetailsData } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-board-details-data/index.tsx';
import { InvalidStateHandler } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/invalid-state-handler/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { TimelineConfigDataContainer } from '../../controllers/main.tsx';
import { TimelineSettingsContainer } from './main.tsx';

// Deprecated with simplified-boards-milestone-2_07hbk
const TimelineSettingsPage = (timelineConfig: TimelineConfigProps) => (
	<JiraRelayEnvironmentProvider>
		<TimelineConfigDataContainer {...timelineConfig}>
			<TimelineSettingsContainer />
		</TimelineConfigDataContainer>
	</JiraRelayEnvironmentProvider>
);

type Query = { queryReference: timelineSettingsPageQuery };

export const TimelineSettingPageContent = (entryPointProps: EntryPointProps<Query, {}, {}, {}>) => {
	if (fg('board-settings-graphql-refactor_cyvz9')) {
		return <GraphQlPageContent {...entryPointProps} />;
	}

	return <EditModelPageContent />;
};

const GraphQlPageContent = ({
	queries: { queryReference },
}: EntryPointProps<Query, {}, {}, {}>) => {
	const { boardScope } = usePreloadedQuery(
		graphql`
			query timelineSettingsPageQuery($boardId: ID!) {
				boardScope(boardId: $boardId, isCMP: true)
					@optIn(to: "modularBoardSettings")
					@required(action: THROW) {
					currentUser {
						permissions
					}
					features @required(action: THROW) {
						key @required(action: THROW)
						status @required(action: THROW)
					}
					isCrossProject @required(action: THROW)
					roadmapConfig @required(action: THROW) {
						isRoadmapEnabled @required(action: THROW)
						isChildIssuePlanningEnabled @required(action: THROW)
						prefersChildIssueDatePlanning @required(action: THROW)
					}
					savedFilterConfig @required(action: THROW) {
						id @required(action: THROW)
					}
				}
			}
		`,
		queryReference,
	);

	const rapidViewId = useBoardId();
	const { data: boardDetailsData, loading, error } = useBoardDetailsData();

	if (!rapidViewId) {
		throw new Error('Timeline settings page failed to load');
	}

	const boardLocationModel = boardDetailsData?.location;

	const timelineConfig: TimelineConfigProps = useMemo(() => {
		const canEdit = boardScope.currentUser.permissions.includes(EDIT_BOARD_CONFIG);

		const { roadmapConfig, savedFilterConfig, isCrossProject } = boardScope;

		return {
			canEdit,
			rapidViewId,
			isSprintSupportEnabled: boardScope.features.some(
				(feature) => feature?.key === SPRINTS_FEATURE_KEY && feature?.status === 'ENABLED',
			),
			isChildIssuePlanningEnabled: roadmapConfig.isChildIssuePlanningEnabled,
			prefersChildIssueDatePlanning: roadmapConfig.prefersChildIssueDatePlanning,
			isRoadmapEnabled: roadmapConfig.isRoadmapEnabled,
			isBoardCrossProject: isCrossProject,
			projectName: boardLocationModel?.displayName ?? '',
			boardQueryFilterId: Number(JiraFilterAri.parse(savedFilterConfig.id).filterId),
		};
	}, [boardScope, rapidViewId, boardLocationModel]);

	if (loading) {
		return null;
	}
	if (error || !boardDetailsData) {
		return <InvalidStateHandler error={error} />;
	}

	return (
		<TimelineConfigDataContainer {...timelineConfig}>
			<TimelineSettingsContainer />
			<SpaStatePageReady />
		</TimelineConfigDataContainer>
	);
};

const EditModelPageContent = () => (
	<>
		<TimelineSettingsContainer />
		<SpaStatePageReady />
	</>
);

export const TimelineSettingsEditModelProvider = ({
	data,
	children,
}: PropsWithChildren<{
	data: RapidBoardConfig;
}>) => {
	const props = {
		rapidViewId: data.id,
		canEdit: data.canEdit,
		isBoardCrossProject: data.isBoardCrossProject,
		isRoadmapEnabled: data.isRoadmapEnabled,
		isSprintSupportEnabled: data.isSprintSupportEnabled,
		isChildIssuePlanningEnabled: data.isChildIssuePlanningEnabled,
		prefersChildIssueDatePlanning: data.prefersChildIssueDatePlanning,
		projectName: data.boardLocationModel?.name ?? '',
		boardQueryFilterId: data.filterConfig.id,
	};

	return (
		<TimelineConfigDataContainer {...props} isGlobal>
			{children}
		</TimelineConfigDataContainer>
	);
};

export default TimelineSettingsPage;
