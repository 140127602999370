import { useCallback } from 'react';
import type { CardColorStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import { useFetchStrategyConfig } from '../../../services/fetch-strategy-config/index.tsx';
import { useRapidViewId } from '../../selectors/board/index.tsx';
import { useCardColors } from '../../selectors/colors/index.tsx';

export const useFetchStrategyConfigHandler = () => {
	const [rapidViewId] = useRapidViewId();
	const [, { setCardColors }] = useCardColors();
	const { loading, commit: fetchStrategyConfig } = useFetchStrategyConfig(rapidViewId);

	const handleFetchStrategyConfig = useCallback(
		async (cardColorStrategy: CardColorStrategy) => {
			const result = await fetchStrategyConfig({ cardColorStrategy });
			if (result) {
				setCardColors(result.cardColors || []);
			}
		},
		[fetchStrategyConfig, setCardColors],
	);

	return { loading, handleFetchStrategyConfig };
};
