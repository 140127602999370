import React, { useMemo, useState, useCallback } from 'react';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import type { SaveFilter } from '@atlassian/jira-software-board-settings-general-common/src/common/types/index.tsx';
import { useBoardFilterConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import {
	useIsCMPBoard,
	useRapidViewId,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board/index.tsx';
import { useSavedFilters } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/form-data/index.tsx';
import type { FetchBoardAdminsQuery } from '@atlassian/jira-software-board-settings-general-settings/src/services/fetch-available-board-admins/types.tsx';
import { useFireAnalyticsTrackEvent } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-fire-analytics-event/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import type { SingleValue } from '../../../common/types.tsx';
import { useFetchRemoteSavedFilters } from '../../../services/fetch-remote-saved-filters/index.tsx';
import { useUpdateRemoteSelectedBoardFilter } from '../../../services/update-remote-selected-board-filter/index.tsx';
import messages from './messages.tsx';

export const toOption = (f: SaveFilter): SingleValue<SaveFilter> => ({
	label: f.name,
	value: f,
	key: f.id,
});

export const useAvailableSavedFilters = () => {
	const [savedFilters, { updateSavedFilters }] = useSavedFilters();
	const { commit: fetchSavedFilters, loading } = useFetchRemoteSavedFilters();

	const fetchAvailableSavedFilters = useCallback(
		async ({ query }: FetchBoardAdminsQuery) => {
			const response = await fetchSavedFilters({ query });
			if (response) {
				response && updateSavedFilters(response.filters);
			}
		},
		[updateSavedFilters, fetchSavedFilters],
	);

	return {
		options: useMemo(() => savedFilters.map(toOption), [savedFilters]),
		loading,
		fetchAvailableSavedFilters,
	};
};

export const useCurrentBoardFilterOption = (): {
	value: SingleValue<SaveFilter>;
	requestUpdate(value: { value: SaveFilter } | null): void;
} => {
	const [pending, setPending] = useState<SaveFilter | undefined>();
	const rapidViewId = useRapidViewId();
	const isCMPBoard = useIsCMPBoard();
	const [boardFilter, { updateSelectedBoardFilter }] = useBoardFilterConfig();
	const { fireTrackEvent } = useFireAnalyticsTrackEvent();

	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const { commit: requestCommit } = useUpdateRemoteSelectedBoardFilter(rapidViewId);

	const requestUpdate = useCallback(
		async (update: { value: SaveFilter } | null) => {
			if (update == null) return;

			const { value } = update;
			setPending(value);

			const newFilter = await requestCommit({ savedFilterId: value.id });

			if (newFilter) {
				updateSelectedBoardFilter(newFilter);

				fireTrackEvent('updateBoardFilter succeeded');
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						isCMPBoard={isCMPBoard}
						title={formatMessage(messages.successFlag)}
						{...props}
					/>
				));
			}

			setPending(undefined);
		},
		[requestCommit, showFlag, formatMessage, updateSelectedBoardFilter, isCMPBoard, fireTrackEvent],
	);

	return {
		value: useMemo(() => toOption(pending ?? boardFilter), [pending, boardFilter]),
		requestUpdate,
	};
};
