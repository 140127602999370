import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.projects-included.header',
		defaultMessage: 'Projects in board',
		description: 'Header of table that shows projects in the board',
	},
	projectColumn: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.projects-included.project-column',
		defaultMessage: 'Project',
		description: 'Table column heading for a column containing project information for that row.',
	},
	permissionColumn: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.projects-included.permission-column',
		defaultMessage: 'Permissions',
		description: 'Table column heading for a column containing permissions assigned to users.',
	},
	viewPermissionLinkText: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.projects-included.view-permission-link-text',
		defaultMessage: 'View permissions',
		description: 'Link text to project filter permissions',
	},
	viewPermissionAriaLabel: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.projects-included.view-permission-aria-label',
		defaultMessage: 'View permissions for {projectName}',
		description:
			'Aria Label text to project filter view permissions link. {projectName} is replaced with actual project name.',
	},
	noViewPermissionLinkText: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.projects-included.no-view-permission-link-text',
		defaultMessage: "You can't view these permissions.",
		description: 'Link text to project filter permissions',
	},
	currentProjectsCountInBoard: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.projects-included.current-projects-count-in-board',
		defaultMessage:
			'This board contains {projectsCount} projects. Check your filter query to see the projects.',
		description: 'This is the description to show current project count of the current board.',
	},
});
