import { useMemo } from 'react';
import { useRouter } from '@atlassian/react-resource-router';

export const useIsBoardSettingsTabOpen = (definedTabParamValue: string) => {
	const [
		{
			query: { config },
		},
	] = useRouter();

	return useMemo(
		() =>
			// compare case-insenstive between definedTabParamValue with router `config` query param to check if a board settings tab is open
			definedTabParamValue.localeCompare(config, undefined, {
				sensitivity: 'base',
			}) === 0,
		[config, definedTabParamValue],
	);
};
