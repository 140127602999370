import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addCardLayoutFieldFailureTitle: {
		id: 'software-board-settings-card-layout.services.add-card-layout-field.add-card-layout-field-failure-title',
		defaultMessage: "We couldn't add the {fieldName} field",
		description: 'Title for the error flag when adding a card layout field fails',
	},
	addCardLayoutFieldFailureDescription: {
		id: 'software-board-settings-card-layout.services.add-card-layout-field.add-card-layout-field-failure-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description for the error flag when adding a card layout field fails',
	},
});
