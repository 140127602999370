import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { ColorItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import { useRankCardColor } from '../../../services/rank-card-color/index.tsx';
import { useRapidViewId } from '../../selectors/board/index.tsx';
import { useIsOptimisticCardColors, useCardColors } from '../../selectors/colors/index.tsx';
import messages from './messages.tsx';

export const useRankCardColorHandler = () => {
	const { formatMessage } = useIntl();
	const [rapidViewId] = useRapidViewId();

	const { commit: commitRankCardColor } = useRankCardColor(
		rapidViewId,
		formatMessage(messages.failedToRankErrorTitle),
	);

	const [, { beginCardColorRankUpdate, endCardColorRankUpdate, revertCardColorRankUpdate }] =
		useCardColors();

	const [isOptimisticCardColors] = useIsOptimisticCardColors();

	const handleRank = useCallback(
		async (colorItem: ColorItem, afterColorItemId: ColorItem['id'] | null) => {
			if (isOptimisticCardColors) {
				return;
			}

			beginCardColorRankUpdate(colorItem.id, afterColorItemId);

			const result = await commitRankCardColor({
				cardColorId: colorItem.id,
				afterCardColorId: afterColorItemId,
			});

			if (result !== undefined) {
				// ranking succeeded, reset optimistic state now
				endCardColorRankUpdate();
			} else {
				// Some error happened, restore to cardColors to before optimistic update
				revertCardColorRankUpdate();
			}
		},
		[
			isOptimisticCardColors,
			commitRankCardColor,
			beginCardColorRankUpdate,
			endCardColorRankUpdate,
			revertCardColorRankUpdate,
		],
	);
	return handleRank;
};
