import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	boardSettingsTitle: {
		id: 'software-board-settings-rapidboard-config-common.settings-container.header.board-settings-title.non-final',
		defaultMessage: 'Settings for {boardName}',
		description: 'Title for the board settings page, it includes the current board name.',
	},
	backToBoard: {
		id: 'software-board-settings-rapidboard-config-common.settings-container.header.back-to-board.non-final',
		defaultMessage: 'Back to board',
		description: 'Label for the back to board button',
	},
});
