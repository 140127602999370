import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import DefaultProjectAvatar from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/default-project-avatar/index.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ProjectShare } from '@atlassian/jira-shared-types/src/share-permission.tsx';
import { AvatarIllustrationContainer } from '../common/index.tsx';
import messages from './messages.tsx';

type Props = Intl & {
	sharePermission: ProjectShare;
};

export function ProjectName(props: Props) {
	const {
		intl: { formatMessage },
		sharePermission: { project, role },
	} = props;

	return (
		<>
			{!project.avatarUrl ? (
				<AvatarIllustrationContainer>
					<DefaultProjectAvatar alt="" />
				</AvatarIllustrationContainer>
			) : (
				<Avatar appearance="square" src={project.avatarUrl} size="small" />
			)}
			<ProjectNameContainer>
				<ProjectNameWrapper>{project.name}</ProjectNameWrapper>
				<RoleNameWrapper>{`, ${
					(role && role.name) || formatMessage(messages.allRoles)
				}`}</RoleNameWrapper>
			</ProjectNameContainer>
		</>
	);
}

export default injectIntl(ProjectName);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectNameContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RoleNameWrapper = styled.div({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectNameWrapper = styled(RoleNameWrapper)({
	marginLeft: token('space.100', '8px'),
});
