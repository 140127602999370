import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	successFlagForResetingCardColor: {
		id: 'software-board-settings-card-colors.controllers.handlers.use-reset-to-global-color-handler.success-flag-for-reseting-card-color',
		defaultMessage: 'Your card color has been reset',
		description: 'Title of success flag when succesfully reset a card color',
	},
	failedToResetErrorTitle: {
		id: 'software-board-settings-card-colors.controllers.handlers.use-reset-to-global-color-handler.failed-to-reset-error-title',
		defaultMessage: 'We couldn’t reset your card color',
		description: 'Title of error flag when failed to reset a card color',
	},
});
