import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.default-swimlane-name-field.label',
		defaultMessage: 'Name of swimlane for other issues',
		description:
			"Field label for a text field that provides a names for the swimlane which contains issues that weren't categorised into other JQL swimlanes",
	},
	editButtonLabel: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.default-swimlane-name-field.edit-button-label',
		defaultMessage: 'Rename {swimlane} swimlane',
		description:
			"Button label for a button that shows a text field for renaming the swimlane which contains issues that weren't categorised into other JQL swimlanes",
	},
	description: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.default-swimlane-name-field.description',
		defaultMessage: 'All issues that are not grouped by queries will appear in this swimlane.',
		description:
			"Field label for a text field that provides a names for the swimlane which contains issues that weren't categorised into other JQL swimlanes",
	},
	failedToUpdateErrorTitle: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.default-swimlane-name-field.failed-to-update-error-title',
		defaultMessage: "We couldn't save your changes",
		description: 'An error message shown when failing to update the default swimlane title',
	},
	successfullyUpdateDefaultSwimlane: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.default-swimlane-name-field.successfully-update-default-swimlane',
		defaultMessage: 'Your swimlane name has been updated',
		description:
			'A confirmation message shown after successfully updating the default swimlane title',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.default-swimlane-name-field.description-issue-term-refresh',
		defaultMessage: 'All work items that are not grouped by queries will appear in this swimlane.',
		description:
			"Field label for a text field that provides a names for the swimlane which contains issues that weren't categorised into other JQL swimlanes",
	},
	labelIssueTermRefresh: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.default-swimlane-name-field.label-issue-term-refresh',
		defaultMessage: 'Name of swimlane for other work items',
		description:
			"Field label for a text field that provides a names for the swimlane which contains issues that weren't categorised into other JQL swimlanes",
	},
});
