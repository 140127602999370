import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	save: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-modal.save',
		defaultMessage: 'Save',
		description: 'Copy for a CTA a modal that modifies permissions for a filter',
	},
	editFilter: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-modal.edit-filter',
		defaultMessage: 'Edit Filter',
		description: 'Title for a modal that modifies permissions for a filter',
	},
	successFlag: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-modal.success-flag',
		defaultMessage: 'Your board filter has been updated',
		description:
			'The message shown the user when successfully updating their board filters permissions',
	},
});
