import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.query-strategy.empty-view.header',
		defaultMessage: 'Add card color',
		description: 'Empty view header',
	},
	description: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.query-strategy.empty-view.description',
		defaultMessage: 'Use JQL queries to assign colors to cards.',
		description: 'Empty view description',
	},
	buttonText: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.query-strategy.empty-view.button-text',
		defaultMessage: 'Add color',
		description: 'Empty view create swimlanes button',
	},
});
