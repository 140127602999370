import { DEFAULT_LEVEL_ONE_NAME } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { SwimlaneStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';
import type { State } from '../types/index.tsx';

export const INITIAL_STATE: State = {
	isInitialised: false,
	isCMPBoard: false,
	rapidViewId: -1,
	swimlaneStrategy: SwimlaneStrategy.none,
	swimlanesBeforeOptimistic: null,
	canEdit: false,
	swimlanes: [],
	defaultSwimlane: null,
	isOptimisticSwimlanes: false,
	itemToEdit: null,
	itemToDelete: null,
	levelOneName: DEFAULT_LEVEL_ONE_NAME,
};
