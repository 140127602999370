import React, { memo, useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../common/constants/data-test.tsx';
import type { ColorItem } from '../../common/types/index.tsx';
import { ColorField } from '../../common/ui/modal-form/color-field/index.tsx';
import { ModalForm } from '../../common/ui/modal-form/index.tsx';
import { QueryField, type QueryFieldValue } from '../../common/ui/modal-form/query-field/index.tsx';
import messages from './messages.tsx';

export interface EditColorQueryModalProps {
	colorItem: ColorItem;
	title: string;
	description: React.ReactNode;
	onSubmit: (colorQueryItem: ColorItem) => Promise<void>;
	onCancel: () => void;
	analyticsSource: string;
	isSubmitting: boolean;
	queryValidationErrorMessage?: string;
}

type ModalFormValue = {
	color: string;
	name?: string;
	query: QueryFieldValue;
	isUsed: boolean;
	isGlobalColor: boolean;
};

export const EditColorQueryModal = memo(
	({
		onSubmit,
		colorItem,
		analyticsSource,
		queryValidationErrorMessage,
		...props
	}: EditColorQueryModalProps) => {
		const { formatMessage } = useIntl();
		const { id } = colorItem;

		const onSubmitCallback = useCallback(
			({ query: { jql }, ...value }: ModalFormValue) => {
				onSubmit({ ...value, id, query: jql });
			},
			[onSubmit, id],
		);

		const { color, query } = colorItem;

		return (
			<ModalForm<ModalFormValue>
				onSubmit={onSubmitCallback}
				submitButtonLabel={formatMessage(messages.submit)}
				testId={dataTestIds.editModal}
				{...props}
			>
				<ColorField name="color" defaultValue={color} />
				<QueryField
					name="query"
					defaultValue={query}
					analyticsSource={analyticsSource}
					queryValidationErrorMessage={queryValidationErrorMessage}
				/>
			</ModalForm>
		);
	},
);
