import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	prefersChildDatePlanningErrorTitle: {
		id: 'software-board-settings-timeline.services.update-child-issue-scheduling.prefers-child-date-planning-error-title',
		defaultMessage: "We couldn't schedule issues by their dates",
		description:
			'Title for error flag when we fail to update child-level issue scheduling options for the timeline',
	},
	prefersSprintDatesPlanningErrorTitle: {
		id: 'software-board-settings-timeline.services.update-child-issue-scheduling.prefers-sprint-dates-planning-error-title',
		defaultMessage: "We couldn't schedule issues by sprint dates",
		description:
			'Title for error flag when we fail to update sprint-level issue scheduling options for the timeline',
	},
	prefersChildDatePlanningErrorTitleIssueTermRefresh: {
		id: 'software-board-settings-timeline.services.update-child-issue-scheduling.prefers-child-date-planning-error-title-issue-term-refresh',
		defaultMessage: "We couldn't schedule work items by their dates",
		description:
			'Title for error flag when we fail to update child-level issue scheduling options for the timeline',
	},
	prefersSprintDatesPlanningErrorTitleIssueTermRefresh: {
		id: 'software-board-settings-timeline.services.update-child-issue-scheduling.prefers-sprint-dates-planning-error-title-issue-term-refresh',
		defaultMessage: "We couldn't schedule work items by sprint dates",
		description:
			'Title for error flag when we fail to update sprint-level issue scheduling options for the timeline',
	},
});
