import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	failedToDeleteErrorTitle: {
		id: 'software-board-settings-card-colors.controllers.handlers.use-delete-card-color-handler.failed-to-delete-error-title',
		defaultMessage: "We couldn't delete your card color",
		description: 'Title of error flag when failed to delete a card color',
	},
	successFlagForDeletingCardColor: {
		id: 'software-board-settings-card-colors.controllers.handlers.use-delete-card-color-handler.success-flag-for-deleting-card-color',
		defaultMessage: 'Your card color has been deleted',
		description: 'Title of success flag when succesfully delete a card color',
	},
});
