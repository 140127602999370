import React from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const Asterisk = () => <AsteriskWrapper>*</AsteriskWrapper>;

export const QueryModalDescription = ({ description }: { description: string }) => (
	<Text as="p">
		{description} <Asterisk />
	</Text>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AsteriskWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.danger', colors.R500),
});
