import type { User } from '../model/index.tsx';
import type { RestUserResponse, RestUsersResponse } from '../rest/types.tsx';

export const transformUser = ({ accountId, displayName, avatarUrls }: RestUserResponse): User => ({
	accountId,
	value: accountId,
	displayName,
	label: displayName,
	avatarUrl: avatarUrls['24x24'],
});

export const transformUsers = (response: RestUsersResponse): User[] =>
	response.users.map(({ accountId, avatarUrl, displayName }) => ({
		accountId,
		value: accountId,
		displayName,
		label: displayName,
		avatarUrl,
	}));
