import type { State } from '../types/index.tsx';

export const INITIAL_STATE: State = {
	isInitialised: false,
	rapidViewId: -1,
	canEdit: false,
	currentEstimationStatistic: {
		id: '',
	},
	availableEstimationStatistics: [],
	currentTrackingStatistic: {
		id: '',
		isEnabled: false,
	},
	isTimeTrackingEnabledInJira: false,
};
