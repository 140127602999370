import { useMemo } from 'react';
import {
	PUBLIC_SHARE,
	OPEN_SHARE,
	GROUP_SHARE,
	PROJECT_SHARE,
	PROJECT_UNKNOWN_SHARE,
	USER_SHARE,
	type SharePermission,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import { useBoardFilterConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import { unreachable } from '../../../../common/types.tsx';
import type { AccessKind, PermissionEntryWithAccess } from './types.tsx';

const permissionIdentifier = (permission: SharePermission): string | undefined => {
	switch (permission.type) {
		case USER_SHARE:
			return permission.user.accountId;
		case PROJECT_SHARE:
			return permission.project.id;
		case GROUP_SHARE:
			// Fallback to index which won't change
			return permission.group.groupId ?? permission.group.name;

		case PUBLIC_SHARE:
		case OPEN_SHARE:
		case PROJECT_UNKNOWN_SHARE:
			return permission.type;

		/*
		 * In practice this won't happen unless
		 * there's a botched update of the editmodel
		 */
		default:
			return unreachable(permission, undefined);
	}
};

const addAccessToEntry =
	(access: AccessKind) =>
	(permission: SharePermission): PermissionEntryWithAccess | undefined => {
		const identifier = permissionIdentifier(permission);
		if (identifier == null) return undefined;

		const key = `${access}-${permission.type}-${identifier}`;
		return { permission, access, key };
	};

const exists = <T,>(it: T | undefined): it is T => it != null;

export const usePermissions = () => {
	const [filterConfig] = useBoardFilterConfig();

	const permissions = useMemo((): PermissionEntryWithAccess[] => {
		const { editPermissionEntries, sharePermissionEntries, owner } = filterConfig;
		let edits = editPermissionEntries.map(addAccessToEntry('edit'));
		const shares = sharePermissionEntries.map(addAccessToEntry('share'));

		if (owner) {
			const ownerEntry = addAccessToEntry('owner')({
				type: 'user',
				user: {
					avatarUrl: owner.avatarUrl || '',
					accountId: owner.accountId || '',
					displayName: owner.displayName,
				},
			});

			// Add filter owner as the first edit entry and exclude any edit user that is duplicate of filter owner
			edits = [
				ownerEntry,
				...edits.filter(
					(editPermissionEntry) =>
						editPermissionEntry?.permission?.type !== 'user' ||
						editPermissionEntry?.permission?.user?.accountId !== owner.accountId,
				),
			];
		}

		return edits.concat(shares).filter(exists);
	}, [filterConfig]);

	return { permissions };
};
