import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-board-settings-general-sub-filter-settings.board-sub-filter-query.label',
		defaultMessage: 'Sub-filter query',
		description: 'Label for the board sub-filter input.',
	},
	subFilterAriaLabel: {
		id: 'software-board-settings-general-sub-filter-settings.board-sub-filter-query.sub-filter-aria-label',
		defaultMessage: 'Edit sub-filter query, current query {query}',
		description:
			'Aria Label text for the board sub-filter input. {query} will be replace with the real jql query',
	},
	subFilterAriaLabelForEmptyQuery: {
		id: 'software-board-settings-general-sub-filter-settings.board-sub-filter-query.sub-filter-aria-label-for-empty-query',
		defaultMessage: 'Edit sub-filter query, there is currently no query',
		description: 'Aria Label text for the board sub-filter input when the sub query is empty.',
	},
	successFlag: {
		id: 'software-board-settings-general-sub-filter-settings.board-sub-filter-query.success-flag',
		defaultMessage: 'Your sub-filter has been updated',
		description: 'The message shown the user when successfully updating their board sub-filter',
	},
});
