import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, SaveFilter } from '../../../common/types/index.tsx';

export const updateSavedFilters =
	(savedFilters: SaveFilter[]) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const currentState = getState();
		setState({
			...currentState,
			savedFilters,
		});
	};

export type UpdateSavedFilters = typeof updateSavedFilters;
