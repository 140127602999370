import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'software-board-settings-query-list-config-common.common.modal-form.description-field.description',
		defaultMessage: 'Description',
		description: 'Label for a text field for entering a description',
	},
	descriptionLengthValidation: {
		id: 'software-board-settings-query-list-config-common.common.modal-form.description-field.description-length-validation',
		defaultMessage: 'Enter a description with less than 255 characters.',
		description:
			'An error that is shown when the user has provided a description with more than 255 characters.',
	},
});
