import React, { type PropsWithChildren } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import SettingsContainerContent from './main.tsx';

const SETTINGS_CONTAINER_ID = 'boardSettingsContainer';

type ContainerProps = {
	packageId: string;
};

const SettingsContainer = ({ children, packageId }: PropsWithChildren<ContainerProps>) => (
	<JSErrorBoundary id={SETTINGS_CONTAINER_ID} packageName={packageId} teamName="a4t-pandora">
		<SettingsContainerContent>{children}</SettingsContainerContent>
	</JSErrorBoundary>
);

export default SettingsContainer;
