import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	groupPermission: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.group-permission',
		defaultMessage: 'Group {groupName}',
		description: 'Text identifying a share permission targeted at the group level',
	},
	projectPermission: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.project-permission',
		defaultMessage: 'Project {projectName}',
		description: 'Text identifying a share permission targeted at the project level',
	},
	projectWithRolePermission: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.project-with-role-permission',
		defaultMessage: 'Project {projectName}, {projectRole}',
		description: 'Text identifying a share permission targeted at the project level',
	},
	userPermission: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.user-permission',
		defaultMessage: 'User {displayName}',
		description: 'Text identifying a share permission targeted at the user level',
	},
	allRolesPermission: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.all-roles-permission',
		defaultMessage: 'All roles',
		description:
			'When the role comes empty, that means that access is given to every role for that project',
	},
	publicPermission: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.public-permission',
		defaultMessage: 'Public',
		description:
			'Type picker option Public, if the user picks this type, they will share the entity with everybody, even anonymous users',
	},
	unknownProjectPermission: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.unknown-project-permission',
		defaultMessage: 'Unknown project',
		description: 'UI placeholder for projects that the user does not have permissions to view',
	},
	privatePermission: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.private-permission',
		defaultMessage: 'Private',
		description:
			"Type picker option private, if the user picks this type, they won't share the entity",
	},
	myOrganizationPermission: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.my-organization-permission',
		defaultMessage: 'My organization',
		description:
			'Type picker option Open, if the user picks this type, they will share the entity with everybody in the Jira Instance',
	},
	ownerOnlyText: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.owner-only-text',
		defaultMessage: '(Owner)',
		description: 'This is the text for displaying the owner of a filter',
	},
	ownerAndProfileText: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.owner-and-profile-text',
		defaultMessage: '(Owner, <button>view profile</button>)',
		description:
			'This is the text for displaying the owner of a filter with the link button to view the owner profile',
	},
	viewProfileLinkAriaLabel: {
		id: 'software-board-settings-general-filter-settings.board-filter-details.filter-permissions.permission-table.share-identity-column.view-profile-link-aria-label',
		defaultMessage: "View owner's profile",
		description: 'This is the aria label for the view profile link to view filter owner profile',
	},
});
