import React, { useCallback } from 'react';
import { ErrorMessage, Field } from '@atlaskit/form';
import { useIntl } from '@atlassian/jira-intl';
import { ColorPaletteMenu } from '@atlassian/jira-software-board-settings-color-picker/src/ui/color-picker/color-palette-menu/index.tsx';
import { dataTestIds } from '../../../constants/data-test.tsx';
import messages from './messages.tsx';

type ColorFieldProps = {
	name: string;
	defaultValue?: string;
};

export const ColorField = (props: ColorFieldProps) => {
	const { formatMessage } = useIntl();
	const validate = useCallback(
		(value: string | undefined) => {
			if (!value) {
				return formatMessage(messages.requiredError);
			}
			return undefined;
		},
		[formatMessage],
	);

	return (
		<Field
			{...props}
			validate={validate}
			label={formatMessage(messages.color)}
			isRequired
			testId={dataTestIds.modalColorField}
		>
			{({ fieldProps, error, valid }) => (
				<>
					<ColorPaletteMenu
						label={formatMessage(messages.changeColorLabel)}
						onChange={(value) => fieldProps.onChange(value)}
						selectedColor={fieldProps.value || props.defaultValue}
					/>
					{error && !valid && <ErrorMessage>{error}</ErrorMessage>}
				</>
			)}
		</Field>
	);
};
