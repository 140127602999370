import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editLabel: {
		id: 'software-board-settings-query-list-config-common.query-list.actions-menu.edit-label',
		defaultMessage: 'Edit',
		description: 'Dropdown edit label',
	},
	deleteLabel: {
		id: 'software-board-settings-query-list-config-common.query-list.actions-menu.delete-label',
		defaultMessage: 'Delete',
		description: 'Dropdown delete label',
	},
	moreActionsForNameLabel: {
		id: 'software-board-settings-query-list-config-common.query-list.actions-menu.more-actions-for-name-label',
		defaultMessage: 'More actions for {name}',
		description: 'Label for more action button of a given name',
	},
});
