import React, { memo, useState, useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../common/constants/data-test.tsx';
import type { QueryItem } from '../../common/types/index.tsx';
import { DescriptionField } from '../../common/ui/modal-form/description-field/index.tsx';
import { ModalForm } from '../../common/ui/modal-form/index.tsx';
import { NameField } from '../../common/ui/modal-form/name-field/index.tsx';
import { QueryField, type QueryFieldValue } from '../../common/ui/modal-form/query-field/index.tsx';
import messages from './messages.tsx';

export interface CreateQueryModalProps {
	title: string;
	description: React.ReactNode;
	onSubmit: (newQueryItem: Omit<QueryItem, 'id'>) => Promise<void>;
	onCancel: () => void;
	isDisabled?: boolean;
	analyticsSource: string;
	queryValidationErrorMessage?: string;
}

type ModalFormValue = {
	name: string;
	query: QueryFieldValue;
	description: string;
};

export const CreateQueryModal = memo(
	({ analyticsSource, onSubmit, queryValidationErrorMessage, ...props }: CreateQueryModalProps) => {
		const { formatMessage } = useIntl();
		const [isSubmitting, setIsSubmitting] = useState(false);

		const onSubmitImpl = useCallback(
			async ({ query: { jql }, ...value }: ModalFormValue) => {
				setIsSubmitting(true);
				await onSubmit({ ...value, query: jql });
				setIsSubmitting(false);
			},
			[onSubmit],
		);

		return (
			<ModalForm<ModalFormValue>
				{...props}
				isSubmitting={isSubmitting}
				submitButtonLabel={formatMessage(messages.submit)}
				onSubmit={onSubmitImpl}
				testId={dataTestIds.createModal}
			>
				<NameField name="name" />
				<QueryField
					name="query"
					analyticsSource={analyticsSource}
					queryValidationErrorMessage={queryValidationErrorMessage}
				/>
				<DescriptionField name="description" />
			</ModalForm>
		);
	},
);
