import type { User as RestUser } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import {
	PUBLIC_SHARE,
	OPEN_SHARE,
	GROUP_SHARE,
	PROJECT_SHARE,
	PROJECT_UNKNOWN_SHARE,
	USER_SHARE,
	type SharePermission,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import type { User as TransformedUser } from '../model/index.tsx';
import type {
	ProjectSharePermissionBody,
	SharePermissionRequestBody,
	RestSharePermission,
} from '../rest/types.tsx';
import { OPEN_SHARE_SERVER, PROJECT_ROLE_SHARE_SERVER } from './constants.tsx';

const assertUnreachable = (_x: never): never => {
	throw new Error("Didn't expect to get here");
};

export const transformUser = (baseUrl: string, user: RestUser): TransformedUser => ({
	displayName: user.displayName,
	avatarUrl: user.avatarUrls && user.avatarUrls['48x48'],
	accountId: user.accountId,
	profileUrl: `/jira/people/${user.accountId}`,
});

export const transformSharePermission = (
	sharePermission: RestSharePermission,
): SharePermission | null => {
	switch (sharePermission.type) {
		case PUBLIC_SHARE:
			return { type: PUBLIC_SHARE };
		case OPEN_SHARE:
			return { type: OPEN_SHARE };
		case GROUP_SHARE:
			return { type: GROUP_SHARE, group: { ...sharePermission.group } };
		case PROJECT_SHARE: {
			const { project, role } = sharePermission;

			return {
				type: PROJECT_SHARE,
				project: {
					id: project.id,
					name: project.name,
					avatarUrl: project.avatarUrls['48x48'],
				},
				role: role && { id: role.id, name: role.name },
			};
		}
		case PROJECT_UNKNOWN_SHARE:
			return sharePermission;
		case USER_SHARE: {
			const { user } = sharePermission;

			return {
				type: USER_SHARE,
				user: {
					accountId: user.accountId,
					displayName: user.displayName,
					avatarUrl: user.avatarUrls['48x48'],
				},
			};
		}
		default:
			assertUnreachable(sharePermission);
			return null;
	}
};

export const transformSharePermissionsToServer = (
	sharePermissions: SharePermission[],
	shouldReturnGroupIdForGroupIfAvailable = false,
): SharePermissionRequestBody[] =>
	sharePermissions.map((sharePermission) => {
		switch (sharePermission.type) {
			case GROUP_SHARE: {
				let group: { name?: string; groupId?: string } = {
					name: sharePermission.group.name,
				};
				if (shouldReturnGroupIdForGroupIfAvailable && sharePermission.group.groupId) {
					group = { groupId: sharePermission.group.groupId };
				}
				return {
					type: sharePermission.type,
					group,
				};
			}
			case USER_SHARE:
				return {
					type: sharePermission.type,
					user: {
						accountId: sharePermission.user.accountId,
					},
				};
			case PROJECT_SHARE: {
				const permission: ProjectSharePermissionBody = {
					type: sharePermission.role ? PROJECT_ROLE_SHARE_SERVER : PROJECT_SHARE,
					project: {
						id: sharePermission.project.id,
					},
				};

				if (sharePermission.role)
					permission.role = {
						id: sharePermission.role.id,
					};

				return permission;
			}
			case PROJECT_UNKNOWN_SHARE:
				return sharePermission;
			case OPEN_SHARE:
				return {
					type: OPEN_SHARE_SERVER,
				};
			case PUBLIC_SHARE:
				return sharePermission;
			default:
				assertUnreachable(sharePermission);
				return sharePermission;
		}
	});
