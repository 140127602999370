import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { QueryItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import { useRankSwimlane } from '../../../services/rank-swimlane/index.tsx';
import { useRapidViewId } from '../../selectors/board/index.tsx';
import {
	useSwimlanesWithoutDefault,
	useIsOptimisticSwimlanes,
} from '../../selectors/swimlanes/index.tsx';
import messages from './messages.tsx';

export const useRankSwimlaneHandler = () => {
	const [rapidViewId] = useRapidViewId();
	const { formatMessage } = useIntl();
	const { commit: commitRankSwimlane } = useRankSwimlane(
		rapidViewId,
		formatMessage(messages.failedToRankErrorTitle),
	);

	const [, { beginSwimlaneRankUpdate, endSwimlaneRankUpdate, revertSwimlaneRankUpdate }] =
		useSwimlanesWithoutDefault();

	const [isOptimisticSwimlanes] = useIsOptimisticSwimlanes();

	const handleRank = useCallback(
		async (queryItem: QueryItem, afterQueryItemId: QueryItem['id'] | null) => {
			if (isOptimisticSwimlanes) {
				return;
			}

			beginSwimlaneRankUpdate(queryItem.id, afterQueryItemId);

			const result = await commitRankSwimlane({
				swimlaneId: queryItem.id,
				afterSwimlaneId: afterQueryItemId,
			});

			if (result !== undefined) {
				// ranking succeeded, reset optimistic state now
				endSwimlaneRankUpdate();
			} else {
				// Some error happened, restore to swimlanes before optimistic update
				revertSwimlaneRankUpdate();
			}
		},
		[
			isOptimisticSwimlanes,
			commitRankSwimlane,
			beginSwimlaneRankUpdate,
			endSwimlaneRankUpdate,
			revertSwimlaneRankUpdate,
		],
	);
	return handleRank;
};
