import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	disableTimelineFailureTitle: {
		id: 'software-board-settings-timeline.services.disable-timeline.disable-timeline-failure-title',
		defaultMessage: "We couldn't turn off your timeline",
		description:
			'Title for the error flag which appears when the API call to disable the timeline fails',
	},
});
