import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	successFlagForUpdatingStrategy: {
		id: 'software-board-settings-swimlanes.controllers.handlers.use-swimlanes-strategy-transition.success-flag-for-updating-strategy',
		defaultMessage: 'Your changes were saved',
		description: 'Success flag title after successfully update the swimlane strategy method.',
	},
	failedToUpdateSwimlaneStrategy: {
		id: 'software-board-settings-swimlanes.controllers.handlers.use-swimlanes-strategy-transition.failed-to-update-swimlane-strategy',
		defaultMessage: "We couldn't update your swimlane grouping method ",
		description:
			'Title of error flag when the server fails to update the users method for grouping their swimlanes',
	},
});
