import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import { DynamicTable } from '../../../../common/ui/dynamic-table/index.tsx';
import messages from './messages.tsx';
import { ShareIdentityColumn } from './share-identity-column/index.tsx';
import type { AccessKind } from './types.tsx';
import { usePermissions } from './utils.tsx';

export const PermissionColumn = ({ access }: { access: AccessKind }) => {
	const message = ['edit', 'owner'].includes(access)
		? messages.permissionEditor
		: messages.permissionViewer;
	return <FormattedMessage {...message} />;
};

export const PermissionTable = () => {
	const { permissions } = usePermissions();

	const head = useMemo(
		() => ({
			cells: [
				{
					key: 'users',
					content: (
						<InitialHeader>
							<FormattedMessage {...messages.userColumn} />
						</InitialHeader>
					),
				},
				{
					key: 'permissions',
					content: <FormattedMessage {...messages.permissionColumn} />,
				},
			],
		}),
		[],
	);

	const rows = useMemo(
		() =>
			permissions.map(({ key, access, permission }) => ({
				key,
				cells: [
					{
						key: `${key}-name`,
						content: <ShareIdentityColumn permission={permission} />,
					},
					{
						key: `${key}-access`,
						content: <PermissionColumn access={access} />,
					},
				],
			})),
		[permissions],
	);

	return <DynamicTable rows={rows} head={head} width="fill" />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InitialHeader = styled.span({
	paddingLeft: token('space.100', '8px'),
});
