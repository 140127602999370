import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unknownProjectWarning: {
		id: 'shareable-entity-dialog.access.sharee-list.sharee-list-row.unknown-project.unknown-project-warning',
		defaultMessage: 'You do not have access to this project. Saving will remove this share.',
		description:
			'Text explaining the "unkown project" share entry and warning that saving it could lose data.',
	},
});
