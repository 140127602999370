import React from 'react';
import { type Intl, toAccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import ShareePicker, { type Props as ViewProps } from './view.tsx';

type Props = Flow.Diff<
	ViewProps,
	{
		accountId: ViewProps['accountId'];
		isAdmin: ViewProps['isAdmin'];
	} & Intl
>;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<TenantContextSubscriber>
		{({ tenantContext: { atlassianAccountId, isAdmin } }) =>
			atlassianAccountId != null && (
				<ShareePicker accountId={toAccountId(atlassianAccountId)} isAdmin={isAdmin} {...props} />
			)
		}
	</TenantContextSubscriber>
);
