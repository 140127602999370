import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { Stack, xcss } from '@atlaskit/primitives';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { InProductHelpButton } from '@atlassian/jira-insights-common/src/common/ui/in-product-help-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import {
	BOARD_SETTINGS_ARTICLE_ID_GENERAL_BOARD_SUBFILTER_JIRA_SOFTWARE,
	BOARD_SETTINGS_ARTICLE_ID_GENERAL_BOARD_SUBFILTER_ANALYTICS_ID,
} from '../../common/constants/in-product-help.tsx';
import messages from './messages.tsx';

export const BoardSubFilterSection = () => {
	const { formatMessage } = useIntl();
	const isJSMBoard = useIsJSMBoard();

	return (
		<Stack xcss={containerStyles}>
			<Heading size="small" as="h3">
				{formatMessage(messages.title)}
			</Heading>
			<About>
				{isJSMBoard
					? formatMessage(messages.aboutJsm)
					: formatMessage(messages.aboutJsw, {
							button: (chunks: ReactNode[]) => (
								<InProductHelpButton
									articleId={BOARD_SETTINGS_ARTICLE_ID_GENERAL_BOARD_SUBFILTER_JIRA_SOFTWARE}
									articleAnalyticsId={
										BOARD_SETTINGS_ARTICLE_ID_GENERAL_BOARD_SUBFILTER_ANALYTICS_ID
									}
									appearance="link"
								>
									{chunks}
								</InProductHelpButton>
							),
						})}
			</About>
		</Stack>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AboutOld = styled.p({
	marginTop: '0',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AboutNew = styled.p({
	marginTop: '0',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& a': {
		textDecoration: 'underline',
	},
});

const About = componentWithCondition(() => fg('jira-a11y-links-contrast-fix'), AboutNew, AboutOld);

const containerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	rowGap: 'space.150',
});
