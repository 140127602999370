import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useQueryValidationErrorMessage } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-query-validation-error-message/index.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	UPDATE_CARD_COLOR_ACTION_SUBJECT,
	UPDATE_CARD_COLOR_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { CARD_COLOR_API_URI } from '../../common/constants/url.tsx';
import messages from './messages.tsx';
import type { EditCardColorResponse, EditCardColorInput } from './types.tsx';

export const useEditCardColor = (rapidViewId: number): EditCardColorResponse => {
	const { formatMessage } = useIntl();

	const editCardColor = useCallback(
		(cardColor: EditCardColorInput) =>
			performPutRequest(`${CARD_COLOR_API_URI}/${rapidViewId}/color/${cardColor.id}`, {
				body: JSON.stringify(cardColor),
			}),
		[rapidViewId],
	);

	const {
		data,
		loading,
		error,
		hasError,
		resetError,
		fetch: commit,
	} = useServiceWithAnalytics<EditCardColorResponse['data'], EditCardColorInput>(
		editCardColor,
		{},
		{ actionSubject: UPDATE_CARD_COLOR_ACTION_SUBJECT, packageName: APP_CMP_METRIC_KEY },
		{ browserMetricsKey: `${APP_CMP_METRIC_KEY}.${UPDATE_CARD_COLOR_BROWSER_METRICS_KEY}` },
	);

	const queryValidationErrorMessage = useQueryValidationErrorMessage(error);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError && !queryValidationErrorMessage,
		errorTitle: formatMessage(messages.failedToEditErrorTitle),
		errorMessage: formatMessage(messages.failedToEditErrorDescription),
		error,
	});

	return { data, loading, commit, error, hasError, resetError, queryValidationErrorMessage };
};
