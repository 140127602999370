import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.empty-view.header',
		defaultMessage: 'Add swimlanes to your board',
		description: 'Empty view header',
	},
	description: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.empty-view.description',
		defaultMessage: 'Use JQL queries to create swimlanes.',
		description: 'Empty view description',
	},
	buttonText: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.empty-view.button-text',
		defaultMessage: 'Create swimlane',
		description: 'Empty view create swimlanes button',
	},
});
