import { useCallback } from 'react';
import { useFetchCardLayoutConfig } from '../../../services/fetch-card-layout-config/index.tsx';
import { useRapidViewId } from '../../selectors/board/index.tsx';
import { useCurrentFields } from '../../selectors/card-layout/index.tsx';

export const useFetchCardLayoutConfigHandler = () => {
	const [rapidViewId] = useRapidViewId();
	const [, { setCardLayoutFields }] = useCurrentFields();

	const { loading, commit: fetchCardLayoutConfig } = useFetchCardLayoutConfig(rapidViewId);

	const handleFetchCardLayoutConfig = useCallback(async () => {
		const result = await fetchCardLayoutConfig();
		if (result && result.cardLayoutConfig) {
			const { currentFields, availableFields } = result.cardLayoutConfig;
			setCardLayoutFields(currentFields, availableFields);
		}
	}, [fetchCardLayoutConfig, setCardLayoutFields]);

	return { loading, handleFetchCardLayoutConfig };
};
