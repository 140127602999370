import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useServiceDeskAnalyticsContext } from '@atlassian/jira-router-resources-service-desk-analytics-context/src/services/index.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	UPDATE_SWIMLANE_STRATEGY_ACTION_SUBJECT,
	UPDATE_SWIMLANE_STRATEGY_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { UPDATE_SWIMLANE_STRATEGY_API_URI } from '../../common/constants/url.tsx';
import type { UpdateSwimlaneStrategyInput, UpdateSwimlaneStrategyResponse } from './types.tsx';

export const useUpdateSwimlaneStrategy = (
	rapidViewId: number,
	errorTitle: string,
): UpdateSwimlaneStrategyResponse => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data: analyticsContextAttributes } = useServiceDeskAnalyticsContext();
	const updateSwimlaneStrategy = useCallback(
		({ swimlaneStrategy }: UpdateSwimlaneStrategyInput) => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'updateSwimlaneStrategy success', {
				...analyticsContextAttributes,
				method: swimlaneStrategy,
			});
			return performPutRequest(UPDATE_SWIMLANE_STRATEGY_API_URI, {
				body: JSON.stringify({
					id: rapidViewId,
					swimlaneStrategyId: swimlaneStrategy,
				}),
			});
		},
		[analyticsContextAttributes, createAnalyticsEvent, rapidViewId],
	);

	const {
		loading,
		error,
		hasError,
		fetch: commit,
		resetError,
	} = useServiceWithAnalytics<UpdateSwimlaneStrategyResponse['data'], UpdateSwimlaneStrategyInput>(
		updateSwimlaneStrategy,
		{},
		{
			actionSubject: UPDATE_SWIMLANE_STRATEGY_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${UPDATE_SWIMLANE_STRATEGY_BROWSER_METRICS_KEY}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		handleValidationError: true,
		error,
		errorTitle,
	});

	return {
		commit,
		resetError,
		loading,
		error,
		hasError,
	};
};
