import React from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { Stack, Inline } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { useFireAnalyticsTrackEvent } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-fire-analytics-event/index.tsx';
import { dataTestIds } from '../../../common/constants/data-test.tsx';
import { useUpdateShowDaysInColumnHandler } from '../../../controllers/handlers/update-show-days-in-column-handler/index.tsx';
import { useShowDaysInColumn } from '../../../controllers/selectors/card-layout/index.tsx';
import messages from './messages.tsx';

export type ShowDaysInColumnProps = {
	isReadOnly: boolean;
};

export const ShowDaysInColumn = ({ isReadOnly }: ShowDaysInColumnProps) => {
	const { formatMessage } = useIntl();
	const [showDaysInColumn] = useShowDaysInColumn();
	const { fireTrackEvent } = useFireAnalyticsTrackEvent();

	const { handleShowDaysInColumnUpdate } = useUpdateShowDaysInColumnHandler();
	const showDaysInColumnOnChange = () => {
		handleShowDaysInColumnUpdate();
		fireTrackEvent('boardCardLayout daysInColumn', { showDaysInColumn: !showDaysInColumn });
	};

	return (
		<Stack space="space.100">
			<Inline space="space.100" alignBlock="center" alignInline="start">
				<Heading as="h3" size="small">
					{formatMessage(messages.title)}
				</Heading>
				<Toggle
					testId={dataTestIds.showDaysInColumnToggle}
					label={formatMessage(messages.fieldLabel)}
					isChecked={showDaysInColumn}
					isDisabled={isReadOnly}
					onChange={showDaysInColumnOnChange}
				/>
			</Inline>
			<Description>
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.descriptionIssueTermRefresh
						: messages.description,
				)}
			</Description>
		</Stack>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.p({
	margin: 0,
});
