import { useCallback, useState } from 'react';
import type {
	CurrentField,
	FieldMode,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import { tick, reorderField } from '../../../common/utils/index.tsx';
import { useRankCardLayoutField } from '../../../services/rank-card-layout-field/index.tsx';
import { useRapidViewId } from '../../selectors/board/index.tsx';

export const useRankCardLayoutFieldHandler = (
	currentFields: CurrentField[],
	fieldMode: FieldMode,
) => {
	const [rapidViewId, { rankCardLayoutField }] = useRapidViewId();
	const [pendingFields, setPendingFields] = useState<CurrentField[] | undefined>();

	const { commit } = useRankCardLayoutField(rapidViewId, fieldMode);

	const optimisticFields = pendingFields ?? currentFields;
	const rankCurrentField = useCallback(
		async (itemIndex: number, afterItemIndex: number | null) => {
			const src = optimisticFields[itemIndex];
			const dst = afterItemIndex != null ? optimisticFields[afterItemIndex] : undefined;
			const next = reorderField(optimisticFields, src, dst);

			setPendingFields(next);

			const result = await commit({ id: src.id, mode: src.mode, after: dst?.id });

			if (result.ok) {
				rankCardLayoutField(src, dst);
			}

			// to avoid flicker before rank card layout field runs.
			await tick();
			// if pending doesn't match our update it means there's another
			// update happening in the background
			setPendingFields((pending) => (pending === next ? undefined : pending));
		},
		[commit, optimisticFields, rankCardLayoutField],
	);

	return {
		optimisticFields,
		rankCurrentField,
	};
};
