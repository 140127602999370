import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import { Field } from '@atlaskit/form';
import Select, { type InputActionMeta } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEdit } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/permissions/index.tsx';
import messages from './messages.tsx';
import { useAvailableSavedFilters, useCurrentBoardFilterOption } from './utils.tsx';

export const BoardFilterField = () => {
	const canEdit = useCanEdit();
	const { formatMessage } = useIntl();
	const { value, requestUpdate } = useCurrentBoardFilterOption();
	const { options, loading, fetchAvailableSavedFilters } = useAvailableSavedFilters();

	const searchAvailableSavedFilters = useCallback(
		(textInput: string, actionMeta: InputActionMeta) => {
			if (actionMeta.action === 'input-change') {
				fetchAvailableSavedFilters({ query: textInput });
			}
		},
		[fetchAvailableSavedFilters],
	);

	return (
		<Container data-testid="software-board-settings-general-filter-settings.ui.board-filter-section.board-filter-field.select">
			<Field
				name="board-filter"
				label={formatMessage(messages.label)}
				isDisabled={!canEdit}
				isRequired
			>
				{({ fieldProps: { id, ...props } }) => (
					<Select
						{...props}
						inputId={id}
						value={value}
						options={options}
						onMenuOpen={() => fetchAvailableSavedFilters({ query: '' })}
						onChange={requestUpdate}
						isLoading={loading}
						onInputChange={debounce(searchAvailableSavedFilters, 200)}
					/>
				)}
			</Field>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	width: '352px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		marginTop: '0',
	},
});
