import React, { type ReactNode, useEffect, useMemo } from 'react';
import { Stack } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { InProductHelpButton } from '@atlassian/jira-insights-common/src/common/ui/in-product-help-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { BoardSettingsCardLayoutSkeleton } from '@atlassian/jira-skeletons/src/ui/board-settings/BoardSettingsCardLayoutSkeleton.tsx';
import { boardSettingsQueryConfigParamValues } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { useIsBoardSettingsTabOpen } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-board-settings-tab-open/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { useOnce } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-once/index.tsx';
import { ConfigDisabledSection } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/config-disabled-section/index.tsx';
import { PageHeader } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/page-header/index.tsx';
import {
	JIRA_SERVICEDESK_BOARD_SETTINGS_ARTICLE_ID_CARD_LAYOUT,
	JIRA_SOFTWARE_BOARD_SETTINGS_ARTICLE_ID_CARD_LAYOUT,
	BOARD_SETTINGS_ARTICLE_ID_CARD_LAYOUT_ANALYTICS_ID,
} from '../../common/constants/card-layout.tsx';
import { useFetchCardLayoutConfigHandler } from '../../controllers/handlers/fetch-card-layout-config-handler/index.tsx';
import { useIsInitialised } from '../../controllers/selectors/board/index.tsx';
import { useCanEdit } from '../../controllers/selectors/permissions/index.tsx';
import { useViewCardLayoutSettingsExperienceTracker } from '../../services/experience-tracker/index.tsx';
import { AddField } from './card-layout-table/add-field/index.tsx';
import { CardLayoutTable } from './card-layout-table/index.tsx';
import messages from './messages.tsx';
import { ShowDaysInColumn } from './show-days-in-column/index.tsx';
import { useCardLayoutTableConfig } from './utils.tsx';

export const CardLayoutSettingsContainer = () => {
	const { formatMessage } = useIntl();

	const isJSMBoard = useIsJSMBoard();

	const [canEdit] = useCanEdit();
	const [isInitialised] = useIsInitialised();

	const { fireExperienceSuccess } = useViewCardLayoutSettingsExperienceTracker({
		isClassic: true,
	});
	useEffect(() => {
		if (isInitialised) {
			// State initialisation is now finished so we mark view experience as success
			fireExperienceSuccess({ isClassic: true });
		}
	}, [isInitialised, fireExperienceSuccess]);

	const isCardLayoutTabOpen = useIsBoardSettingsTabOpen(
		boardSettingsQueryConfigParamValues.cardLayout,
	);

	// remove JSM check when they move to consume gql
	const isRelayConsumptionEnabled = useMemo(() => {
		if (!isJSMBoard && fg('simplified-boards-milestone-2_07hbk')) {
			return fg('board-settings-graphql-refactor_cyvz9');
		}

		return false;
	}, [isJSMBoard]);

	const { handleFetchCardLayoutConfig, loading } = useFetchCardLayoutConfigHandler();
	useOnce(
		() => handleFetchCardLayoutConfig(),
		() => isCardLayoutTabOpen && !isRelayConsumptionEnabled,
	);

	const tableConfig = useCardLayoutTableConfig();

	if (!isCardLayoutTabOpen) {
		return null;
	}

	// Loading skeleton should be deferred to the entrypoint when Relay suspends
	if (loading && !isRelayConsumptionEnabled) {
		return <BoardSettingsCardLayoutSkeleton />;
	}

	return (
		<Stack space="space.400">
			<PageHeader
				title={formatMessage(messages.title)}
				description={
					<>
						{formatMessage(messages.description, {
							button: (chunks: ReactNode[]) => (
								<InProductHelpButton
									articleId={
										isJSMBoard
											? JIRA_SERVICEDESK_BOARD_SETTINGS_ARTICLE_ID_CARD_LAYOUT
											: JIRA_SOFTWARE_BOARD_SETTINGS_ARTICLE_ID_CARD_LAYOUT
									}
									articleAnalyticsId={BOARD_SETTINGS_ARTICLE_ID_CARD_LAYOUT_ANALYTICS_ID}
									appearance="link"
								>
									{chunks}
								</InProductHelpButton>
							),
						})}
					</>
				}
			/>
			{!canEdit && <ConfigDisabledSection />}
			{tableConfig.map(({ title, testId, fields, fieldMode }, index) => (
				<CardLayoutTable
					key={`${title}-${index}`}
					testId={testId}
					title={title}
					fields={fields}
					fieldMode={fieldMode}
					addField={<AddField fieldMode={fieldMode} />}
				/>
			))}
			<ShowDaysInColumn isReadOnly={!canEdit} />
		</Stack>
	);
};
