import React from 'react';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../common/constants/data-test.tsx';
import type { DeleteQueryConfirmationModalProps } from '../../common/types/index.tsx';
import messages from './messages.tsx';

export const DeleteQueryConfirmationModal = ({
	title,
	description,
	onSubmit,
	onCancel,
	isSubmitting,
	deleteButtonName,
}: DeleteQueryConfirmationModalProps) => {
	const { formatMessage } = useIntl();

	return (
		<Modal
			shouldReturnFocus
			onClose={onCancel}
			shouldCloseOnOverlayClick={!isSubmitting}
			shouldCloseOnEscapePress={!isSubmitting}
			testId={dataTestIds.deleteModal}
		>
			<ModalHeader>
				<ModalTitle appearance="warning">{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>{description}</ModalBody>
			<ModalFooter>
				<ButtonGroup>
					<Button
						onClick={onCancel}
						appearance="subtle"
						isDisabled={isSubmitting}
						testId="software-board-settings-query-list-config-common.ui.delete-query-confirmation-modal.cancel"
					>
						{formatMessage(messages.cancelButton)}
					</Button>
					<Button
						onClick={onSubmit}
						appearance="warning"
						isLoading={isSubmitting}
						testId="software-board-settings-query-list-config-common.ui.delete-query-confirmation-modal.submit"
					>
						{deleteButtonName ?? formatMessage(messages.deleteButton)}
					</Button>
				</ButtonGroup>
			</ModalFooter>
		</Modal>
	);
};
