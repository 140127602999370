import {
	addCardColor,
	beginCardColorRankUpdate,
	deleteCardColor,
	endCardColorRankUpdate,
	revertCardColorRankUpdate,
	setCardColors,
	setCardColorStrategy,
	editCardColor,
	setItemToEdit,
	setNextCardColorStrategy,
} from './colors/index.tsx';
import { initState } from './init/index.tsx';
import type { Actions } from './types.tsx';

export const actions: Actions = {
	addCardColor,
	beginCardColorRankUpdate,
	deleteCardColor,
	endCardColorRankUpdate,
	initState,
	revertCardColorRankUpdate,
	setCardColors,
	setCardColorStrategy,
	setNextCardColorStrategy,
	editCardColor,
	setItemToEdit,
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Actions } from './types';
