import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	nameHeader: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.card-layout-table.name-header',
		defaultMessage: 'Name',
		description: 'Header for the name column in the card fields table',
	},
	actionsHeader: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.card-layout-table.actions-header',
		defaultMessage: 'Actions',
		description: 'Header for the actions column in the card fields table',
	},
	cardFieldLimit: {
		id: 'software-board-settings-card-layout.card-layout-settings-page.card-layout-table.card-field-limit',
		defaultMessage: 'Add up to three fields.',
		description:
			'A small piece of text that mentions to the user the maximum number of allowed fields they can add to the card layout.',
	},
});
