import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleteCardLayoutFieldFailureTitle: {
		id: 'software-board-settings-card-layout.services.delete-card-layout-field.delete-card-layout-field-failure-title',
		defaultMessage: "We couldn't remove the {fieldName} field",
		description: 'Title for flag shown when a field cannot be deleted from card layout',
	},
	deleteCardLayoutFieldFailureDescription: {
		id: 'software-board-settings-card-layout.services.delete-card-layout-field.delete-card-layout-field-failure-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description for flag shown when a field cannot be deleted from card layout',
	},
});
