import React, { useCallback, useMemo, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import CheckIcon from '@atlaskit/icon/utility/migration/check-mark--check';
import Link from '@atlaskit/link';
import { Box, Flex, Inline, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { InProductHelpButton } from '@atlassian/jira-insights-common/src/common/ui/in-product-help-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointButtonTrigger } from '@atlassian/jira-modal-entry-point-button-trigger/src/ModalEntryPointButtonTrigger.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SERVICEDESK,
	LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SERVICEDESK_ANALYTICS_ID,
	LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SOFTWARE,
	LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SOFTWARE_ANALYTICS_ID,
	LEARN_MORE_ABOUT_ISSUE_RANKING_SAC_ARTICLE_JIRA_SERVICEDESK,
	LEARN_MORE_ABOUT_ISSUE_RANKING_SAC_ARTICLE_JIRA_SOFTWARE,
} from '../../../common/constants/in-product-help.tsx';
import {
	FILTER_QUERY_DETAILS_TABLE_WIDTH,
	ISSUE_RANKING_MODAL_TEST_ID,
} from '../../../common/constants/index.tsx';
import { FilterDetailsHeader } from '../../../common/ui/filter-details-header/index.tsx';
import { issueRankingConfirmationEntryPoint } from './confirmation-modal/entrypoint.tsx';
import messages from './messages.tsx';
import { useBoardIssueRanking } from './utils.tsx';

const IssueRanking = () => {
	const { filterQueryId, issueRank, canEdit, onUpdate } = useBoardIssueRanking();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const isJSMBoard = useIsJSMBoard();

	const articleId = isJSMBoard
		? LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SERVICEDESK
		: LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SOFTWARE;
	const articleAnalyticsId = isJSMBoard
		? LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SERVICEDESK_ANALYTICS_ID
		: LEARN_MORE_ABOUT_ISSUE_RANKING_IPH_ARTICLE_JIRA_SOFTWARE_ANALYTICS_ID;
	const articleFallbackUrl = isJSMBoard
		? LEARN_MORE_ABOUT_ISSUE_RANKING_SAC_ARTICLE_JIRA_SERVICEDESK
		: LEARN_MORE_ABOUT_ISSUE_RANKING_SAC_ARTICLE_JIRA_SOFTWARE;

	const entryPointProps = useMemo(
		() => ({
			onClose: () => {
				const analyticsEvent = createAnalyticsEvent({
					action: 'cancel',
					actionSubject: 'modalDialog',
				});
				fireUIAnalytics(analyticsEvent, 'issueRankingModal');
			},
			onConfirm: () => {
				const analyticsEvent = createAnalyticsEvent({
					action: 'confirm',
					actionSubject: 'modalDialog',
				});
				fireUIAnalytics(analyticsEvent, 'issueRankingModal');
				onUpdate();
			},
		}),
		[onUpdate, createAnalyticsEvent],
	);

	const modalProps = useMemo(
		() => ({
			width: 'small',
			testId: ISSUE_RANKING_MODAL_TEST_ID,
			onOpenComplete: () => {
				const analyticsEvent = createAnalyticsEvent({
					action: 'open',
					actionSubject: 'modalDialog',
				});
				fireUIAnalytics(analyticsEvent, 'issueRankingModal');
			},
		}),
		[createAnalyticsEvent],
	);

	const onHelpClick = useCallback(
		(_e: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) =>
			fireUIAnalytics(analyticsEvent, articleAnalyticsId),
		[articleAnalyticsId],
	);

	return (
		<Box xcss={wrapperStyles}>
			{!isJSMBoard && fg('simplified-boards-milestone-2_07hbk') ? (
				<FilterDetailsHeader>{formatMessage(messages.issueRankingHeader)}</FilterDetailsHeader>
			) : (
				<Heading as="h5" size="xxsmall">
					<Inline
						as="span"
						xcss={headerStyles}
						testId="software-board-settings-general-filter-settings.ui.board-filter-details.issue-ranking.issue-ranking"
					>
						{formatMessage(messages.issueRankingHeader)}
					</Inline>
				</Heading>
			)}
			{fg('jsw-board-settings-general-page-a11y-fixes') ? (
				<Text as="p">
					{formatMessage(messages.issueRankingDescription, {
						button: (chunks: React.ReactNode[]) => (
							<HelpPanelLink
								articleId={articleId}
								appearance="link"
								spacing="none"
								onClick={onHelpClick}
								fallbackComponent={
									<Link href={articleFallbackUrl} target="_blank" onClick={onHelpClick}>
										{chunks}
									</Link>
								}
							>
								{chunks}
							</HelpPanelLink>
						),
					})}
				</Text>
			) : (
				<Paragraph>
					{formatMessage(messages.issueRankingDescription, {
						button: (chunks: ReactNode[]) => (
							<InProductHelpButton
								articleId={articleId}
								articleAnalyticsId={articleAnalyticsId}
								appearance="link"
							>
								{chunks}
							</InProductHelpButton>
						),
					})}
				</Paragraph>
			)}

			{!issueRank && (
				<Box>
					{/* This `Box` is here for layout reasons, the parent container makes
					 * all direct descendants full width, a wrapping element fixes that. */}
					<ModalEntryPointButtonTrigger
						entryPoint={issueRankingConfirmationEntryPoint}
						entryPointProps={entryPointProps}
						aria-label={formatMessage(messages.enableIssueRankingButtonText)}
						isDisabled={!canEdit}
						interactionName="open-issue-ranking-confirmation-modal"
						useInternalModal
						modalProps={modalProps}
					>
						{formatMessage(messages.enableIssueRankingButtonText)}
					</ModalEntryPointButtonTrigger>
				</Box>
			)}

			{issueRank && (
				<Flex xcss={flexStyle}>
					<Inline
						as="span"
						xcss={[selectedButtonStyles, isVisualRefreshEnabled() && selectedButtonSpacingStyles]}
					>
						<CheckIcon
							label={formatMessage(messages.rankingOnButtonText)}
							LEGACY_size="small"
							LEGACY_margin="0 4px 0 0"
							color="currentColor"
						/>
						{formatMessage(messages.rankingOnButtonText)}
					</Inline>
					{canEdit && (
						<EditFilterButton
							appearance="link"
							href={`/issues/?filter=${filterQueryId}`}
							target="_blank"
							iconAfter={<ShortcutIcon label="" size="small" />}
						>
							{formatMessage(messages.editRankingUsingFilter)}
						</EditFilterButton>
					)}
				</Flex>
			)}
		</Box>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Paragraph = styled.p({
	margin: 0,
});

const wrapperStyles = xcss({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${FILTER_QUERY_DETAILS_TABLE_WIDTH}px`,
	rowGap: 'space.100',
	flexDirection: 'column',
});

const headerStyles = xcss({
	textTransform: 'uppercase',
	font: token('font.heading.xxsmall'),
	color: 'color.text.subtle',
});

const selectedButtonStyles = xcss({
	display: 'inline-flex',
	backgroundColor: 'elevation.surface.sunken',
	alignItems: 'center',
	borderRadius: 'border.radius.100',
	textTransform: 'uppercase',
	paddingRight: 'space.100',
	paddingLeft: 'space.100',
	font: token('font.body.UNSAFE_small'),
});

const selectedButtonSpacingStyles = xcss({
	gap: 'space.050',
});

const flexStyle = xcss({
	height: '20px',
});

// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditFilterButton = styled(Button)({
	// need to use this since @atlaskit/button still doesn't support style overrides via xcss
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		height: '20px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > span': {
		fontSize: '14px',
		lineHeight: '21px',
	},
});

export { IssueRanking };
