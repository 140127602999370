import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { APP_METRIC_KEY } from '@atlassian/jira-software-board-settings-general-common/src/common/constants/analytics.tsx';
import type { FilterConfig } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/filter.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	UPDATE_GENERAL_SELECTED_BOARD_FILTER_ACTION_SUBJECT,
	UPDATE_GENERAL_SELECTED_BOARD_FILTER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { BOARD_FILTER_API_PATH } from '../../common/constants/urls.tsx';
import messages from './messages.tsx';
import type { UpdateBoardArguments } from './types.tsx';

export const useUpdateRemoteSelectedBoardFilter = (rapidBoardViewId: number) => {
	const { formatMessage } = useIntl();

	const updateRemoteBoardFilter = useCallback(
		({ savedFilterId }: UpdateBoardArguments) =>
			performPutRequest<FilterConfig | undefined>(BOARD_FILTER_API_PATH, {
				body: JSON.stringify({
					id: rapidBoardViewId,
					savedFilterId: `${savedFilterId}`,
				}),
			}),
		[rapidBoardViewId],
	);

	const { error, hasError, fetch } = useServiceWithAnalytics(
		updateRemoteBoardFilter,
		{},
		{
			actionSubject: UPDATE_GENERAL_SELECTED_BOARD_FILTER_ACTION_SUBJECT,
			packageName: APP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_METRIC_KEY}.${UPDATE_GENERAL_SELECTED_BOARD_FILTER_METRICS_KEY}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		error,
		errorTitle: formatMessage(messages.errorFlag),
	});

	return {
		commit: fetch,
	};
};
