import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { BoardSettingsItemListSkeleton } from '@atlassian/jira-skeletons/src/ui/board-settings/BoardSettingsCardLayoutSkeleton.tsx';
import { CardColorStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import {
	useCardColorStrategy,
	useNextCardColorStrategy,
} from '../../../controllers/selectors/colors/index.tsx';
import messages from './messages.tsx';
import { NonQueryStrategy } from './non-query-strategy/index.tsx';
import { QueryStrategy } from './query-strategy/index.tsx';
import { shouldShowSkeleton } from './utils.tsx';

const NonQueryStrategies: CardColorStrategy[] = [
	CardColorStrategy.assignee,
	CardColorStrategy.issueType,
	CardColorStrategy.priority,
	CardColorStrategy.requestType,
];

const NonQueryStrategyHeaders: Record<
	Exclude<CardColorStrategy, typeof CardColorStrategy.custom | typeof CardColorStrategy.none>,
	MessageDescriptor
> = {
	[CardColorStrategy.assignee]: messages.assigneeHeaderName,
	[CardColorStrategy.issueType]: messages.issueTypeHeaderName,
	[CardColorStrategy.priority]: messages.priorityHeaderName,
	[CardColorStrategy.requestType]: messages.requestTypeHeaderName,
};

const NonQueryStrategyHeadersIssueTermRefresh: Record<
	Exclude<CardColorStrategy, typeof CardColorStrategy.custom | typeof CardColorStrategy.none>,
	MessageDescriptor
> = {
	[CardColorStrategy.assignee]: messages.assigneeHeaderName,
	[CardColorStrategy.issueType]: messages.issueTypeHeaderNameIssueTermRefresh,
	[CardColorStrategy.priority]: messages.priorityHeaderName,
	[CardColorStrategy.requestType]: messages.requestTypeHeaderName,
};

export const CardColorStrategyContent = ({ isLoading }: { isLoading: boolean }) => {
	const { formatMessage } = useIntl();

	const [strategy] = useCardColorStrategy();
	const [nextCardColorStrategy] = useNextCardColorStrategy();

	if (isLoading && shouldShowSkeleton(nextCardColorStrategy ?? strategy)) {
		return <BoardSettingsItemListSkeleton />;
	}

	if (strategy === CardColorStrategy.none || nextCardColorStrategy === CardColorStrategy.none) {
		return null;
	}

	if (strategy === CardColorStrategy.custom) {
		return <QueryStrategy />;
	}

	if (NonQueryStrategies.includes(strategy)) {
		return (
			<NonQueryStrategy
				headerName={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? NonQueryStrategyHeadersIssueTermRefresh[strategy]
						: NonQueryStrategyHeaders[strategy],
				)}
			/>
		);
	}

	return null;
};
