import parameters from '@atlassian/jira-relay/src/__generated__/swimlanesSettingsPageQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { boardId: string; cloudId: string };

export const swimlanesSettingPageEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jsw-board-settings-swimlanes" */ './ui/swimlanes-settings-page/index.tsx'
		).then((module) => module.SwimlanesSettingPageContent),
	),
	getPreloadProps: ({ boardId, cloudId }: EntryPointParams) => ({
		queries: {
			queryReference: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters,
				variables: {
					boardId,
					cloudId,
				},
			},
		},
	}),
});
