import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	enableChildDatePlanningSuccess: {
		id: 'software-board-settings-timeline.controllers.handlers.update-child-issue-scheduling-handler.enable-child-date-planning-success',
		defaultMessage: 'Your child issues will be scheduled by their start and due date',
		description: 'Success message when child level issue planning is enabled',
	},
	disableChildDatePlanningSuccess: {
		id: 'software-board-settings-timeline.controllers.handlers.update-child-issue-scheduling-handler.disable-child-date-planning-success',
		defaultMessage: 'Your child issues will be scheduled by sprint dates',
		description: 'Success message when child level issue planning is disabled',
	},
	enableChildDatePlanningSuccessIssueTermRefresh: {
		id: 'software-board-settings-timeline.controllers.handlers.update-child-issue-scheduling-handler.enable-child-date-planning-success-issue-term-refresh',
		defaultMessage: 'Your child work items will be scheduled by their start and due date',
		description: 'Success message when child level issue planning is enabled',
	},
	disableChildDatePlanningSuccessIssueTermRefresh: {
		id: 'software-board-settings-timeline.controllers.handlers.update-child-issue-scheduling-handler.disable-child-date-planning-success-issue-term-refresh',
		defaultMessage: 'Your child work items will be scheduled by sprint dates',
		description: 'Success message when child level issue planning is disabled',
	},
});
