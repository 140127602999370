import { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { SETTINGS_CONFIG } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { MENU_ITEMS } from './constants.tsx';
import type { SettingsLink } from './types.tsx';

export const useMenuItems = ({
	isJsm,
	isUserLocatedBoard,
	isKanban,
}: {
	isJsm: boolean;
	isUserLocatedBoard: boolean;
	isKanban: boolean;
}): SettingsLink[] => {
	const { formatMessage } = useIntl();

	return useMemo(
		() =>
			MENU_ITEMS.flatMap((item) => {
				return (SETTINGS_CONFIG[item.id].jsmAllowed || !isJsm) &&
					(SETTINGS_CONFIG[item.id].userLocatedBoardAllowed || !isUserLocatedBoard) &&
					(SETTINGS_CONFIG[item.id].kanbanAllowed || !isKanban)
					? [
							{
								...item,
								name: formatMessage(item.name),
								configQuery: SETTINGS_CONFIG[item.id].configQuery,
							},
						]
					: [];
			}),
		[formatMessage, isJsm, isUserLocatedBoard, isKanban],
	);
};
