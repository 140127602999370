import type { Location } from '../../services/fetch-board-location/index.tsx';

export type GroupOption = {
	label: string;
	options: Location[];
};

export const LocationType = {
	PROJECT: 'project',
	USER: 'user',
};
