/**
 * @generated SignedSource<<7281c4a5014f727f790af6f68bb85107>>
 * @relayHash 84a983abcd553c93e68d1ffd91ddc4cf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4f6e258bd486bfbba34de66af25424837bbc200a316a2a9134c9ba8cc10b7c51

import type { ConcreteRequest, Query } from 'relay-runtime';
export type workingDaysSettingsPageQuery$variables = {
  boardId: string;
};
export type workingDaysSettingsPageQuery$data = {
  readonly boardScope: {
    readonly currentUser: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
    readonly workingDaysConfig: {
      readonly nonWorkingDays: ReadonlyArray<{
        readonly date: AGG$Date;
      } | null | undefined>;
      readonly timeZoneEditModel: {
        readonly currentTimeZoneId: string;
        readonly regions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly displayName: string;
              readonly id: string;
            };
          } | null | undefined>;
        };
        readonly timeZones: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly city: string;
              readonly displayName: string;
              readonly gmtOffset: string;
              readonly id: string;
              readonly regionKey: string;
            };
          } | null | undefined>;
        };
      };
      readonly weekDays: {
        readonly friday: boolean;
        readonly monday: boolean;
        readonly saturday: boolean;
        readonly sunday: boolean;
        readonly thursday: boolean;
        readonly tuesday: boolean;
        readonly wednesday: boolean;
      };
    };
  } | null | undefined;
};
export type workingDaysSettingsPageQuery = {
  response: workingDaysSettingsPageQuery$data;
  variables: workingDaysSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  {
    "kind": "Literal",
    "name": "isCMP",
    "value": true
  }
],
v2 = {
  "concreteType": "CurrentUser",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "permissions"
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "name": "monday"
},
v4 = {
  "kind": "ScalarField",
  "name": "tuesday"
},
v5 = {
  "kind": "ScalarField",
  "name": "wednesday"
},
v6 = {
  "kind": "ScalarField",
  "name": "thursday"
},
v7 = {
  "kind": "ScalarField",
  "name": "friday"
},
v8 = {
  "kind": "ScalarField",
  "name": "saturday"
},
v9 = {
  "kind": "ScalarField",
  "name": "sunday"
},
v10 = {
  "kind": "ScalarField",
  "name": "date"
},
v11 = {
  "kind": "ScalarField",
  "name": "currentTimeZoneId"
},
v12 = {
  "kind": "ScalarField",
  "name": "id"
},
v13 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v14 = {
  "kind": "ScalarField",
  "name": "gmtOffset"
},
v15 = {
  "kind": "ScalarField",
  "name": "city"
},
v16 = {
  "kind": "ScalarField",
  "name": "regionKey"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "workingDaysSettingsPageQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JswWorkingDaysConfig",
              "kind": "LinkedField",
              "name": "workingDaysConfig",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "concreteType": "JswWeekDaysConfig",
                    "kind": "LinkedField",
                    "name": "weekDays",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v3/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.workingDaysConfig.weekDays.monday"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v4/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.workingDaysConfig.weekDays.tuesday"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v5/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.workingDaysConfig.weekDays.wednesday"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v6/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.workingDaysConfig.weekDays.thursday"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v7/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.workingDaysConfig.weekDays.friday"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v8/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.workingDaysConfig.weekDays.saturday"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v9/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.workingDaysConfig.weekDays.sunday"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "boardScope.workingDaysConfig.weekDays"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "concreteType": "JswNonWorkingDayConfig",
                    "kind": "LinkedField",
                    "name": "nonWorkingDays",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v10/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.workingDaysConfig.nonWorkingDays.date"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "boardScope.workingDaysConfig.nonWorkingDays"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "concreteType": "JswTimeZoneEditModel",
                    "kind": "LinkedField",
                    "name": "timeZoneEditModel",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v11/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.workingDaysConfig.timeZoneEditModel.currentTimeZoneId"
                      },
                      {
                        "kind": "RequiredField",
                        "field": {
                          "concreteType": "JswRegionConnection",
                          "kind": "LinkedField",
                          "name": "regions",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JswRegionEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": {
                                      "concreteType": "JswRegion",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "RequiredField",
                                          "field": (v12/*: any*/),
                                          "action": "THROW",
                                          "path": "boardScope.workingDaysConfig.timeZoneEditModel.regions.edges.node.id"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v13/*: any*/),
                                          "action": "THROW",
                                          "path": "boardScope.workingDaysConfig.timeZoneEditModel.regions.edges.node.displayName"
                                        }
                                      ]
                                    },
                                    "action": "THROW",
                                    "path": "boardScope.workingDaysConfig.timeZoneEditModel.regions.edges.node"
                                  }
                                ]
                              },
                              "action": "THROW",
                              "path": "boardScope.workingDaysConfig.timeZoneEditModel.regions.edges"
                            }
                          ]
                        },
                        "action": "THROW",
                        "path": "boardScope.workingDaysConfig.timeZoneEditModel.regions"
                      },
                      {
                        "kind": "RequiredField",
                        "field": {
                          "concreteType": "JswTimeZoneConnection",
                          "kind": "LinkedField",
                          "name": "timeZones",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JswTimeZoneEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": {
                                      "concreteType": "JswTimeZone",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "RequiredField",
                                          "field": (v12/*: any*/),
                                          "action": "THROW",
                                          "path": "boardScope.workingDaysConfig.timeZoneEditModel.timeZones.edges.node.id"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v13/*: any*/),
                                          "action": "THROW",
                                          "path": "boardScope.workingDaysConfig.timeZoneEditModel.timeZones.edges.node.displayName"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v14/*: any*/),
                                          "action": "THROW",
                                          "path": "boardScope.workingDaysConfig.timeZoneEditModel.timeZones.edges.node.gmtOffset"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v15/*: any*/),
                                          "action": "THROW",
                                          "path": "boardScope.workingDaysConfig.timeZoneEditModel.timeZones.edges.node.city"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v16/*: any*/),
                                          "action": "THROW",
                                          "path": "boardScope.workingDaysConfig.timeZoneEditModel.timeZones.edges.node.regionKey"
                                        }
                                      ]
                                    },
                                    "action": "THROW",
                                    "path": "boardScope.workingDaysConfig.timeZoneEditModel.timeZones.edges.node"
                                  }
                                ]
                              },
                              "action": "THROW",
                              "path": "boardScope.workingDaysConfig.timeZoneEditModel.timeZones.edges"
                            }
                          ]
                        },
                        "action": "THROW",
                        "path": "boardScope.workingDaysConfig.timeZoneEditModel.timeZones"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "boardScope.workingDaysConfig.timeZoneEditModel"
                }
              ]
            },
            "action": "THROW",
            "path": "boardScope.workingDaysConfig"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "workingDaysSettingsPageQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "JswWorkingDaysConfig",
            "kind": "LinkedField",
            "name": "workingDaysConfig",
            "plural": false,
            "selections": [
              {
                "concreteType": "JswWeekDaysConfig",
                "kind": "LinkedField",
                "name": "weekDays",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ]
              },
              {
                "concreteType": "JswNonWorkingDayConfig",
                "kind": "LinkedField",
                "name": "nonWorkingDays",
                "plural": true,
                "selections": [
                  (v10/*: any*/)
                ]
              },
              {
                "concreteType": "JswTimeZoneEditModel",
                "kind": "LinkedField",
                "name": "timeZoneEditModel",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  {
                    "concreteType": "JswRegionConnection",
                    "kind": "LinkedField",
                    "name": "regions",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JswRegionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JswRegion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v13/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "concreteType": "JswTimeZoneConnection",
                    "kind": "LinkedField",
                    "name": "timeZones",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JswTimeZoneEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JswTimeZone",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v12/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "4f6e258bd486bfbba34de66af25424837bbc200a316a2a9134c9ba8cc10b7c51",
    "metadata": {},
    "name": "workingDaysSettingsPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "979e14cda50c569eede46af5ada044eb";

export default node;
