import React, { useCallback } from 'react';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useDeleteCardColor } from '../../../services/delete-card-color/index.tsx';
import { useRapidViewId, useIsCMPBoard } from '../../selectors/board/index.tsx';
import { useCardColorStrategy } from '../../selectors/colors/index.tsx';
import { useFetchStrategyConfigHandler } from '../use-fetch-strategy-handler/index.tsx';
import messages from './messages.tsx';

export const useResetToGlobalColor = () => {
	const [rapidViewId] = useRapidViewId();
	const [isCMPBoard] = useIsCMPBoard();
	const [colorStrategy] = useCardColorStrategy();

	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const { commit: commitDeleteCardColor, loading: isDeletingCardColor } = useDeleteCardColor(
		rapidViewId,
		formatMessage(messages.failedToResetErrorTitle),
	);
	const { handleFetchStrategyConfig, loading: isRefetcingStrategyConfig } =
		useFetchStrategyConfigHandler();

	const resetToGlobalColor = useCallback(
		async (itemId: string) => {
			const id = Number(itemId);
			const result = await commitDeleteCardColor({ id });

			if (result.ok) {
				await handleFetchStrategyConfig(colorStrategy);

				showFlag((props) => (
					<SuccessFlagWithBoardLink
						isCMPBoard={isCMPBoard}
						title={formatMessage(messages.successFlagForResetingCardColor)}
						{...props}
					/>
				));
			}
		},
		[
			colorStrategy,
			commitDeleteCardColor,
			formatMessage,
			handleFetchStrategyConfig,
			isCMPBoard,
			showFlag,
		],
	);

	return {
		resetToGlobalColor,
		isResettingToGlobalColor: isDeletingCardColor || isRefetcingStrategyConfig,
	};
};
