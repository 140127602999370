import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addFieldSuccessBacklogTitle: {
		id: 'software-board-settings-card-layout.controllers.handlers.add-card-layout-field-handler.add-field-success-backlog-title',
		defaultMessage: "You've added the {fieldName} field to cards on your backlog",
		description: 'Title for the success flag when adding a card layout field succeeds',
	},
	addFieldSuccessActiveSprintsTitle: {
		id: 'software-board-settings-card-layout.controllers.handlers.add-card-layout-field-handler.add-field-success-active-sprints-title',
		defaultMessage: "You've added the {fieldName} field to cards on your active sprints",
		description: 'Title for the success flag when adding a card layout field succeeds',
	},
	addFieldSuccessKanbanBoardTitle: {
		id: 'software-board-settings-card-layout.controllers.handlers.add-card-layout-field-handler.add-field-success-kanban-board-title',
		defaultMessage: "You've added the {fieldName} field to cards on your kanban board",
		description: 'Title for the success flag when adding a card layout field succeeds',
	},
});
