import React, { useMemo } from 'react';
import Heading from '@atlaskit/heading';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type {
	CurrentField,
	FieldMode,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import { SortableTable } from '@atlassian/jira-software-board-settings-sortable-table/src/ui/sortable-table/index.tsx';
import { useLastRowFocusHandler } from '../../../controllers/handlers/global-focus-handler/index.tsx';
import { useRankCardLayoutFieldHandler } from '../../../controllers/handlers/rank-card-layout-field-handler/index.tsx';
import { useCanEdit } from '../../../controllers/selectors/permissions/index.tsx';
import { FieldLimit } from './field-limit/index.tsx';
import messages from './messages.tsx';
import { useTableHeadAndRows } from './utils.tsx';

// VISIBLE FOR TESTING
export const CUSTOM_FIELD_LIMIT = 3;

type CardLayoutTableProps = {
	addField: React.ReactNode;
	fields: CurrentField[];
	fieldMode: FieldMode;
	testId?: string;
	title: string;
};

export const CardLayoutTable = ({
	title,
	fields,
	fieldMode,
	addField,
	testId,
}: CardLayoutTableProps) => {
	const [canEdit] = useCanEdit();
	const { formatMessage } = useIntl();
	const { rankCurrentField, optimisticFields } = useRankCardLayoutFieldHandler(fields, fieldMode);
	const { head, rows } = useTableHeadAndRows({
		fields: optimisticFields.slice(0, CUSTOM_FIELD_LIMIT),
		isReadOnly: !canEdit,
	});

	const { tableContainerRef } = useLastRowFocusHandler({
		mode: fieldMode,
		rowSelector: 'tbody > tr',
	});

	const footer = useMemo(() => {
		if (!canEdit) return null;

		const showAddField = fields.length < CUSTOM_FIELD_LIMIT;
		return showAddField ? addField : <FieldLimit />;
	}, [canEdit, fields.length, addField]);

	return (
		<Stack space="space.100" testId={testId}>
			<Heading as="h3" size="small">
				{title}
			</Heading>
			<Box as="p" xcss={textStyles}>
				{formatMessage(messages.cardFieldLimit)}
			</Box>
			<Box ref={tableContainerRef} xcss={tableStyles}>
				<SortableTable head={head} rows={rows} onRank={rankCurrentField} isReadOnly={!canEdit} />
				{footer}
			</Box>
		</Stack>
	);
};

const textStyles = xcss({
	marginTop: '0',
	marginBottom: 'space.200',
});

const tableStyles = xcss({
	maxWidth: '680px',
});
