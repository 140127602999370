import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { useIsUserLocatedBoard } from '../use-is-user-located-board/index.tsx';

export const useIsLocatedInUserContainer = (): boolean => {
	const [
		{
			match: { params },
		},
	] = useRouter();

	const { atlassianAccountId } = useTenantContext();

	const isUserLocatedBoard = useIsUserLocatedBoard();

	return isUserLocatedBoard && atlassianAccountId === params.accountId;
};
