import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	name: {
		id: 'software-board-settings-query-list-config-common.common.modal-form.name-field.name',
		defaultMessage: 'Name',
		description: 'Label for Name text field',
	},
	nameLengthValidation: {
		id: 'software-board-settings-query-list-config-common.common.modal-form.name-field.name-length-validation',
		defaultMessage: 'Enter a name with less than 255 characters.',
		description:
			'An error that is shown when the user has provided no name or one longer than 255 characters.',
	},
});
