import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-timeline.timeline-settings-page.board-cross-project-message.title.non-final',
		defaultMessage: 'Timelines are designed for single projects',
		description:
			'Heading of a dialog which informs the user that their project needs to be a single project to use timelines for their project',
	},
	description: {
		id: 'software-board-settings-timeline.timeline-settings-page.board-cross-project-message.description.non-final',
		defaultMessage:
			'The timeline isn’t available because it uses a filter that contains multiple projects. Adjust the board filter to only include issues from {projectName} to use your timeline.',
		description:
			'A paragraph about why they cannot use a timeline for their project and instructs them how to change that.',
	},
	callToAction: {
		id: 'software-board-settings-timeline.timeline-settings-page.board-cross-project-message.callToAction.non-final',
		defaultMessage: 'Edit Filter Query',
		description: 'Text for a link that opens a page where they can modify their filter query.',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-settings-timeline.timeline-settings-page.board-cross-project-message.description-issue-term-refresh.non-final',
		defaultMessage:
			'The timeline isn’t available because it uses a filter that contains multiple projects. Adjust the board filter to only include work from {projectName} to use your timeline.',
		description:
			'A paragraph about why they cannot use a timeline for their project and instructs them how to change that.',
	},
});
