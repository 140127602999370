import { initState } from './init/index.tsx';
import {
	updateIsRoadmapEnabled,
	updateIsChildIssuePlanningEnabled,
	updatePrefersChildIssueDatePlanning,
} from './timeline/index.tsx';
import type { Actions } from './types.tsx';

export const actions: Actions = {
	initState,
	updateIsRoadmapEnabled,
	updateIsChildIssuePlanningEnabled,
	updatePrefersChildIssueDatePlanning,
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Actions } from './types';
