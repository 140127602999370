import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../../common/constants/data-test.tsx';
import type { QueryItem, GenericQueryItem } from '../../../common/types/index.tsx';
import messages from './messages.tsx';

export type ActionsMenuProps<T extends GenericQueryItem = QueryItem> = {
	item: T;
	labelName: string;
	onEdit: (item: T) => void;
	onDelete: (item: T) => void;
};

export const ActionsMenu = <T extends GenericQueryItem = QueryItem>({
	item,
	labelName,
	onEdit,
	onDelete,
}: ActionsMenuProps<T>) => {
	const { formatMessage } = useIntl();

	const handleEditClick = useCallback(() => {
		onEdit(item);
	}, [item, onEdit]);

	const handleDeleteClick = useCallback(() => {
		onDelete(item);
	}, [item, onDelete]);

	return (
		<ActionsMenuWrapper>
			<DropdownMenu
				shouldRenderToParent
				spacing="compact"
				placement="bottom-end"
				trigger={({ triggerRef, testId, ...providedProps }) => (
					<Button
						{...providedProps}
						appearance="subtle"
						ref={triggerRef}
						testId={dataTestIds.queryListActionMenuButton}
						iconBefore={
							<MoreIcon
								size="medium"
								label={formatMessage(messages.moreActionsForNameLabel, {
									name: labelName,
								})}
							/>
						}
					/>
				)}
			>
				<ContentContainer data-testid={dataTestIds.queryListActionMenu}>
					<DropdownItemGroup>
						<DropdownItem
							onClick={handleEditClick}
							testId="software-board-settings-query-list-config-common.ui.query-list.actions-menu.edit"
						>
							{formatMessage(messages.editLabel)}
						</DropdownItem>
						<DropdownItem
							onClick={handleDeleteClick}
							testId="software-board-settings-query-list-config-common.ui.query-list.actions-menu.delete"
						>
							{formatMessage(messages.deleteLabel)}
						</DropdownItem>
					</DropdownItemGroup>
				</ContentContainer>
			</DropdownMenu>
		</ActionsMenuWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsMenuWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	marginRight: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'td:has(> &)': {
		width: token('space.500', '40px'),
		borderBottom: `${token('color.border', '#091E4224')} 1px solid`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	minWidth: `calc(${token('space.100', '8px')} * 17)`,
});
