import React, { useCallback, useMemo, useEffect } from 'react';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import type { QueryItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { BOARD_SETTINGS_EDIT_SWIMLANE_MODAL_ID } from '../../../common/constants/modals.tsx';
import { useUpdateSwimlane } from '../../../services/update-swimlane/index.tsx';
import type { UpdateSwimlaneSuccessResponse } from '../../../services/update-swimlane/types.tsx';
import { useRapidViewId, useIsCMPBoard } from '../../selectors/board/index.tsx';
import { useSwimlanesWithoutDefault, useItemToEdit } from '../../selectors/swimlanes/index.tsx';
import messages from './messages.tsx';

export const useEditSwimlaneHandler = () => {
	const [rapidViewId] = useRapidViewId();
	const [isCMPBoard] = useIsCMPBoard();
	const [, { updateSwimlane }] = useSwimlanesWithoutDefault();
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const [isEditModalOpen, { on: openEditModal, off: closeEditModal }] = useSwitchModals(
		BOARD_SETTINGS_EDIT_SWIMLANE_MODAL_ID,
	);
	const [itemToEdit, { setItemToEdit }] = useItemToEdit();

	const {
		commit: commitUpdateSwimlane,
		queryValidationErrorMessage,
		error,
		resetError,
	} = useUpdateSwimlane(rapidViewId, formatMessage(messages.failedToUpdateErrorTitle));

	const handleItemEdit = useCallback(
		(queryItem: QueryItem) => {
			setItemToEdit(queryItem);
			openEditModal();
		},
		[openEditModal, setItemToEdit],
	);

	const handleItemEditSubmit = useCallback(
		async (swimlane: QueryItem) => {
			const result = await commitUpdateSwimlane(swimlane);
			if (result && !result.errors) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				updateSwimlane(result as UpdateSwimlaneSuccessResponse);
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						isCMPBoard={isCMPBoard}
						title={formatMessage(messages.successFlagTitleForUpdatingSwimlane)}
						{...props}
					/>
				));

				setItemToEdit(null);
				closeEditModal();
			}
		},
		[
			commitUpdateSwimlane,
			formatMessage,
			isCMPBoard,
			showFlag,
			updateSwimlane,
			setItemToEdit,
			closeEditModal,
		],
	);

	useEffect(() => {
		// Close edit modal if there is error but error is not validation query error
		if (error && !queryValidationErrorMessage) {
			setItemToEdit(null);
			closeEditModal();
		}
	}, [error, queryValidationErrorMessage, setItemToEdit, closeEditModal]);

	const editQueryValidationErrorMessage = useMemo(() => {
		if (queryValidationErrorMessage) {
			return `${queryValidationErrorMessage} ${formatMessage(
				messages.validationErrorInstructionForUpdatingSwimlane,
			)}`;
		}
	}, [formatMessage, queryValidationErrorMessage]);

	useEffect(() => {
		if (isEditModalOpen) {
			// reset request error on modal open so it won't show error from the last modal request
			resetError();
		} else {
			// reset itemToEdit state of modal is closed
			setItemToEdit(null);
		}
	}, [resetError, isEditModalOpen, setItemToEdit]);

	return {
		handleItemEdit,
		handleItemEditSubmit,
		itemToEdit,
		editQueryValidationErrorMessage,
	};
};
