/** @jsx jsx */
import React, { useMemo } from 'react';
import { styled, jsx, css } from '@compiled/react';
import { Stack, xcss } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import RainbowImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/rainbow-paint/index.tsx';
import { type MessageDescriptor, useIntl } from '@atlassian/jira-intl';
import { CardColorStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import { useCardColorStrategy } from '../../../../../controllers/selectors/colors/index.tsx';
import messages from './messages.tsx';

export const EmptyView = () => {
	const { formatMessage } = useIntl();

	const [strategy] = useCardColorStrategy();

	const emptyViewMessages: Record<string, MessageDescriptor> = useMemo(
		() => ({
			[CardColorStrategy.assignee]: expVal(
				'issue-terminology-refresh-m2-replace',
				'isEnabled',
				false,
			)
				? messages.noAssigneeEmptyStateDescriptionIssueTermRefresh
				: messages.noAssigneeEmptyStateDescription,
			[CardColorStrategy.issueType]: expVal(
				'issue-terminology-refresh-m2-replace',
				'isEnabled',
				false,
			)
				? messages.noIssueTypesEmptyStateDescriptionIssueTermRefresh
				: messages.noIssueTypesEmptyStateDescription,
		}),
		[],
	);

	if (!emptyViewMessages[strategy]) {
		return null;
	}

	return (
		<Stack
			alignBlock="center"
			alignInline="center"
			space="space.300"
			xcss={emptyViewContainerStyles}
		>
			<RainbowImage css={emptyImage} alt="" />
			<EmptyDescription>{formatMessage(emptyViewMessages[strategy])}</EmptyDescription>
		</Stack>
	);
};

const emptyViewContainerStyles = xcss({
	marginBlock: 'space.600',
	marginInline: 'auto',
	width: '464px',
});

const emptyImage = css({
	maxWidth: '160px',
	maxHeight: '160px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyDescription = styled.p({
	margin: 0,
	textAlign: 'center',
});
