import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createSwimlaneModalTitle: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.modals.create-swimlane-modal-title',
		defaultMessage: 'Create swimlane',
		description: 'This is the dialog title for creating a swimlane',
	},
	createOrEditSwimlaneModalGuidelines: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.modals.create-or-edit-swimlane-modal-guidelines',
		defaultMessage: 'Required fields are marked with an asterisk',
		description: 'This is the guidelines shown on the top of dialog for creating a swimlane',
	},
	deleteSwimlaneModalTitle: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.modals.delete-swimlane-modal-title',
		defaultMessage: 'Delete "{swimlaneName}" swimlane',
		description: 'This is the dialog title for deleting a swimlane',
	},
	deleteSwimlaneModalDescription: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.modals.delete-swimlane-modal-description',
		defaultMessage:
			"This will permanently delete the swimlane but won't remove any issues from your project.",
		description: 'This is the description shown on the dialog for deleting a swimlane',
	},
	editModalTitle: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.modals.edit-modal-title',
		defaultMessage: 'Edit swimlane',
		description: 'Edit swimlane modal title',
	},
	deleteSwimlaneModalDescriptionIssueTermRefresh: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.modals.delete-swimlane-modal-description-issue-term-refresh',
		defaultMessage:
			"This will permanently delete the swimlane but won't remove any work items from your project.",
		description: 'This is the description shown on the dialog for deleting a swimlane',
	},
});
