import type { ExperienceAttributes } from '@atlassian/jira-experience-tracker/src/common/types.tsx';
import { useExperienceTracker } from '@atlassian/jira-software-logging/src/services/experience-tracker/index.tsx';
import { VIEW_GENERAL_SETTINGS_EXPERIENCE_NAME } from '../../common/constants/analytics.tsx';
import { APP_IDENTIFIER, APP_SOURCE_NAME } from '../../common/constants/app.tsx';

export const useViewEstimationSettingsExperienceTracker = (attributes: ExperienceAttributes = {}) =>
	useExperienceTracker(
		APP_IDENTIFIER,
		VIEW_GENERAL_SETTINGS_EXPERIENCE_NAME,
		APP_SOURCE_NAME,
		attributes,
	);
