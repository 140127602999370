import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	enableErrorTitle: {
		id: 'software-board-settings-timeline.services.update-child-planning.enable-error-title',
		defaultMessage: "We couldn't add child-level issues",
		description:
			'Title for error flag when we fail to turn on child-level planning for the timeline',
	},
	disableErrorTitle: {
		id: 'software-board-settings-timeline.services.update-child-planning.disable-error-title',
		defaultMessage: "We couldn't remove child-level issues",
		description:
			'Title for error flag when we fail to turn off child-level planning for the timeline',
	},
	enableErrorTitleIssueTermRefresh: {
		id: 'software-board-settings-timeline.services.update-child-planning.enable-error-title-issue-term-refresh',
		defaultMessage: "We couldn't add child-level work items",
		description:
			'Title for error flag when we fail to turn on child-level planning for the timeline',
	},
	disableErrorTitleIssueTermRefresh: {
		id: 'software-board-settings-timeline.services.update-child-planning.disable-error-title-issue-term-refresh',
		defaultMessage: "We couldn't remove child-level work items",
		description:
			'Title for error flag when we fail to turn off child-level planning for the timeline',
	},
});
