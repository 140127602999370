import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { APP_METRIC_KEY } from '@atlassian/jira-software-board-settings-general-common/src/common/constants/analytics.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import {
	UPDATE_GENERAL_BOARD_NAME_ACTION_SUBJECT,
	UPDATE_GENERAL_BOARD_NAME_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import type { UpdateNameInput, UpdateBoardNameResponse } from './types.tsx';

const BOARD_NAME_API_URI = '/rest/greenhopper/1.0/rapidviewconfig/name';

export const useUpdateRemoteBoardName = (): UpdateBoardNameResponse => {
	const updateBoardName = useCallback(
		({ id, name }: UpdateNameInput) =>
			performPutRequest(`${BOARD_NAME_API_URI}`, {
				body: JSON.stringify({
					id,
					name,
				}),
			}),
		[],
	);
	const { data, loading, error, hasError, resetError, fetch } = useServiceWithAnalytics<
		UpdateBoardNameResponse['data'],
		UpdateNameInput
	>(
		updateBoardName,
		{},
		{
			actionSubject: UPDATE_GENERAL_BOARD_NAME_ACTION_SUBJECT,
			packageName: APP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_METRIC_KEY}.${UPDATE_GENERAL_BOARD_NAME_METRICS_KEY}`,
		},
	);

	return { loading, error, data, commit: fetch, resetError, hasError };
};
