/**
 * @generated SignedSource<<ecb2cb17fe7ae2e0a69722af2e5a3184>>
 * @relayHash 0ee26ae9486b2b05f4212bea312401ff
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 301536014b9fff430dff73b99de0b1161433a944c82a3f3f7b42292e44a3c42d

import type { ConcreteRequest, Query } from 'relay-runtime';
export type cardColorsSettingsPageQuery$variables = {
  boardId: string;
};
export type cardColorsSettingsPageQuery$data = {
  readonly boardScope: {
    readonly cardColorConfig: {
      readonly current: {
        readonly cardColors: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly color: string;
              readonly displayValue: string;
              readonly id: string;
              readonly isGlobalColor: boolean | null | undefined;
              readonly isUsed: boolean;
              readonly strategy: string;
              readonly value: string;
            };
          } | null | undefined>;
        };
        readonly id: string;
      };
    };
    readonly currentUser: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
  };
};
export type cardColorsSettingsPageQuery = {
  response: cardColorsSettingsPageQuery$data;
  variables: cardColorsSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  {
    "kind": "Literal",
    "name": "isCMP",
    "value": true
  }
],
v2 = {
  "concreteType": "CurrentUser",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "permissions"
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "strategy"
},
v5 = {
  "kind": "ScalarField",
  "name": "value"
},
v6 = {
  "kind": "ScalarField",
  "name": "displayValue"
},
v7 = {
  "kind": "ScalarField",
  "name": "color"
},
v8 = {
  "kind": "ScalarField",
  "name": "isUsed"
},
v9 = {
  "kind": "ScalarField",
  "name": "isGlobalColor"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "cardColorsSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v1/*: any*/),
          "concreteType": "BoardScope",
          "kind": "LinkedField",
          "name": "boardScope",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JswCardColorConfig",
                "kind": "LinkedField",
                "name": "cardColorConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JswCardColorStrategy",
                      "kind": "LinkedField",
                      "name": "current",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v3/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.cardColorConfig.current.id"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JswCardColorConnection",
                            "kind": "LinkedField",
                            "name": "cardColors",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "JswCardColorEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "JswCardColor",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "RequiredField",
                                            "field": (v3/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.cardColorConfig.current.cardColors.edges.node.id"
                                          },
                                          {
                                            "kind": "RequiredField",
                                            "field": (v4/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.cardColorConfig.current.cardColors.edges.node.strategy"
                                          },
                                          {
                                            "kind": "RequiredField",
                                            "field": (v5/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.cardColorConfig.current.cardColors.edges.node.value"
                                          },
                                          {
                                            "kind": "RequiredField",
                                            "field": (v6/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.cardColorConfig.current.cardColors.edges.node.displayValue"
                                          },
                                          {
                                            "kind": "RequiredField",
                                            "field": (v7/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.cardColorConfig.current.cardColors.edges.node.color"
                                          },
                                          {
                                            "kind": "RequiredField",
                                            "field": (v8/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.cardColorConfig.current.cardColors.edges.node.isUsed"
                                          },
                                          (v9/*: any*/)
                                        ]
                                      },
                                      "action": "THROW",
                                      "path": "boardScope.cardColorConfig.current.cardColors.edges.node"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "boardScope.cardColorConfig.current.cardColors.edges"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.cardColorConfig.current.cardColors"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.cardColorConfig.current"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.cardColorConfig"
            }
          ]
        },
        "action": "THROW",
        "path": "boardScope"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cardColorsSettingsPageQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "JswCardColorConfig",
            "kind": "LinkedField",
            "name": "cardColorConfig",
            "plural": false,
            "selections": [
              {
                "concreteType": "JswCardColorStrategy",
                "kind": "LinkedField",
                "name": "current",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "concreteType": "JswCardColorConnection",
                    "kind": "LinkedField",
                    "name": "cardColors",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JswCardColorEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JswCardColor",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "301536014b9fff430dff73b99de0b1161433a944c82a3f3f7b42292e44a3c42d",
    "metadata": {},
    "name": "cardColorsSettingsPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "58546d2471b9194457ecb402173796b5";

export default node;
