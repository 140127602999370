/**
 * @generated SignedSource<<e3a93b22bb858cfc8ff898e31a471c97>>
 * @relayHash b2a410eebdb751b4e55925d6116a5a10
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e2d9f64dbab3e3e369272ea7348936a3914de7cdea424ac9f623dc73e7a8b2ff

import type { ConcreteRequest, Query } from 'relay-runtime';
export type BoardFeatureStatus = "COMING_SOON" | "DISABLED" | "ENABLED" | "%future added value";
export type cardLayoutSettingsPageQuery$variables = {
  boardId: string;
};
export type cardLayoutSettingsPageQuery$data = {
  readonly boardScope: {
    readonly board: {
      readonly showDaysInColumn: boolean;
    };
    readonly cardLayoutConfig: {
      readonly backlog: {
        readonly availableFields: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly fieldId: string;
              readonly isValid: boolean;
              readonly name: string;
            };
          } | null | undefined>;
        };
        readonly fields: ReadonlyArray<{
          readonly cardLayoutId: string;
          readonly fieldId: string;
          readonly isValid: boolean;
          readonly name: string;
        } | null | undefined>;
      };
      readonly board: {
        readonly availableFields: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly fieldId: string;
              readonly isValid: boolean;
              readonly name: string;
            };
          } | null | undefined>;
        };
        readonly fields: ReadonlyArray<{
          readonly cardLayoutId: string;
          readonly fieldId: string;
          readonly isValid: boolean;
          readonly name: string;
        } | null | undefined>;
      };
    };
    readonly currentUser: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
    readonly features: ReadonlyArray<{
      readonly key: AGG$SoftwareBoardFeatureKey;
      readonly status: BoardFeatureStatus;
    } | null | undefined>;
  };
};
export type cardLayoutSettingsPageQuery = {
  response: cardLayoutSettingsPageQuery$data;
  variables: cardLayoutSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  {
    "kind": "Literal",
    "name": "isCMP",
    "value": true
  }
],
v2 = {
  "concreteType": "CurrentUser",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "permissions"
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "name": "key"
},
v4 = {
  "kind": "ScalarField",
  "name": "status"
},
v5 = {
  "kind": "ScalarField",
  "name": "showDaysInColumn"
},
v6 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v7 = {
  "kind": "ScalarField",
  "name": "cardLayoutId"
},
v8 = {
  "kind": "ScalarField",
  "name": "name"
},
v9 = {
  "kind": "ScalarField",
  "name": "isValid"
},
v10 = {
  "kind": "ScalarField",
  "name": "id"
},
v11 = [
  {
    "concreteType": "JswCurrentCardLayoutField",
    "kind": "LinkedField",
    "name": "fields",
    "plural": true,
    "selections": [
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ]
  },
  {
    "concreteType": "JswAvailableCardLayoutFieldConnection",
    "kind": "LinkedField",
    "name": "availableFields",
    "plural": false,
    "selections": [
      {
        "concreteType": "JswAvailableCardLayoutFieldEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "concreteType": "JswAvailableCardLayoutField",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v6/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "cardLayoutSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v1/*: any*/),
          "concreteType": "BoardScope",
          "kind": "LinkedField",
          "name": "boardScope",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "BoardFeature",
                "kind": "LinkedField",
                "name": "features",
                "plural": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v3/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.key"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v4/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.status"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.features"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "SoftwareBoard",
                "kind": "LinkedField",
                "name": "board",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v5/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.board.showDaysInColumn"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.board"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JswCardLayoutConfig",
                "kind": "LinkedField",
                "name": "cardLayoutConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JswCardLayoutContainer",
                      "kind": "LinkedField",
                      "name": "backlog",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JswCurrentCardLayoutField",
                            "kind": "LinkedField",
                            "name": "fields",
                            "plural": true,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/),
                              {
                                "kind": "RequiredField",
                                "field": (v8/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.cardLayoutConfig.backlog.fields.name"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v9/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.cardLayoutConfig.backlog.fields.isValid"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.cardLayoutConfig.backlog.fields"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JswAvailableCardLayoutFieldConnection",
                            "kind": "LinkedField",
                            "name": "availableFields",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "JswAvailableCardLayoutFieldEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "JswAvailableCardLayoutField",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "RequiredField",
                                            "field": (v8/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.cardLayoutConfig.backlog.availableFields.edges.node.name"
                                          },
                                          (v6/*: any*/),
                                          {
                                            "kind": "RequiredField",
                                            "field": (v9/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.cardLayoutConfig.backlog.availableFields.edges.node.isValid"
                                          }
                                        ]
                                      },
                                      "action": "THROW",
                                      "path": "boardScope.cardLayoutConfig.backlog.availableFields.edges.node"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "boardScope.cardLayoutConfig.backlog.availableFields.edges"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.cardLayoutConfig.backlog.availableFields"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.cardLayoutConfig.backlog"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JswCardLayoutContainer",
                      "kind": "LinkedField",
                      "name": "board",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JswCurrentCardLayoutField",
                            "kind": "LinkedField",
                            "name": "fields",
                            "plural": true,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/),
                              {
                                "kind": "RequiredField",
                                "field": (v8/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.cardLayoutConfig.board.fields.name"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v9/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.cardLayoutConfig.board.fields.isValid"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.cardLayoutConfig.board.fields"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JswAvailableCardLayoutFieldConnection",
                            "kind": "LinkedField",
                            "name": "availableFields",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "JswAvailableCardLayoutFieldEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "JswAvailableCardLayoutField",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "RequiredField",
                                            "field": (v8/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.cardLayoutConfig.board.availableFields.edges.node.name"
                                          },
                                          (v6/*: any*/),
                                          {
                                            "kind": "RequiredField",
                                            "field": (v9/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.cardLayoutConfig.board.availableFields.edges.node.isValid"
                                          }
                                        ]
                                      },
                                      "action": "THROW",
                                      "path": "boardScope.cardLayoutConfig.board.availableFields.edges.node"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "boardScope.cardLayoutConfig.board.availableFields.edges"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.cardLayoutConfig.board.availableFields"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.cardLayoutConfig.board"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.cardLayoutConfig"
            }
          ]
        },
        "action": "THROW",
        "path": "boardScope"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cardLayoutSettingsPageQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "BoardFeature",
            "kind": "LinkedField",
            "name": "features",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          },
          {
            "concreteType": "SoftwareBoard",
            "kind": "LinkedField",
            "name": "board",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v10/*: any*/)
            ]
          },
          {
            "concreteType": "JswCardLayoutConfig",
            "kind": "LinkedField",
            "name": "cardLayoutConfig",
            "plural": false,
            "selections": [
              {
                "concreteType": "JswCardLayoutContainer",
                "kind": "LinkedField",
                "name": "backlog",
                "plural": false,
                "selections": (v11/*: any*/)
              },
              {
                "concreteType": "JswCardLayoutContainer",
                "kind": "LinkedField",
                "name": "board",
                "plural": false,
                "selections": (v11/*: any*/)
              }
            ]
          },
          (v10/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "e2d9f64dbab3e3e369272ea7348936a3914de7cdea424ac9f623dc73e7a8b2ff",
    "metadata": {},
    "name": "cardLayoutSettingsPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a7fa209482d066e6ba737c91ffa3c807";

export default node;
