import { createHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const getIsSprintSupportEnabled = (state: State) => state.isSprintSupportEnabled;

export const useIsSprintSupportEnabled = createHook(Store, {
	selector: getIsSprintSupportEnabled,
});

export const getIsBoardCrossProject = (state: State) => state.isBoardCrossProject;

export const useIsBoardCrossProject = createHook(Store, {
	selector: getIsBoardCrossProject,
});

export const getIsChildIssuePlanningEnabled = (state: State) => state.isChildIssuePlanningEnabled;

export const useIsChildIssuePlanningEnabled = createHook(Store, {
	selector: getIsChildIssuePlanningEnabled,
});

export const getPrefersChildIssueDatePlanning = (state: State) =>
	state.prefersChildIssueDatePlanning;

export const usePrefersChildIssueDatePlanning = createHook(Store, {
	selector: getPrefersChildIssueDatePlanning,
});

export const getIsRoadmapEnabled = (state: State) => state.isRoadmapEnabled;

export const useIsRoadmapEnabled = createHook(Store, {
	selector: getIsRoadmapEnabled,
});
