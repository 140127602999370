import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	groupPermission: {
		id: 'shareable-entity-dialog.access.sharee-list.sharee-list-row.group-permission',
		defaultMessage: 'Group {groupName}',
		description: 'Text identifying a share permission targeted at the group level',
	},
	projectPermission: {
		id: 'shareable-entity-dialog.access.sharee-list.sharee-list-row.project-permission',
		defaultMessage: 'Project {projectName}, {projectRole}',
		description: 'Text identifying a share permission targeted at the project level',
	},
	userPermission: {
		id: 'shareable-entity-dialog.access.sharee-list.sharee-list-row.user-permission',
		defaultMessage: 'User {displayName}',
		description: 'Text identifying a share permission targeted at the user level',
	},
	removeViewerSharePermissionButton: {
		id: 'shareable-entity-dialog.access.sharee-list.sharee-list-row.remove-viewer-share-permission-button',
		defaultMessage: 'Remove viewer permission for {sharePermission}',
		description: 'Label for button which allows user to remove a share permission for editing',
	},
	removeEditorSharePermissionButton: {
		id: 'shareable-entity-dialog.access.sharee-list.sharee-list-row.remove-editor-share-permission-button',
		defaultMessage: 'Remove editor permission for {sharePermission}',
		description: 'Label for button which allows user to remove a share permission for viewing',
	},
});
