import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-board-settings-general-completed-issues-settings.completed-issues-section.completed-issues-field.label',
		defaultMessage: 'Select time frame',
		description: 'An input field to select input for ticket clearance time.',
	},
	updateCompletedIssuesSuccessfulTitle: {
		id: 'software-board-settings-general-completed-issues-settings.completed-issues-section.completed-issues-field.update-completed-issues-successful-title',
		defaultMessage: 'The time frame has been updated',
		description:
			'A confirmation message shown after successfully updating the completed issues title',
	},
	updateCompletedIssuesFailureTitle: {
		id: 'software-board-settings-general-completed-issues-settings.completed-issues-section.completed-issues-field.update-completed-issues-failure-title',
		defaultMessage: 'We couldn’t update the time frame',
		description: 'Title of the error flag when failing to update completed issues',
	},
	updateCompletedIssuesFailureDescription: {
		id: 'software-board-settings-general-completed-issues-settings.completed-issues-section.completed-issues-field.update-completed-issues-failure-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description of the error flag when failing to update completed issues',
	},
});
