import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dragHandleLabel: {
		id: 'software-board-settings-sortable-table.sortable-table.drag-handle.drag-handle-button.drag-handle-label',
		defaultMessage: 'Change order for {name}',
		description:
			'Label text for a button which shows a menu allowing the user to change order for a list item.',
	},
});
