/**
 * @generated SignedSource<<4214e262fa9e46da8f7accf59d4054a4>>
 * @relayHash 23ef6079f326a72c8bda72ae3506506c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7d6679951c89abda404e6f3f7cce71cbc490092385417c7266ffbc1e75922452

import type { ConcreteRequest, Query } from 'relay-runtime';
export type BoardFeatureStatus = "COMING_SOON" | "DISABLED" | "ENABLED" | "%future added value";
export type columnsSettingsPageQuery$variables = {
  boardId: string;
  cloudId: string;
};
export type columnsSettingsPageQuery$data = {
  readonly boardScope: {
    readonly board: {
      readonly columnConfigs: {
        readonly constraintsStatisticsField: {
          readonly availableConstraints: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
          } | null | undefined>;
          readonly currentId: string;
        };
        readonly workflow: {
          readonly canSimplifyWorkflow: boolean | null | undefined;
          readonly isProjectAdminOfSimplifiedWorkflow: boolean | null | undefined;
          readonly userCanSimplifyWorkflow: boolean | null | undefined;
          readonly usingSimplifiedWorkflow: boolean;
        };
      };
      readonly columns: ReadonlyArray<{
        readonly columnStatus: ReadonlyArray<{
          readonly status: {
            readonly category: string;
            readonly id: string;
            readonly isPresentInWorkflow: boolean;
            readonly isResolutionDone: boolean | null | undefined;
            readonly issueMetaData: {
              readonly issueCount: number;
            };
            readonly name: string;
          };
        }>;
        readonly id: string;
        readonly isKanPlanColumn: boolean;
        readonly maxCardCount: number | null | undefined;
        readonly minCardCount: number | null | undefined;
        readonly name: string;
      } | null | undefined>;
      readonly showEpicAsPanel: boolean;
      readonly unmappedStatuses: ReadonlyArray<{
        readonly category: string;
        readonly id: string;
        readonly isPresentInWorkflow: boolean;
        readonly isResolutionDone: boolean | null | undefined;
        readonly issueMetaData: {
          readonly issueCount: number;
        };
        readonly name: string;
      } | null | undefined>;
    };
    readonly currentUser: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
    readonly features: ReadonlyArray<{
      readonly key: AGG$SoftwareBoardFeatureKey;
      readonly status: BoardFeatureStatus;
    } | null | undefined>;
    readonly id: string;
  };
  readonly jira: {
    readonly issueHierarchyConfig: {
      readonly data: ReadonlyArray<{
        readonly hierarchyLevel: {
          readonly level: number;
          readonly name: string;
        };
      }>;
    };
  };
};
export type columnsSettingsPageQuery = {
  response: columnsSettingsPageQuery$data;
  variables: columnsSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "level"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  {
    "kind": "Literal",
    "name": "isCMP",
    "value": true
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "permissions"
},
v7 = {
  "kind": "ScalarField",
  "name": "key"
},
v8 = {
  "kind": "ScalarField",
  "name": "status"
},
v9 = {
  "kind": "ScalarField",
  "name": "showEpicAsPanel"
},
v10 = {
  "kind": "ScalarField",
  "name": "isKanPlanColumn"
},
v11 = {
  "kind": "ScalarField",
  "name": "maxCardCount"
},
v12 = {
  "kind": "ScalarField",
  "name": "minCardCount"
},
v13 = {
  "kind": "ScalarField",
  "name": "category"
},
v14 = {
  "kind": "ScalarField",
  "name": "issueCount"
},
v15 = {
  "kind": "ScalarField",
  "name": "isPresentInWorkflow"
},
v16 = {
  "kind": "ScalarField",
  "name": "isResolutionDone"
},
v17 = {
  "kind": "ScalarField",
  "name": "usingSimplifiedWorkflow"
},
v18 = {
  "kind": "ScalarField",
  "name": "isProjectAdminOfSimplifiedWorkflow"
},
v19 = {
  "kind": "ScalarField",
  "name": "canSimplifyWorkflow"
},
v20 = {
  "kind": "ScalarField",
  "name": "userCanSimplifyWorkflow"
},
v21 = {
  "kind": "ScalarField",
  "name": "currentId"
},
v22 = [
  (v5/*: any*/),
  (v3/*: any*/),
  (v13/*: any*/),
  {
    "concreteType": "JswCardStatusIssueMetaData",
    "kind": "LinkedField",
    "name": "issueMetaData",
    "plural": false,
    "selections": [
      (v14/*: any*/)
    ]
  },
  (v15/*: any*/),
  (v16/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "columnsSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "concreteType": "JiraIssueHierarchyConfigurationQuery",
                "kind": "LinkedField",
                "name": "issueHierarchyConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JiraIssueHierarchyConfigData",
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JiraIssueTypeHierarchyLevel",
                            "kind": "LinkedField",
                            "name": "hierarchyLevel",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v2/*: any*/),
                                "action": "THROW",
                                "path": "jira.issueHierarchyConfig.data.hierarchyLevel.level"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v3/*: any*/),
                                "action": "THROW",
                                "path": "jira.issueHierarchyConfig.data.hierarchyLevel.name"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "jira.issueHierarchyConfig.data.hierarchyLevel"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.issueHierarchyConfig.data"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.issueHierarchyConfig"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      },
      {
        "kind": "RequiredField",
        "field": {
          "args": (v4/*: any*/),
          "concreteType": "BoardScope",
          "kind": "LinkedField",
          "name": "boardScope",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": (v5/*: any*/),
              "action": "THROW",
              "path": "boardScope.id"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v6/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.currentUser.permissions"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.currentUser"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "BoardFeature",
                "kind": "LinkedField",
                "name": "features",
                "plural": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v7/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.key"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v8/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.status"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.features"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "SoftwareBoard",
                "kind": "LinkedField",
                "name": "board",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v9/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.board.showEpicAsPanel"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "Column",
                      "kind": "LinkedField",
                      "name": "columns",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v5/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.columns.id"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v10/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.columns.isKanPlanColumn"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v3/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.columns.name"
                        },
                        (v11/*: any*/),
                        (v12/*: any*/),
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "ColumnStatus",
                            "kind": "LinkedField",
                            "name": "columnStatus",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "CardStatus",
                                  "kind": "LinkedField",
                                  "name": "status",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v5/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.status.id"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v3/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.status.name"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v13/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.status.category"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "JswCardStatusIssueMetaData",
                                        "kind": "LinkedField",
                                        "name": "issueMetaData",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "RequiredField",
                                            "field": (v14/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.board.columns.columnStatus.status.issueMetaData.issueCount"
                                          }
                                        ]
                                      },
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.status.issueMetaData"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v15/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.status.isPresentInWorkflow"
                                    },
                                    (v16/*: any*/)
                                  ]
                                },
                                "action": "THROW",
                                "path": "boardScope.board.columns.columnStatus.status"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.board.columns.columnStatus"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.board.columns"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "CardStatus",
                      "kind": "LinkedField",
                      "name": "unmappedStatuses",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v5/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.unmappedStatuses.id"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v3/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.unmappedStatuses.name"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v13/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.unmappedStatuses.category"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JswCardStatusIssueMetaData",
                            "kind": "LinkedField",
                            "name": "issueMetaData",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v14/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.unmappedStatuses.issueMetaData.issueCount"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.board.unmappedStatuses.issueMetaData"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v15/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.unmappedStatuses.isPresentInWorkflow"
                        },
                        (v16/*: any*/)
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.board.unmappedStatuses"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "ColumnsConfig",
                      "kind": "LinkedField",
                      "name": "columnConfigs",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "ColumnWorkflowConfig",
                            "kind": "LinkedField",
                            "name": "workflow",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v17/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.columnConfigs.workflow.usingSimplifiedWorkflow"
                              },
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v20/*: any*/)
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.board.columnConfigs.workflow"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "ColumnConstraintStatisticConfig",
                            "kind": "LinkedField",
                            "name": "constraintsStatisticsField",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v21/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.columnConfigs.constraintsStatisticsField.currentId"
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "AvailableColumnConstraintStatistics",
                                  "kind": "LinkedField",
                                  "name": "availableConstraints",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v5/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columnConfigs.constraintsStatisticsField.availableConstraints.id"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v3/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columnConfigs.constraintsStatisticsField.availableConstraints.name"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "boardScope.board.columnConfigs.constraintsStatisticsField.availableConstraints"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.board.columnConfigs.constraintsStatisticsField"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.board.columnConfigs"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.board"
            }
          ]
        },
        "action": "THROW",
        "path": "boardScope"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "columnsSettingsPageQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssueHierarchyConfigurationQuery",
            "kind": "LinkedField",
            "name": "issueHierarchyConfig",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueHierarchyConfigData",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeHierarchyLevel",
                    "kind": "LinkedField",
                    "name": "hierarchyLevel",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "args": (v4/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "concreteType": "CurrentUser",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ]
          },
          {
            "concreteType": "BoardFeature",
            "kind": "LinkedField",
            "name": "features",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          },
          {
            "concreteType": "SoftwareBoard",
            "kind": "LinkedField",
            "name": "board",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "concreteType": "Column",
                "kind": "LinkedField",
                "name": "columns",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v10/*: any*/),
                  (v3/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "concreteType": "ColumnStatus",
                    "kind": "LinkedField",
                    "name": "columnStatus",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "CardStatus",
                        "kind": "LinkedField",
                        "name": "status",
                        "plural": false,
                        "selections": (v22/*: any*/)
                      }
                    ]
                  }
                ]
              },
              {
                "concreteType": "CardStatus",
                "kind": "LinkedField",
                "name": "unmappedStatuses",
                "plural": true,
                "selections": (v22/*: any*/)
              },
              {
                "concreteType": "ColumnsConfig",
                "kind": "LinkedField",
                "name": "columnConfigs",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "ColumnWorkflowConfig",
                    "kind": "LinkedField",
                    "name": "workflow",
                    "plural": false,
                    "selections": [
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/)
                    ]
                  },
                  {
                    "concreteType": "ColumnConstraintStatisticConfig",
                    "kind": "LinkedField",
                    "name": "constraintsStatisticsField",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      {
                        "concreteType": "AvailableColumnConstraintStatistics",
                        "kind": "LinkedField",
                        "name": "availableConstraints",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "7d6679951c89abda404e6f3f7cce71cbc490092385417c7266ffbc1e75922452",
    "metadata": {},
    "name": "columnsSettingsPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b428103c1fb67b8c21a8fe0090a5f799";

export default node;
