import { useMemo } from 'react';
import memoizeOne from 'memoize-one';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { COLOR_CARD_SIZE } from '../../../common/constants/index.tsx';
import type { PaletteColor } from '../../../common/types.tsx';
import { useDefaultI18nColorPalette } from '../../../controllers/use-default-color-palette/index.tsx';
import messages from './messages.tsx';

export const getWidth = (cols: number) => cols * (COLOR_CARD_SIZE + gridSize / 2);

export const getSelectedValueInPalette = memoizeOne((palette: PaletteColor[], selectedColor = '') =>
	palette.find((color) => color.value.toLowerCase() === selectedColor.toLowerCase()),
);

export const useColorPaletteMenuLabel = (label: string, selectedColor?: string) => {
	const palette = useDefaultI18nColorPalette();

	const { formatMessage } = useIntl();

	return useMemo(() => {
		const selectedValueInPalette = getSelectedValueInPalette(palette, selectedColor);
		if (selectedValueInPalette) {
			return formatMessage(messages.labelWithSelectedColor, {
				label,
				selectedColorLabel: selectedValueInPalette.label,
			});
		}

		if (selectedColor) {
			return formatMessage(messages.labelWithSelectedCustomColor, {
				label,
				selectedColorLabel: selectedColor,
			});
		}

		return label;
	}, [formatMessage, label, palette, selectedColor]);
};
