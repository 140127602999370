export type FocusDirective = { location: 'row'; index: number } | { location: 'external' };

export const determineFocusFromDiff = function <ID>(
	prev: readonly { id: ID }[],
	curr: readonly { id: ID }[],
): FocusDirective | undefined {
	const currLen = curr.length;
	const prevLen = prev.length;

	if (currLen >= prevLen) {
		return;
	}

	if (currLen === 0) {
		return { location: 'external' };
	}

	if (currLen === 1) {
		return { location: 'row', index: 0 };
	}

	const removedIndex = prev.findIndex((p, i) => p.id !== curr[i]?.id);
	const focusIndex = removedIndex <= 0 ? 0 : removedIndex - 1;
	return { location: 'row', index: focusIndex };
};
