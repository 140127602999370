import isNil from 'lodash/isNil';

export const KNOWN_NETWORK_ERRORS: string[] = [
	'A server with the specified hostname could not be found',
	'Failed to fetch',
	'NetworkError when attempting to fetch resource',
	'The request timed out',
	'Type error',
	'cancelled',
	'cannot parse response',
	'upstream connect error or disconnect/reset before headers. reset reason: connection failure',
	'JSON.parse: unexpected character at line 1 column 1 of the JSON data',
];

export const isClientNetworkError = (message: string): boolean =>
	!isNil(KNOWN_NETWORK_ERRORS.find((knownMessage: string) => message.includes(knownMessage)));
