import React, { useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { Field, HelperMessage } from '@atlaskit/form';
import Select, { type ValueType } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import { DEFAULT_LEVEL_ONE_NAME } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { SwimlaneStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { dataTestIds } from '../../../common/constants/data-test.tsx';
import { SWIMLANE_STRATEGY_FIELD_WIDTH } from '../../../common/constants/swimlanes.tsx';
import type { SwimlaneStrategyTransition } from '../../../common/types/index.tsx';
import { useLevelOneName } from '../../../controllers/selectors/global-config/index.tsx';
import { useCanEdit } from '../../../controllers/selectors/permissions/index.tsx';
import messages from './messages.tsx';
import type { SwimlaneStrategyFieldProps, SwimlaneStrategyOption } from './types.tsx';
import { getStrategyHelperMessage } from './utils.tsx';

const getDisplayedStrategy = (t: SwimlaneStrategyTransition) => {
	const shown = t.next ?? t.current;
	return shown === SwimlaneStrategy.assigneeUnassignedFirst
		? SwimlaneStrategy.assigneeUnassignedLast
		: shown;
};

export const SwimlaneStrategyField = ({
	transition,
	onChange = noop,
}: SwimlaneStrategyFieldProps) => {
	const { formatMessage } = useIntl();
	const [canEdit] = useCanEdit();
	const [levelOneName] = useLevelOneName();
	const isDefaultLevelOneName = !levelOneName || levelOneName === DEFAULT_LEVEL_ONE_NAME;
	const displayedStrategy = getDisplayedStrategy(transition);
	const loading = transition.kind === 'in-progress';

	const isJSMBoard = useIsJSMBoard();

	const swimlaneStrategyOptions = useMemo(
		() => [
			...(isJSMBoard
				? [
						{
							label: formatMessage(messages.requestTypeOptionLabel),
							value: SwimlaneStrategy.requestType,
						},
					]
				: []),
			{
				label: formatMessage(messages.queriesOptionLabel),
				value: SwimlaneStrategy.custom,
			},
			...(!isJSMBoard
				? [
						{
							label: formatMessage(messages.storiesOptionLabel),
							value: SwimlaneStrategy.parentChild,
						},
					]
				: []),
			{
				label: formatMessage(messages.assigneeOptionLabel),
				value: SwimlaneStrategy.assigneeUnassignedLast,
			},
			...(!isJSMBoard
				? [
						{
							label:
								isDefaultLevelOneName || !levelOneName
									? formatMessage(messages.epicsOptionLabel)
									: levelOneName,
							value: SwimlaneStrategy.epic,
						},
						{
							label: formatMessage(messages.projectsOptionLabel),
							value: SwimlaneStrategy.project,
						},
					]
				: []),
			{ label: formatMessage(messages.noneOptionLabel), value: SwimlaneStrategy.none },
		],
		[formatMessage, isDefaultLevelOneName, isJSMBoard, levelOneName],
	);

	const { currentSwimlaneStrategy, helperMessage } = useMemo(() => {
		const current =
			swimlaneStrategyOptions.find(({ value }) => value === displayedStrategy) ?? null;

		return {
			currentSwimlaneStrategy: current,
			helperMessage: current
				? getStrategyHelperMessage(current.value, isDefaultLevelOneName)
				: null,
		};
	}, [displayedStrategy, isDefaultLevelOneName, swimlaneStrategyOptions]);

	const onSelect = useCallback(
		(option: SwimlaneStrategyOption | null) => {
			option?.value && onChange(option.value);
		},
		[onChange],
	);

	return (
		<Field<ValueType<SwimlaneStrategyOption>>
			id="swimlaneStrategy"
			label={formatMessage(messages.fieldLabel)}
			name="swimlaneStrategy"
		>
			{({ fieldProps: { id, ...otherFieldProps } }) => (
				<>
					<SwimlaneStrategyFieldWrapper data-testid={dataTestIds.swimlanesStrategyField}>
						<Select
							options={swimlaneStrategyOptions}
							inputId={id}
							{...otherFieldProps}
							value={currentSwimlaneStrategy}
							isLoading={loading}
							onChange={onSelect}
							name="swimlaneStrategy"
							isDisabled={!canEdit}
							instanceId="swimlane-strategy"
						/>
					</SwimlaneStrategyFieldWrapper>
					{helperMessage && (
						<HelperMessage>{formatMessage(helperMessage, { levelOneName })}</HelperMessage>
					)}
				</>
			)}
		</Field>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneStrategyFieldWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${SWIMLANE_STRATEGY_FIELD_WIDTH}px`,
});
