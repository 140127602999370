import React, { useMemo, type PropsWithChildren } from 'react';
import { graphql, usePreloadedQuery, type EntryPointProps } from 'react-relay';
import { useBoardId } from '@atlassian/jira-board-settings-common/src/common/utils/use-board-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import type { cardLayoutSettingsPageQuery } from '@atlassian/jira-relay/src/__generated__/cardLayoutSettingsPageQuery.graphql';
import { editModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import type { RapidBoardConfig } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/types/index.tsx';
import { BoardSettingsCardLayoutSkeleton } from '@atlassian/jira-skeletons/src/ui/board-settings/BoardSettingsCardLayoutSkeleton.tsx';
import { EDIT_BOARD_CONFIG } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import type { CardLayoutConfigProps } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
import { InvalidStateHandler } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/invalid-state-handler/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { CardLayoutConfigDataContainer } from '../../controllers/main.tsx';
import { CardLayoutSettingsContainer } from './main.tsx';
import { transformCardLayoutData } from './utils.tsx';

// Deprecated with simplified-boards-milestone-2_07hbk
const CardLayoutSettingsPage = (cardLayoutConfig: CardLayoutConfigProps) => (
	<JiraRelayEnvironmentProvider>
		<CardLayoutConfigDataContainer {...cardLayoutConfig}>
			<CardLayoutSettingsContainer />
		</CardLayoutConfigDataContainer>
	</JiraRelayEnvironmentProvider>
);

type Query = { queryReference: cardLayoutSettingsPageQuery };

export const CardLayoutSettingPageContent = (
	entryPointProps: EntryPointProps<Query, {}, {}, {}>,
) => {
	if (fg('board-settings-graphql-refactor_cyvz9')) {
		return <GraphQlPageContent {...entryPointProps} />;
	}

	return <EditModelPageContent />;
};

const GraphQlPageContent = ({
	queries: { queryReference },
}: EntryPointProps<Query, {}, {}, {}>) => {
	/* eslint-disable @atlassian/relay/unused-fields */
	const { boardScope } = usePreloadedQuery(
		graphql`
			query cardLayoutSettingsPageQuery($boardId: ID!) {
				boardScope(boardId: $boardId, isCMP: true) @required(action: THROW) {
					currentUser {
						permissions
					}
					features @required(action: THROW) {
						key @required(action: THROW)
						status @required(action: THROW)
					}
					board @required(action: THROW) {
						showDaysInColumn @required(action: THROW)
					}
					cardLayoutConfig @optIn(to: "modularBoardSettings") @required(action: THROW) {
						backlog @required(action: THROW) {
							fields @required(action: THROW) {
								fieldId
								cardLayoutId
								name @required(action: THROW)
								isValid @required(action: THROW)
							}
							availableFields @required(action: THROW) {
								edges @required(action: THROW) {
									node @required(action: THROW) {
										name @required(action: THROW)
										fieldId
										isValid @required(action: THROW)
									}
								}
							}
						}
						board @required(action: THROW) {
							fields @required(action: THROW) {
								fieldId
								cardLayoutId
								name @required(action: THROW)
								isValid @required(action: THROW)
							}
							availableFields @required(action: THROW) {
								edges @required(action: THROW) {
									node @required(action: THROW) {
										name @required(action: THROW)
										fieldId
										isValid @required(action: THROW)
									}
								}
							}
						}
					}
				}
			}
		`,
		queryReference,
	);

	const rapidViewId = useBoardId();

	if (!rapidViewId) {
		throw new Error('Card layout page failed to load');
	}

	const canEdit = boardScope.currentUser.permissions.includes(EDIT_BOARD_CONFIG);

	// TODO: remove all this transform logic once card layout page is refactored to adopt relay fully
	const cardLayoutInitProps: CardLayoutConfigProps = useMemo(() => {
		const transformedData = transformCardLayoutData(boardScope);

		return {
			rapidViewId,
			canEdit,
			...transformedData,
		};
	}, [boardScope, canEdit, rapidViewId]);

	return (
		<CardLayoutConfigDataContainer {...cardLayoutInitProps}>
			<CardLayoutSettingsContainer />
			<SpaStatePageReady />
		</CardLayoutConfigDataContainer>
	);
};

const EditModelPageContent = () => {
	const { data, error, loading } = useResource(editModelResource);

	if (loading) {
		return <BoardSettingsCardLayoutSkeleton />;
	}

	if (error || !data) {
		return <InvalidStateHandler error={error} />;
	}

	return (
		<>
			<CardLayoutSettingsContainer />
			<SpaStatePageReady />
		</>
	);
};

export const CardLayoutSettingsEditModelProvider = ({
	data,
	children,
}: PropsWithChildren<{
	data: RapidBoardConfig;
}>) => {
	const {
		id: rapidViewId,
		canEdit,
		showDaysInColumn,
		isKanPlanEnabled,
		isSprintSupportEnabled,
	} = data;

	return (
		<CardLayoutConfigDataContainer
			rapidViewId={rapidViewId}
			canEdit={canEdit}
			showDaysInColumn={showDaysInColumn}
			isKanPlanEnabled={isKanPlanEnabled}
			isSprintSupportEnabled={isSprintSupportEnabled}
			isGlobal
		>
			{children}
		</CardLayoutConfigDataContainer>
	);
};

export default CardLayoutSettingsPage;
