import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-estimation.estimation-settings-page.time-tracking.title',
		defaultMessage: 'Time tracking',
		description: 'Title for the time tracking section of the estimations statistics page',
	},
	description: {
		id: 'software-board-settings-estimation.estimation-settings-page.time-tracking.description',
		defaultMessage: 'Track the actual time spent on an issue against the estimated time remaining.',
		description: 'Description for the time tracking feature toggle',
	},
	inProductHelp: {
		id: 'software-board-settings-estimation.estimation-settings-page.time-tracking.in-product-help',
		defaultMessage: '<button>Read more about time tracking</button>.',
		description: 'Text for in-product help link for time tracking feature.',
	},
	fieldLabel: {
		id: 'software-board-settings-estimation.estimation-settings-page.time-tracking.field-label',
		defaultMessage: 'Time tracking',
		description: 'Field label for the time tracking toggle',
	},
	successFlagTitleForEnabledTimeTracking: {
		id: 'software-board-settings-estimation.estimation-settings-page.time-tracking.success-flag-title-for-enabled-time-tracking',
		defaultMessage: "You've turned on time tracking",
		description: 'Success flag title when you turned on the time tracking option.',
	},
	successFlagTitleForDisabledTimeTracking: {
		id: 'software-board-settings-estimation.estimation-settings-page.time-tracking.success-flag-title-for-disabled-time-tracking',
		defaultMessage: "You've turned off time tracking",
		description: 'Success flag title when you turned off the time tracking option.',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-settings-estimation.estimation-settings-page.time-tracking.description-issue-term-refresh',
		defaultMessage:
			'Track the actual time spent on a work item against the estimated time remaining.',
		description: 'Description for the time tracking feature toggle',
	},
});
