import React, { useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { useBoardFilterConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { FilterDetailsHeader } from '../../../common/ui/filter-details-header/index.tsx';
import messages from './messages.tsx';
import { PermissionModal } from './permission-modal/index.tsx';
import { PermissionTable } from './permission-table/index.tsx';

export const FilterPermissions = () => {
	const [{ canEdit }] = useBoardFilterConfig();
	const { formatMessage } = useIntl();
	const isJSMBoard = useIsJSMBoard();

	const [showModal, setShowModal] = useState(false);
	const requestModal = useCallback(() => setShowModal(true), []);
	const requestModalDismiss = useCallback(() => setShowModal(false), []);

	return (
		<Container data-testid="software-board-settings-general-filter-settings.ui.board-filter-details.filter-permissions.permissions-container">
			{!isJSMBoard && fg('simplified-boards-milestone-2_07hbk') ? (
				<FilterDetailsHeader>
					<FormattedMessage {...messages.heading} />
				</FilterDetailsHeader>
			) : (
				<Heading as="h5" size="xxsmall">
					<HeadingInner>
						<FormattedMessage {...messages.heading} />
					</HeadingInner>
				</Heading>
			)}
			<About>
				<FormattedMessage {...messages.about} />
			</About>
			<TableContainer>
				<PermissionTable />
			</TableContainer>
			{/* The element container makes direct descendants
			 * full width, hence the wrapping div here */}
			<Button
				aria-label={formatMessage(messages.editPermissionButtonArialLabel)}
				onClick={requestModal}
				isDisabled={!canEdit}
				testId="software-board-settings-general-filter-settings.ui.board-filter-details.filter-permissions.permissions-button"
			>
				<FormattedMessage {...messages.editPermissions} />
			</Button>
			{showModal && <PermissionModal onDismiss={requestModalDismiss} />}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'grid',
	paddingTop: token('space.100', '8px'),
	gap: token('space.100', '8px'),
	gridTemplateColumns: 'auto 1fr',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableContainer = styled.div({
	gridColumn: '1 / 3',
	paddingTop: token('space.075', '6px'),
	paddingBottom: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingInner = styled.span({
	color: token('color.text.subtle', '#44546F'),
	textTransform: 'uppercase',
	font: token('font.heading.xxsmall'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'h5:has(> &)': {
		gridColumn: '1 / 3',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const About = styled.p({
	gridColumn: '1 / 3',
	marginTop: '0',
});
