import type { BoardSwimlaneStrategy } from '@atlassian/jira-relay/src/__generated__/swimlanesSettingsPageQuery.graphql';
import { SwimlaneStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';

// This is required since we use a frontend-defined SwimlaneStrategy object + typing which doesn't match
// up 1-1 with the Relay response. Ideally we should remove this and use the Relay type directly.
export const transformSwimlaneStrategy = (strategy: BoardSwimlaneStrategy): SwimlaneStrategy => {
	switch (strategy) {
		case 'CUSTOM':
			return SwimlaneStrategy.custom;
		case 'PARENT_CHILD':
			return SwimlaneStrategy.parentChild;
		case 'ASSIGNEE_UNASSIGNED_LAST':
			return SwimlaneStrategy.assigneeUnassignedLast;
		case 'ASSIGNEE_UNASSIGNED_FIRST':
			return SwimlaneStrategy.assigneeUnassignedFirst;
		case 'EPIC':
			return SwimlaneStrategy.epic;
		case 'PROJECT':
			return SwimlaneStrategy.project;
		case 'ISSUE_PARENT':
			return SwimlaneStrategy.issueParent;
		case 'ISSUE_CHILDREN':
			return SwimlaneStrategy.issueChildren;
		case 'REQUEST_TYPE':
			return SwimlaneStrategy.requestType;
		case 'NONE':
		default:
			return SwimlaneStrategy.none;
	}
};
