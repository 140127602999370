import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-board-settings-general-settings.board-name-field.label',
		defaultMessage: 'Board name',
		description: 'Lable for the board name text editor',
	},
	editButtonAccessibleLabel: {
		id: 'software-board-settings-general-settings.board-name-field.edit-button-accessible-label',
		defaultMessage: 'Edit board name, current name is {name}',
		description:
			'Button label for a button that shows a text field for the board field. {name} will be replace with real board name',
	},
	updateBoardNameSuccessfulTitle: {
		id: 'software-board-settings-general-settings.board-name-field.update-board-name-successful-title',
		defaultMessage: 'Your board name has been updated',
		description: 'A confirmation message shown after successfully updating the board name title',
	},
	updateBoardnameFailureTitle: {
		id: 'software-board-settings-general-settings.board-name-field.update-boardname-failure-title',
		defaultMessage: 'We couldn’t apply your changes',
		description: 'Title of the error flag when failing to update board name',
	},
	updateBoardnameFailureDescription: {
		id: 'software-board-settings-general-settings.board-name-field.update-boardname-failure-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description of the error flag when failing to update board name',
	},
});
