import type { GeneralConfigProps } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
import {
	type StoreActionApi,
	createContainer,
	createStore,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import type { State } from '../common/types/index.tsx';
import { type Actions, actions } from './actions/index.tsx';

const INITIAL_STATE: State = {
	isInitialised: false,
	rapidViewId: -1,
	canEdit: false,
	warnBeforeEditingOwner: true,
	warnBeforeEditingLocation: true,
	isCMPBoard: false,
	boardLocationModel: {
		name: '',
		avatarURI: '',
	},
	userConfig: {
		accountId: '',
		name: '',
	},
	isSprintSupportEnabled: false,
	canUseBoardAdminsPicker: false,
	subqueryConfig: {
		subqueries: [],
	},
	name: '',
	filterConfig: {
		id: -1,
		canEdit: false,
		description: '',
		name: '',
		query: '',
		editPermissionEntries: [],
		sharePermissionEntries: [],
		queryProjects: {
			projects: [],
		},
		isOrderedByRank: false,
	},
	oldDoneIssuesCutoff: '',
	oldDoneIssuesCutoffOptions: [],
	savedFilters: [],
	boardAdmins: {
		userKeys: [],
		groupKeys: [],
	},
};

export const Store = createStore<State, Actions>({
	name: 'GeneralSettingsState',
	initialState: INITIAL_STATE,
	actions,
});

export const GeneralSettingsDataContainer = createContainer<State, Actions, GeneralConfigProps>(
	Store,
	{
		onInit:
			() =>
			({ setState, getState }: StoreActionApi<State>, generalConfig: GeneralConfigProps) => {
				setState({
					...getState(),
					...generalConfig,
					isInitialised: true,
				});
			},
	},
);

const useGeneralSettingsDataActions = createActionsHook(Store);
export const useRemoveEditAccess = () => useGeneralSettingsDataActions().removeEditAccess;
export const useUpdateBoardName = () => useGeneralSettingsDataActions().updateBoardName;
export const useUpdateBoardAdmins = () => useGeneralSettingsDataActions().updateBoardAdmins;
export const useUpdateBoardLocation = () => useGeneralSettingsDataActions().updateBoardLocation;
