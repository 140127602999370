import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	group: {
		id: 'shareable-entity-dialog.model.group',
		defaultMessage: 'Group',
		description:
			'Type picker option group, if the user picks this type, they will share the entity with a Jira group type i.e. Administrators',
	},
	project: {
		id: 'shareable-entity-dialog.model.project',
		defaultMessage: 'Project',
		description:
			'Type picker option project, if the user picks this type, they will share the entity with the collaborators of a Jira project',
	},
	private: {
		id: 'shareable-entity-dialog.model.private',
		defaultMessage: 'Private',
		description:
			"Type picker option private, if the user picks this type, they won't share the entity",
	},
	user: {
		id: 'shareable-entity-dialog.model.user',
		defaultMessage: 'User',
		description:
			'Type picker option user, if the user picks this type, they will share the entity will specified user',
	},
	myOrganization: {
		id: 'shareable-entity-dialog.model.my-organization',
		defaultMessage: 'My organization',
		description:
			'Type picker option Open, if the user picks this type, they will share the entity with everybody in the Jira Instance',
	},
	public: {
		id: 'shareable-entity-dialog.model.public',
		defaultMessage: 'Public',
		description:
			'Type picker option Public, if the user picks this type, they will share the entity with everybody, even anonymous users',
	},
	unknownProject: {
		id: 'shareable-entity-dialog.model.unknown-project',
		defaultMessage: 'Unknown project',
		description: 'UI placeholder for projects that the user does not have permissions to view',
	},
});
