import React, { type ChangeEvent, useState, useCallback } from 'react';
import type { OptionsPropType } from '@atlaskit/radio/types';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	SPRINT_DATES_SCHEDULING,
	START_AND_DUE_DATE_SCHEDULING,
} from '../../../common/constants/timeline.tsx';
import { SuccessFlagWithTimelineLink } from '../../../common/ui/success-flag-with-timeline-link/index.tsx';
import { useUpdateChildIssueScheduling } from '../../../services/update-child-issue-scheduling/index.tsx';
import { useIsCMPBoard, useRapidViewId } from '../../selectors/board/index.tsx';
import { usePrefersChildIssueDatePlanning } from '../../selectors/timeline/index.tsx';
import messages from './messages.tsx';

const getInitialValue = (options: OptionsPropType, prefersChildIssueDatePlanning: boolean) => {
	const childIssueDatePlanning = options.find(({ name }) => name === START_AND_DUE_DATE_SCHEDULING);
	const sprintDatesPlanning = options.find(({ name }) => name === SPRINT_DATES_SCHEDULING);

	return prefersChildIssueDatePlanning ? childIssueDatePlanning?.value : sprintDatesPlanning?.value;
};

// Using aliases for testing purposes
export const useEnableChildIssueScheduling = (rapidViewId: number) =>
	useUpdateChildIssueScheduling(rapidViewId, true);
export const useDisableChildIssueScheduling = (rapidViewId: number) =>
	useUpdateChildIssueScheduling(rapidViewId, false);

// this handler makes the assumption that there are only 2 options passed in, corresponding to
// start and due date scheduling, and sprint dates scheduling
export const useUpdateChildIssueSchedulingHandler = (options: OptionsPropType) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const [rapidViewId] = useRapidViewId();
	const [isCMPBoard] = useIsCMPBoard();
	const [prefersChildIssueDatePlanning, { updatePrefersChildIssueDatePlanning }] =
		usePrefersChildIssueDatePlanning();

	const { commit: commitEnableChildIssueScheduling } = useEnableChildIssueScheduling(rapidViewId);
	const { commit: commitDisableChildIssueScheduling } = useDisableChildIssueScheduling(rapidViewId);

	const [value, setValue] = useState(getInitialValue(options, prefersChildIssueDatePlanning));

	const updateChildIssueScheduling = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			const { name, value: nextValue } = event.currentTarget;
			const previousValue = value;
			setValue(nextValue);

			const nextPrefersChildDatePlanning = name === START_AND_DUE_DATE_SCHEDULING;
			const commit = nextPrefersChildDatePlanning
				? commitEnableChildIssueScheduling
				: commitDisableChildIssueScheduling;

			const result = await commit();
			if (!result.ok) {
				setValue(previousValue);
				return;
			}

			const enableChildDatePlanningSuccessMessage = expVal(
				'issue-terminology-refresh-m2-replace',
				'isEnabled',
				false,
			)
				? messages.enableChildDatePlanningSuccessIssueTermRefresh
				: messages.enableChildDatePlanningSuccess;

			const disableChildDatePlanningSuccessMessage = expVal(
				'issue-terminology-refresh-m2-replace',
				'isEnabled',
				false,
			)
				? messages.disableChildDatePlanningSuccessIssueTermRefresh
				: messages.disableChildDatePlanningSuccess;

			updatePrefersChildIssueDatePlanning(nextPrefersChildDatePlanning);
			showFlag((props) => (
				<SuccessFlagWithTimelineLink
					{...props}
					title={formatMessage(
						nextPrefersChildDatePlanning
							? enableChildDatePlanningSuccessMessage
							: disableChildDatePlanningSuccessMessage,
					)}
					isCMPBoard={isCMPBoard}
				/>
			));
		},
		[
			commitDisableChildIssueScheduling,
			commitEnableChildIssueScheduling,
			formatMessage,
			isCMPBoard,
			showFlag,
			updatePrefersChildIssueDatePlanning,
			value,
		],
	);

	return { updateChildIssueScheduling, value };
};
