import React from 'react';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const ReorderHeader = () => {
	const { formatMessage } = useIntl();

	return (
		<Box paddingInlineStart="space.150">
			<DragHandlerIcon size="small" label={formatMessage(messages.tableHeadReorderLabel)} />
		</Box>
	);
};
