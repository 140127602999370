import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tableHeadReorderLabel: {
		id: 'software-board-settings-query-list-config-common.query-list.table-head-reorder-label',
		defaultMessage: 'Reorder',
		description: 'Table header label for the Reorder column',
	},
	tableHeadActionsLabel: {
		id: 'software-board-settings-query-list-config-common.query-list.table-head-actions-label',
		defaultMessage: 'Actions',
		description: 'Table header label for the Actions column',
	},
	tableHeadNameLabel: {
		id: 'software-board-settings-query-list-config-common.query-list.table-head-name-label',
		defaultMessage: 'Name',
		description: 'Table header label for the name column',
	},
	tableHeadJqlQueryLabel: {
		id: 'software-board-settings-query-list-config-common.query-list.table-head-jql-query-label',
		defaultMessage: 'JQL query',
		description: 'Table header label for the JQL query column',
	},
	tableHeadDescriptionLabel: {
		id: 'software-board-settings-query-list-config-common.query-list.table-head-description-label',
		defaultMessage: 'Description',
		description: 'Table header label for the Description column',
	},
	tableHeadColorLabel: {
		id: 'software-board-settings-query-list-config-common.query-list.table-head-color-label',
		defaultMessage: 'Color',
		description: 'Table header label for the color column',
	},
	changeColorLabel: {
		id: 'software-board-settings-query-list-config-common.query-list.change-color-label',
		defaultMessage: 'Change color',
		description: 'Label of changing color button',
	},
	changeColorForTypeLabel: {
		id: 'software-board-settings-query-list-config-common.query-list.change-color-for-type-label',
		defaultMessage: 'Change color for {type}',
		description:
			'Label of changing color button for color for a type e.g. Priority types, Assignees, Issue Types',
	},
	resetButtonTooltipContent: {
		id: 'software-board-settings-query-list-config-common.query-list.reset-button-tooltip-content',
		defaultMessage: 'Reset to priority color applied in global settings',
		description: 'This is tooltip content for the reset button for priority color settings',
	},
	priorityGlobalColorInformationMessage: {
		id: 'software-board-settings-query-list-config-common.query-list.priority-global-color-information-message',
		defaultMessage: 'You’re currently using the global color for this priority.',
		description:
			'This is information message showing when current priority color is coming from global settings',
	},
});
