import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useServiceDeskAnalyticsContext } from '@atlassian/jira-router-resources-service-desk-analytics-context/src/services/index.tsx';
import { emptyResponseCommitToBoolean } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/utils.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	UPDATE_CARD_COLOR_STRATEGY_ACTION_SUBJECT,
	UPDATE_CARD_COLOR_STRATEGY_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { CARD_COLOR_API_URI } from '../../common/constants/url.tsx';
import type { UpdateCardColorStrategyInput, UpdateCardColorStrategyResponse } from './types.tsx';

export const useUpdateCardColorStrategy = (
	rapidViewId: number,
): UpdateCardColorStrategyResponse => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data: analyticsContextAttributes } = useServiceDeskAnalyticsContext();
	const updateCardColorStrategy = useCallback(
		({ cardColorStrategy }: UpdateCardColorStrategyInput) => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'updateCardColourStrategy success', {
				...analyticsContextAttributes,
				method: cardColorStrategy,
			});

			return performPutRequest(`${CARD_COLOR_API_URI}/${rapidViewId}/strategy`, {
				body: JSON.stringify({
					id: rapidViewId,
					cardColorStrategyId: cardColorStrategy,
				}),
			});
		},
		[analyticsContextAttributes, createAnalyticsEvent, rapidViewId],
	);

	const { data, loading, error, hasError, fetch, resetError } = useServiceWithAnalytics<
		UpdateCardColorStrategyResponse['data'],
		UpdateCardColorStrategyInput
	>(
		updateCardColorStrategy,
		{},
		{
			actionSubject: UPDATE_CARD_COLOR_STRATEGY_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${UPDATE_CARD_COLOR_STRATEGY_BROWSER_METRICS_KEY}`,
		},
	);

	useErrorFlagOnUpdateFailure({ hasFailure: hasError, handleValidationError: true, error });

	return {
		data,
		loading,
		error,
		hasError,
		commit: emptyResponseCommitToBoolean(fetch),
		resetError,
	};
};
