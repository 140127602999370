import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Box, xcss, Flex } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { InProductHelpButton } from '@atlassian/jira-insights-common/src/common/ui/in-product-help-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useBoardFilterConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import {
	LEARN_MORE_ABOUT_JQL_FOR_BOARD_FILTER_IPH_ARTICLE,
	LEARN_MORE_ABOUT_JQL_FOR_BOARD_FILTER_IPH_ARTICLE_ANALYTICS_ID,
} from '../../common/constants/in-product-help.tsx';
import messages from './messages.tsx';

const BoardFilterQuery = () => {
	const [{ id, query, queryProjects }] = useBoardFilterConfig();
	const { formatMessage } = useIntl();

	const isProjectsUnboundedInFilter =
		queryProjects &&
		'isProjectsUnboundedInFilter' in queryProjects &&
		queryProjects.isProjectsUnboundedInFilter;

	return (
		<Box testId="software-board-settings-general-filter-settings.ui.board-filter-query.container">
			<Box xcss={boxStyles}>
				<Heading as="h4" size="xxsmall">
					<Header>{formatMessage(messages.heading)}</Header>
				</Heading>
				<Flex xcss={flexStyles}>
					<Box xcss={codeStyles}>
						<code>{query}</code>
					</Box>
					<Box xcss={buttonStyles}>
						<Button
							appearance="link"
							aria-label={formatMessage(messages.editFilterQueryLink)}
							iconAfter={
								<ShortcutIcon label={formatMessage(messages.editFilterQueryLink)} size="small" />
							}
							href={`/issues/?filter=${id}`}
							target="_blank"
						>
							{formatMessage(messages.editFilterQueryLink)}
						</Button>
					</Box>
				</Flex>
			</Box>
			{isProjectsUnboundedInFilter && (
				<Box xcss={boxWithMarginTopStyles}>
					<SectionMessage
						title={formatMessage(messages.addAProjectToYourQueryTitle)}
						appearance="warning"
						actions={[
							<SectionMessageAction key="read-more-about-jql">
								<Box xcss={inlineBlockStyles}>
									{formatMessage(messages.readMoreAboutJql, {
										button: (chunks: ReactNode[]) => (
											<InProductHelpButton
												articleId={LEARN_MORE_ABOUT_JQL_FOR_BOARD_FILTER_IPH_ARTICLE}
												articleAnalyticsId={
													LEARN_MORE_ABOUT_JQL_FOR_BOARD_FILTER_IPH_ARTICLE_ANALYTICS_ID
												}
												appearance="link"
											>
												{chunks}
											</InProductHelpButton>
										),
									})}
								</Box>
							</SectionMessageAction>,
						]}
					>
						{formatMessage(messages.addAProjectToYourQueryDescription)}
					</SectionMessage>
				</Box>
			)}
		</Box>
	);
};

const boxStyles = xcss({
	backgroundColor: 'elevation.surface.sunken',
	padding: 'space.200',
	flexDirection: 'column',
	borderRadius: 'border.radius.100',
	columnGap: 'space.100',
});

const boxWithMarginTopStyles = xcss({
	marginTop: 'space.200',
});

const inlineBlockStyles = xcss({
	display: 'inline-block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.span({
	textTransform: 'none',
	color: token('color.text.subtle', '#44546F'),
});

const flexStyles = xcss({
	flexDirection: 'row',
});

const codeStyles = xcss({
	width: '77%',
	justifyContent: 'flex-start',
	alignSelf: 'stretch',
	paddingTop: 'space.100',
	font: token('font.body.UNSAFE_small'),
	wordWrap: 'break-word',
});

const buttonStyles = xcss({
	width: '23%',
	justifyContent: 'flex-end',
});

export { BoardFilterQuery };
