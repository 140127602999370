import parameters from '@atlassian/jira-relay/src/__generated__/workingDaysSettingsPageQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { boardId: string };
export const workingDaysPageEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jsw-board-settings-working-days" */ './ui/working-days-settings-page/index.tsx'
		).then((module) => module.WorkingDaysSettingPageContent),
	),
	getPreloadProps: ({ boardId }: EntryPointParams) => ({
		queries: {
			queryReference: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters,
				variables: {
					boardId,
				},
			},
		},
	}),
});
