import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	allRoles: {
		id: 'shareable-entity-dialog.access.sharee-list.sharee-list-row.project-name.all-roles',
		defaultMessage: 'All roles',
		description:
			'When the role comes empty, that means that access is given to every role for that project',
	},
});
