export const ADD_SWIMLANE_ACTION_SUBJECT = 'addSwimlane';
export const ADD_SWIMLANE_BROWSER_METRICS_KEY = 'add-swimlane';

export const DELETE_SWIMLANE_ACTION_SUBJECT = 'deleteSwimlane';
export const DELETE_SWIMLANE_BROWSER_METRICS_KEY = 'delete-swimlane';

export const UPDATE_SWIMLANE_ACTION_SUBJECT = 'updateSwimlane';
export const UPDATE_SWIMLANE_BROWSER_METRICS_KEY = 'update-swimlane';

export const UPDATE_SWIMLANE_STRATEGY_ACTION_SUBJECT = 'updateSwimlaneStrategy';
export const UPDATE_SWIMLANE_STRATEGY_BROWSER_METRICS_KEY = 'update-swimlane-strategy';

export const RANK_SWIMLANE_ACTION_SUBJECT = 'rankSwimlane';
export const RANK_SWIMLANE_BROWSER_METRICS_KEY = 'rank-swimlane';

export const VIEW_SWIMLANES_SETTINGS_EXPERIENCE_NAME = 'viewSwimlanesSettings';
