export const SwimlaneStrategy = {
	none: 'none',
	custom: 'custom',
	parentChild: 'parentChild',
	assigneeUnassignedLast: 'assignee',
	assigneeUnassignedFirst: 'assigneeUnassignedFirst',
	epic: 'epic',
	project: 'project',
	issueParent: 'issueparent',
	issueChildren: 'issuechildren',
	requestType: 'request_type',
} as const;

export type SwimlaneStrategy = (typeof SwimlaneStrategy)[keyof typeof SwimlaneStrategy];

export type Swimlane = {
	id: number | string;
	name: string;
	query: string;
	description: string;
	isDefault: boolean;
};

export type SwimlanesConfig = {
	rapidViewId: number;
	swimlaneStrategy: SwimlaneStrategy;
	canEdit: boolean;
	swimlanes: Swimlane[];
};
