import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	color: {
		id: 'software-board-settings-query-list-config-common.common.modal-form.color-field.color',
		defaultMessage: 'Color',
		description: 'Label for Color text field',
	},
	requiredError: {
		id: 'software-board-settings-query-list-config-common.common.modal-form.color-field.required-error',
		defaultMessage: 'Select a color',
		description: 'Label for field validation error',
	},
	changeColorLabel: {
		id: 'software-board-settings-query-list-config-common.common.modal-form.color-field.change-color-label',
		defaultMessage: 'Change color',
		description: 'Label of changing color button',
	},
});
