import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createSwimlaneDescription: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.create-swimlane-description',
		defaultMessage: 'Create swimlanes using Jira Query Language (JQL).',
		description: 'Create swimlane table description',
	},
	createButtonLabel: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.query-strategy.create-button-label',
		defaultMessage: 'Create swimlane',
		description: 'Button label for creating a swimlane',
	},
});
