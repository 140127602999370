import React, { useCallback, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { dataTestIds } from '../../../common/constants/data-test.tsx';
import { COLOR_CARD_SIZE } from '../../../common/constants/index.tsx';

export type Props = {
	value: string;
	label?: string;
	onClick?: () => void;
	expanded?: boolean;
};

export const ColorCardButton = ({ onClick, value, label, expanded }: Props) => {
	const handleClick = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.currentTarget.focus();

			if (onClick) {
				e.preventDefault();
				onClick();
			}
		},
		[onClick],
	);

	const handleMouseDown = useCallback((e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
	}, []);

	return (
		<Tooltip content={label}>
			<ColorCardButtonWrapper
				data-testid="software-board-settings-color-picker.ui.color-picker.color-card-button.color-card-button-wrapper"
				onClick={handleClick}
				onMouseDown={handleMouseDown}
				focused={expanded}
				aria-label={label}
				aria-expanded={expanded}
				aria-haspopup
				type="button"
			>
				<ColorCardButtonContent
					data-testid={dataTestIds.cardColorButtonContent}
					color={value || 'transparent'}
				/>
			</ColorCardButtonWrapper>
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorCardButtonWrapper = styled.button<{ focused?: boolean }>({
	display: 'inline-block',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${COLOR_CARD_SIZE}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${COLOR_CARD_SIZE}px`,
	borderWidth: '2px',
	borderStyle: 'solid',
	boxSizing: 'border-box',
	borderRadius: `${token('space.075', '6px')}`,
	transition: 'border-color 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	backgroundColor: 'transparent',
	padding: 0,
	cursor: 'pointer',
	outline: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderColor: ({ focused }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		focused ? token('color.border.focused', colors.B100) : 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:focus):hover, &:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: `${token('color.border.focused', colors.B100)}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorCardButtonContent = styled.div<{ color: string }>({
	position: 'absolute',

	top: '1px',

	left: '1px',
	width: token('space.300', '24px'),
	height: token('space.300', '24px'),
	borderRadius: token('border.radius', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ color }) => color,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: `inset 0px 0px 0px 1px ${token('color.background.inverse.subtle', colors.DN600A)}`,
});
