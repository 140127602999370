import { Component, type ReactElement } from 'react';
import { type UIAnalyticsEvent, withAnalyticsEvents } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { SharingPermissionKey } from '../../model/types.tsx';

type State = {
	loading: boolean;
	error: Error | undefined;
	data: Record<SharingPermissionKey, boolean> | undefined;
	sharedObjectError: Error | undefined;
	sharedObjectsPermission?:
		| {
				permissions?: {
					CREATE_SHARED_OBJECTS?: {
						havePermission?: boolean;
					};
				};
		  }
		| undefined;
};

type ProviderState = State & {
	sharedObjectPermissionLoading: boolean;
};
type Props = {
	children: (arg1: State) => ReactElement;
	createAnalyticsEvent: (arg1: { [key: string]: string }) => UIAnalyticsEvent;
};

// eslint-disable-next-line jira/react/no-class-components
export class SharingPermissionsDataProvider extends Component<Props, ProviderState> {
	state = {
		loading: false,
		error: undefined,
		data: undefined,
		sharedObjectError: undefined,
		sharedObjectPermissionLoading: false,
		sharedObjectsPermission: undefined,
	};

	componentDidMount() {
		this.fetchData();
		this.fetchPermissions();
	}

	// eslint-disable-next-line jira/react-arrow-function-property-naming, jira/react-no-unneeded-arrow-function-properties
	fetchData = async () => {
		this.setState({ loading: true });
		try {
			const url = '/rest/internal/2/sharing-permissions';
			const permissions = await performGetRequest(url);
			this.setState({ loading: false, error: undefined, data: permissions });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			this.setState({ loading: false, error: e, data: undefined });
			const event = this.props.createAnalyticsEvent({});
			fireErrorAnalytics({
				error: e,
				event,
				meta: { id: 'fetchData', packageName: 'jiraShareableEntityDialog' },
			});
		}
	};

	// eslint-disable-next-line jira/react-arrow-function-property-naming, jira/react-no-unneeded-arrow-function-properties
	fetchPermissions = async () => {
		this.setState({ sharedObjectPermissionLoading: true });
		try {
			const sharedObjectsPermission = await performGetRequest(
				'/rest/api/3/mypermissions?permissions=CREATE_SHARED_OBJECTS',
			);
			this.setState({
				sharedObjectPermissionLoading: false,
				sharedObjectError: undefined,
				sharedObjectsPermission,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			this.setState({
				sharedObjectPermissionLoading: false,
				sharedObjectError: e,
				sharedObjectsPermission: undefined,
			});
			const event = this.props.createAnalyticsEvent({});
			fireErrorAnalytics({
				event,
				error: e,
				meta: { id: 'fetchPermissions', packageName: 'jiraShareableEntityDialog' },
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	};

	render() {
		const { children } = this.props;
		const {
			loading,
			error,
			data,
			sharedObjectsPermission,
			sharedObjectError,
			sharedObjectPermissionLoading,
		} = this.state;

		return children({
			loading: loading || sharedObjectPermissionLoading,
			error,
			data,
			sharedObjectError,
			sharedObjectsPermission,
		});
	}
}

export default withAnalyticsEvents()(SharingPermissionsDataProvider);
