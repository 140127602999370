import { useCallback } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { emptyResponseCommitToBoolean } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/utils.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	ENABLE_INCLUDE_CHILD_LEVEL_ISSUES_ACTION_SUBJECT,
	ENABLE_INCLUDE_CHILD_LEVEL_ISSUES_BROWSER_METRICS_KEY,
	DISABLE_INCLUDE_CHILD_LEVEL_ISSUES_ACTION_SUBJECT,
	DISABLE_INCLUDE_CHILD_LEVEL_ISSUES_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { TIMELINE_FEATURES_API_URI } from '../../common/constants/url.tsx';
import messages from './messages.tsx';

const getPutUrl = (rapidViewId: number, shouldBeEnabled: boolean) =>
	`${TIMELINE_FEATURES_API_URI}/${rapidViewId}/${shouldBeEnabled ? 'enableChildPlanning' : 'disableChildPlanning'}`;

export const useUpdateChildPlanning = (rapidViewId: number, isChildPlanningEnabled: boolean) => {
	const { formatMessage } = useIntl();

	const [actionSubject, browserMetricsKey, errorTitle] = isChildPlanningEnabled
		? [
				ENABLE_INCLUDE_CHILD_LEVEL_ISSUES_ACTION_SUBJECT,
				ENABLE_INCLUDE_CHILD_LEVEL_ISSUES_BROWSER_METRICS_KEY,
				formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.enableErrorTitleIssueTermRefresh
						: messages.enableErrorTitle,
				),
			]
		: [
				DISABLE_INCLUDE_CHILD_LEVEL_ISSUES_ACTION_SUBJECT,
				DISABLE_INCLUDE_CHILD_LEVEL_ISSUES_BROWSER_METRICS_KEY,
				formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.disableErrorTitleIssueTermRefresh
						: messages.disableErrorTitle,
				),
			];

	const updateChildPlanning = useCallback(
		() => performPutRequest<null | undefined>(getPutUrl(rapidViewId, isChildPlanningEnabled)),
		[isChildPlanningEnabled, rapidViewId],
	);

	const { error, hasError, fetch, ...rest } = useServiceWithAnalytics(
		updateChildPlanning,
		{},
		{
			actionSubject,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${browserMetricsKey}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		error,
		errorTitle,
	});

	return { error, hasError, commit: emptyResponseCommitToBoolean(fetch), ...rest };
};
