import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-timeline.timeline-settings-page.title',
		defaultMessage: 'Timeline',
		description: 'Title of the timeline settings page',
	},
	description: {
		id: 'software-board-settings-timeline.timeline-settings-page.description',
		defaultMessage:
			'Create and manage large pieces of work on the timeline to increase visibility, understand dependencies, and stay across the details. <button>Read more about the timeline view</button>.',
		description: 'Description of the timeline settings page',
	},
});
