import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	priorityHeaderName: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.priority-header-name',
		defaultMessage: 'Priority',
		description: 'Table header label for the priority column',
	},
	assigneeHeaderName: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.assignee-header-name',
		defaultMessage: 'Assignee',
		description: 'Table header label for the assignee column',
	},
	issueTypeHeaderName: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.issue-type-header-name',
		defaultMessage: 'Issue type',
		description: 'Table header label for the issue type column',
	},
	requestTypeHeaderName: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.request-type-header-name',
		defaultMessage: 'Request type',
		description: 'Table header label for the request type column',
	},
	issueTypeHeaderNameIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.issue-type-header-name-issue-term-refresh',
		defaultMessage: 'Work type',
		description: 'Table header label for the issue type column',
	},
});
