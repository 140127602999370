import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editCardColorModalTitle: {
		id: 'software-board-settings-card-colors.controllers.handlers.use-edit-card-color-handler.edit-card-color-modal-title',
		defaultMessage: 'Edit card color',
		description: 'Title for the edit card color modal',
	},
	editCardColorModalGuidelines: {
		id: 'software-board-settings-card-colors.controllers.handlers.use-edit-card-color-handler.edit-card-color-modal-guidelines',
		defaultMessage: 'Required fields are marked with an asterisk',
		description:
			'This is the guidelines shown on the top of the modal dialog for editing a card color',
	},
	editCardColorSuccess: {
		id: 'software-board-settings-card-colors.controllers.handlers.use-edit-card-color-handler.edit-card-color-success',
		defaultMessage: 'Your card color has been updated',
		description:
			'Title message for the success flag which appears when editing a card color operation is successful',
	},
	validationErrorInstructionForUpdatingCardColor: {
		id: 'software-board-settings-card-colors.controllers.handlers.use-edit-card-color-handler.validation-error-instruction-for-updating-card-color',
		defaultMessage: 'Update the query and save it again.',
		description: 'Instruction when failing to update a query.',
	},
});
