import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.query-strategy.delete-card-color-modal.title',
		defaultMessage: 'Delete card color',
		description: 'Title of modal for deleting a card color',
	},
	description: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.query-strategy.delete-card-color-modal.description',
		defaultMessage:
			'This will permanently delete the card color but won’t remove any issues from your project.',
		description: 'Description of modal for deleting a card color',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.query-strategy.delete-card-color-modal.description-issue-term-refresh',
		defaultMessage:
			'This will permanently delete the card color but won’t remove any work items from your project.',
		description: 'Description of modal for deleting a card color',
	},
});
