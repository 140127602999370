import { useCallback } from 'react';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	RANK_SWIMLANE_ACTION_SUBJECT,
	RANK_SWIMLANE_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { SWIMLANE_API_URI } from '../../common/constants/url.tsx';
import type { RankSwimlaneResponse, RankSwimlaneInput, RankSwimlanePayload } from './types.tsx';

export const useRankSwimlane = (rapidViewId: number, errorTitle: string): RankSwimlaneResponse => {
	const rankSwimlane = useCallback(
		({ swimlaneId, afterSwimlaneId }: RankSwimlaneInput) => {
			const moveApiUrl = `${SWIMLANE_API_URI}/${rapidViewId}/${swimlaneId}/move`;
			const payload: RankSwimlanePayload = afterSwimlaneId
				? {
						after: `${SWIMLANE_API_URI}/${rapidViewId}/${afterSwimlaneId}`,
					}
				: {
						position: 'First',
					};

			return performPostRequest(moveApiUrl, {
				body: JSON.stringify(payload),
			});
		},
		[rapidViewId],
	);

	const {
		data,
		loading,
		error,
		hasError,
		fetch: commit,
		resetError,
	} = useServiceWithAnalytics<RankSwimlaneResponse['data'], RankSwimlaneInput>(
		rankSwimlane,
		{},
		{
			actionSubject: RANK_SWIMLANE_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${RANK_SWIMLANE_BROWSER_METRICS_KEY}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		handleValidationError: true,
		error,
		errorTitle,
	});

	return { loading, error, data, commit, resetError, hasError };
};
