import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleteSuccessBacklogFlagTitle: {
		id: 'software-board-settings-card-layout.controllers.handlers.delete-card-layout-field-handler.delete-success-backlog-flag-title',
		defaultMessage: "You've removed the {fieldName} field from cards on your backlog",
		description: 'Title for flag shown when a field is deleted from card layout',
	},
	deleteSuccessKanbanBoardFlagTitle: {
		id: 'software-board-settings-card-layout.controllers.handlers.delete-card-layout-field-handler.delete-success-kanban-board-flag-title',
		defaultMessage: "You've removed the {fieldName} field from cards on your kanban board",
		description: 'Title for flag shown when a field is deleted from card layout',
	},
	deleteSuccessActiveSprintsFlagTitle: {
		id: 'software-board-settings-card-layout.controllers.handlers.delete-card-layout-field-handler.delete-success-active-sprints-flag-title',
		defaultMessage: "You've removed the {fieldName} field from cards on your active sprints",
		description: 'Title for flag shown when a field is deleted from card layout',
	},
});
