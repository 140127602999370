import { useCallback, useState } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { emptyResponseCommitToBoolean } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/utils.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	UPDATE_SHOW_DAYS_IN_COLUMN_ACTION_SUBJECT,
	UPDATE_SHOW_DAYS_IN_COLUMN_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { SHOW_DAYS_IN_COLUMN_URI } from '../../common/constants/url.tsx';
import messages from './messages.tsx';
import type {
	UpdateShowDaysInColumnInput,
	UpdateShowDaysInColumnResponse,
	UpdateShowDaysInColumnResponseCommitToBoolean,
} from './types.tsx';

export const useUpdateShowDaysInColumn = (
	rapidViewId: number,
): UpdateShowDaysInColumnResponseCommitToBoolean => {
	const { formatMessage } = useIntl();
	const [nextToggleValue, setNextToggleValue] = useState<boolean | null>(null);

	const updateShowDaysInColumn = useCallback(
		({ showDaysInColumn }: UpdateShowDaysInColumnInput) => {
			setNextToggleValue(showDaysInColumn);
			return performPutRequest(SHOW_DAYS_IN_COLUMN_URI, {
				body: JSON.stringify({
					rapidViewId,
					showDaysInColumn,
				}),
			});
		},

		[rapidViewId],
	);

	const { data, loading, error, hasError, fetch, resetError } = useServiceWithAnalytics<
		UpdateShowDaysInColumnResponse['data'],
		UpdateShowDaysInColumnInput
	>(
		updateShowDaysInColumn,
		{},
		{
			actionSubject: UPDATE_SHOW_DAYS_IN_COLUMN_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${UPDATE_SHOW_DAYS_IN_COLUMN_BROWSER_METRICS_KEY}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		error,
		errorTitle: nextToggleValue
			? formatMessage(messages.toggleOnShowDaysInColumnFailureTitle)
			: formatMessage(messages.toggleOffShowDaysInColumnFailureTitle),
		errorMessage: formatMessage(messages.toggleShowDaysInColumnFailureDescription),
	});

	return {
		data,
		loading,
		error,
		hasError,
		resetError,
		commit: emptyResponseCommitToBoolean(fetch),
	};
};
