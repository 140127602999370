export const METRICS_PAGE_ID = 'board-settings-apps.estimation';

export const BOARD_SETTINGS_ARTICLE_ID_ESTIMATION = '131fs2gkVp057OmppQpHJY';
export const BOARD_SETTINGS_ARTICLE_ID_ESTIMATION_ANALYTICS_ID = `${METRICS_PAGE_ID}.esimation.article.analytics`;

export const BOARD_SETTINGS_ARTICLE_ID_TIME_TRACKING = '7yitsnjaKhMblrEZym2u1d';
export const BOARD_SETTINGS_ARTICLE_ID_TIME_TRACKING_ANALYTICS_ID = `${METRICS_PAGE_ID}.time-tracking.article.analytics`;

export const NONE_TIME_TRACKING_STATISTIC_ID = 'none_' as const;
export const TIME_ESTIMATE_TIME_TRACKING_STATISTIC_ID = 'field_timeestimate' as const;

export const SECTION_MAX_WIDTH = 540;
