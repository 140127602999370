import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { SharingPermissionAwareShareableEntityModal } from '@atlassian/jira-shareable-entity-dialog/src/index.tsx';
import { useBoardFilterConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import messages from './messages.tsx';
import { getServerSideValidationErrors, useModalOperation } from './utils.tsx';

export const PermissionModal = ({ onDismiss }: { onDismiss: () => void }) => {
	const [filterConfig] = useBoardFilterConfig();
	const { editPermissionEntries, sharePermissionEntries } = filterConfig;
	const { formatMessage } = useIntl();
	const { requestPermissionUpdate, onUpdateCompleted } = useModalOperation({ onDismiss });

	return (
		<SharingPermissionAwareShareableEntityModal
			title={formatMessage(messages.editFilter)}
			actionButtonCaption={formatMessage(messages.save)}
			isShareScopeLoading={false}
			initialName={filterConfig.name}
			initialOwner={filterConfig.owner}
			initialDescription={filterConfig.description}
			editPermissions={editPermissionEntries}
			sharePermissions={sharePermissionEntries}
			sharingPermissionType="filter"
			onCancel={onDismiss}
			onOperationSucceed={onUpdateCompleted}
			operation={requestPermissionUpdate}
			onOperationFailed={onDismiss}
			getServerSideValidationErrors={getServerSideValidationErrors}
		/>
	);
};
