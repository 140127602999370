import { useCallback, useState, useMemo, useEffect } from 'react';
import { metrics } from '@atlassian/browser-metrics';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import { getHttpStatusCodeGroup } from '@atlassian/jira-fetch/src/utils/http-status-code.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { DEFAULT_METRICS_HISTOGRAM } from '../../common/constants.tsx';
import type { State, AnalyticsTracking, BrowserMetrics } from './types.tsx';

/*
  This is just a simple wrapper for useService but with extra analytics for interactions in Board Settings
*/
export const useServiceWithAnalytics = <Data, RequestData = void>(
	request: (requestData: RequestData) => Promise<Data | undefined>,
	initialState: Partial<State<Data>> = {},
	analyticsTracking: AnalyticsTracking,
	browserMetrics: BrowserMetrics,
): State<Data> & {
	fetch: (requestData: RequestData) => Promise<Data | undefined>;
} => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { browserMetricsKey, histogram } = browserMetrics;
	const { actionSubject, packageName, attributes = {} } = analyticsTracking;
	const [requestError, setRequestError] = useState<Error | undefined>();
	const fetch = useCallback(
		async (requestData: RequestData) => {
			const metricsTracker = metrics.interaction({
				key: browserMetricsKey,
				// @ts-expect-error - TS2741 - Property '[BMInteractionMetrics.response]' is missing in type '{ result: string; }' but required in type 'InteractionHistogramConfig'.
				histogram: {
					result: histogram || DEFAULT_METRICS_HISTOGRAM,
				},
			});
			metricsTracker.start();
			const analyticsEvent = createAnalyticsEvent({});
			try {
				const data = await request(requestData);
				fireOperationalAnalytics(analyticsEvent, `${actionSubject} succeeded`, {
					...attributes,
					packageName,
				});
				metricsTracker.stop();
				return data;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				metricsTracker.cancel();
				fireOperationalAnalytics(analyticsEvent, `${actionSubject} failed`, {
					isClientFetchError: isClientFetchError(err),
					errorMessage: err.statusCode !== 400 ? err.message : undefined,
					statusCode: err.statusCode,
					statusCodeGroup: getHttpStatusCodeGroup(err.statusCode),
					packageName,
					...attributes,
				});
				// Still throw error so useService can handle error as normal
				throw err;
			}
		},
		[
			browserMetricsKey,
			histogram,
			createAnalyticsEvent,
			request,
			actionSubject,
			attributes,
			packageName,
		],
	);

	const result = useService<Data, RequestData>(fetch, initialState);
	const { error } = result;

	useEffect(() => {
		setRequestError(error);
	}, [error]);

	const hasError = useMemo(() => !!requestError, [requestError]);

	const resetError = useCallback(() => {
		setRequestError(undefined);
	}, []);

	return {
		...result,
		error: requestError,
		hasError,
		resetError,
	};
};
