import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import capitalize from 'lodash/capitalize';
import { Field } from '@atlaskit/form';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import Select, { type ValueType } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { SECTION_MAX_WIDTH } from '../../../common/constants/estimation.tsx';
import { useUpdateStatisticsHandler } from '../../../controllers/handlers/update-statistics-handler/index.tsx';
import {
	useAvailableEstimationStatistics,
	useCurrentEstimationStatistic,
} from '../../../controllers/selectors/estimation/index.tsx';
import messages from './messages.tsx';
import type { EstimationMethodOption, EstimationMethodProps } from './types.tsx';

export const EstimationMethod = ({ isDisabled }: EstimationMethodProps) => {
	const { formatMessage } = useIntl();
	const { isEstimationUpdating, handleEstimationMethodUpdate } = useUpdateStatisticsHandler();
	const [availableEstimationStatistics] = useAvailableEstimationStatistics();
	const [currentEstimationStatistic] = useCurrentEstimationStatistic();

	const options = useMemo(
		() =>
			availableEstimationStatistics.map(({ id, name }) => ({
				label: capitalize(name),
				value: id,
			})),
		[availableEstimationStatistics],
	);

	const { showFlag } = useFlagService();

	const currentEstimationOption = useMemo(
		() => options.find(({ value }) => value === currentEstimationStatistic.id) ?? null,
		[currentEstimationStatistic.id, options],
	);

	const onSelect = useCallback(
		async (option: EstimationMethodOption | null) => {
			if (option?.value && option.value !== currentEstimationOption?.value) {
				const result = await handleEstimationMethodUpdate({
					estimateStatisticId: option.value,
				});
				if (result) {
					showFlag((props) => (
						<SuccessFlagWithBoardLink
							isCMPBoard
							title={formatMessage(messages.successFlagTitle, {
								estimationMethod: option.label.toLowerCase(),
							})}
							{...props}
						/>
					));
				}
			}
		},
		[currentEstimationOption?.value, formatMessage, handleEstimationMethodUpdate, showFlag],
	);

	return (
		<Box xcss={boxStyles}>
			<Heading as="h3" size="small">
				{formatMessage(messages.title)}
			</Heading>

			<Box xcss={textStyles}>{formatMessage(messages.description)}</Box>
			<SelectContainer>
				<Field<ValueType<EstimationMethodOption>>
					id="estimationMethod"
					label={formatMessage(messages.fieldLabel)}
					name="estimationMethod"
					isDisabled={isDisabled}
				>
					{({ fieldProps: { id, ...fieldProps } }) => (
						<Select
							options={options}
							inputId={id}
							{...fieldProps}
							value={currentEstimationOption}
							isLoading={isEstimationUpdating}
							onChange={onSelect}
							name="estimationMethod"
						/>
					)}
				</Field>
			</SelectContainer>
		</Box>
	);
};

const boxStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${SECTION_MAX_WIDTH}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectContainer = styled.div({
	maxWidth: '232px',
	marginTop: token('space.200', '16px'),
});

const textStyles = xcss({
	marginTop: 'space.100',
});
