import React, { useMemo } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { DeleteQueryConfirmationModal } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/delete-query-confirmation-modal/index.tsx';
import { CardColorStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import messages from './messages.tsx';

export const DeleteStaleCardColorModal = ({
	onSubmit,
	onCancel,
	isSubmitting,
	colorStrategy,
	deletingItemName,
}: {
	onSubmit: () => void;
	onCancel: () => void;
	isSubmitting: boolean;
	colorStrategy: CardColorStrategy;
	deletingItemName: string;
}) => {
	const { formatMessage } = useIntl();

	const description = useMemo(() => {
		if (colorStrategy === CardColorStrategy.assignee) {
			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.descriptionForAssigneeIssueTermRefresh
					: messages.descriptionForAssignee,
			);
		}

		if (colorStrategy === CardColorStrategy.issueType) {
			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.descriptionForIssueTypeIssueTermRefresh
					: messages.descriptionForIssueType,
			);
		}

		if (colorStrategy === CardColorStrategy.requestType) {
			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.descriptionForRequestTypeIssueTermRefresh
					: messages.descriptionForRequestType,
			);
		}

		return '';
	}, [colorStrategy, formatMessage]);

	return (
		<DeleteQueryConfirmationModal
			title={formatMessage(messages.title, { cardColorName: deletingItemName })}
			description={description}
			deleteButtonName={formatMessage(messages.removeButton)}
			onSubmit={onSubmit}
			onCancel={onCancel}
			isSubmitting={isSubmitting}
		/>
	);
};
