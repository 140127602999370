import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { APP_METRIC_KEY } from '@atlassian/jira-software-board-settings-general-common/src/common/constants/analytics.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import {
	UPDATE_GENERAL_BOARD_FILTER_ACTION_SUBJECT,
	UPDATE_GENERAL_BOARD_FILTER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { FILTER_API_PATH } from '../../common/constants/urls.tsx';
import messages from './messages.tsx';
import type { UpdateBoardFilterRequest, UpdateBoardFilterResponseBody } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { UpdateBoardFilterResponseBody };
export const useUpdateRemoteBoardFilter = () => {
	const { formatMessage } = useIntl();
	const isAdmin = useIsAdmin();

	const updateFilter = useCallback(
		({ filterId, body }: UpdateBoardFilterRequest) =>
			performPutRequest<UpdateBoardFilterResponseBody>(
				`${FILTER_API_PATH}/${filterId}${isAdmin ? '?overrideSharePermissions=true' : ''}`,
				{
					body: JSON.stringify(body),
				},
			),
		[isAdmin],
	);

	const {
		data,
		loading,
		error,
		hasError,
		fetch: commit,
		resetError,
	} = useServiceWithAnalytics(
		updateFilter,
		{},
		{
			actionSubject: UPDATE_GENERAL_BOARD_FILTER_ACTION_SUBJECT,
			packageName: APP_METRIC_KEY,
		},
		{
			browserMetricsKey: `${APP_METRIC_KEY}.${UPDATE_GENERAL_BOARD_FILTER_METRICS_KEY}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		error,
		errorTitle: formatMessage(messages.errorFlag),
	});

	return {
		data,
		loading,
		error,
		hasError,
		commit,
		resetError,
	};
};
