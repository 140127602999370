import { useJiraUserBoardNavigationResource } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/jsw-user-board-navigation-resource/index.tsx';
import { useJiraSoftwareProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { useIsUserLocatedBoard } from '../use-is-user-located-board/index.tsx';

export const useBoardAndLocationContext = () => {
	const isUserLocated = useIsUserLocatedBoard();
	// This hook returns project data + board info for project-located boards
	const { data: projectData, error: projectError } = useJiraSoftwareProjectNavigation();
	// This hook returns user-located board data
	const { data: userData, error: userError } = useJiraUserBoardNavigationResource();

	return {
		project: projectData?.project,
		user: userData?.owner,
		board: isUserLocated ? userData?.boardInfo : projectData?.boardInfo,
		error: isUserLocated ? userError : projectError,
	};
};
