import { useCallback, useMemo, useState } from 'react';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldMode } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import { useServiceWithAnalytics } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-service-with-analytics/index.tsx';
import { useErrorFlagOnUpdateFailure } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/flags/use-error-flag-on-request-failure/index.tsx';
import {
	ADD_CARD_LAYOUT_FIELD_ACTION_SUBJECT,
	ADD_CARD_LAYOUT_FIELD_PLAN_MODE_BROWSER_METRICS_KEY,
	ADD_CARD_LAYOUT_FIELD_WORK_MODE_BROWSER_METRICS_KEY,
} from '../../common/constants/analytics.tsx';
import { APP_CMP_METRIC_KEY } from '../../common/constants/app.tsx';
import { PLAN_MODE } from '../../common/constants/card-layout.tsx';
import { CARD_LAYOUT_API_URI } from '../../common/constants/url.tsx';
import messages from './messages.tsx';
import type { AddCardLayoutFieldResponse, AddCardLayoutFieldInput } from './types.tsx';

export const getAddUrl = (rapidViewId: number, mode: FieldMode) =>
	`${CARD_LAYOUT_API_URI}/${rapidViewId}/${mode}/field`;

export const useAddCardLayoutField = (
	rapidViewId: number,
	fieldMode: FieldMode,
): AddCardLayoutFieldResponse => {
	const { formatMessage } = useIntl();

	const [fieldToAddName, setFieldToAddName] = useState<string | null>(null);

	const addCardLayoutField = useCallback(
		({ fieldId, mode, name }: AddCardLayoutFieldInput) => {
			setFieldToAddName(name);
			return performPostRequest(getAddUrl(rapidViewId, mode), {
				body: JSON.stringify({ fieldId }),
			});
		},
		[rapidViewId],
	);

	const browserMetricsKey = useMemo(
		() =>
			fieldMode === PLAN_MODE
				? ADD_CARD_LAYOUT_FIELD_PLAN_MODE_BROWSER_METRICS_KEY
				: ADD_CARD_LAYOUT_FIELD_WORK_MODE_BROWSER_METRICS_KEY,
		[fieldMode],
	);

	const {
		error,
		hasError,
		fetch: commit,
		...rest
	} = useServiceWithAnalytics<AddCardLayoutFieldResponse['data'], AddCardLayoutFieldInput>(
		addCardLayoutField,
		{},
		{
			actionSubject: ADD_CARD_LAYOUT_FIELD_ACTION_SUBJECT,
			packageName: APP_CMP_METRIC_KEY,
			attributes: {
				fieldMode,
			},
		},
		{
			browserMetricsKey: `${APP_CMP_METRIC_KEY}.${browserMetricsKey}`,
		},
	);

	useErrorFlagOnUpdateFailure({
		hasFailure: hasError,
		error,
		errorTitle: formatMessage(messages.addCardLayoutFieldFailureTitle, {
			fieldName: fieldToAddName,
		}),
		errorMessage: formatMessage(messages.addCardLayoutFieldFailureDescription),
	});

	return { commit, error, hasError, ...rest };
};
