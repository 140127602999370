import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageTitle: {
		id: 'software-board-settings-general.general-settings-page.page-title',
		defaultMessage: 'General settings',
		description: 'Page title for general settings page',
	},
	pageDescription: {
		id: 'software-board-settings-general.general-settings-page.page-description',
		defaultMessage: 'Required fields are marked with an asterisk <strong>*</strong>',
		description: 'Page description for general settings page',
	},
});
