import React, { useCallback, useState } from 'react';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectSidebarNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { SuccessFlagWithTimelineLink } from '../../../common/ui/success-flag-with-timeline-link/index.tsx';
import { useDisableTimeline } from '../../../services/disable-timeline/index.tsx';
import { useEnableTimeline } from '../../../services/enable-timeline/index.tsx';
import { useIsCMPBoard, useRapidViewId } from '../../selectors/board/index.tsx';
import { useIsBoardCrossProject, useIsRoadmapEnabled } from '../../selectors/timeline/index.tsx';
import messages from './messages.tsx';

export const useUpdateTimelineHandler = () => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const [rapidViewId] = useRapidViewId();
	const [isCMPBoard] = useIsCMPBoard();
	const [isRoadmapEnabled, { updateIsRoadmapEnabled }] = useIsRoadmapEnabled();
	const [isBoardCrossProject] = useIsBoardCrossProject();

	const [isEnabled, setIsEnabled] = useState(isRoadmapEnabled);

	const { commit: commitEnableTimeline } = useEnableTimeline(rapidViewId);
	const { commit: commitDisableTimeline } = useDisableTimeline(rapidViewId);

	const { refresh: refreshSidebar } = useProjectSidebarNavigation();

	const enableTimeline = useCallback(async () => {
		const result = await commitEnableTimeline({
			crossProject: isBoardCrossProject,
			roadmapEnabled: true,
		});

		if (!result) {
			setIsEnabled(false);
			return;
		}

		refreshSidebar();

		updateIsRoadmapEnabled(true);
		showFlag((props) => (
			<SuccessFlagWithTimelineLink
				{...props}
				title={formatMessage(messages.enableTimelineSuccess)}
				isCMPBoard={isCMPBoard}
			/>
		));
	}, [
		commitEnableTimeline,
		formatMessage,
		isBoardCrossProject,
		isCMPBoard,
		refreshSidebar,
		showFlag,
		updateIsRoadmapEnabled,
	]);

	const disableTimeline = useCallback(async () => {
		const result = await commitDisableTimeline();

		if (!result.ok) {
			setIsEnabled(true);
			return;
		}

		refreshSidebar();

		updateIsRoadmapEnabled(false);
		showFlag((props) => (
			<SuccessFlagWithBoardLink
				{...props}
				title={formatMessage(messages.disableTimelineSuccess)}
				isCMPBoard={isCMPBoard}
			/>
		));
	}, [
		commitDisableTimeline,
		formatMessage,
		isCMPBoard,
		refreshSidebar,
		showFlag,
		updateIsRoadmapEnabled,
	]);

	const updateTimeline = useCallback(async () => {
		const nextState = !isEnabled;
		setIsEnabled(nextState);

		nextState ? await enableTimeline() : await disableTimeline();
	}, [disableTimeline, enableTimeline, isEnabled]);

	return { updateTimeline, isEnabled };
};
