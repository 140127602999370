/**
 * @generated SignedSource<<e127c2f0155ecac72a7cdb5122e471b6>>
 * @relayHash 7a5b6ddd11eaa683df7cf3b71904550a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ff3c573b8a3e5cfcc2627051f8ace5da8c2aeeafdbfaf869298ceffd40dd87fe

import type { ConcreteRequest, Query } from 'relay-runtime';
export type BoardFeatureStatus = "COMING_SOON" | "DISABLED" | "ENABLED" | "%future added value";
export type timelineSettingsPageQuery$variables = {
  boardId: string;
};
export type timelineSettingsPageQuery$data = {
  readonly boardScope: {
    readonly currentUser: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
    readonly features: ReadonlyArray<{
      readonly key: AGG$SoftwareBoardFeatureKey;
      readonly status: BoardFeatureStatus;
    } | null | undefined>;
    readonly isCrossProject: boolean;
    readonly roadmapConfig: {
      readonly isChildIssuePlanningEnabled: boolean;
      readonly isRoadmapEnabled: boolean;
      readonly prefersChildIssueDatePlanning: boolean;
    };
    readonly savedFilterConfig: {
      readonly id: string;
    };
  };
};
export type timelineSettingsPageQuery = {
  response: timelineSettingsPageQuery$data;
  variables: timelineSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  {
    "kind": "Literal",
    "name": "isCMP",
    "value": true
  }
],
v2 = {
  "concreteType": "CurrentUser",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "permissions"
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "name": "key"
},
v4 = {
  "kind": "ScalarField",
  "name": "status"
},
v5 = {
  "kind": "ScalarField",
  "name": "isCrossProject"
},
v6 = {
  "kind": "ScalarField",
  "name": "isRoadmapEnabled"
},
v7 = {
  "kind": "ScalarField",
  "name": "isChildIssuePlanningEnabled"
},
v8 = {
  "kind": "ScalarField",
  "name": "prefersChildIssueDatePlanning"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "timelineSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v1/*: any*/),
          "concreteType": "BoardScope",
          "kind": "LinkedField",
          "name": "boardScope",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "BoardFeature",
                "kind": "LinkedField",
                "name": "features",
                "plural": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v3/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.key"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v4/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.status"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.features"
            },
            {
              "kind": "RequiredField",
              "field": (v5/*: any*/),
              "action": "THROW",
              "path": "boardScope.isCrossProject"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JswBoardScopeRoadmapConfig",
                "kind": "LinkedField",
                "name": "roadmapConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v6/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.roadmapConfig.isRoadmapEnabled"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v7/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.roadmapConfig.isChildIssuePlanningEnabled"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v8/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.roadmapConfig.prefersChildIssueDatePlanning"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.roadmapConfig"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JswSavedFilterConfig",
                "kind": "LinkedField",
                "name": "savedFilterConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v9/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.savedFilterConfig.id"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.savedFilterConfig"
            }
          ]
        },
        "action": "THROW",
        "path": "boardScope"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "timelineSettingsPageQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "BoardFeature",
            "kind": "LinkedField",
            "name": "features",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          },
          (v5/*: any*/),
          {
            "concreteType": "JswBoardScopeRoadmapConfig",
            "kind": "LinkedField",
            "name": "roadmapConfig",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          },
          {
            "concreteType": "JswSavedFilterConfig",
            "kind": "LinkedField",
            "name": "savedFilterConfig",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ]
          },
          (v9/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "ff3c573b8a3e5cfcc2627051f8ace5da8c2aeeafdbfaf869298ceffd40dd87fe",
    "metadata": {},
    "name": "timelineSettingsPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "41f0911733ef24cda4cf7550395834d5";

export default node;
