import { createHook, createStateHook } from '@atlassian/react-sweet-state';
import { Store } from '../../main.tsx';

export const useBoardName = createStateHook(Store, {
	selector: ({ name }) => name,
});

export const useBoardAdmins = createStateHook(Store, {
	selector: ({ boardAdmins }) => boardAdmins,
});

export const useBoardFilterConfig = createHook(Store, {
	selector: ({ filterConfig }) => filterConfig,
});

export const useBoardSubFilterConfig = createHook(Store, {
	selector: ({ subqueryConfig }) => subqueryConfig,
});

export const useBoardLocation = createHook(Store, {
	selector: ({ boardLocationModel }) => boardLocationModel,
});
export const useOldDoneIssuesCutoff = createHook(Store, {
	selector: ({ oldDoneIssuesCutoff }) => oldDoneIssuesCutoff,
});

export const useOldDoneIssuesCutoffOptions = createHook(Store, {
	selector: ({ oldDoneIssuesCutoffOptions }) => oldDoneIssuesCutoffOptions,
});
