import type { OperationData } from '@atlassian/jira-shareable-entity-dialog/src/model/types.tsx';
import type { FilterConfig } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/filter.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const updateSelectedBoardFilter =
	(filterConfig: FilterConfig) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const currentState = getState();
		setState({
			...currentState,
			filterConfig,
		});
	};

export const updateBoardFilter =
	(filterConfig: FilterConfig, update: OperationData) =>
	({ getState, setState }: StoreActionApi<State>) => {
		setState({
			...getState(),
			filterConfig: {
				...filterConfig,
				name: update.name,
				description: update.description,
				sharePermissionEntries: update.sharePermissions,
				editPermissionEntries: update.editPermissions,
			},
		});
	};

export type UpdateSelectedBoardFilter = typeof updateSelectedBoardFilter;
export type UpdateBoardFilterPermissions = typeof updateBoardFilter;
