import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Group } from '../model/index.tsx';
import {
	getAllGroups as getAllGroupsRest,
	getUserGroups as getUserGroupsRest,
} from '../rest/index.tsx';
import { filterUserGroups, transformAllGroups, transformUserGroups } from './transformers.tsx';

export const getAllGroups = (
	baseUrl: string,
	searchQuery?: string,
	maxResults?: number,
): Promise<Group[]> => getAllGroupsRest(baseUrl, searchQuery, maxResults).then(transformAllGroups);

export const getUserGroups = (
	baseUrl: string,
	accountId?: AccountId,
	searchQuery?: string,
): Promise<Group[]> => {
	if (!accountId) return Promise.resolve([]);

	return getUserGroupsRest(baseUrl, accountId).then((response) => {
		const filteredGroups = filterUserGroups(response, searchQuery);
		return transformUserGroups(filteredGroups);
	});
};
