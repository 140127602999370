import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { Field } from '@atlaskit/form';
import type { ExternalError } from '@atlaskit/jql-editor';
import TextField from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import JQLBuilderAdvanced from '@atlassian/jira-jql-builder-advanced/src/async.tsx';
import { dataTestIds } from '../../../constants/data-test.tsx';
import messages from './messages.tsx';

export interface QueryFieldProps {
	name: string;
	defaultValue?: string;
	analyticsSource: string;
	queryValidationErrorMessage?: string;
}

export interface QueryFieldValue {
	builderErrors?: { message: string }[];
	jql: string;
}

const ErrorKind = {
	Required: 'REQUIRED',
	InvalidJQL: 'INVALID_JQL',
};

const createError = (message: string) => ({
	type: 'error' as const,
	message,
});

const validate = (state: QueryFieldValue | undefined): string | undefined => {
	if (!state) return ErrorKind.Required;
	const sanitisedJql = (state.jql ?? '').trim();
	if (sanitisedJql === '') return ErrorKind.Required;
	if (state.builderErrors?.length) return ErrorKind.InvalidJQL;
	return undefined;
};

export const QueryField = ({
	analyticsSource,
	queryValidationErrorMessage,
	...props
}: QueryFieldProps) => {
	const { formatMessage } = useIntl();

	const defaultValue = useMemo<QueryFieldValue>(
		() => ({ jql: props.defaultValue ?? '', buildErrors: [] }),
		[props.defaultValue],
	);

	const getJqlErrors = (
		{ builderErrors }: QueryFieldValue,
		error: string,
	): ExternalError[] | undefined => {
		if (error === ErrorKind.Required) {
			return [createError(formatMessage(messages.requiredError))];
		}
		if (error === ErrorKind.InvalidJQL) {
			return builderErrors?.map((e) => createError(e.message));
		}
		if (Array.isArray(error) && error.length) {
			return error.map(createError);
		}
		return [];
	};

	return (
		<Field
			{...props}
			label={formatMessage(messages.query)}
			isRequired
			defaultValue={defaultValue}
			validate={validate}
			testId={dataTestIds.modalQueryField}
		>
			{({ fieldProps, error, valid }) => {
				let jqlMessages: ExternalError[] | undefined;

				if (queryValidationErrorMessage) {
					jqlMessages = [createError(queryValidationErrorMessage)];
				} else {
					jqlMessages = !!error && !valid ? getJqlErrors(fieldProps.value, error) : undefined;
				}

				if (fieldProps.isDisabled) {
					return <TextField {...fieldProps} value={fieldProps.value.jql} />;
				}

				return (
					<Wrapper hasErrors={messages != null}>
						<JQLBuilderAdvanced
							analyticsSource={analyticsSource}
							query={fieldProps.value.jql}
							jqlMessages={jqlMessages}
							onUpdate={(jql, { errors }) => {
								fieldProps.onChange({ jql, builderErrors: errors });
							}}
						/>
					</Wrapper>
				);
			}}
		</Field>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.span<{ hasErrors: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > div > div:nth-of-type(2)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ hasErrors }) => (hasErrors ? 'unset' : 'none'),
	},
});
