import { useCallback, useState } from 'react';
import type { CardColorStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import { useUpdateCardColorStrategy } from '../../../services/update-card-color-strategy/index.tsx';
import { useRapidViewId } from '../../selectors/board/index.tsx';
import { useCardColorStrategy, useNextCardColorStrategy } from '../../selectors/colors/index.tsx';
import { useFetchStrategyConfigHandler } from '../use-fetch-strategy-handler/index.tsx';

export const useUpdateCardColorStrategyHandler = () => {
	const [rapidViewId] = useRapidViewId();
	const [, { setNextCardColorStrategy }] = useNextCardColorStrategy();
	const [, { setCardColorStrategy }] = useCardColorStrategy();

	/**
	 * We can't use the loading states from the service calls for UI loading states since:
	 *
	 * - there's a slight delay between the first strategy update finishing, and the second card colors
	 * fetch starting. This results in a brief flicker in the UI where it enters a non-loading state
	 * - the service calls finish before updating the strategy in sweet-state, so there's a brief flicker
	 * where the UI shows the old strategy + content before sweet-state is updated
	 */
	const [isLoading, setIsLoading] = useState(false);

	const {
		commit: commitCardColorStrategy,
		hasError,
		...other
	} = useUpdateCardColorStrategy(rapidViewId);
	const { handleFetchStrategyConfig: handleFetchStrategyContent } = useFetchStrategyConfigHandler();

	const handleStrategyUpdate = useCallback(
		async (cardColorStrategy: CardColorStrategy) => {
			setIsLoading(true);
			setNextCardColorStrategy(cardColorStrategy);

			const result = await commitCardColorStrategy({ cardColorStrategy });
			if (result.ok) {
				await handleFetchStrategyContent(cardColorStrategy);
				setCardColorStrategy(cardColorStrategy);
			}

			setNextCardColorStrategy(null);
			setIsLoading(false);
		},
		[
			commitCardColorStrategy,
			handleFetchStrategyContent,
			setCardColorStrategy,
			setNextCardColorStrategy,
		],
	);

	return {
		...other,
		loading: isLoading,
		hasError,
		handleStrategyUpdate,
	};
};
