import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addCardColorModalTitle: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.query-strategy.add-card-color-modal.add-card-color-modal-title',
		defaultMessage: 'Add card color',
		description: 'Title for the add card color modal',
	},
	addCardColorModalGuidelines: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.query-strategy.add-card-color-modal.add-card-color-modal-guidelines',
		defaultMessage: 'Required fields are marked with an asterisk',
		description:
			'This is the guidelines shown on the top of the modal dialog for creating a card color',
	},
	createCardColorSuccess: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.query-strategy.add-card-color-modal.create-card-color-success',
		defaultMessage: "You've created a card color",
		description:
			'Title message for the success flag which appears when creating a card color operation is successful',
	},
});
