/**
 * @generated SignedSource<<fb9a9c2e5831f29a3067cd258c2a86ec>>
 * @relayHash d4612deeee8027f3d08b69df2f02c901
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a05648719b45f795c4bc2d1fa9e62ffd0055d29250116e9c2e9bd29d349e4066

import type { ConcreteRequest, Query } from 'relay-runtime';
export type BoardSwimlaneStrategy = "ASSIGNEE_UNASSIGNED_FIRST" | "ASSIGNEE_UNASSIGNED_LAST" | "CUSTOM" | "EPIC" | "ISSUE_CHILDREN" | "ISSUE_PARENT" | "NONE" | "PARENT_CHILD" | "PROJECT" | "REQUEST_TYPE" | "%future added value";
export type swimlanesSettingsPageQuery$variables = {
  boardId: string;
  cloudId: string;
};
export type swimlanesSettingsPageQuery$data = {
  readonly boardScope: {
    readonly board: {
      readonly boardSwimlaneStrategy: BoardSwimlaneStrategy;
      readonly customSwimlaneConfig: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly description: string;
            readonly id: string;
            readonly isDefault: boolean;
            readonly name: string;
            readonly query: string;
          };
        } | null | undefined>;
      };
    };
    readonly currentUser: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
  };
  readonly jira: {
    readonly issueHierarchyConfig: {
      readonly data: ReadonlyArray<{
        readonly hierarchyLevel: {
          readonly level: number;
          readonly name: string;
        };
      }>;
    };
  };
};
export type swimlanesSettingsPageQuery = {
  response: swimlanesSettingsPageQuery$data;
  variables: swimlanesSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "level"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  {
    "kind": "Literal",
    "name": "isCMP",
    "value": true
  }
],
v5 = {
  "concreteType": "CurrentUser",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "permissions"
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "name": "boardSwimlaneStrategy"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = {
  "kind": "ScalarField",
  "name": "description"
},
v9 = {
  "kind": "ScalarField",
  "name": "isDefault"
},
v10 = {
  "kind": "ScalarField",
  "name": "query"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "swimlanesSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "concreteType": "JiraIssueHierarchyConfigurationQuery",
                "kind": "LinkedField",
                "name": "issueHierarchyConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JiraIssueHierarchyConfigData",
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JiraIssueTypeHierarchyLevel",
                            "kind": "LinkedField",
                            "name": "hierarchyLevel",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v2/*: any*/),
                                "action": "THROW",
                                "path": "jira.issueHierarchyConfig.data.hierarchyLevel.level"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v3/*: any*/),
                                "action": "THROW",
                                "path": "jira.issueHierarchyConfig.data.hierarchyLevel.name"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "jira.issueHierarchyConfig.data.hierarchyLevel"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.issueHierarchyConfig.data"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.issueHierarchyConfig"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      },
      {
        "kind": "RequiredField",
        "field": {
          "args": (v4/*: any*/),
          "concreteType": "BoardScope",
          "kind": "LinkedField",
          "name": "boardScope",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "SoftwareBoard",
                "kind": "LinkedField",
                "name": "board",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v6/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.board.boardSwimlaneStrategy"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JswCustomSwimlaneConnection",
                      "kind": "LinkedField",
                      "name": "customSwimlaneConfig",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JswCustomSwimlaneEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "JswCustomSwimlane",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v7/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.customSwimlaneConfig.edges.node.id"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v3/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.customSwimlaneConfig.edges.node.name"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v8/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.customSwimlaneConfig.edges.node.description"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v9/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.customSwimlaneConfig.edges.node.isDefault"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v10/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.customSwimlaneConfig.edges.node.query"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "boardScope.board.customSwimlaneConfig.edges.node"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.board.customSwimlaneConfig.edges"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.board.customSwimlaneConfig"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.board"
            }
          ]
        },
        "action": "THROW",
        "path": "boardScope"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "swimlanesSettingsPageQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssueHierarchyConfigurationQuery",
            "kind": "LinkedField",
            "name": "issueHierarchyConfig",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueHierarchyConfigData",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeHierarchyLevel",
                    "kind": "LinkedField",
                    "name": "hierarchyLevel",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "args": (v4/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "concreteType": "SoftwareBoard",
            "kind": "LinkedField",
            "name": "board",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "concreteType": "JswCustomSwimlaneConnection",
                "kind": "LinkedField",
                "name": "customSwimlaneConfig",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JswCustomSwimlaneEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JswCustomSwimlane",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v3/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v7/*: any*/)
            ]
          },
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "a05648719b45f795c4bc2d1fa9e62ffd0055d29250116e9c2e9bd29d349e4066",
    "metadata": {},
    "name": "swimlanesSettingsPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ce2e7dadaeddbee5fdbeabe2bf6be528";

export default node;
