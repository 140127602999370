import { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	PUBLIC_SHARE,
	OPEN_SHARE,
	GROUP_SHARE,
	PROJECT_SHARE,
	PROJECT_UNKNOWN_SHARE,
	USER_SHARE,
	type SharePermission,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import { useBoardFilterConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import { unreachable } from '../../../../../common/types.tsx';
import messages from './messages.tsx';
import {
	type Avatar,
	AVATAR_GROUP,
	AVATAR_GLOBAL,
	AVATAR_LOGGEDIN,
	AVATAR_UNKNOWN,
	AVATAR_URL,
} from './types.tsx';

export const useLabelAndAvatar = (
	permission: SharePermission,
):
	| {
			label: string;
			avatar: Avatar;
			isOwner?: boolean;
	  }
	| undefined => {
	const { formatMessage } = useIntl();
	const [{ owner }] = useBoardFilterConfig();

	return useMemo(() => {
		switch (permission.type) {
			case USER_SHARE:
				return {
					avatar: {
						type: AVATAR_URL,
						circle: true,
						url: permission.user.avatarUrl,
					},
					label: formatMessage(messages.userPermission, {
						displayName: permission.user.displayName,
					}),
					isOwner: owner && owner.accountId === permission.user.accountId,
				};

			case GROUP_SHARE:
				return {
					avatar: { type: AVATAR_GROUP },
					label: formatMessage(messages.groupPermission, {
						groupName: permission.group.name,
					}),
				};

			case PROJECT_SHARE: {
				const message = permission.role
					? messages.projectWithRolePermission
					: messages.projectPermission;

				return {
					avatar: {
						type: AVATAR_URL,
						circle: false,
						url: permission.project.avatarUrl,
					},
					label: formatMessage(message, {
						projectName: permission.project.name,
						projectRole: permission.role?.name,
					}),
				};
			}

			case PROJECT_UNKNOWN_SHARE:
				return {
					avatar: { type: AVATAR_UNKNOWN },
					label: formatMessage(messages.unknownProjectPermission),
				};

			case OPEN_SHARE:
				return {
					avatar: { type: AVATAR_LOGGEDIN },
					label: formatMessage(messages.myOrganizationPermission),
				};

			case PUBLIC_SHARE:
				return {
					avatar: { type: AVATAR_GLOBAL },
					label: formatMessage(messages.publicPermission),
				};

			default:
				return unreachable(permission, undefined);
		}
	}, [permission, formatMessage, owner]);
};
