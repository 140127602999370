import {
  JiraSoftwareCardColorAriResourceOwner,
  JiraSoftwareCardColorAriResourceType
} from "./chunk-OWEX3L44.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira-software/card-color/manifest.ts
var jiraSoftwareCardColorAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraSoftwareCardColorAriResourceOwner,
  resourceType: JiraSoftwareCardColorAriResourceType,
  resourceIdSlug: "activation/{activationId}/{boardId}/{cardColorId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    boardId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/,
    // eslint-disable-line no-useless-escape
    cardColorId: /[0-9]+/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira-software/card-color/index.ts
var JiraSoftwareCardColorAri = class _JiraSoftwareCardColorAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._boardId = opts.resourceIdSegmentValues.boardId, this._cardColorId = opts.resourceIdSegmentValues.cardColorId;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get boardId() {
    return this._boardId;
  }
  get cardColorId() {
    return this._cardColorId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraSoftwareCardColorAriStaticOpts.qualifier,
      platformQualifier: jiraSoftwareCardColorAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraSoftwareCardColorAriStaticOpts.resourceOwner,
      resourceType: jiraSoftwareCardColorAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.boardId}/${opts.cardColorId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        boardId: opts.boardId,
        cardColorId: opts.cardColorId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraSoftwareCardColorAriStaticOpts);
    return new _JiraSoftwareCardColorAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraSoftwareCardColorAriStaticOpts);
    return new _JiraSoftwareCardColorAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      boardId: this.boardId,
      cardColorId: this.cardColorId
    };
  }
};

export {
  JiraSoftwareCardColorAri
};
