import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	disabledSectionDescription: {
		id: 'software-board-settings-rapidboard-config-common.config-disabled-section.disabled-section-description',
		defaultMessage: 'To configure settings on this page, you must be a project or board admin.',
		description: 'Description explains the requirements for configuring the settings',
	},
	disabledSectionLearnMore: {
		id: 'software-board-settings-rapidboard-config-common.config-disabled-section.disabled-section-learn-more',
		defaultMessage: 'Learn more about permissions',
		description: 'Text for link the redirects user to learn more about permissions',
	},
});
