import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	failedToEditErrorTitle: {
		id: 'software-board-settings-card-colors.services.edit-card-color.failed-to-edit-error-title',
		defaultMessage: "We couldn't save your changes",
		description: 'Title of error flag when failed to edit a card color.',
	},
	failedToEditErrorDescription: {
		id: 'software-board-settings-card-colors.services.edit-card-color.failed-to-edit-error-description',
		defaultMessage:
			"Try to save your changes again. If it doesn't work, you can refresh the page, but your changes will be lost.",
		description: 'Description of error flag when failed to edit a card color.',
	},
});
