import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.assignee-strategy.title',
		defaultMessage: 'Unassigned issues',
		description: 'Create swimlane table description',
	},
	description: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.assignee-strategy.description',
		defaultMessage: 'Choose where unassigned issues will be shown on your board.',
		description: 'Button label for creating a swimlane',
	},
	showBelow: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.assignee-strategy.show-below',
		defaultMessage: 'Show below other swimlanes',
		description: 'label on radio button for placing assignee swimlanes below other swimlanes',
	},
	showAbove: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.assignee-strategy.show-above',
		defaultMessage: 'Show above other swimlanes',
		description: 'label on radio button for placing assignee swimlanes above other swimlanes',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.assignee-strategy.description-issue-term-refresh',
		defaultMessage: 'Choose where unassigned work will be shown on your board.',
		description: 'Button label for creating a swimlane',
	},
	titleIssueTermRefresh: {
		id: 'software-board-settings-swimlanes.swimlanes-settings-page.strategy-content.assignee-strategy.title-issue-term-refresh',
		defaultMessage: 'Unassigned work items',
		description: 'Create swimlane table description',
	},
});
