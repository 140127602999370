import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const removeEditAccess =
	() =>
	({ getState, setState }: StoreActionApi<State>) => {
		const currentState = getState();
		setState({
			...currentState,
			canEdit: false,
		});
	};

export type RemoveEditAccess = typeof removeEditAccess;
