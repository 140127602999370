/**
 * @generated SignedSource<<7a266eb92d63f94b440306e3ca8914f2>>
 * @relayHash 26b3ab0fa5649a1d27417697e967ad5d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 870b941b2db3a538fdee40a3d6fa44cfcfd9a376d886b1757f7449bd82f49bff

import type { ConcreteRequest, Query } from 'relay-runtime';
export type quickFilters_RootQuery$variables = {
  boardId: string;
  isCMP: boolean;
  isSimplifiedBoardsM2Enabled: boolean;
};
export type quickFilters_RootQuery$data = {
  readonly boardScope: {
    readonly __id: string;
    readonly currentUser?: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
    readonly customFilters: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly filterQuery: {
        readonly sanitisedJql: string;
      } | null | undefined;
      readonly id: string;
      readonly name: string;
    } | null | undefined>;
  };
};
export type quickFilters_RootQuery = {
  response: quickFilters_RootQuery$data;
  variables: quickFilters_RootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isCMP"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSimplifiedBoardsM2Enabled"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  {
    "kind": "Variable",
    "name": "isCMP",
    "variableName": "isCMP"
  }
],
v2 = {
  "condition": "isSimplifiedBoardsM2Enabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "permissions"
        }
      ]
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "concreteType": "CustomFilter",
  "kind": "LinkedField",
  "name": "customFilters",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "concreteType": "FilterQuery",
      "kind": "LinkedField",
      "name": "filterQuery",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "sanitisedJql"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "description"
    }
  ]
},
v5 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "quickFilters_RootQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v1/*: any*/),
          "concreteType": "BoardScope",
          "kind": "LinkedField",
          "name": "boardScope",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "RequiredField",
              "field": (v4/*: any*/),
              "action": "THROW",
              "path": "boardScope.customFilters"
            },
            (v5/*: any*/)
          ]
        },
        "action": "THROW",
        "path": "boardScope"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "quickFilters_RootQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "870b941b2db3a538fdee40a3d6fa44cfcfd9a376d886b1757f7449bd82f49bff",
    "metadata": {},
    "name": "quickFilters_RootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2ee11af30599b056385908005692cd94";

export default node;
