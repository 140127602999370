import React, { useCallback } from 'react';
import { ErrorMessage, Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../../constants/data-test.tsx';
import messages from './messages.tsx';

const MAX_DESC_LENGTH = 255;

type DescriptionFieldProps = {
	name: string;
	defaultValue?: string;
};

export const DescriptionField = (props: DescriptionFieldProps) => {
	const { formatMessage } = useIntl();

	const validate = useCallback(
		(value: string | undefined) => {
			if (value !== undefined && value.length > MAX_DESC_LENGTH) {
				return formatMessage(messages.descriptionLengthValidation);
			}
			return undefined;
		},
		[formatMessage],
	);

	return (
		<Field
			{...props}
			validate={validate}
			label={formatMessage(messages.description)}
			testId={dataTestIds.modalDescriptionField}
		>
			{({ fieldProps, error }) => (
				<>
					<TextField {...fieldProps} autoComplete="off" />
					{error && <ErrorMessage>{error}</ErrorMessage>}
				</>
			)}
		</Field>
	);
};
