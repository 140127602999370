import { createHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const useCardLayoutStoreActions = createHook(Store, {
	selector: null,
});

export const getCurrentFields = (state: State) => state.currentFields;

export const useCurrentFields = createHook(Store, {
	selector: getCurrentFields,
});

export const getAvailableFields = (state: State) => state.availableFields;

export const useAvailableFields = createHook(Store, {
	selector: getAvailableFields,
});

export const getShowDaysInColumn = (state: State) => state.showDaysInColumn;

export const useShowDaysInColumn = createHook(Store, {
	selector: getShowDaysInColumn,
});
